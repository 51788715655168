import { FC } from 'react';
import { Button, Modal, Stack } from "react-bootstrap"

interface IDeleteModal {
  show?: boolean;
  handleClose?: any;
  imageUrl?: string;
  contentType?: string;
}

const ImageModal: FC<IDeleteModal> = ({
  show, handleClose, imageUrl,contentType
}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered={true}
      scrollable={true}
      size="sm"
      className="theme-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="fs-16 fw-semibold">Content Preview</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-2">

      {contentType === 'image' && <img src={imageUrl} alt="Preview" className="img-fluid" />}
        {contentType === 'audio' && (
          <audio controls autoPlay>
            <source src={imageUrl} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        )}
        {contentType === 'video' && (
          <video controls className="video-player" autoPlay>
            <source  src={imageUrl} type="video/mp4" />
            Your browser does not support the video element.
          </video>
        )}
        {!contentType && <img src={imageUrl} alt="Default Preview" className="img-fluid" />}
      </Modal.Body>
      <Modal.Footer className="pt-0 border-0">
        <Button variant="secondary" onClick={handleClose} className="text-dark fw-semibold fs-13 mw-90">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ImageModal;