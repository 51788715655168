import axios from "axios";
import { toast } from 'react-toastify';
import { NETWORK_ERROR } from "../validations/ValidationErrors";

axios.defaults.timeout = 60000;
axios.defaults.headers.common["device-type"] = "WEB";
axios.defaults.headers.common["is-debug"] = "0";
axios.defaults.headers.common["app-version"] = "1.0";
axios.defaults.headers.common["environment"] = "development";
axios.defaults.headers.common["locale-code"] = "en";

axios.defaults.headers.common["app-signature"] = "";
const handleSuccess = (response) => {};
const handleClientError = (response) => {};

const getRange = (code) => {
  if (code >= 200 && code < 300) {
    return "SUCCESS_RANGE";
  }
  if (code >= 400 && code < 500) {
    return "CLIENT_ERROR_RANGE";
  }
  if (code >= 500 && code < 600) {
    return "SERVER_ERROR_RANGE";
  }
  return "UNKNOWN";
};
const handleResponse = (response) => {
  const range = getRange(response.status);
  switch (range) {
    case "SUCCESS_RANGE":
      handleSuccess(response);
      break;
    case "CLIENT_ERROR_RANGE":
      handleClientError(response);
      break;
    case "SERVER_ERROR_RANGE":

      break;
    case "UNKNOWN":
      break;
    default:
      break;
  }
};

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL_ADMIN,
  responseType: "json",
});

export const axiosAuth = axios.create({
  baseURL: process.env.REACT_APP_API_URL_ADMIN,
  responseType: "json",
});

axiosInstance.interceptors.response.use(
  (response) => {
    handleResponse(response);
    return Promise.resolve(response);
  },
  (error) => {
    console.log(error.response, "error.response");
    handleResponse(error.response);
    return Promise.reject(error);
  }
);

axiosAuth.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
  if(error.code === "ERR_NETWORK")
  {
    toast.error(NETWORK_ERROR);
  }
  return Promise.reject(error);
  }
);

axiosAuth.interceptors.request.use(function (config) {
  config.headers.Authorization = `Bearer ${localStorage.getItem(
    "admin-token"
  )}`;
  return config;
});
