import { Button, Col, Row, Stack } from "react-bootstrap"
import { Link, useParams, useNavigate } from "react-router-dom"
import { IoIosArrowBack } from "react-icons/io";
import { UserTabs } from "./UserTabs/UserTabs";
import DetailsCard from "./UserTabs/DetailsCard";
import { useEffect, useState } from "react";
import { getUserApiCall } from "../../../services/userService";
import Loader from '../../../components/common/Loader';
import { toast } from "react-toastify";
import ReleaseLegacyModal from "../../../components/common/ReleaseLegacyModal";
import storage from "../../../helpers/storage";


export const ViewUser = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState<any>();
  const [plan, setPlan] = useState<any>();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showReleaseLegacy, setShowReleaseLegacy] = useState(false);
  useEffect(() => {
    setLoading(true);
    if(id)
    {
    getUserApiCall({'id':id}).then(
      (resp) => {
        setLoading(false);
        let res = resp?.data[0];
        let planData = resp?.data[1];
        setUser(res);
        setPlan(planData);
        setShowReleaseLegacy(res.show_legacy_release_button);
        storage.setReleaseLegacy(res.legacy_released);
          // navigate(adminRoutes.home.path);
      },
      (error:any) => {
        setLoading(false);
        const err = error?.response?.data;
        if (err?.statusCode === 400) {
          toast.error(err.msg);
          //navigate(`/app/users`);
        }
      }
    );
  }
  }, [id]);
  const handleClose = () => {
    setShow(false);
  };
  return (
    <>
    {user ?
    <>
    <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
      <div className="pageHeader px-3 my-1 pt-1 user-select-none">
        <Row className="align-items-center min-h-51">
          <Col>
            <h1 className="fw-semibold h4 my-2">
              <Link to="/app/users/list" className="align-items-center d-inline-flex overflow-hidden text-dark text-decoration-none">
                <span className="me-1 ms-n1">
                  <IoIosArrowBack size="1.68rem" />
                </span> Users
              </Link>
            </h1>
          </Col>
          {showReleaseLegacy  ?
           <Col xs="auto">
            <Stack direction="horizontal">
              <Button variant="primary" className="my-2 fw-semibold fs-13 text-white" onClick={()=>{setShow(true)}}>Release Legacy</Button>
            </Stack>
          </Col>
:''}
 
        </Row>
      </div>
      <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
        <div className="d-flex flex-column h-100 detail-page-main">
          <DetailsCard controlId="users" redirectID="1" Data={user} Plan={plan}/>
          <UserTabs />
        </div>
      </div>
    </div>
    <Loader isLoading={loading} />
    <ReleaseLegacyModal show={show} handleClose={handleClose} title="Release Legacy" details={`Are you sure you want to release the legacy of ${user.name}?`}id={user.id} setShow={setShow} setShowReleaseLegacy={setShowReleaseLegacy}/>
    </>
    : ''}
    </>
    
  )
}
