// import { FC } from 'react';
// import { Button, Modal, Stack } from 'react-bootstrap';
// import CustomTextArea from '../../../components/common/CustomTextArea';

// interface IRejectModal {
//     show: any;
//     onHide: any;
// }

// /**
//  * Custom Reject Modal Component
//  * @date 4/20/2023 - 2:45:07 PM
//  *
//  * @param {{ show: any; onHide: any; }} {
//     show, onHide
// }
//  * @returns {*}
//  */

// export const RejectModal: FC<IRejectModal> = ({
//     show, onHide
// }) => {
//     return (
//         <Modal
//             show={show}
//             onHide={onHide}
//             backdrop="static"
//             keyboard={false}
//             centered={true}
//             scrollable={true}
//             size="sm"
//             className="theme-modal"
//         >
//             <Modal.Header closeButton>
//                 <Modal.Title className="fs-16 fw-semibold">Reject Proclamation</Modal.Title>
//             </Modal.Header>
//             <Modal.Body className="pb-2">
//                 <div className="fs-14 fw-semibold pb-2 mb-1">Are you sure you want to Reject the proclamation of John Simpson ?</div>
//                 <CustomTextArea
//                     controlId="reason"
//                     placeholder="Reason"
//                 />
//             </Modal.Body>
//             <Modal.Footer className="pt-0 border-0">
//                 <Stack direction="horizontal" gap={3} className="flex-wrap pb-2">
//                     <Button variant="secondary" onClick={onHide} className="text-dark fw-semibold fs-13 mw-90">
//                         Cancel
//                     </Button>
//                     <Button variant="danger" className="fw-semibold fs-13 text-white mw-90">Reject</Button>
//                 </Stack>
//             </Modal.Footer>
//         </Modal>
//     )
// }

import { FC, useState } from 'react';
import { Button, Form, Modal, Stack } from 'react-bootstrap';
import InputText from '../../../components/common/InputText';
import { FaCalendarDay } from 'react-icons/fa';
import { Formik } from 'formik';
import { LegacyReleaseSubmitSchema, proclamationApproveSchema, proclamationRejectSchema } from '../../../validations/LegacyRelease';
import { toast } from 'react-toastify';
import Loader from '../../../components/common/Loader';
import { useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import moment from 'moment';
import formatData from '../../../constants/formatData';
import "react-datepicker/dist/react-datepicker.css";
import { proclamationApprove } from '../../../services/PproclamationService';
import { EditEntitiyMethod } from '../../../helpers/helpersMethods';
import { CHARITY_UPDATED_SUCCESS } from '../../../validations/ValidationErrors';
import { nameBasedProtectedRoutes } from '../../../router/protected';
import CustomTextArea from '../../../components/common/CustomTextArea';
interface IRejectModal {
    show?: any;
    onHide?: any;
    details?:string;
    id?:any;
}

/**
 * Custom Approve Modal Component
 * @date 4/20/2023 - 1:36:02 PM
 *
 * @param {{ show: any; onHide: any; }} {
    show, onHide
}
 * @returns {*}
 */

export const RejectModal: FC<IRejectModal> = ({
    show, onHide,details,id
}) => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const submitForm = (value: any) => {
        toast.dismiss();
        EditEntitiyMethod(setLoading, proclamationApprove,id, value, CHARITY_UPDATED_SUCCESS, nameBasedProtectedRoutes.pendingProclamation.path, navigate);
    }
    return (
        <Modal
            show={show}
            onHide={onHide}
            backdrop="static"
            keyboard={false}
            centered={true}
            scrollable={false}
            size="sm"
            className="theme-modal"
        >
            <Formik
                    validationSchema={proclamationRejectSchema}
                    initialValues={{
                        expired_at: null,
                        status: 'rejected',
                        reason: ''
                    }}
                    onSubmit={(values, actions) => {
                        values.reason = values.reason.trim();
                        submitForm(values);
                    }}


                >
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        errors,
                        setFieldValue,
                    }) => (
            <>
            <Form className="" noValidate onSubmit={handleSubmit}>
            <Modal.Header closeButton>
                <Modal.Title className="fs-16 fw-semibold">Reject Proclamation</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pb-2">
                <div className="fs-14 fw-semibold pb-2 mb-1">{details}</div>
                <CustomTextArea
                                        controlId="reason"
                                        label=""
                                        placeholder="Reason"
                                        touched={touched.reason}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        errorsField={errors.reason}
                                        value={values.reason}
                                    />
                
                          
            </Modal.Body>
            <Modal.Footer className="pt-0 border-0">
                <Stack direction="horizontal" gap={3} className="flex-wrap pb-2">
                    <Button variant="secondary" onClick={onHide} className="text-dark fw-semibold fs-13 mw-90">
                        Cancel
                    </Button>
                    <Button variant="danger" className="fw-semibold fs-13 text-white mw-90" type="submit">Reject</Button>
                </Stack>
            </Modal.Footer>
            </Form>
            </>
             )}
             </Formik >
             <Loader isLoading={loading} />       
        </Modal>
    )
}