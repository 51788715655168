import * as yup from "yup";
import {
  CHAR_MAX_50, MAXIMUM_1000_CHAR, MINIMUM_1_CHAR, REQUIRED
} from "./ValidationErrors";

/**
 * LegacyReleaseSchema for Validation
 * @date 4/19/2023 - 9:26:15 AM
 *
 * @type {*}
 */

export const LegacyReleaseSchema = yup.object().shape({
  user: yup.string(),
  trustee: yup.string(),
  trusteeEmail: yup.string(),
  trusteePhone: yup.string(),
  dateTime: yup.string(),
  status: yup.string(),
  reason: yup.string().max(50, CHAR_MAX_50),
});
export const LegacyReleaseEditSchema = yup.object().shape({
  status: yup.string().required(REQUIRED),
  reason: yup.string().min(1, MINIMUM_1_CHAR).max(1000,MAXIMUM_1000_CHAR).required(REQUIRED),
});
export const LegacyReleaseSubmitSchema = yup.object().shape({
  reason: yup.string().min(1, MINIMUM_1_CHAR).max(1000,MAXIMUM_1000_CHAR).required(REQUIRED),
});
export const proclamationApproveSchema = yup.object().shape({
  expired_at: yup.string().required(REQUIRED),
});
export const proclamationRejectSchema = yup.object().shape({
  reason: yup.string().min(1, MINIMUM_1_CHAR).max(1000,MAXIMUM_1000_CHAR).required(REQUIRED),
});