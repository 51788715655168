import { useRoutes } from "react-router-dom";
import storage from "../helpers/storage";
import { TokenNotFound } from "../pages/NotFound/TokenNotFound";
import { protectedRoutes } from "./protected";
import { publicRoutes } from "./public";

/**
 *
 * @returns Routes Elements
 */
export default function AppRoutes() {
  const commonRoutes = [
    {
      path: "*",
      element: <TokenNotFound />,
    },
  ];
  const mainRoutes = storage.getToken() != null ? protectedRoutes : [];
  const element = useRoutes([...publicRoutes,...mainRoutes,...commonRoutes]);
  return <>{element}</>;
}

