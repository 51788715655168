import moment from 'moment';
import { FC } from 'react';
import Form from 'react-bootstrap/Form';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import formatData from '../../constants/formatData';
interface IDateModal {
    placeholder?: any;
    label?:any;
    selected?:any;
    onBlur?:any;
    error?:any;
    touched?:any;
    handleChange?:any;
    name?:String;
    setFieldValue?:any;
    value?:any;
    errorsField?:any;
    controlId?:any;
    allowPastDates?: boolean;
}
const FormWithoutTimeDatePicker: FC<IDateModal> = ({
    placeholder,label,handleChange,name,setFieldValue,value,errorsField,controlId,allowPastDates
}) => {
    const handleDateChangeRaw = (e:any) => {
        e.preventDefault();
    }
    const minDate = allowPastDates ? null : moment().toDate();
    return (
        <Form.Group className="mb-3" controlId={controlId}>
        {label ? (
            <Form.Label className="lh-sm">{label}</Form.Label>
        ) : null}
        <div className="position-relative">
        <DatePicker
                            onChange={(event:any)=>
                            {
                                setFieldValue(name,moment(event).format(formatData.dateFormats.databaseFormat))
                            }}
                            dateFormat={placeholder}
                            className="form-control"
                            placeholderText="MM/DD/YYYY"
                            value={value ? moment(value).format(formatData.dateFormats.commaSeparatedDateTime):''}
                            onChangeRaw={handleDateChangeRaw}
                            minDate={minDate}
                            
                          />
                <Form.Control.Feedback type="invalid" className={errorsField?"d-block":''}>
                    {errorsField}
                </Form.Control.Feedback>
          
        </div>
    </Form.Group>
    );
}


export default FormWithoutTimeDatePicker;
