import { axios } from "../services/axios";
import { adminApi } from "../api";
export type aboutMeDTO = {
  media_name: string;
  recipient_id: string;
};

/**
 * About us List
 * @date 04/05/2023 - 13:21:19
 *
 * @async
 * @param {*} params
 * @returns {unknown}
 */
export const getList = async (params: any) => {
  return await axios.get(`${adminApi.aboutMeList}/${params.userid}`, {
    params,
  });
};

/**
 * About us Get
 * @date 04/05/2023 - 17:54:51
 *
 * @async
 * @param {string} id
 * @returns {Promise<any>}
 */
export const getDetails = async (id: string): Promise<any> => {
  return axios.get(`${adminApi.aboutMeDetails}/${id}`);
};

/**
 * About us Edit
 * @date 04/05/2023 - 17:54:51
 *
 * @async
 * @param {string} id
 * @returns {Promise<any>}
 */
export const edit = async (id: string, data: aboutMeDTO): Promise<any> => {
  const config = { headers: { "content-type": "multipart/form-data" } };
  return axios.post(`${adminApi.aboutMeEdit}/${id}`, data, config);
};

/**
 * Delete
 * @date 27/05/2023 - 17:50:59
 *
 * @async
 * @param {string} id
 * @returns {Promise<any>}
 */
export const deleteAboutme = async (id: string): Promise<any> => {
  return axios.delete(`${adminApi.aboutMeDelete}/${id}`);
};
