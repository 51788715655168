import { Button, Card, Col, Form, Row, Stack } from "react-bootstrap"
import { IoIosArrowBack } from "react-icons/io"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Formik } from "formik"
import { TrusteeFrequencySchema } from "../../../../../../validations/Users"
import CustomRadio from "../../../../../../components/common/CustomRadio"
import CustomSelect from "../../../../../../components/common/CustomSelect"
import constantValues from "../../../../../../constants/constantValues"
import { useEffect, useState } from "react"
import { getRulesDetailsByUserId, updateExecuteLegacyRule } from "../../../../../../services/userService"
import { toast } from "react-toastify"
import Loader from "../../../../../../components/common/Loader"
import { EditEntitiyMethod } from "../../../../../../helpers/helpersMethods"
import { USER_UPDATED_SUCCESS } from "../../../../../../validations/ValidationErrors"

export const EditTrusteeFrequency = () => {

  const navigate = useNavigate();
  const [durationData, setDurationData] = useState<any>([]);
  const [selectRadio, setSelectRadio] = useState('daily');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();
  const [submitting, setSubmitting] = useState(false);
  const [redirect, setRedirect] = useState<any>();
  const { id } = useParams();

  
  const radioData = [
    { label: 'Hourly', value: 'hourly' },
    { label: 'Daily', value: 'daily' },
    { label: 'Weekly', value: 'weekly' },
    { label: 'Monthly', value: 'monthly' },
  ];
  useEffect(() => {
    setLoading(true);
    if(id)
    {
      getRulesDetailsByUserId({'user_id':id}).then(
        (resp) => {
          setTimeout(() => {
            setLoading(false);
        }, 1000);
          
          const res = resp?.data;
          setSelectRadio(res.check_on_period_type);
          setRedirect(`/app/users/view/${res.user_id}`);
          setData(res);
        },
        (error:any) => {
          setLoading(false);
          const err = error?.response?.data;
          if (err?.statusCode === 400) {
            toast.error(err.msg);
            navigate(`/app/users`);
          }
        }
      );
  }
  }, [id]);

  useEffect(()=>{
    if(selectRadio == 'daily')
    {
      setDurationData(constantValues.dailyData);
    }else if(selectRadio =='hourly')
    {
      setDurationData(constantValues.hoursData);
    }else if(selectRadio =='monthly')
    {
      setDurationData(constantValues.MonthData);
    }else
    {
      setDurationData(constantValues.weekData);
    }
  },[selectRadio]);

  const submitForm = (value: any) => {
    if (id && data) {
        EditEntitiyMethod(setSubmitting, updateExecuteLegacyRule, id, value, USER_UPDATED_SUCCESS, `/app/users/view/${id}`, navigate);
    }
  }
  return (
    <>
    {!loading && data && selectRadio ? <Formik
        validationSchema={TrusteeFrequencySchema}
        initialValues={{
          check_on_period: data ? data.check_on_period : '',
          check_on_period_type: data ? data.check_on_period_type: '',
      }}
      onSubmit={(values, actions) =>{
        actions.setSubmitting(false);
        submitForm(values)
   }}

      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          setFieldValue,
          touched,
          isValid,
          errors,
        }) => (
          <Form className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1" noValidate onSubmit={handleSubmit}>
            <div className="pageHeader px-3 my-1 pt-1 user-select-none">
              <Row className="align-items-center min-h-51">
                <Col>
                  <h1 className="fw-semibold h4 my-2">
                    <Link to={redirect} className="align-items-center d-inline-flex overflow-hidden text-dark text-decoration-none">
                      <span className="me-1 ms-n1">
                        <IoIosArrowBack size="1.68rem" />
                      </span> Users
                    </Link>
                  </h1>
                </Col>
              </Row>
            </div>
            <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
              <Card className="h-100 w-100 overflow-auto border-light-subtle pageContentInner">
                <Card.Header className="bg-white fs-16 fw-semibold p-3 border-secondary">
                  <Card.Text className="px-xl-1">Edit Rules to Execute Legacy</Card.Text>
                </Card.Header>
                <Card.Body className="p-xl-4">

                  <h6 className="fs-16 fw-bold mb-3 pb-1">After how much time do we need to contact Digital Trustees to check on you?</h6>
                  <CustomRadio
                    inline={true}
                    label="Select Frequency"
                    name="check_on_period_type"
                    touched={touched.check_on_period_type}
                    handleBlur={handleBlur}
                    handleChange={(e:any)=>{
                      handleChange(e);
                      setSelectRadio(e.target.value);
                      if(data.check_on_period_type != e.target.value)
                      {
                        setFieldValue('check_on_period', '');
                      }else
                      {
                        setFieldValue('check_on_period', data.check_on_period);
                      }
                    }}
                    errorsField={errors.check_on_period_type}
                    value={values.check_on_period_type}
                    data={radioData}
                  />                 

                  <div className="mw-300">
                    <CustomSelect
                      label="Select Duration"
                      controlId="check_on_period"
                      placeholder="Select"
                      touched={touched.check_on_period}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errorsField={errors.check_on_period}
                      value={values.check_on_period}
                      data={durationData}
                    />
                  </div>
                  <Stack direction="horizontal" gap={3} className="pt-2">
                    <Button className="fw-semibold fs-13 text-white mw-90 my-1" variant="primary" type="submit">Update</Button>
                    <Link to={redirect} className="btn btn-secondary text-dark fw-semibold fs-13 mw-90 my-1">Cancel</Link>
                  </Stack>

                </Card.Body>
              </Card>
            </div>
          </Form>
        )}
      </Formik >:''}
      <Loader isLoading={loading} />
    </>

  )
}
