import { Button, Card, Col, Form, Row, Stack } from "react-bootstrap"
import { IoIosArrowBack } from "react-icons/io"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Formik } from "formik"
import { SleepTimeSchema } from "../../../../../../validations/Users"
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState } from "react"
import FormDatePicker from "../../../../../../components/common/FormDatePicker"
import { clearSleepRule, getRulesDetailsByUserId, updateFrequencyRule, updateSleepRule } from "../../../../../../services/userService"
import { toast } from "react-toastify"
import { EditEntitiyMethod } from "../../../../../../helpers/helpersMethods"
import { USER_UPDATED_SUCCESS } from "../../../../../../validations/ValidationErrors"
import moment from "moment"
import Loader from "../../../../../../components/common/Loader"
import ClearModal from "../../../../../../components/common/ClearModal"
import FormTimePicker from "../../../../../../components/common/FormTimePicker"

export const EditSleepTime = () => {
  const navigate = useNavigate();
  const [redirect, setRedirect] = useState<any>();
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedEndTime, setSelectedEndTime] = useState(null);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();
  const [submitting, setSubmitting] = useState(false);
  const [show, setShow] = useState(false);
  const [deletestatus, setDeletestatus] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);  

  useEffect(() => {
    setLoading(true);
    if(id)
    {
      getRulesDetailsByUserId({'user_id':id}).then(
        (resp) => {
          setTimeout(() => {
            setLoading(false);
        }, 1000);
          const res = resp?.data;
          setRedirect(`/app/users/view/${res.user_id}`);
          setData(res);    
        },
        (error:any) => {
          setLoading(false);
          const err = error?.response?.data;
          if (err?.statusCode === 400) {
            toast.error(err.msg);
            navigate(`/app/users`);
          }
        }
      );
  }
  }, [id]);
//clear time entry
  useEffect(()=>{
    if(deletestatus)
    {
      setLoading(true);
      setShow(false);
      let value = {'sleep_start_time':'','sleep_end_time':''};
      if (id && data) {
          EditEntitiyMethod(setLoading, clearSleepRule, id, value, USER_UPDATED_SUCCESS, `/app/users/view/${id}`, navigate);
      }
    }
   },[deletestatus]);

  const submitForm = (value: any,setErrors: any) => {
    if (id && data) {
      let startTime = value.sleep_start_time.valueOf();
      let endTime = value.sleep_end_time.valueOf();
      // if(endTime < startTime || startTime > endTime)
      // {
      //   setErrors({
      //     sleep_end_time: 'End time must be greater than start time'
      //   });
      // }else
      // {
      //    EditEntitiyMethod(setSubmitting, updateSleepRule, id, value, USER_UPDATED_SUCCESS, `/app/users/view/${id}`, navigate);
      // }
      EditEntitiyMethod(setLoading, updateSleepRule, id, value, USER_UPDATED_SUCCESS, `/app/users/view/${id}`, navigate);

       
    }
  }
  return (
    <>
     {data && redirect ? 
      <Formik
        validationSchema={SleepTimeSchema}
        initialValues={{
          sleep_start_time: data && data.sleep_start_time != null ? data.sleep_start_time : '',
          sleep_end_time: data && data.sleep_end_time != null? data.sleep_end_time : '',
      }}
        onSubmit={(values, actions) =>{
          actions.setSubmitting(false);
          submitForm(values,actions.setErrors)
     }}
     onReset={(values, actions) =>
      {
        values.sleep_start_time = '';
        values.sleep_end_time = '';
      }}
     
      >
        
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
          setFieldValue,
          handleReset
        }) => (
          <Form className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1" noValidate onSubmit={handleSubmit}>
            <div className="pageHeader px-3 my-1 pt-1 user-select-none">
              <Row className="align-items-center min-h-51">
                <Col>
                  <h1 className="fw-semibold h4 my-2">
                    <Link to={redirect} className="align-items-center d-inline-flex overflow-hidden text-dark text-decoration-none">
                      <span className="me-1 ms-n1">
                        <IoIosArrowBack size="1.68rem" />
                      </span> Users
                    </Link>
                  </h1>
                </Col>
              </Row>
            </div>
            <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
              <Card className="h-100 w-100 overflow-auto border-light-subtle pageContentInner">
                <Card.Header className="bg-white fs-16 fw-semibold p-3 border-secondary">
                  <Card.Text className="px-xl-1">Edit Rules to Execute Legacy</Card.Text>
                </Card.Header>
                <Card.Body className="p-xl-4">

                  <h6 className="fs-16 fw-bold mb-3 pb-1">Sleep Time</h6>
                  <div className="mw-200">
                       <FormTimePicker
                              controlId="sleep_start_time"
                              name="sleep_start_time"
                              placeholder="hh:mm A"
                              label=""
                              selected={values.sleep_start_time}
                              onBlur={handleBlur}
                              error={errors.sleep_start_time}
                              touched={touched.sleep_start_time}
                              handleChange={handleChange}
                              setFieldValue={setFieldValue}
                              value={values.sleep_start_time ? values.sleep_start_time : ''}
                              errorsField={errors.sleep_start_time}
                            />
                       <FormTimePicker
                              controlId="sleep_end_time"
                              name="sleep_end_time"
                              placeholder="hh:mm A"
                              label=""
                              selected={values.sleep_end_time}
                              onBlur={handleBlur}
                              error={errors.sleep_end_time}
                              touched={touched.sleep_end_time}
                              handleChange={handleChange}
                              setFieldValue={setFieldValue}
                              value={values.sleep_end_time ? values.sleep_end_time : ''}
                              errorsField={errors.sleep_end_time}
                            />
                    
                  </div>
                  <Stack direction="horizontal" gap={3} className="pt-2 mt-1 flex-wrap">
                    <Button className="fw-semibold fs-13 text-white mw-90" variant="primary" type="submit">Update</Button>
                    <Link to={redirect} className="btn btn-secondary text-dark fw-semibold fs-13 mw-90">Cancel</Link>
                    {
                      data && data.sleep_start_time ?
                    
                    <Button className="fw-semibold fs-13 text-white mw-90" variant="danger" type="reset" onClick={handleReset}>Clear</Button>
                    : ''}
                  </Stack>

                </Card.Body>
              </Card>
            </div>
          </Form>
        )}
      </Formik >:''}
      <ClearModal show={show} handleClose={handleClose} setDeletestatus={setDeletestatus}/>
      <Loader isLoading={loading} />
    </>

  )
}
