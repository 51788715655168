import React, { useEffect, useState } from 'react';

import { Badge, Button, Card, Col, Form, Image, Row, Stack } from "react-bootstrap"
import { IoIosArrowBack } from "react-icons/io"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Formik } from "formik"
import { toast } from 'react-toastify';
import InputTextHorizontal from '../../../components/common/InputTextHorizontal';
import TextAreaHorizontal from '../../../components/common/TextAreaHorizontal';
import Loader from '../../../components/common/Loader';
import { CreateSchema } from '../../../validations/Faq';
import { nameBasedProtectedRoutes } from '../../../router/protected';
import CustomRadioHorizontal from '../../../components/common/CustomRadioHorizontal';
import { EditEntitiyMethod } from '../../../helpers/helpersMethods';
import { FAQ_UPDATED_SUCCESS } from '../../../validations/ValidationErrors';
import { editEmailTemplate, getEmailTemplateDetails } from '../../../services/templateService';
import ReactQuill from 'react-quill';
import { TemplateSchema } from '../../../validations/Users';
import ViewTagsHorizontal from '../../../components/common/ViewTagsHorizontal';

export const EditTemplate = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [redirect, setRedirect] = useState<any>();
    const [data, setData] = useState<any>();
    const [submitting, setSubmitting] = useState(false);
    const [searchTag, setSearchTag] = useState(true);
    const modules = {
        toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "code-block"],

            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }], // superscript/subscript
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            [{ direction: "rtl" }], // text direction

            [{ size: ["small", false, "large", "huge"] }], // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            [{ color: [] }, { background: ["black"] }], // dropdown with defaults from theme
            [{ font: [] }],
            [{ align: [] }],
            ["link"],

            ["clean"],
            [{ source: null }],
        ],
    };

    useEffect(() => {
        setLoading(true);
        setRedirect(nameBasedProtectedRoutes.emailTemplateList.path);
        if (id) {
            getEmailTemplateDetails(id).then(
                (resp: any) => {
                    const res = resp?.data;
                    setData(res);
                    setTimeout(() => {
                        setLoading(false);

                    }, 500);
                },
                (error: any) => {
                    const err = error?.response?.data;
                    setLoading(false);
                    if (err?.statusCode === 400) {
                        toast.error(err.msg);
                        navigate(nameBasedProtectedRoutes.faq.path);
                    }

                }
            );
        }

    }, [id]);
  
    /**
       * Editing Employees
       *
       * @param {*} value
       */
    const submitForm = async(value: any) => {
        if (id)
        {
            toast.dismiss();
            try {
                await validateTags(value);
                const { tags, ...submitData } = value;
                 EditEntitiyMethod(
                        setSubmitting,
                        editEmailTemplate,
                        id,
                        submitData,
                        FAQ_UPDATED_SUCCESS,
                        nameBasedProtectedRoutes.emailTemplateList.path,
                        navigate
                      );
                
               
              } catch (error) {
                // Handle validation error if needed
                toast.error('Required tags are missing in the template description');
              }
            

            
        }
    }
    const validateTags = (value: any) => {
        // Return a promise that resolves after the validation code execution
        return new Promise((resolve, reject) => {
          if (value.tags.length > 0) {
            const missingTags = value.tags.filter((tag: any) => {
              let tagLabel = '[' + tag.id + ']';
              return !value.message.includes(tagLabel);
            });
      
            if (missingTags.length > 0) {
              reject(new Error('Validation failed'));
            } else {
              resolve(true);
            }
          } else {
            resolve(true);
          }
        });
      };
      
    return (
        <>
            {!loading && data ? <Formik
                validationSchema={TemplateSchema}
                initialValues={{
                    title: data ? data.title : '',
                    subject: data ? data.subject : '',
                    tags: data ? data.tags.map((tag:any) => ({ id: tag, text: tag })) : '',
                    message: data ? data.message : '',
                }}
                onSubmit={(values, actions) => {
                    values.title = values.title.trim();
                    values.subject = values.subject.trim();
                    values.message = values.message;
                    const { title,subject, ...formData } = values;
                    actions.setSubmitting(false);
                    submitForm(formData)
                }}

            >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    isValid,
                    errors,
                    setFieldValue,
                }) => (
                    <Form className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1" noValidate onSubmit={handleSubmit}>
                        <div className="pageHeader px-3 my-1 pt-1 user-select-none">
                            <Row className="align-items-center min-h-51">
                                <Col>
                                    <h1 className="fw-semibold h4 my-2">
                                        <Link to={redirect} className="align-items-center d-inline-flex overflow-hidden text-dark text-decoration-none">
                                            <span className="me-1 ms-n1">
                                                <IoIosArrowBack size="1.68rem" />
                                            </span> Email Template
                                        </Link>
                                    </h1>
                                </Col>
                            </Row>
                        </div>
                        <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
                            <Card className="h-100 w-100 overflow-auto border-light-subtle pageContentInner">
                                <Card.Header className="bg-white fs-16 fw-semibold p-3 border-secondary">
                                    <Card.Text className="px-xl-1">Edit Template</Card.Text>
                                </Card.Header>
                                <Card.Body className="p-xl-4">

                                    <InputTextHorizontal
                                        leftColSmall={true}
                                        label="Title"
                                        controlId="title"
                                        placeholder="Title"
                                        touched={touched.title}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        errorsField={errors.title}
                                        value={values.title}
                                        disabled={true}
                                    />
                                    <InputTextHorizontal
                                        leftColSmall={true}
                                        label="Subject"
                                        controlId="subject"
                                        placeholder="Subject"
                                        touched={touched.subject}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        errorsField={errors.subject}
                                        value={values.subject}
                                        disabled={true}
                                    />
                                     <ViewTagsHorizontal
                                        label="Tags"
                                        setColunView={false}
                                        value={values.tags}
                                        leftColSmall={true}
                            
                                        />
                               
                                    <div className="right-col-600">
                                        <Form.Group className="theme-form-horizontal pb-sm-1 mb-3" controlId="">
                                            <Row>
                                                <Col sm="auto" className="left-col left-col-sm">
                                                    <Form.Label>Description</Form.Label>
                                                </Col>
                                                <Col sm>
                                                    <div className="position-relative right-col reactEditor">
                                                        {/* <ReactQuill onBlur={handleBlur} onChange={handleChange} value={value} 
                                    className="theme-editor"
                                /> */}
                                                        <ReactQuill
                                                            theme="snow"
                                                            value={values.message}
                                                            onChange={(event) => {
                                                                setFieldValue("message", event);
                                                            }}
                                                            modules={modules}
                                                            placeholder="message"
                                                            className={`ctmeditor rounded overflow-hidden ${touched.message &&
                                                                errors.message &&
                                                                "is-invalid form-control"
                                                                } `}
                                                        />
                                                        {touched.message && errors.message && (
                                                            <Form.Control.Feedback type="invalid">
                                                                {String(errors.message)}
                                                            </Form.Control.Feedback>

                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form.Group>

                                    </div>

                                    <div className="theme-form-horizontal py-3">
                                        <Row>
                                            <Col sm="auto" className="left-col left-col-sm"></Col>
                                            <Col sm="auto" className="right-col">
                                                <Stack direction="horizontal" gap={3}>
                                                    <Button className="fw-semibold fs-13 text-white mw-90 my-1" variant="primary" type="submit">Update</Button>
                                                    <Link to={redirect} className="btn btn-secondary text-dark fw-semibold fs-13 mw-90 my-1">Cancel</Link>
                                                </Stack>
                                            </Col>
                                        </Row>
                                    </div>

                                </Card.Body>
                            </Card>
                        </div>
                    </Form>
                )}
            </Formik > : ''}
            <Loader isLoading={loading || submitting} />
        </>

    )
}
