import React, { useEffect, useState } from 'react';

import { Button, Card, Col, Form, Row, Stack } from "react-bootstrap"
import { IoIosArrowBack } from "react-icons/io"
import { Link, useNavigate } from "react-router-dom"
import { Formik } from "formik"
import { toast } from 'react-toastify';
import InputTextHorizontal from '../../../components/common/InputTextHorizontal';
import TextAreaHorizontal from '../../../components/common/TextAreaHorizontal';
import Loader from '../../../components/common/Loader';
import { CreateSchema } from '../../../validations/Ads';
import { nameBasedProtectedRoutes } from '../../../router/protected';
import CustomRadioHorizontal from '../../../components/common/CustomRadioHorizontal';
import { AddEntitiyMethod } from '../../../helpers/helpersMethods';
import { addAds } from '../../../services/adsService';
import { ADS_UPDATED_SUCCESS } from '../../../validations/ValidationErrors';
import FormDatesPicker from '../../../components/common/FormDatesPicker';
import TagsHorizontal from '../../../components/common/TagsHorizontal';
import { Tag } from 'react-tag-input';

export const Add = () => {

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [redirect, setRedirect] = useState<any>();
  const [submitting, setSubmitting] = useState(false);
  
  const radioData = [
    { label: 'Enable', value: 'active' },
    { label: 'Disable', value: 'inactive' },
  ];
  const defaultTags:any = [];
  useEffect(() => {
    setLoading(false);
    setRedirect(nameBasedProtectedRoutes.ads.path);
    
  });
  const handleTagsChange = (selectedTags: Tag[]) =>
  {
      //setTag(selectedTags);
  };
  

  /**
     * Editing Employees
     *
     * @param {*} value
     */
  const submitForm = (value: any) => {
    if (value) {
      AddEntitiyMethod(setSubmitting, addAds, value, ADS_UPDATED_SUCCESS, nameBasedProtectedRoutes.ads.path, navigate);
    }
  }


  return (
    <>
      {!loading  ? <Formik
        validationSchema={CreateSchema}
        initialValues={{
          title: '',
          owner: '',
          status: 'active',
          message: '',
          publish_date: '',
          tags:''
        }}
        onSubmit={(values, actions) => {
          values.title = values.title.trim();
          values.owner = values.owner.trim();
          values.message = values.message.trim();
          values.publish_date = values.publish_date;
          actions.setSubmitting(false);
          submitForm(values)
        }}

      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
          setFieldValue,
        }) => (
          <Form className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1" noValidate onSubmit={handleSubmit}>
            <div className="pageHeader px-3 my-1 pt-1 user-select-none">
              <Row className="align-items-center min-h-51">
                <Col>
                  <h1 className="fw-semibold h4 my-2">
                    <Link to={redirect} className="align-items-center d-inline-flex overflow-hidden text-dark text-decoration-none">
                      <span className="me-1 ms-n1">
                        <IoIosArrowBack size="1.68rem" />
                      </span> Manage Ads
                    </Link>
                  </h1>
                </Col>
              </Row>
            </div>
            <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
              <Card className="h-100 w-100 overflow-auto border-light-subtle pageContentInner">
                <Card.Header className="bg-white fs-16 fw-semibold p-3 border-secondary">
                  <Card.Text className="px-xl-1">Add Ads</Card.Text>
                </Card.Header>
                <Card.Body className="p-xl-4">

                  <InputTextHorizontal
                    leftColSmall={true}
                    label="Title"
                    controlId="title"
                    placeholder="Title"
                    touched={touched.title}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.title}
                    value={values.title}
                  />
                  <InputTextHorizontal
                    leftColSmall={true}
                    label="Owner"
                    controlId="owner"
                    placeholder="Owner"
                    touched={touched.owner}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.owner}
                    value={values.owner}
                  />
                  <TagsHorizontal
                    name="tags"
                    leftColSmall={true}
                    label="Tags"
                    controlId="tags"
                    value={defaultTags}
                    setFieldValue={setFieldValue}
                    errorsField={errors.tags}
                  />
                  <TextAreaHorizontal
                    leftColSmall={true}
                    controlId="message"
                    label="Message"
                    placeholder="Message"
                    touched={touched.message}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.message}
                    value={values.message}
                  />
                  <FormDatesPicker
                    controlId="publish_date"
                    name="publish_date"
                    placeholder="Select date"
                    label="Publish Date"
                    selected={values.publish_date}
                    onBlur={handleBlur}
                    error={errors.publish_date}
                    touched={touched.publish_date}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    errorsField={errors.publish_date}
                    leftColSmall={true}
                    value={values.publish_date ? values.publish_date : ''}

                  />
                  <CustomRadioHorizontal
                    leftColSmall={true}
                    inline={true}
                    label="Status"
                    name="status"
                    touched={touched.status}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.status}
                    value={values.status}
                    data={radioData}
                  />


                  <div className="theme-form-horizontal py-3">
                    <Row>
                      <Col sm="auto" className="left-col left-col-sm"></Col>
                      <Col sm="auto" className="right-col">
                        <Stack direction="horizontal" gap={3}>
                          <Button className="fw-semibold fs-13 text-white mw-90 my-1" variant="primary" type="submit">Submit</Button>
                          <Link to={redirect} className="btn btn-secondary text-dark fw-semibold fs-13 mw-90 my-1">Cancel</Link>
                        </Stack>
                      </Col>
                    </Row>
                  </div>

                </Card.Body>
              </Card>
            </div>
          </Form>
        )}
      </Formik > : ''}
      <Loader isLoading={loading || submitting} />
    </>

  )
}
