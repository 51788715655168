import { Button, Card, Col, Form, Image, Row, Stack } from "react-bootstrap"
import { IoIosArrowBack } from "react-icons/io"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Formik } from "formik"
import { LegacyReleaseSchema } from '../../../validations/LegacyRelease';
import CustomRadioHorizontal from "../../../components/common/CustomRadioHorizontal";
import TextAreaHorizontal from "../../../components/common/TextAreaHorizontal";
import ViewTextHorizontal from "../../../components/common/ViewTextHorizontal";
import { nameBasedProtectedRoutes } from "../../../router/protected";
import { useEffect, useState } from "react";
import { getDetails } from "../../../services/faqService";
import { toast } from "react-toastify";
import Loader from "../../../components/common/Loader";

export const ViewFAQ = () => {
  const redirect = nameBasedProtectedRoutes.faq.path;
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(false);
    if (id) {
      getDetails(id).then(
        (resp:any) => {
          const res = resp?.data;
          setData(res);
          setTimeout(() => {
            setLoading(false);

          }, 1000);
        },
        (error: any) => {
          const err = error?.response?.data;
          setLoading(false);
          if (err?.statusCode === 400) {
            toast.error(err.msg);
            navigate(nameBasedProtectedRoutes.faq.path);
          }

        }
      );
    }

  }, [id]);

  return (
    <>
      {!loading && data ? <Formik
        validationSchema={LegacyReleaseSchema}
        initialValues={{
          question: data?data.question:'-',
          answer: data?data.answer:'-',
          status: data?data.status:'-',
          order_sequence: data?data.order_sequence:'-',
        }}
        onSubmit={() => navigate(nameBasedProtectedRoutes.faq.path)}

      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <Form className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1" noValidate onSubmit={handleSubmit}>
            <div className="pageHeader px-3 my-1 pt-1 user-select-none">
              <Row className="align-items-center min-h-51">
                <Col>
                  <h1 className="fw-semibold h4 my-2">
                    <Link to={redirect} className="align-items-center d-inline-flex overflow-hidden text-dark text-decoration-none">
                      <span className="me-1 ms-n1">
                        <IoIosArrowBack size="1.68rem" />
                      </span> FAQ
                    </Link>
                  </h1>
                </Col>
              </Row>
            </div>
            <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
              <Card className="h-100 w-100 overflow-auto border-light-subtle pageContentInner">
                <Card.Header className="bg-white fs-16 fw-semibold p-3 border-secondary">
                  <Card.Text className="px-xl-1">FAQ Details</Card.Text>
                </Card.Header>
                <Card.Body className="p-xl-4">

                  <ViewTextHorizontal
                    label="Question"
                    setColunView={true}
                    value={values.question}
                  />

                  <ViewTextHorizontal
                    label="answer"
                    setColunView={true}
                    value={values.answer}
                  />
                  <ViewTextHorizontal
                    label="Status"
                    setColunView={true}
                    value={values.status == 'active' ? 'Enable' : 'disable'}
                    style={{ textTransform: 'capitalize' }}
                  />
                  <div className="theme-form-horizontal pb-3">
                    <Row>
                      <Col sm="auto" className="left-col"></Col>
                      <Col sm="auto" className="right-col">
                        <Stack direction="horizontal" gap={3}>
                          <Button className="fw-semibold fs-13 text-white mw-90 my-1" variant="primary" type="submit">Back</Button>
                        </Stack>
                      </Col>
                    </Row>
                  </div>

                </Card.Body>
              </Card>
            </div>
          </Form>
        )}
      </Formik >: ''}
      <Loader isLoading={loading} />
    </>
  )
}