import { FC } from 'react';
import { Button, Col, Form, Row, Stack } from 'react-bootstrap';
import { IoIosVideocam } from 'react-icons/io';

interface IViewTextHorizontal {
    label?: string;
    value?: any;
    setColunView?: boolean;
    leftColSmall?: boolean;
    isVideoHandler?: any;
    style?: any;
}


/**
 * View Text Horizontal Reusable Component
 * @date 4/19/2023 - 11:45:59 AM
 *
 * @param {{ label: any; value: any; setColunView: any; leftColSmall: any; }} {
    label, value, setColunView, leftColSmall
}
 * @returns {*}
 */

const ViewTextHorizontal: FC<IViewTextHorizontal> = ({
    label, value, setColunView, leftColSmall, isVideoHandler,style
}) => {
    return (
        <Form.Group className="theme-form-horizontal pb-sm-2 mb-3">
            <Row>
                <Col sm="auto" className={`left-col ${leftColSmall ? 'left-col-sm' : ''}`}>
                    {label ? (
                        <Stack direction="horizontal" gap={2} className="justify-content-sm-between">
                            <Form.Label>{label}</Form.Label>
                            {setColunView ? <span className="colun-content mb-1">:</span> : null}
                        </Stack>
                    ) : null}
                </Col>
                <Col sm>
                    <div className="position-relative right-col">
                        <div className="text-data" style={style}>
                            {!isVideoHandler ?
                                value
                                :
                                <Button onClick={isVideoHandler} variant="link" className="align-items-center d-inline-flex fs-12 fw-semibold p-0 text-decoration-none">
                                    <span className="d-inline-flex bg-success align-items-center justify-content-center custom-small-badge me-1">
                                        <IoIosVideocam size={10} />
                                    </span> {value}
                                </Button>
                            }
                        </div>
                    </div>
                </Col>
            </Row>
        </Form.Group>
    );
}

export default ViewTextHorizontal;