import ActionCell from '../../../../../components/common/dataGrid/ActionCell';
import DataGrid from '../../../../../components/common/dataGrid/DataGrid';
import { useEffect, useState } from 'react';
import constantValues from '../../../../../constants/constantValues';
import { gettrusteeListApiCall } from '../../../../../services/userService';
import Loader from '../../../../../components/common/Loader';
import moment from 'moment';
import formatData from '../../../../../constants/formatData';
import { useParams } from 'react-router-dom';
import { maskPhoneNumber, truncateText } from '../../../../../helpers/helpersMethods';
import storage from '../../../../../helpers/storage';

export const Trustee = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(constantValues.rowPerPage);
  const [totalPages, setTotalPages] = useState(0);
  const [totalReults, setTotalResults] = useState(0);
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [releaseLegacy, setReleaseLegacy] = useState(storage.getReleaseLegacy());
  useEffect(() => {
    setLoading(false);
    trusteeList({ page: currentPage, limit: rowPerPage,userid:id });
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    setLoading(false);
    trusteeList({ page: currentPage, limit: rowPerPage,sortColumn:sortColumn,sortDirection:sortDirection,userid:id });
  }, [sortColumn,sortDirection]);
  
  const trusteeList = async (params:any) => {
    setLoading(true);
    gettrusteeListApiCall({ ...params, search: params.search?.trim() }).then(
      (response) => {
        setData(response.data.record);
        setTotalPages(response.data.totalPages);
        setTotalResults(response.data.totalResults);
        setLoading(false);
      }
    );
  };


  const columns = [
    {
      id:'id',
      name: '#',
      sortable: true,
      center: true,
      selector: (row:any, index:any) => (currentPage - 1) * rowPerPage + index + 1,
      width: '60px',
    },
    {
      id:'name',
      selector: (row: any) => row.name,
      name: 'Name',
      sortable: true,
      minWidth: '150px',
      cell: (row: any) => {return truncateText(row.name)}
    },
    {
      id:'email',
      selector: (row: any) => row.email,
      name: 'Email',
      sortable: true,
      minWidth: '150px',
    },
    {
      id:'phone_no',
      selector: (row: any) => row.phone_no,
      name: 'Phone',
      sortable: true,
      minWidth: '150px',
      cell: (row:any) =>
      {
      
        return row.phone_no ? maskPhoneNumber(row.phone_country_code+row.phone_no):'-';
      },
    },
    {
      id:'created_at',
      selector: (row: any) => row.created_at,
      name: 'Creation Date',
      sortable: true,
      minWidth: '150px',
      cell: (row:any) =>
      moment(row.created_at).format(
        formatData.datetimeFormats.forwordSeparatedDateTime
      ),
    },
    {
      selector: (row: any) => row.action,
      name: 'Action',
      width: '100px',
      center: true,
      button: true,
      cell: (row: any) =>
      {
          const editStatus = row.status == 'accepted' || row.status == 'rejected' && !releaseLegacy  ? true : false;
          return <ActionCell controlId="users/trustee" isEditEnable={true} disabled={editStatus} redirectID={row.id} />;
      }
  
    },
  ];
  return (
    <div className="d-flex flex-column h-100 rounded-0 dataGridListMain">
      
      <DataGrid
        showTitle="Digital Trustee"
        columns={columns}
        data={data}
        pagination={true}
        totalPages={totalPages}
        totalRecords={totalReults}
        rowPerPage={rowPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        sortColumn={sortColumn}
        setSortColumn = {setSortColumn}
        sortDirection={sortDirection}
        setSortDirection = {setSortDirection}
      />
       <Loader isLoading={loading} />
    </div>
  )
}
