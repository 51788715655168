import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap"
import { toast } from "react-toastify";
import Loader from "../../components/common/Loader";
import storage from "../../helpers/storage";
import { dashboardData } from "../../services/userService";
import { DashboardCards } from "./DashboardCards";
import { DashboardChart } from "./DashboardChart";

export const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();
  
  useEffect(() =>
  {
    storage.setTab('tab-1');
    setLoading(true);
    dashboardData().then(
      (resp: any) => {
        const res = resp?.data;
        setData(res);
        setTimeout(() => {
          setLoading(false);

        }, 1000);
      },
      (error: any) => {
        const err = error?.response?.data;
        setLoading(false);
        if (err?.statusCode === 400) {
          toast.error(err.msg);
        }

      }
    );
  }, []);
  return (
    <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
      <div className="pageHeader px-3 my-1 pt-1 user-select-none">
        <Row className="align-items-center min-h-51">
          <Col>
            <h1 className="fw-semibold h4 my-2">Dashboard</h1>
          </Col>
        </Row>
      </div>
      <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3 pt-1">
      {!loading && data ?
        <> 
        <DashboardCards cardData={data} />
        <DashboardChart chartData={data}/>
        </>
         : ''}
      </div>
      <Loader isLoading={loading} />
    </div>
    
  )
}
