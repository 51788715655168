import { Route, Routes } from 'react-router-dom';
import { AllUsers } from './List/List';
import { ViewUser } from './View/View';
import { EditUser } from './Edit/Edit';
import { EditMemories } from './View/UserTabs/Memories/Edit/Edit';
import { EditOwnFrequency } from './View/UserTabs/RulesExecuteLegacy/OwnFrequency/Edit';
import { EditTrusteeFrequency } from './View/UserTabs/RulesExecuteLegacy/TrusteeFrequency/Edit';
import { EditSleepTime } from './View/UserTabs/RulesExecuteLegacy/SleepTime/Edit';
import { EditDNDFrequency } from './View/UserTabs/RulesExecuteLegacy/DNDFrequency/Edit';
import { EditOrganDonar } from './View/UserTabs/OrganDonar/Edit/Edit';
import { EditAboutMe } from './View/UserTabs/AboutMe/Edit/Edit';
import { EditPublicProclamation } from './View/UserTabs/PublicProclamation/Edit/Edit';
import { EditTrustee } from './View/UserTabs/DigitalTrustee/Edit/Edit'
import { NotFound } from '../NotFound/NotFound';
import { EditRecipient } from './View/UserTabs/Recipients/Edit/Edit';

export const Users = () => {
    return (
        <Routes>
            <Route path="/" element={<AllUsers />} />
            <Route path="list" element={<AllUsers />} />
            <Route path="view/:id" element={<ViewUser />} />
            <Route path="edit/:id" element={<EditUser />} />
            <Route path="memories/edit/:id" element={<EditMemories />} />
            <Route path="trustee/edit/:id" element={<EditTrustee />} />
            <Route path="rulese-execute-legacy/edit/own-frequency/:id" element={<EditOwnFrequency />} />
            <Route path="rulese-execute-legacy/edit/trustee-frequency/:id" element={<EditTrusteeFrequency />} />
            <Route path="rulese-execute-legacy/edit/sleep-time/:id" element={<EditSleepTime />} />
            <Route path="rulese-execute-legacy/edit/dnd-frequency/:id" element={<EditDNDFrequency />} />
            <Route path="organ-donar/edit/:id" element={<EditOrganDonar />} />
            <Route path="recipient/edit/:id" element={<EditRecipient />} />
            <Route path="about-me/edit/:id" element={<EditAboutMe />} />
            <Route path="public-proclamation/edit/:id" element={<EditPublicProclamation />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    )
};
