import React, { useEffect, useRef, useState } from 'react';

import { Button, Card, Col, Form, Image, Modal, Row, Stack } from "react-bootstrap"
import { IoIosArrowBack, IoIosVideocam } from "react-icons/io"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Formik } from "formik"
import { UsersMemorySchema, UsersMemoryUploadSchema } from "../../../../../../validations/Users"
import InputTextHorizontal from "../../../../../../components/common/InputTextHorizontal"
import CustomSelectHorizontal from "../../../../../../components/common/CustomSelectHorizontal"
import TextAreaHorizontal from "../../../../../../components/common/TextAreaHorizontal"
import { getMemoryDetailsById, recipientListData, updateMemoryInfo } from '../../../../../../services/userService';
import { toast } from 'react-toastify';
import Loader from '../../../../../../components/common/Loader';
import { EditEntitiyMethod } from '../../../../../../helpers/helpersMethods';
import { USER_UPDATED_SUCCESS } from '../../../../../../validations/ValidationErrors';

export const EditMemories = () => {

  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [redirect, setRedirect] = useState<any>();
  const [data, setData] = useState<any>();
  const [recipientList, setRecipientList] = useState<any>([]);
  const [submitting, setSubmitting] = useState(false);
  const [imagethumb, setImagethumb] = useState<any>();
  const [show, setShow] = useState(false);

  useEffect(() => {
    setLoading(true);
    if(id)
    {
      
      getMemoryDetailsById({'id':id}).then(
        (resp) => {
          setLoading(false);
          const res = resp?.data;
          setData(res);
          setImagethumb(res.asset_url_name);
          setRedirect(`/app/users/view/${res.user_id}`);
          selectList(res.user_id);
        },
        (error:any) => {
          setLoading(false);
          const err = error?.response?.data;
          if (err?.statusCode === 400) {
            toast.error(err.msg);
            navigate(`/app/users`);
          }
        }
      );
  }
  }, [id]);
  const selectList = async (id:any) => 
  {
    if(id)
    {
      await recipientListData(id).then(
        (resp) => {
          setLoading(false);
          const res = resp?.data;
          setRecipientList(res); 
        },
        (error:any) => {
          setLoading(false);
          const err = error?.response?.data;
          if (err?.statusCode === 400) {
            toast.error(err.msg);
            navigate(`/app/users`);
          }
        }
      );
    }
     
  };
  const typeData = [
    { id: 'message', name: 'Message' },
    { id: 'thing', name: 'Thing' }
  ];

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const handleFileChange = (event: any) => {
    const file = event.target.files?.[0];
    const allowedTypes = ['mp4'];
    const maxFileSize = 15 * 1024 * 1024; // 15MB in bytes
  
    if (event.target.files && event.target.files.length > 0)
    {
      const fileType = file.type.split('/')[1];
      if (!allowedTypes.includes(fileType)) {
        toast.error('Only MP4 files are allowed');
        return;
      }
      if (file.size > maxFileSize) {
        toast.error('File size is too large, maximum file size is 15MB');
        return;
      }
      setSelectedFile(event.target.files[0]);
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (event:any) =>
        {
          if (reader.result !== null)
          {
              setImagethumb(reader.result);
          }
        }
      }
    }
  };
 
 
  /**
     * Editing Employees
     *
     * @param {*} value
     */
  const submitForm = (value: any) => {
    if (id && data) {
        value.asset_file = selectedFile;
        EditEntitiyMethod(setSubmitting, updateMemoryInfo, id, value, USER_UPDATED_SUCCESS, `/app/users/view/${data.user_id}`, navigate);
    }
  }

  const handleClose = () => {
    // this function will be called when the button is clicked
    setShow(false);
  };
  const handelPlay = ()=>
  {

    setShow(true);
  };

  return (
    <>
      {!loading && data ? <Formik
        validationSchema={data.type == "message" ? UsersMemorySchema:UsersMemoryUploadSchema}
        initialValues={{
          legacy_title: data ? data.legacy_title : '',
          recipient_id: data ? data.recipient_id: '',
          type: data ? data.type : '',
          message: data ? data.message != null ? data.message : "" : "",
      }}
      onSubmit={(values, actions) =>{
         actions.setSubmitting(false);
         submitForm(values)
    }}

      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <Form className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1" noValidate onSubmit={handleSubmit}>
            <div className="pageHeader px-3 my-1 pt-1 user-select-none">
              <Row className="align-items-center min-h-51">
                <Col>
                  <h1 className="fw-semibold h4 my-2">
                    <Link to={redirect} className="align-items-center d-inline-flex overflow-hidden text-dark text-decoration-none">
                      <span className="me-1 ms-n1">
                        <IoIosArrowBack size="1.68rem" />
                      </span> Users
                    </Link>
                  </h1>
                </Col>
              </Row>
            </div>
            <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
              <Card className="h-100 w-100 overflow-auto border-light-subtle pageContentInner">
                <Card.Header className="bg-white fs-16 fw-semibold p-3 border-secondary">
                  <Card.Text className="px-xl-1">Memory Details</Card.Text>
                </Card.Header>
                <Card.Body className="p-xl-4">

                  <InputTextHorizontal
                    leftColSmall={true}
                    label="Legacy Title"
                    controlId="legacy_title"
                    placeholder="Enter Legacy Title"
                    touched={touched.legacy_title}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.legacy_title}
                    value={values.legacy_title}
                  />

                  {/* <InputTextHorizontal
                    leftColSmall={true}
                    label="Recipient"
                    controlId="recipient_id"
                    placeholder="Enter Recipient"
                    touched={touched.recipient_id}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.recipient_id}
                    value={values.recipient_id}
                    disabled={true}
                  /> */}

                  <CustomSelectHorizontal
                    leftColSmall={true}
                    label="Recipient"
                    controlId="recipient_id"
                    placeholder="Select"
                    touched={touched.recipient_id}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.recipient_id}
                    value={values.recipient_id}
                    data={recipientList}
                  />
                  <CustomSelectHorizontal
                    leftColSmall={true}
                    label="Type"
                    controlId="type"
                    placeholder="Select"
                    touched={touched.type}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.type}
                    value={values.type}
                    data={typeData}
                    disabled={true}
                  />
                  
                  <TextAreaHorizontal
                    leftColSmall={true}
                    controlId="message"
                    label="Message"
                    placeholder="Message"
                    touched={touched.message}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.message}
                    value={values.message}
                  />
                  { data.type != "message" ?
                  <div className="theme-form-horizontal pb-3">
                    <Row>
                      <Col sm="auto" className="left-col left-col-sm">
                        <Stack direction="horizontal" gap={3} className="justify-content-between my-sm-2 py-sm-1">
                          <label className="fw-semibold">Video</label>
                        </Stack>
                      </Col>
                      <Col sm="auto" className="right-col">
                        <Row className="gx-3">
                          <Col xs="auto">
                            <div className="align-items-center border d-flex h-85 justify-content-center overflow-hidden position-relative rounded text-light w-85">
                              {!selectedFile && !imagethumb && (
                                <IoIosVideocam size={40} />
                              )}
                              {imagethumb && (
                                <div className="w-100 h-100 d-block cursor-pointer" onClick={handelPlay}>
                                  <span className="position-absolute start-50 text-white top-50 translate-middle" >
                                    <svg width={58} height={58} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 58">
                                      <defs>
                                        <filter id="videoIcon" width="58" height="58" x="0" y="0" filterUnits="userSpaceOnUse">
                                          <feOffset />
                                          <feGaussianBlur result="blur" stdDeviation="5" />
                                          <feFlood floodOpacity=".78" />
                                          <feComposite in2="blur" operator="in" />
                                          <feComposite in="SourceGraphic" />
                                        </filter>
                                      </defs>
                                      <g filter="url(#videoIcon)">
                                        <path fill="currentColor" d="M29.003 15.003a14 14 0 1 0 14 14 14 14 0 0 0-14-14Zm5.64 14.263-9.235 5.587a.3.3 0 0 1-.451-.263V23.416a.3.3 0 0 1 .451-.262l9.235 5.587a.309.309 0 0 1 0 .524Z" />
                                      </g>
                                    </svg>
                                  </span>
                                  <video width={85} height={85} src={imagethumb}  /> 
                                  {/* <video  src={imagethumb} width={85} height={85} ref={videoRef}></video>
                                  <canvas ref={canvasRef} style={{ display: 'none' }} /> */}
                                </div>
                              )}
                            </div>
                          </Col>
                          {/* <video src={videoUrl} ref={videoRef} onPlay={handlePlay} /> */}
                          <Col xs className="mw-1">
                            <Form.Group controlId="formFile" className="mb-3">
                              <Stack direction="horizontal" gap={2}>
                                <div className="position-relative">
                                  <Form.Control type="file" className="bottom-0 end-0 opacity-0 position-absolute start-0 top-0 z-1" onChange={handleFileChange} />
                                  <Button className="fw-semibold fs-13 text-dark mw-90" variant="secondary">Upload</Button>
                                  
                                </div>
                                {selectedFile && (
                                  <Form.Text className="my-1 text-truncate" muted>{selectedFile.name}</Form.Text>
                                )}
                              </Stack>
                              <div className="fs-13 fst-italic fw-semibold text-dark text-opacity-50 my-1">Max 15MB video</div>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                  </div>
                  :''}
                  <div className="theme-form-horizontal py-3">
                    <Row>
                      <Col sm="auto" className="left-col left-col-sm"></Col>
                      <Col sm="auto" className="right-col">
                        <Stack direction="horizontal" gap={3}>
                        <Button className="fw-semibold fs-13 text-white mw-90 my-1" variant="primary"  type="submit">Update</Button>
                         <Link to={redirect} className="btn btn-secondary text-dark fw-semibold fs-13 mw-90 my-1">Cancel</Link>
                        </Stack>
                      </Col>
                    </Row>
                  </div>

                </Card.Body>
              </Card>
            </div>
          </Form>
        )}
      </Formik >:''}
      <Loader isLoading={loading || submitting} />
      <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered={true}
            scrollable={true}
            size="sm"
            className="theme-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title className="fs-16 fw-semibold">Play Video</Modal.Title>
            </Modal.Header>
            <Modal.Body className="">
              {imagethumb ? 
              <video src={imagethumb} controls autoPlay width={300}/>
              :'Video not available please try again'}
            </Modal.Body>
        </Modal>
    </>

  )
}
