import { Card } from "react-bootstrap"
import CustomSelect from "../../components/common/CustomSelect"
import { useState } from "react";
import moment from 'moment';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
  } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js';
import formatData from "../../constants/formatData";
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );

  export const DashboardChart = (data:any) => {
    const sortBy = [
        { id: '1', name: 'Monthly' },
        { id: '2', name: 'Yearly' },
        { id: '3', name: 'Weekly' },
        { id: '4', name: 'Daily' },
    ];
    const monthlyLabels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
    const monthlyData = [65, 59, 80, 81, 56, 55];
    const yearlyLabels = ['2019', '2020', '2021', '2022', '2023'];
    const yearlyData = [200, 300, 400, 350, 500];
    
    const [selectedOption, setSelectedOption] = useState(sortBy[0].id);
    const [chartlabel, setChartlabel] = useState<any>();
    const [chartValue, setChartValue] = useState<any>();

    const handleSelectChange = (e:any) => 
    {
        if(!e.target.value)
        {
            setSelectedOption('1');
        }else
        {

            setSelectedOption(e.target.value);
        }
    };

    const getChartData:any = () => {
        if (selectedOption === '1') {
            const labels = data.chartData.user_info_year_month_wise.map((item:any) => item.month);
            const values = data.chartData.user_info_year_month_wise.map((item:any) => item.user_count);
            return {
                labels: labels,
                datasets: [
                    {
                        fill: true,
                        label: 'Users',
                        data: values,
                        borderColor: 'rgb(53, 162, 235)',
                        backgroundColor: 'rgba(53, 162, 235, 0.5)',
                        
                    },
                ],
            };
        } else if (selectedOption === '2') {
            const labels = data.chartData.user_info_year_wise.map((item:any) => item.year);
            const values = data.chartData.user_info_year_wise.map((item:any) => item.user_count);
            return {
                labels: labels,
                datasets: [
                    {
                        fill: true,
                        label: 'Users',
                        data: values,
                        borderColor: 'rgb(53, 162, 235)',
                        backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    },
                ],
            };
        }
        else if (selectedOption === '3') {
            const labels = data.chartData.user_info_year_week_wise.map((item:any) => item.week);
            const values = data.chartData.user_info_year_week_wise.map((item:any) => item.total);
            return {
                labels: labels,
                datasets: [
                    {
                        fill: true,
                        label: 'Users',
                        data: values,
                        borderColor: 'rgb(53, 162, 235)',
                        backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    },
                ],
            };
        }
        else if (selectedOption === '4') {
            const labels = data.chartData.user_info_year_daily_wise.map((item:any) => moment(item.created_at).format(formatData.dateFormats.commaSeparatedDateTime));
            const values = data.chartData.user_info_year_daily_wise.map((item:any) => item.count);
            return {
                labels: labels,
                datasets: [
                    {
                        fill: true,
                        label: 'Users',
                        data: values,
                        borderColor: 'rgb(53, 162, 235)',
                        backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    },
                ],
            };
        }
    };

    const chartOptions: ChartOptions<'line'> = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top' as const,
          },
          title: {
            display: true,
            text: 'Line Chart',
          },
        },
        // Adjust the height property as needed
        
      };

    return (
        <Card className="border-light-subtle pageContentInner mt-2">
            <Card.Header className="bg-white py-2 border-secondary d-flex align-items-center">
                <h5 className="px-xl-1 py-1 mb-0 fw-bold me-auto">Users</h5>
                <div className="w-120 py-1 remove-inner-margin">
                    <CustomSelect
                        controlId="viewByPlan"
                        data={sortBy}
                        placeholder="Select"
                        size="sm"
                        value={selectedOption}
                        handleChange={handleSelectChange}
                    />
                </div>
            </Card.Header>
            <Card.Body>
                <div className="chartData p-xl-1 chartHeight">
                    <Line data={getChartData()} options={chartOptions} />
                </div>
            </Card.Body>
        </Card>
    );
};







