import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ActionCell from '../../../../../components/common/dataGrid/ActionCell';
import DataGrid from '../../../../../components/common/dataGrid/DataGrid';
import ImageModal from '../../../../../components/common/ImagePreview';
import ImagePreview from '../../../../../components/common/ImagePreview';
import Loader from '../../../../../components/common/Loader';
import constantValues from '../../../../../constants/constantValues';
import formatData from '../../../../../constants/formatData';
import { truncateText } from '../../../../../helpers/helpersMethods';
import storage from '../../../../../helpers/storage';
import { getList } from '../../../../../services/organDonarService';


export const OrganDonar = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(constantValues.rowPerPage);
  const [totalPages, setTotalPages] = useState(0);
  const [totalReults, setTotalResults] = useState(0);
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [showImagePreview, setShowImagePreview] = useState(false);
  const [previewImageUrl, setPreviewImageUrl] = useState('');
  const [releaseLegacy, setReleaseLegacy] = useState(storage.getReleaseLegacy());

  const handleImagePreview = (imageUrl: string) => {
    setShowImagePreview(true);
    setPreviewImageUrl(imageUrl);
  };
  useEffect(() => {
    setLoading(false);
    trusteeList({ page: currentPage, limit: rowPerPage, userid: id });
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    setLoading(false);
    trusteeList({ page: currentPage, limit: rowPerPage, sortColumn: sortColumn, sortDirection: sortDirection, userid: id });
  }, [sortColumn, sortDirection]);

  const trusteeList = async (params: any) => {
    setLoading(true);
    getList({ ...params, search: params.search?.trim() }).then(
      (response) => {
        setData(response.data.record);
        setTotalPages(response.data.totalPages);
        setTotalResults(response.data.totalResults);
        setLoading(false);
      }
    );
  };
  const columns = [
    {
      id: 'id',
      name: '#',
      sortable: true,
      center: true,
      selector: (row: any, index: any) => (currentPage - 1) * rowPerPage + index + 1,
      width: '60px',
    },
    {
      id: 'organ_name',
      selector: (row: any) => row.organ_name,
      name: 'Title',
      sortable: true,
      minWidth: '150px',
      cell: (row: any) => { return truncateText(row.organ_name) }
    },
    {
      id: 'recipient_name',
      selector: (row: any) => row.organ_donor_recipients_name,
      name: 'Recipient',
      sortable: true,
      minWidth: '150px',
      cell: (row: any) => { return truncateText(row.organ_donor_recipients_name) }
    },
    {
      id: 'preview',
      name: 'Preview',
      sortable: false,
      minWidth: '100px',
      cell: (row: any) => (
        <div className="position-relative">
          <img
            src={row.asset_url_name}
            alt="Organ Donor Image"
            width={30}
            height={30}
            className="border border-light rounded-1 rounded-sm cursor-pointer"
            onClick={() => handleImagePreview(row.asset_url_name)}
          />
        </div>
      ),
    },
    {
      id: 'created_at',
      selector: (row: any) => row.created_at,
      name: 'Date and Time',
      sortable: true,
      minWidth: '150px',
      cell: (row: any) =>
        moment(row.created_at).format(
          formatData.datetimeFormats.forwordSeparatedDateTime
        ),
    },
    {
      selector: (row: any) => row.action,
      name: 'Action',
      width: '100px',
      center: true,
      button: true,
      cell: (row: any) => (
        <ActionCell controlId="users/organ-donar" disabled={releaseLegacy} isEditEnable={true} redirectID={row.id} />
      )

    },
  ];
  return (
    <div className="d-flex flex-column h-100 rounded-0 dataGridListMain">
      <DataGrid
        showTitle="Organ Doner"
        columns={columns}
        data={data}
        pagination={true}
        totalPages={totalPages}
        totalRecords={totalReults}
        rowPerPage={rowPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        sortColumn={sortColumn}
        setSortColumn={setSortColumn}
        sortDirection={sortDirection}
        setSortDirection={setSortDirection}
      />
      <Loader isLoading={loading} />
      {showImagePreview && (
        
        <ImageModal
        show={showImagePreview}
        handleClose={() => setShowImagePreview(false)}
        imageUrl={previewImageUrl}
      />
)}
    </div>
  )
}
