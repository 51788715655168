import {axios} from "../services/axios";
import { adminApi } from "../api";


export type subscriptionDTO = {
  title: string,
};




/**
 * subscription List
 * @date 01/06/2023 - 10:31:59
 *
 * @async
 * @param {*} params
 * @returns {unknown}
 */
export const getList = async (params:any) => {
    return await axios.get(`${adminApi.paymentList}`, { params });
  };


/**
 * Export subscription
 * @date 19/06/2023 - 15:08:28
 *
 * @async
 * @param {*} params
 * @returns {unknown}
 */
export const DownloadSubscriptionList = async (params:any) => {
    return await axios.get(`${adminApi.subscriptionExport}`, { params });
  };




  
 
 