import * as yup from "yup";
import {
  CHAR_MAX_50,
  EMAIL_REQUIRED,
  MAXIMUM_1000_CHAR,
  MAXIMUM_100_CHAR,
  MAXIMUM_200_CHAR,
  MAXIMUM_5000_CHAR,
  MAXIMUM_50_CHAR,
  MINIMUM_1_CHAR,
  MINIMUM_3_CHAR,
  MINIMUM_8_CHAR,
  PHONE_NO_REQUIRED,
  PHONE_NUMBER_NOT_VALID,
  PHONE_NUMBER_WITH_COUNTRY_CODE_NOT_VALID,
  PHONE_NUM_LENGTH,
  RECIPIENTSELECT,
  RELATIONSELECT,
  REQUIRED,
  VALID_EMAIL,
} from "./ValidationErrors";


/**
 * UsersSchema for Validation
 * @date 4/17/2023 - 9:51:28 AM
 *
 * @type {*}
 */
export const UsersSchema = yup.object().shape({
  name: yup.string().required(REQUIRED).min(3, MINIMUM_3_CHAR).max(50, MAXIMUM_50_CHAR),
  email: yup.string().trim().required(EMAIL_REQUIRED).email(VALID_EMAIL).matches(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,VALID_EMAIL),
  phone: yup.string().matches(/^\d{10}$/, PHONE_NUMBER_NOT_VALID).min(10, PHONE_NUM_LENGTH).max(10, PHONE_NUM_LENGTH).required(REQUIRED),
  status: yup.string().required(REQUIRED),
  phone_country_code: yup.string().required(REQUIRED)
});

/**
 * UsersMemorySchema for Validation
 * @date 4/17/2023 - 9:51:28 AM
 *
 * @type {*}
 */
export const UsersMemorySchema = yup.object().shape({
  legacy_title: yup.string().required(REQUIRED).min(3, MINIMUM_3_CHAR).max(200, MAXIMUM_100_CHAR),
  recipient_id: yup.number().typeError(RECIPIENTSELECT).required(REQUIRED),
  type: yup.string(),
  message: yup.string().required(REQUIRED).min(3, MINIMUM_3_CHAR).max(1000, MAXIMUM_1000_CHAR),
 // video: yup.string().required(),
});
export const UsersMemoryUploadSchema = yup.object().shape({
  legacy_title: yup.string().required(REQUIRED).min(3, MINIMUM_3_CHAR).max(200, MAXIMUM_100_CHAR),
  recipient_id: yup.number().typeError(RECIPIENTSELECT).required(REQUIRED),
  type: yup.string(),
  message: yup.string().min(8, MINIMUM_8_CHAR).max(1000,MAXIMUM_1000_CHAR).required(REQUIRED),
 // video: yup.string().required(),
});

/**
 * Description placeholder
 * @date 25/04/2023 - 18:54:46
 *
 * @type {*}
 */
export const UsersTruesteeSchema = yup.object().shape({
  name: yup.string().required(REQUIRED).min(3, MINIMUM_3_CHAR).max(50, MAXIMUM_50_CHAR),
  email: yup.string().trim().required(EMAIL_REQUIRED).email(VALID_EMAIL).matches(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,VALID_EMAIL),
  phone_no: yup.string().matches(/^\d{10}$/, PHONE_NUMBER_NOT_VALID).min(10, PHONE_NUM_LENGTH).max(10, PHONE_NUM_LENGTH).required(REQUIRED),
  phone_country_code: yup.string().required(REQUIRED)
});
/**
 * OwnFrequencySchema for Validation
 * @date 4/18/2023 - 12:43:03 PM
 *
 * @type {*}
 */

export const OwnFrequencySchema = yup.object().shape({
  frequency: yup.string().required(REQUIRED),
  frequency_type: yup.string().required(REQUIRED)
});

/**
 * TrusteeFrequencySchema for Validation
 * @date 4/18/2023 - 12:43:15 PM
 *
 * @type {*}
 */

export const TrusteeFrequencySchema = yup.object().shape({
  check_on_period: yup.string().required(REQUIRED),
  check_on_period_type: yup.string().required(REQUIRED)
});

/**
 * SleepTimeSchema for Validation
 * @date 4/18/2023 - 12:43:49 PM
 *
 * @type {*}
 */

export const SleepTimeSchema = yup.object().shape({
  sleep_start_time: yup.string().required(REQUIRED),
  sleep_end_time: yup.string().required(REQUIRED)
  
});


/**
 * DNDFrequencySchema for Validation
 * @date 4/18/2023 - 12:43:59 PM
 *
 * @type {*}
 */

export const DNDFrequencySchema = yup.object().shape({
  frequency: yup.string().required(REQUIRED),
  dnd_start_date: yup.string().required(REQUIRED),
  dnd_end_date: yup.string().required(REQUIRED),

});


/**
 * AboutMeSchema for Validation
 * @date 4/18/2023 - 6:28:10 PM
 *
 * @type {*}
 */

export const AboutMeSchema = yup.object().shape({
  media_name: yup.string().min(3, MINIMUM_3_CHAR).max(100, MAXIMUM_100_CHAR).required(REQUIRED),
  media_type: yup.string().required(REQUIRED),
  recipient_id: yup.string().required(REQUIRED),
  //upload: yup.string().required(REQUIRED)
});


/**
 * OrganDonarSchema for Validation
 * @date 4/18/2023 - 7:11:29 PM
 *
 * @type {*}
 */

export const OrganDonarSchema = yup.object().shape({
  organ_name: yup.string().min(3, MINIMUM_3_CHAR).max(100, MAXIMUM_100_CHAR).required(REQUIRED),
  recipient_id: yup.number().typeError(RECIPIENTSELECT).required(REQUIRED),
  message: yup.string().min(1, MINIMUM_1_CHAR).max(1000,MAXIMUM_1000_CHAR).required(REQUIRED),
  asset_file: yup.mixed().notRequired(),
});


/**
 * PublicProclamationSchema for Validation
 * @date 4/19/2023 - 12:57:23 PM
 *
 * @type {*}
 */

export const PublicProclamationSchema = yup.object().shape({
  proclamation_title: yup.string().min(3, MINIMUM_3_CHAR).max(100, MAXIMUM_100_CHAR).required(REQUIRED),
  category: yup.string().required(REQUIRED),
  tags: yup.array()
    .test('atLeastOneRequired', 'Field is Required.', (value) => value && value.length >= 1)
    .required(REQUIRED),
  asset_file: yup.mixed(),
  message: yup.string().min(8, MINIMUM_8_CHAR).max(1000,MAXIMUM_1000_CHAR).required(REQUIRED),
});


export const RecipientSchema = yup.object().shape({
  name: yup.string().min(3, MINIMUM_3_CHAR).max(100, MAXIMUM_100_CHAR).required(REQUIRED),
  relation: yup.string().typeError(RELATIONSELECT).required(REQUIRED),
  email: yup.string().trim().required(EMAIL_REQUIRED).email(VALID_EMAIL).matches(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,VALID_EMAIL),
  phone_no: yup.string().matches(/^\d{10}$/, PHONE_NUMBER_NOT_VALID).min(10, PHONE_NUM_LENGTH).max(10, PHONE_NUM_LENGTH).required(REQUIRED),
  phone_country_code: yup.string().required(REQUIRED)
});
export const TemplateSchema = yup.object().shape({
  title: yup.string().min(3, MINIMUM_3_CHAR).max(100, MAXIMUM_100_CHAR).required(REQUIRED),
  message: yup.string().min(8, MINIMUM_8_CHAR).max(5000,MAXIMUM_5000_CHAR).required(REQUIRED),
});
