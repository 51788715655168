import React, { FC } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

interface ITextAreaHorizontal {
    controlId: string;
    label?: string;
    placeholder?: string;
    handleChange?: any;
    handleBlur?: any;
    errorsField?: any;
    touched?: any;
    value?: any;
    setColunView?: boolean;
    leftColSmall?: boolean;
}


/**
 * Input Type Text Reusable Component
 *
 * @param {{ controlId: any; label: any; placeholder: any; handleChange: any; handleBlur: any; errorsField: any; touched: any; }} {
    controlId, label, placeholder, handleChange, handleBlur, errorsField, touched
}
 * @returns {*}
 */
const TextAreaHorizontal: FC<ITextAreaHorizontal> = ({
    controlId, label, placeholder, handleChange, handleBlur, errorsField, touched, value, setColunView, leftColSmall
}) => {
    return (
        <Form.Group className="theme-form-horizontal pb-sm-1 mb-3" controlId={controlId}>
            <Row>
                <Col sm="auto" className={`left-col ${leftColSmall ? 'left-col-sm' : ''}`}>
                    {label ? (
                        <Stack direction="horizontal" gap={2} className="justify-content-between h-35">
                            <label className="fw-semibold">{label}</label>
                            {setColunView ? <span className="colun-content">:</span> : null}
                        </Stack>
                    ) : null}
                </Col>
                <Col sm>
                    <div className="position-relative right-col">
                        <Form.Control as="textarea" rows={4} value={value} placeholder={placeholder} onBlur={handleBlur} onChange={handleChange} isInvalid={!!errorsField && touched} />
                        <Form.Control.Feedback type="invalid">
                            {errorsField}
                        </Form.Control.Feedback>
                    </div>
                </Col>
            </Row>
        </Form.Group>
    );
}

export default TextAreaHorizontal