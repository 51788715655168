import { useEffect, useState } from 'react'
import { Footer } from './Footer/Footer'
import { Header } from './Header/Header'
import { Sidebar } from './Sidebar/Sidebar'
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { Col } from 'react-bootstrap';
import storage from '../../helpers/storage';
import { nameBasedRoutes } from '../../router/public';

export const MainLayout = () => {
  const navigate = useNavigate();
  const [isActiveSidebar, setIsActiveSidebar] = useState(false);
  const toggleSidebarButton = (event: any) => 
  {
    setIsActiveSidebar(current => !current);
  };
//   useEffect(() => {
//     if (storage.getToken() == null) {
//         return navigate('/forgot-password');
//     } else {
//         localStorage.removeItem('user-info');
//     }
// }, [])

  return (
    <main className="mainContentBox">
      <div className="vh-100 d-flex position-relative overflow-hidden">
        <Sidebar isActiveSidebar={isActiveSidebar} />
        <Col xs className="mw-100 h-100 mw-1">
          {isActiveSidebar ?
            <div onClick={toggleSidebarButton} className="backdrop bg-black bg-opacity-25 bottom-0 position-fixed start-0 w-100"></div>
            : null
          }
          <div className="d-flex flex-column h-100 w-100">
            <Header isActiveSidebar={isActiveSidebar} toggleSidebarButton={toggleSidebarButton} />
            <div className="flex-grow-1 fs-13 overflow-hidden w-100">
              <Outlet />
            </div>
            <Footer />
          </div>
        </Col>
      </div>
    </main>
  )
}
