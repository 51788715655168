import { MainLayout } from '../components/layouts/MainLayout';
import { CMS } from '../pages/CMS/routes';
import { ChangePassword } from '../pages/ChangePassword/ChangePassword';
import { Dashboard } from '../pages/Dashboard/Dashboard';
import { FAQ } from '../pages/FAQ/routes';
import { LegacyRelease } from '../pages/LegacyRelease/routes';
import { NotFound } from '../pages/NotFound/NotFound';
import { Proclamation } from '../pages/Proclamation/routes';
import { Profile } from '../pages/Profile/Profile';
import { Settings } from '../pages/Settings/Settings';
import { Subscriptions } from '../pages/Subscriptions/Subscriptions';
import { Users } from '../pages/Users/routes';
import { ADS } from '../pages/Ads/routes'
import { Charity } from '../pages/Charity/routes';
import { Template } from '../pages/Template/routes';
const basePath = process.env.REACT_APP_BASE_NAME;

/**
 * Routes which can only be accessed after authentication
 * @type {{}}
 */
export const protectedRoutes = [
  {
    path: `/app`,
    element: <MainLayout />,
    children: [
      { path: ``, element: <Dashboard /> },
      { path: `dashboard`, element: <Dashboard /> },
      { path: `users/*`, element: <Users /> },
      { path: `legacy-release/*`, element: <LegacyRelease /> },
      { path: `subscriptions`, element: <Subscriptions /> },
      { path: `proclamation/*`, element: <Proclamation /> },
      { path: `faq/*`, element: <FAQ /> },
      { path: `ads/*`, element: <ADS /> },
      { path: `charity/*`, element: <Charity /> },
      { path: `settings`, element: <Settings /> },
      { path: `cms/*`, element: <CMS /> },
      { path: `profile`, element: <Profile /> },
      { path: `change-password`, element: <ChangePassword /> },
      { path: `email-template/*`, element: <Template /> },
      { path: `*`, element: <NotFound /> },
    ],
  }
];
// Named Routes
export const nameBasedProtectedRoutes = {
  userDashboard: {
    name: "Dashboard",
    path: ``,
  },
  dashborad: {
    name: "Dashboard",
    path: `/app/dashboard`,
  },
  profile: {
    name: "Profile",
    path: `/app/profile`,
  },
  changePassoword: {
    name: "changePassoword",
    path: `/app/change-password`,
  },
  faq: {
    name: "faq",
    path: `/app/faq`,
  },
  ads: {
    name: "ads",
    path: `/app/ads`,
  },
  charity: {
    name: "ads",
    path: `/app/charity`,
  },
  cms: {
    name: "cms",
    path: `/app/cms`,
  },
  settings: {
    name: "settings",
    path: `/app/settings`,
  },
  Addcharity: {
    name: "charity",
    path: `/app/charity/add`,
  },
  User: {
    name: "user",
    path: `/app/users/view`,
  },
  legacyRelease: {
    name: "user",
    path: `/app/legacy-release`,
  },
  proclamation: {
    name: "proclamation",
    path: `/app/proclamation`,
  },
  pendingProclamation: {
    name: "pending proclamation",
    path: `/app/proclamation/pending-proclamation`,
  },
  emailTemplateList: {
    name: "Email template",
    path: `/app/email-template`,
  }
};
