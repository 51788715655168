import moment from 'moment';
import { FC } from 'react';
import { Button, Card, Col, OverlayTrigger, Row, Stack, Tooltip } from "react-bootstrap"
import { FaEdit, FaMoneyCheckAlt, FaUserCircle } from "react-icons/fa"
import { Link, useNavigate } from "react-router-dom"
import formatData from '../../../../constants/formatData';
import { maskPhoneNumber } from '../../../../helpers/helpersMethods';

interface IDetailsCard {
    controlId: string;
    redirectID: string;
    Data:any;
    Plan:any;
}

const DetailsCard: FC<IDetailsCard> = ({
    controlId, redirectID, Data,Plan
}) => {
    const navigate = useNavigate();
    const GoToEdit = () => {
        navigate(`/app/${controlId}/edit/${Data.id}`);
    }
    return (
        <div className="topCardHeader">
            <Row className="gx-3">
                <Col md className="mb-2 pb-1">
                    <Card className="border-secondary px-1 h-100">
                        <Card.Header className="bg-white border-0 pt-3">
                            <Stack direction="horizontal" className="min-h-28">
                                <h6 className="align-items-center d-inline-flex fw-bold mb-0">
                                    <span className="text-primary me-2">
                                        <FaUserCircle size="1.5rem" />
                                    </span>
                                    User Details
                                </h6>
                                {Data.status != 'inactive'?
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="edit-tooltip">Edit</Tooltip>}
                                >
                                    <Button onClick={GoToEdit} variant="link" className="py-0 px-1 ms-auto">
                                        <FaEdit size={22} />
                                    </Button>
                                </OverlayTrigger>
                                :''}
                            </Stack>
                        </Card.Header>
                        <Card.Body>
                            <div className="pb-1 mb-xl-2">
                                <Row>
                                    <Col sm="auto" className="mw-90 mb-2">Name</Col>
                                    <Col sm className="fw-bold mw-1 mb-2">{Data?Data.name:''}</Col>
                                </Row>
                            </div>
                            <div className="pb-1 mb-xl-2">
                                <Row>
                                    <Col sm="auto" className="mw-90 mb-2">Email</Col>
                                    <Col sm className="fw-bold mw-1 mb-2">
                                    <a href={`mailto:${Data ? Data.email : ''}`} className="text-decoration-none text-dark">{Data ? Data.email : ''}</a>
                                    </Col>
                                </Row>
                            </div>
                            <div className="pb-1 mb-xl-2">
                                <Row>
                                    <Col sm="auto" className="mw-90 mb-2">Phone</Col>
                                    <Col sm className="fw-bold mw-1 mb-2">
                                        <Link className="text-decoration-none text-dark" to="tel:+123 456 7890">{Data ? Data.phone ? maskPhoneNumber(Data.phone_country_code+Data.phone) :'-':''}</Link>
                                    </Col>
                                </Row>
                            </div>
                            {
                               Data.legacy_released ? 
                            <>
                            <div className="pb-1 mb-xl-2">
                                <Row>
                                    <Col sm="auto" className="mw-90 mb-2">Reason</Col>
                                    <Col sm className="fw-bold mw-1 mb-2">
                                        <Link className="text-decoration-none text-dark" to="tel:+123 456 7890">{Data ? Data.reason :''}</Link>
                                    </Col>
                                </Row>
                               
                            </div>
                             <div className="pb-1 mb-xl-2">
                             <Row>
                                    <Col sm="auto" className="mw-90 mb-2">Date</Col>
                                    <Col sm className="fw-bold mw-1 mb-2">
                                        <Link className="text-decoration-none text-dark" to="tel:+123 456 7890">{Data?moment(Data.legacy_released_date).format(
        formatData.dateFormats.commaSeparatedDateTime
      ):''}</Link>
                                    </Col>
                                </Row>
                         </div>
                         </>   
:''}
                        </Card.Body>
                    </Card>
                </Col>
                <Col md className="mb-2 pb-1">
                    <Card className="border-secondary px-1 h-100">
                        <Card.Header className="bg-white border-0 pt-3">
                            <Stack direction="horizontal" className="min-h-28">
                                <h6 className="align-items-center d-inline-flex fw-bold mb-0">
                                    <span className="text-primary me-2">
                                        <FaMoneyCheckAlt size="1.5rem" />
                                    </span>
                                    Plan Details
                                </h6>
                            </Stack>
                        </Card.Header>
                        {Plan ? 
                        <Card.Body>
                            <div className="pb-1 mb-xl-2">
                                <Row>
                                    <Col sm="auto" className="mw-130 mb-2">Plan Name</Col>
                                    <Col sm className="fw-bold mw-1 mb-2">{Plan? Plan.plandetail?Plan.plandetail.plan_name :'-' :''}</Col>
                                </Row>
                            </div>
                            <div className="pb-1 mb-xl-2">
                                <Row>
                                    <Col sm="auto" className="mw-130 mb-2">Plan Activation</Col>
                                    <Col sm className="fw-bold mw-1 mb-2">{Plan?moment(Plan.created_at).format(
        formatData.dateFormats.commaSeparatedDateTime
      ):''}</Col>
                                </Row>
                            </div>
                            <div className="pb-1 mb-xl-2">
                                <Row>
                                    <Col sm="auto" className="mw-130 mb-2">Status</Col>
                                    <Col sm className="fw-bold mw-1 mb-2 text-success">Paid</Col>
                                </Row>
                            </div>
                        </Card.Body>
                        :<Card.Body>
                        <div className="pb-1 mb-xl-2">
                            <Row>
                                <Col sm="auto" className="mw-130 mb-2"></Col>
                            </Row>
                        </div>
                    </Card.Body>}
                    </Card>
                </Col>
            </Row>
        </div>
    )
}
export default DetailsCard;