import { Field } from 'formik';
import { FC } from 'react';
import Form from 'react-bootstrap/Form';

interface ICustomRadio {
    label?: string;
    handleChange?: any;
    handleBlur?: any;
    errorsField?: any;
    touched?: any;
    value?: any;
    data?: any;
    name?: any;
    inline?: boolean;
}

/**
 * Custom Radio Reusable Component
 * @date 4/18/2023 - 11:10:50 AM
 *
 * @param {{ label: any; handleChange: any; handleBlur: any; errorsField: any; touched: any; value: any; data: any; name: any; inline: any; }} {
    label, handleChange, handleBlur, errorsField, touched, value, data, name, inline
}
 * @returns {*}
 */

const CustomRadio: FC<ICustomRadio> = ({
    label, handleChange, handleBlur, errorsField, touched, value, data, name, inline
}) => {
    return (
        <Form.Group className="mb-3">
            {label ? (
                <Form.Label className="lh-sm">{label}</Form.Label>
            ) : null}
            <div className="position-relative">
                {data.map((option: any) => (
                    <Field key={option.value} name={name}>
                        {({ field, form }: any) => (
                            <Form.Check
                                inline={inline}
                                type='radio'
                                className="me-4 pe-1 mb-2 user-select-none theme-radio"
                                id={`${name}-${option.value}`}
                                label={option.label}
                                value={option.value}
                                checked={field.value === option.value}
                                onChange={handleChange}
                                isInvalid={false}
                                feedback={form.errors[name]}
                                name={name}
                            />
                        )}
                    </Field>
                ))}
                <Form.Control.Feedback type="invalid" className={`${!!errorsField && touched? 'd-block mt-0': null}`}>
                    {errorsField}
                </Form.Control.Feedback>
            </div>
        </Form.Group>
    );
}

export default CustomRadio