import { Route, Routes } from 'react-router-dom';
import { EditTemplate } from './Edit/Edit';
import { AllTemplate } from './List/List';
import {ViewTemplate} from './View/View';
// import { AddFAQ } from './Add/Add';
// import { EditFAQ } from './Edit/Edit';
// import { ViewFAQ } from './View/View';

export const Template = () => {
    return (
        <Routes>
            <Route path="/" element={<AllTemplate />} />
            <Route path="/list" element={<AllTemplate />} />
            
            <Route path="/edit/:id" element={<EditTemplate />} />
            <Route path="/view/:id" element={<ViewTemplate />} />
            {/* <Route path="/view/:id" element={<ViewFAQ />} /> */}
        </Routes>
    )
};