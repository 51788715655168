import React from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import constantValues from "../constants/constantValues";
import { nameBasedProtectedRoutes } from "../router/protected";
import storage from "./storage";



/**
   * Masking Email
   *
   * @param {string} [email='']
   * @returns {string}
   */
export const maskEmail = (email = '') => {
  return email.replace(/^(.)(.*)(.@.*)$/,
    (_, a, b, c) => a + b.replace(/./g, '*') + c)
};

/**
 * Calling List API as per modules
 *
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setLoading
 * @param {() => Promise<any>} method
 * @param {React.Dispatch<React.SetStateAction<never[]>>} setData
 * @param {*} setshowSnackBar
 * @returns {any, setData: any, setshowSnackBar: any) => void}
 */
export const GetEntitiesList = (setLoading: React.Dispatch<React.SetStateAction<boolean>>, method: (ar1: any) => Promise<any>,
  setData: React.Dispatch<React.SetStateAction<never[]>>, setshowSnackBar: any, params?: any) => {
  setLoading(true);

  method(serialize(params)).then((response: any) => {
    setLoading(false);
    if (response.results && response.results.length > 0) {
      setData(response.results);
    }

  }).catch((error: any) => {
    setLoading(false);
    setshowSnackBar({
      show: true,
      message: error.response.data.message,
      severity: 'error'
    });
  })
}


/**
 * Add Entity by calling this method
 *
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setLoading
 * @param {(arg1: any) => Promise<any>} method
 * @param {*} values
 * @param {string} successMsg
 * @param {string} redirectLink
 * @param {NavigateFunction} navigate
 * @returns {any, values: any, setshowSnackBar: any, successMsg: string, redirectLink: string) => void}
 */
export const AddEntitiyMethod = (setLoading: React.Dispatch<React.SetStateAction<boolean>>, method: (arg1: any) => Promise<any>,
  values: any, successMsg: string, redirectLink: string, navigate: NavigateFunction) => {
  setLoading(true);
  method(values).then(response => {
    if (response?.statusCode === 200)
    {
      toast.success(response.msg);
    }
    setTimeout(() => {
      setLoading(false);
      navigate(redirectLink)
    }, 1000);
  }).catch(error => {
    setLoading(false);
    const err = error?.response?.data;
    if (err?.statusCode === 400) {
      toast.error(err.msg);
    }
  })
}

/**
 * Edit Entity by calling this method
 *
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setLoading
 * @param {(arg1: any, arg2: any) => Promise<any>} method
 * @param {string} id
 * @param {*} values
 * @param {string} successMsg
 * @param {string} redirectLink
 * @param {NavigateFunction} navigate
 * @returns {any, id: string, values: any, successMsg: string, redirectLink: string) => void}
 */
export const EditEntitiyMethod = (setLoading: React.Dispatch<React.SetStateAction<boolean>>, method: (arg1: any, arg2: any) => Promise<any>, id: string,
  values: any, successMsg: string, redirectLink: string, navigate: NavigateFunction) => {
  setLoading(true);
  toast.dismiss();
  method(id, values).then(response => {
    if (response?.statusCode === 200)
    {
      toast.success(response.msg);
    }
    setTimeout(() => {
      setLoading(false);
      if(redirectLink == "login")
      {
        localStorage.removeItem('MYLO_token');
        localStorage.removeItem('user-info');
        navigate('/');
        setLoading(false);
      }else
      {
        navigate(redirectLink)
      }
    }, 1000);
  }).catch(error => {
    setLoading(false);
    const err = error?.response?.data;
        if (err?.statusCode === 400) {
          toast.error(err.msg);
        }
    
  })
}

/**
 * Get Entity details by ID
 *
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setLoading
 * @param {(arg1: any) => Promise<any>} method
 * @param {string} id
 * @param {*} setData
 */
export const GetEntitiyByIDMethod = (setLoading: React.Dispatch<React.SetStateAction<boolean>>, method: (arg1: any) => Promise<any>, id: string,
  setData: any) => {
  setLoading(true);
  method(id ? id : '').then(response => {
    setLoading(false);
    if (response.data) {
      setData(response.data)
    } else {
      setData(response)
    }
  }).catch(error => {
    setLoading(false);
  })
}

/**
 * Converting Object to Query Param
 *
 * @param {*} obj
 * @returns {*}
 */
export const serialize = (obj: any) => {
  let str: string[] = [];
  for (let p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}
export const logOut = async (navigate: NavigateFunction) => {
  localStorage.removeItem('MYLO_token');
  localStorage.removeItem('user-info');
  navigate('/');
};
export const truncateText = (text: any) => {
    if(text)
    {
        if (text.length > constantValues.MaxLength)
        {
          return `${text.slice(0, constantValues.MaxLength)}...`;
        }
        
    }
    return text;
}
export const CapitalText = (text: any) => {
    if(text)
    {
      const words = text.toLowerCase().split(' ');
      const capitalizedWords = words.map((word: string) => word.charAt(0).toUpperCase() + word.slice(1));
      const status = capitalizedWords.join(' ');
      return status;
    }
}
export const maskPhoneNumber = (phoneNumber:string) =>{
  // Check if the phone number starts with '+1'
  if (phoneNumber.startsWith('+1')) {
    // Mask the last four digits of the phone number
    return phoneNumber.replace(/^(\+1)[ -]?(\d{3})[ -]?(\d{3})[ -]?(\d{4})$/, '$1 $2-$3-$4');
  } else {
    // Return the original phone number if it doesn't start with '+1'
    return phoneNumber;
  }
}


/**
 * Description placeholder
 * @date 26/05/2023 - 15:33:33
 *
 * @param {File} file
 * @param {string} fileType
 * @returns {*}
 */
export const handleFileUpload = (file: File, fileType: string) => {
  let maxFileSize = 15 * 1024 * 1024; // 10MB in bytes
  let sizeErrorMessage = 'File size exceeds the maximum limit of 15MB.';

  if (fileType === 'image') {
    maxFileSize = 10 * 1024 * 1024; // 5MB in bytes
    sizeErrorMessage = 'Image size exceeds the maximum limit of 10MB.';
  }

  const allowedTypesMap:any = {
    image: ['image/jpeg', 'image/png'],
    audio: ['audio/mpeg'],
    video: ['video/mp4'],
  };

  const allowedTypes = allowedTypesMap[fileType];
  const typeErrorMessage = `Unsupported ${fileType} file type. Please upload ${allowedTypes.join(' or ')} files.`;

  if (!allowedTypes || !allowedTypes.includes(file.type)) {
    throw new Error(typeErrorMessage);
  }

  if (file.size > maxFileSize) {
    throw new Error(sizeErrorMessage);
  }

  return URL.createObjectURL(file);
};

export const redirectUser = (id:any,navigate: NavigateFunction) =>
{
  if(id)
  {
    navigate(nameBasedProtectedRoutes.User.path+'/'+id);
  }
}


