import { FaAd, FaClipboardList, FaFileAlt, FaFileVideo, FaMailBulk, FaMoneyBillWave, FaQuestionCircle, FaTachometerAlt, FaUserFriends } from "react-icons/fa";
import { MdSettings } from "react-icons/md";
const basePath = process.env.REACT_APP_BASE_NAME;

export const NavItems = [
    {
        id: 1,
        menuName: 'Dashboard',
        menuIcon: <FaTachometerAlt size={15} />,
        path: `/app/dashboard`,
    },
    {
        id: 2,
        menuName: 'Users',
        menuIcon: <FaUserFriends size={17} />,
        path: `/app/users`,
    },
    {
        id: 3,
        menuName: 'Legacy Release',
        menuIcon: <FaClipboardList size={14} />,
        path: `/app/legacy-release`,
    },
    {
        id: 4,
        menuName: 'Payments',
        menuIcon: <FaMailBulk size={14} />,
        path: `/app/subscriptions`,
    },
    {
        id: 5,
        menuName: 'Proclamation',
        menuIcon: <FaFileVideo size={13} />,
        path: `/app/proclamation`,
    },
    {
        id: 6,
        menuName: 'Charity',
        menuIcon: <FaMoneyBillWave size={14} />,
        path: `/app/charity`,
    },
    {
        id: 7,
        menuName: 'Settings',
        menuIcon: <MdSettings size={16} />,
        path: `/app/settings`,
    },
    {
        id: 9,
        menuName: 'Ads',
        menuIcon: <FaAd size={14} />,
        path: `/app/ads`,
    },
    {
        id: 10,
        menuName: 'CMS',
        menuIcon: <FaFileAlt size={13} />,
        path: `/app/cms`,
    },
    {
        id: 11,
        menuName: 'FAQ',
        menuIcon: <FaQuestionCircle size={12} />,
        path: `/app/faq`,
    },
    {
        id: 12,
        menuName: 'Email Template',
        menuIcon: <FaMailBulk size={12} />,
        path: `/app/email-template`,
    }
]