import { useEffect, useState } from 'react'
import { Card, Col, Row, Stack } from 'react-bootstrap'

import ActionCell from '../../../../components/common/dataGrid/ActionCell';
import { IoIosArrowBack, IoIosVideocam } from 'react-icons/io';
import { Link, useNavigate } from 'react-router-dom';
import constantValues from '../../../../constants/constantValues';

import { toast } from 'react-toastify';
import Loader from '../../../../components/common/Loader';
import { CapitalText, redirectUser, truncateText } from '../../../../helpers/helpersMethods';
import { getProclamationGlobalList, getProclamationPendingGlobalList } from '../../../../services/PproclamationService';
import DataGrid from '../../../../components/common/dataGrid/DataGrid';
import moment from 'moment';
import formatData from '../../../../constants/formatData';
import ImageModal from '../../../../components/common/ImagePreview';

const viewData = [
  { id: 'pending', name: 'Pending' },
  { id: 'rejected', name: 'Rejected' }
];

export const AllPendingProclamation = () => {
  const defaultValueSortByStatus:any = localStorage.getItem('sortByStatus')?localStorage.getItem('sortByStatus'):'pending';
  const [filterData, setFilterData] = useState({ search: '',searchByCreatedDate:'',sortByStatus:defaultValueSortByStatus });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(constantValues.rowPerPage);
  const [totalPages, setTotalPages] = useState(0);
  const [totalReults, setTotalResults] = useState(0);
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [plan, setPlan] = useState([]);
  const [showImagePreview, setShowImagePreview] = useState(false);
  const [previewImageUrl, setPreviewImageUrl] = useState('');
  const navigate = useNavigate();

  const handleImagePreview = (imageUrl: string) => {
    setShowImagePreview(true);
    setPreviewImageUrl(imageUrl);
  };

  useEffect(() => {
    const searchData = filterData ? filterData.search : '';
    const sortByStatus = filterData ? filterData.sortByStatus : '';
    localStorage.setItem('sortByStatus', filterData.sortByStatus);
    if (searchData.length > 1  || sortByStatus.length >= 1) {
      setLoading(false);
      List({
        page: currentPage,
        limit: rowPerPage,
        sortColumn,
        sortDirection,
        search: searchData,
        searchByStatus: sortByStatus
      });
    } else {
      setLoading(false);
      List({ page: currentPage, limit: rowPerPage, sortColumn,sortDirection});
    }
  }, [filterData, sortColumn, sortDirection, rowPerPage,currentPage]);

  useEffect(()=>{
    setCurrentPage(1);
  },[filterData,sortColumn,sortDirection])


  /**
   * Description fetch user list
   * @date 19/04/2023 - 12:10:33
   *
   * @async
   * @param {*} params
   * @returns {*}
   */
  const List = async (params:any) => {
    setLoading(true);
    getProclamationPendingGlobalList({ ...params, search: params.search?.trim() }).then(
      (response) => {
        setData(response.data?.record);
        setTotalPages(response.data?.totalPages);
        setTotalResults(response.data?.totalResults);
        setLoading(false);
      },(error: any) => {
        const err = error?.response?.data;
        setLoading(false);
        if (err?.statusCode === 400) {
          toast.error(err.msg);
        }

      }
    );
  };
  const columns = [
    {
      id:'id',
      name: '#',
      sortable: true,
      center: true,
      selector: (row:any, index:any) => (currentPage - 1) * rowPerPage + index + 1,
      width: '60px',
    },
    {
      id:'proclamation_title',
      selector: (row: any) => row.proclamation_title,
      name: 'Title',
      sortable: true,
      minWidth: '100px',
      cell: (row: any) => {return truncateText(row.proclamation_title)}
    },
    {
      id:'user_name',
      selector: (row: any) => row.proclamation_user_name,
      name: 'User',
      sortable: true,
      minWidth: '100px',
      cell: (row: any) => (<span className='table-linking' onClick={() => redirectUser(row.user_id,navigate)}>{truncateText(row.proclamation_user_name)}</span>)
    },
    
    {
      id:'legacy_released_date',
      selector: (row: any) => row.proclamation_legacy_released_date,
      name: 'Release Legacy Date',
      sortable: true,
      width: '180px',
      cell: (row:any) =>
      moment(row.proclamation_legacy_released_date).format(
        formatData.dateFormats.commaSeparatedDateTime
      ),
    },
    {
      id:'category',
      selector: (row: any) => row.category,
      name: 'Category',
      sortable: true,
      width: '180px',
    },
    {
      selector: (row: any) => row.video,
      name: 'Video',
      sortable: false,
      width: '110px',
      cell: (row: any) => (
        <div>
          <div className="d-flex bg-success align-items-center justify-content-center custom-small-badge cursor-pointer" onClick={() => handleImagePreview(row.asset_url_name)}>
            <IoIosVideocam size={10} />
          </div>
        </div>
      )
    },
    {
      id:'status',
      selector: (row: any) => row.status,
      name: 'Status',
      sortable: true,
      width: '140px',
      cell: (row: any) => {return CapitalText(row.status)}
    },
    {
      selector: (row: any) => row.action,
      name: 'Action',
      width: '120px',
      center: true,
      button: true,
      cell: (row: any) => (
        <ActionCell controlId="proclamation/pending-proclamation" isViewEnable={true} redirectID={row.id} />
      )
  
    },
  ];
  return (
    <> 
    <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
      <div className="pageHeader px-3 my-1 pt-1 user-select-none">
        <Row className="align-items-center min-h-51">
          <Col>
            <h1 className="fw-semibold h4 my-2">
              <Link to="/app/proclamation" className="align-items-center d-inline-flex overflow-hidden text-dark text-decoration-none">
                <span className="me-1 ms-n1">
                  <IoIosArrowBack size="1.68rem" />
                </span> Pending Proclamation
              </Link>
            </h1>
          </Col>
        </Row>
      </div>
      <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
        <Card className="h-100 p-3 w-100 border-light-subtle pageContentInner">
          <div className="d-flex flex-column h-100 rounded-0 dataGridListMain">
          <DataGrid
              columns={columns}
              data={data}
              viewData={viewData}
              FilterSubHeader={true}
              filterData={filterData}
              setFilterData={setFilterData}
              filterByDateRange={false}
              pagination={true}
              totalPages={totalPages}
              totalRecords={totalReults}
              rowPerPage={rowPerPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              sortColumn={sortColumn}
              setSortColumn = {setSortColumn}
              sortDirection={sortDirection}
              setSortDirection = {setSortDirection}
            />
          </div>
        </Card>
      </div>
    </div>
    <Loader isLoading={loading} />
    {showImagePreview && (
        <ImageModal
        show={showImagePreview}
        handleClose={() => setShowImagePreview(false)}
        imageUrl={previewImageUrl}
        contentType="video"
      />
)}
</>
  )
}
