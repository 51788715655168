import ActionCell from '../../../../../components/common/dataGrid/ActionCell';
import DataGrid from '../../../../../components/common/dataGrid/DataGrid';
import { MdChat } from 'react-icons/md';
import { IoIosVideocam } from 'react-icons/io';
import { useEffect, useState } from 'react';
import constantValues from '../../../../../constants/constantValues';
import { getMemoryListApiCall } from '../../../../../services/userService';
import Loader from '../../../../../components/common/Loader';
import moment from 'moment';
import formatData from '../../../../../constants/formatData';
import { useParams } from 'react-router-dom';
import storage from '../../../../../helpers/storage';

export const Memories = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(constantValues.rowPerPage);
  const [totalPages, setTotalPages] = useState(0);
  const [totalReults, setTotalResults] = useState(0);
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [releaseLegacy, setReleaseLegacy] = useState(storage.getReleaseLegacy());
  useEffect(() => {
    setLoading(false);
    memoryList({ page: currentPage, limit: rowPerPage,userid:id });
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    setLoading(false);
    memoryList({ page: currentPage, limit: rowPerPage,sortColumn:sortColumn,sortDirection:sortDirection,userid:id });
  }, [sortColumn,sortDirection]);
  
  const memoryList = async (params:any) => {
    setLoading(true);
    getMemoryListApiCall({ ...params, search: params.search?.trim() }).then(
      (response) => {
        setData(response.data.record);
        setTotalPages(response.data.totalPages);
        setTotalResults(response.data.totalResults);
        setLoading(false);
      }
    );
  };
  const MAX_LENGTH = constantValues.MaxLength;
  const truncateText = (text:any) => {
    if(text)
    {
        if (text.length > MAX_LENGTH)
        {
          return `${text.slice(0, MAX_LENGTH)}...`;
        }
        
    }
    return text;
  };
  const columns = [
    {
      id:'id',
      name: '#',
      sortable: true,
      center: true,
      selector: (row:any, index:any) => (currentPage - 1) * rowPerPage + index + 1,
      width: '60px',
    },
    {
      id:'legacy_title',
      selector: (row: any) => row.legacy_title,
      name: 'Title',
      sortable: true,
      minWidth: '150px',
      cell: (row: any) => {return truncateText(row.legacy_title)}
    },
    {
      id:'message',
      selector: (row: any) => row.message,
      name: 'Message',
      sortable: true,
      minWidth: '150px',
      cell: (row: any) => {return truncateText(row.message)}
    },
    {
      selector: (row: any) => row.type,
      name: 'Type',
      sortable: false,
      width: '80px',
      cell: (row: any) => row.type === 'message' ?
        (
           <div>
            <div className="d-flex align-items-center justify-content-center custom-small-badge">
              <MdChat size={10} />
            </div>
          </div>
        ) : (
          <div>
            <div className="d-flex bg-success align-items-center justify-content-center custom-small-badge">
              <IoIosVideocam size={10} />
            </div>
          </div>
         
        )
    },
    {
      id:'memory_recipients_name',
      selector: (row: any) => row.memory_recipients.name,
      name: 'Recipient',
      sortable: true,
      width: '200px',
    },
    {
      id:'created_at',
      selector: (row: any) => row.created_at,
      name: 'Creation Date',
      sortable: true,
      minWidth: '150px',
      cell: (row:any) =>
      moment(row.created_at).format(
        formatData.datetimeFormats.forwordSeparatedDateTime
      ),
    },
    {
      selector: (row: any) => row.action,
      name: 'Action',
      width: '100px',
      center: true,
      button: true,
      cell: (row: any) =>
      {
        const editStatus = releaseLegacy;
        return <ActionCell controlId="users/memories" isEditEnable={true} disabled={editStatus} redirectID={row.id} />;
      }
  
    },
  ];
 
  return (
    <div className="d-flex flex-column h-100 rounded-0 dataGridListMain">
      
      <DataGrid
        showTitle="Memory Details"
        columns={columns}
        data={data}
        pagination={true}
        totalPages={totalPages}
        totalRecords={totalReults}
        rowPerPage={rowPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        sortColumn={sortColumn}
        setSortColumn = {setSortColumn}
        sortDirection={sortDirection}
        setSortDirection = {setSortDirection}
  
      />
       <Loader isLoading={loading} />
    </div>
  )
}
