import { Route, Routes } from 'react-router-dom';
import { AllLegacyRelease } from './List/List';
import { View } from './View/view';

export const LegacyRelease = () => {
    return (
        <Routes>
            <Route path="/" element={<AllLegacyRelease />} />
            <Route path="/list" element={<AllLegacyRelease />} />
            <Route path="/view/:id" element={<View />} />
        </Routes>
    )
};