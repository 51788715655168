import { FC, useState } from 'react';
import { Button, Card, Form, Modal, Stack } from "react-bootstrap"
import { Formik } from "formik"
import TextAreaHorizontal from './TextAreaHorizontal';
import { LegacyReleaseSubmitSchema } from '../../validations/LegacyRelease';
import { EditEntitiyMethod } from '../../helpers/helpersMethods';
import { toast } from 'react-toastify';
import { releaselegacySubmit } from '../../services/userService';
import { CHARITY_UPDATED_SUCCESS } from '../../validations/ValidationErrors';
import { nameBasedProtectedRoutes } from '../../router/protected';
import { useNavigate } from 'react-router-dom';
import CustomTextArea from './CustomTextArea';
import Loader from './Loader';
interface IReleaseLegacyModal {
    show?: boolean;
    handleClose?: any;
    setDeletestatus?: any;
    recordDelete?: any;
    title?: string;
    details?: string;
    id?: any;
    setShow?: any;
    setShowReleaseLegacy?: any;
}

/**
 * Confirm Delete Modal Reusable Component
 * @date 4/20/2023 - 4:19:58 PM
 *
 * @param {{ show: any; handleClose: any; }} {
    controlId, show, handleClose
}
 * @returns {*}
 */

const ReleaseLegacyModal: FC<IReleaseLegacyModal> = ({
    show, handleClose, setDeletestatus, recordDelete, title, details, id, setShow, setShowReleaseLegacy
}) => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    /**
   * Add 
   * @date 19/05/2023 - 13:34:03
   *
   * @param {*} value
   */
    const submitForm = (value: any) => {
        toast.dismiss();
        EditEntitiyMethod(setLoading, releaselegacySubmit, id, value, CHARITY_UPDATED_SUCCESS, nameBasedProtectedRoutes.User.path + '/' + id, navigate);
        setTimeout(() => {
            setShow(false);
            setShowReleaseLegacy(false);
        }, 1000);

    }
    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered={true}
            scrollable={true}

            className="theme-modal"
        >       <div>
                <Formik
                    validationSchema={LegacyReleaseSubmitSchema}
                    initialValues={{
                        reason: '',
                    }}
                    onSubmit={(values, actions) => {
                        values.reason = values.reason.trim();
                        submitForm(values);
                    }}


                >
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        errors,
                        setFieldValue,
                    }) => (
                        <>
                            <Form className="" noValidate onSubmit={handleSubmit}>
                                <Modal.Header closeButton>
                                    <Modal.Title className="fs-16 fw-semibold">{title}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="pb-0">
                                    <div className="fw-semibold pb-2 mb-1">{details}</div>
                                    <CustomTextArea
                                        controlId="reason"
                                        label="Reason for Releasing Legacy"
                                        placeholder="Reason"
                                        touched={touched.reason}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        errorsField={errors.reason}
                                        value={values.reason}
                                    />
                                </Modal.Body>
                                <Modal.Footer className="pt-0 border-0">
                                    <Stack direction="horizontal" gap={3} className="flex-wrap pb-2">
                                        <Button variant="secondary" onClick={handleClose} className="text-dark fw-semibold fs-13 mw-90">
                                            Cancel
                                        </Button>
                                        <Button className="fw-semibold fs-13 text-white mw-90 my-1" variant="primary" type="submit">Release Legacy</Button>
                                    </Stack>
                                </Modal.Footer>
                            </Form>
                        </>
                    )}
                </Formik >
            </div>
            <Loader isLoading={loading} />
        </Modal>
    );
}

export default ReleaseLegacyModal