const apibasePathDevAdmin = process.env.REACT_APP_API_URL_ADMIN;

export const adminApi = {
  login: `${apibasePathDevAdmin}/auth/login`,
  forgotPassword: `${apibasePathDevAdmin}/auth/forgot-password`,
  logout: `${apibasePathDevAdmin}/auth/logout`,
  resetPassword: `${apibasePathDevAdmin}/auth/reset-password`,
  resetPasswordToken: `${apibasePathDevAdmin}/auth/reset-password-token`,
  createPassword: `${apibasePathDevAdmin}/auth/create-password`,
  userList:`${apibasePathDevAdmin}/user/list`,
  getUserData:`${apibasePathDevAdmin}/user/detail`,
  updateUserData:`${apibasePathDevAdmin}/user/update`,
  memoryList:`${apibasePathDevAdmin}/memory/list`,
  getMemoryDetailsById:`${apibasePathDevAdmin}/memory/details`,
  updateMemoryData:`${apibasePathDevAdmin}/memory/update`,
  trusteeList:`${apibasePathDevAdmin}/trustee/list`,
  gettrusteeDetailsById:`${apibasePathDevAdmin}/trustee/details`,
  updateTrusteeData:`${apibasePathDevAdmin}/trustee/update`,
  deleteTrusteeData:`${apibasePathDevAdmin}/trustee/delete`,
  recipientListData:`${apibasePathDevAdmin}/trustee/recipient-list`,
  getRuleDetails:`${apibasePathDevAdmin}/rule/detail`,
  updateFrequencyRule:`${apibasePathDevAdmin}/rule/update/frequency`,
  updateExecuteLegacyRule:`${apibasePathDevAdmin}/rule/update/execute-legacy`,
  updateSleepRule:`${apibasePathDevAdmin}/rule/update/sleep-time`,
  updateDndRule:`${apibasePathDevAdmin}/rule/update/dnd_date`,
  clearSleepRule:`${apibasePathDevAdmin}/rule/clear/sleep-time`,
  clearDndRule:`${apibasePathDevAdmin}/rule/clear/dnd-time`,
  changePassword:`${apibasePathDevAdmin}/other/change-password`,
  changeProfile:`${apibasePathDevAdmin}/other/change-profile`,
  faqList:`${apibasePathDevAdmin}/faq/list`,
  faqAdd:`${apibasePathDevAdmin}/faq/add`,
  faqDetails:`${apibasePathDevAdmin}/faq/details`,
  faqEdit:`${apibasePathDevAdmin}/faq/update`,
  faqDelete:`${apibasePathDevAdmin}/faq/delete`,
  cmsList:`${apibasePathDevAdmin}/cms/list`,
  cmsDetails:`${apibasePathDevAdmin}/cms/details`,
  cmsEdit:`${apibasePathDevAdmin}/cms/update`,
  planList:`${apibasePathDevAdmin}/plan/list`,
  planEdit:`${apibasePathDevAdmin}/plan/update`,
  planChange:`${apibasePathDevAdmin}/plan/change`,
  cmsBySlug:`${apibasePathDevAdmin}/cms/details-by-slug`,
  adsAdd:`${apibasePathDevAdmin}/ads/add`,
  adsList:`${apibasePathDevAdmin}/ads/list`,
  adsDetails:`${apibasePathDevAdmin}/ads/details`,
  adsEdit:`${apibasePathDevAdmin}/ads/update`,
  adsDelete:`${apibasePathDevAdmin}/ads/delete`,
  charityCategory:`${apibasePathDevAdmin}/charity/category-list`,
  charityAdd:`${apibasePathDevAdmin}/charity/add`,
  charityList:`${apibasePathDevAdmin}/charity/list`,
  charityDetails:`${apibasePathDevAdmin}/charity/details`,
  charityEdit:`${apibasePathDevAdmin}/charity/update`,
  organDonationList:`${apibasePathDevAdmin}/organ_donation/list`,
  organDonationDetails:`${apibasePathDevAdmin}/organ_donation/details`,
  organDonationEdit:`${apibasePathDevAdmin}/organ_donation/update`,
  organDonationDelete:`${apibasePathDevAdmin}/organ_donation/delete`,
  recipientsList:`${apibasePathDevAdmin}/recipient/list`,
  recipientsDetails:`${apibasePathDevAdmin}/recipient/details`,
  recipientsEdit:`${apibasePathDevAdmin}/recipient/update`,
  relationList:`${apibasePathDevAdmin}/recipient/relation-list`,
  ruleSetting:`${apibasePathDevAdmin}/rule/settings`,
  userCharityList:`${apibasePathDevAdmin}/charity/user-chairty-list`,
  aboutMeList:`${apibasePathDevAdmin}/about_me/list`,
  aboutMeDetails:`${apibasePathDevAdmin}/about_me/details`,
  aboutMeEdit:`${apibasePathDevAdmin}/about_me/update`,
  aboutMeDelete:`${apibasePathDevAdmin}/about_me/delete`,
  proclamationList:`${apibasePathDevAdmin}/proclamation/list`,
  proclamationDetails:`${apibasePathDevAdmin}/proclamation/details`,
  proclamationEdit:`${apibasePathDevAdmin}/proclamation/update`,
  proclamationDelete:`${apibasePathDevAdmin}/proclamation/delete`,
  proclamationCategory:`${apibasePathDevAdmin}/proclamation/category-list`,
  releaselegacyList:`${apibasePathDevAdmin}/releaselegacy/list`,
  releaselegacyDetails:`${apibasePathDevAdmin}/releaselegacy/details`,
  releaselegacyUpdate:`${apibasePathDevAdmin}/releaselegacy/update`,
  paymentList:`${apibasePathDevAdmin}/subscription/list`,
  proclamationGlobalList:`${apibasePathDevAdmin}/proclamation/global-status-updated-list`,
  proclamationGlobalEdit:`${apibasePathDevAdmin}/proclamation/global-status-updated-list`,
  releaselegacySubmit:`${apibasePathDevAdmin}/user/releaselegacy`,
  proclamationPublish:`${apibasePathDevAdmin}/proclamation/publishunpublishstatus`,
  proclamationGlobalPendingList:`${apibasePathDevAdmin}/proclamation/global-pending-status-updated-list`,
  proclamationGlobalApprove:`${apibasePathDevAdmin}/proclamation/approverejectstatus`,
  subscriptionExport:`${apibasePathDevAdmin}/subscription/export`,
  dashboard:`${apibasePathDevAdmin}/dashboard/info`,
  emailTemplateList:`${apibasePathDevAdmin}/template/list`,
  emailTemplateEdit:`${apibasePathDevAdmin}/template/update`,
  emailTemplateDetails:`${apibasePathDevAdmin}/template/details`,

};
