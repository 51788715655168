import { FC, useState } from 'react';
import { Button, OverlayTrigger, Stack, Tooltip } from 'react-bootstrap';
import { FaEdit, FaEye, FaTrash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import DeleteModal from '../DeleteModal';

interface IActionCell {
    controlId?: string;
    isViewEnable?: boolean;
    redirectID?: string;
    disabled?: boolean;
    isDeleteEnable?: boolean;
    isEditEnable?: boolean;
    handleDeleteClick?:any;
}


/**
 * Render Action Functions
 *
 * @param {{ controlId: any; isViewEnable: any; isSiteAssociated: any; }} {
    controlId, isViewEnable, isSiteAssociated
}
 * @returns {*}
 */
const ActionCell: FC<IActionCell> = ({
    controlId, isViewEnable, redirectID, isDeleteEnable, isEditEnable, disabled,handleDeleteClick
}) => {
    const navigate = useNavigate()
    const GoToEdit = () => {
        navigate(`/app/${controlId}/edit/${redirectID}`);
    }
    const GoToView = () => {
        navigate(`/app/${controlId}/view/${redirectID}`);
    }
    return (
        <>
            {/* <DeleteModal show={show} handleClose={handleClose} setDeletestatus={setDeletestatus}/> */}
            <Stack direction="horizontal" gap={3} className="align-items-center justify-content-center">
                {isViewEnable ?
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="view-tooltip">View</Tooltip>}
                    >
                        <div className="d-inline-block">
                            <Button onClick={GoToView} disabled={disabled} variant="link" className="p-0">
                                <FaEye size={15} />
                            </Button>
                        </div>
                    </OverlayTrigger>
                    : null}
                {isEditEnable ?
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="edit-tooltip">Edit</Tooltip>}
                    >
                        <div className="d-inline-block">
                            <Button onClick={GoToEdit} disabled={disabled} variant="link" className="p-0">
                                <FaEdit size={14} />
                            </Button>
                        </div>
                    </OverlayTrigger>
                    : null}
                {isDeleteEnable ?
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="delete-tooltip">Delete</Tooltip>}
                    >
                        <div className="d-inline-block">
                            <Button onClick={(handleDeleteClick)}  disabled={disabled} variant="link" className="p-0 text-danger">
                                <FaTrash size={13} />
                            </Button>
                        </div>
                    </OverlayTrigger>
                    : null}
            </Stack>
        </>

    );
}

export default ActionCell;