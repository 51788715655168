import * as React from 'react';
import { Button, Col, Navbar, Offcanvas, Row, Stack } from 'react-bootstrap';
import { FaCalendarDay, FaFilter } from 'react-icons/fa';
import { MdArrowDropDown } from 'react-icons/md';
import InputText from '../InputText';
import CustomSelect from '../CustomSelect';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from 'react';
import formatData from '../../../constants/formatData';
import moment from 'moment';
interface ITableFilters {
    planData?: any;
    viewData?: any;
    filterByDate?: any;
    filterByDateRange?: any;
    filterData?: any;
    setFilterData?: any;
    hideRightFilters?: boolean;
}

/**
 * Data Table Common Reusable Component
 * @date 4/21/2023 - 11:32:22 AM
 *
 * @param {{ planData: any; viewData: any; filterByDate: any; filterByDateRange: any; filterData: any; setFilterData: any; hideRightFilters: any; }} {
    planData, viewData, filterByDate, filterByDateRange, filterData, setFilterData, hideRightFilters
}
 * @returns {*}
 */

const TableFilters: React.FC<ITableFilters> = ({
    planData, viewData, filterByDate, filterByDateRange, filterData, setFilterData, hideRightFilters
}) => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [dateRange, setDateRange] = useState({
        startDate: null,
        endDate: null
    });


    const handleDateChange = (date: any) => {
        setSelectedDate(date);
        let formateDate: any = '';
        if (date) {
            formateDate = moment(date).format(
                formatData.dateFormats.databaseFormat
            );
        }

        setFilterData({
            ...filterData,
            ['searchByCreatedDate']: formateDate
        })
    };
    const handleDateRangeChange = (date: any) => {
        if (!dateRange.startDate || (dateRange.startDate && dateRange.endDate)) {
            setDateRange({
                startDate: date,
                endDate: null
            });
        } else if (dateRange.startDate && !dateRange.endDate && date >= dateRange.startDate) {
            setDateRange({
                ...dateRange,
                endDate: date
            });
        } else if (dateRange.startDate && dateRange.endDate && date < dateRange.startDate) {
            setDateRange({
                startDate: date,
                endDate: null
            });
        } else if (dateRange.startDate && dateRange.endDate && date > dateRange.startDate) {
            setDateRange({
                ...dateRange,
                endDate: date
            });
        }
    };
    React.useEffect(() => {
        let startDate = dateRange.startDate ? moment(dateRange.startDate).format(formatData.dateFormats.commaSeparatedDateTime):'';
        let endDate = dateRange.endDate ? moment(dateRange.endDate).format(formatData.dateFormats.commaSeparatedDateTime):'';
        if(startDate && endDate)
        {
            let dates = startDate + '-' + endDate;
            setFilterData({
                ...filterData,
                ['searchByCreatedDate']: dates
            })
        }
    }, [dateRange]);
    const expand = "md";
    return (
        <Navbar expand={expand} className="pb-0 pt-0 pt-md-2">
            <Navbar.Toggle as="div" className="p-0 border-0 mb-2" aria-controls={`filter-offcanvasNavbar-expand-${expand}`}>
                <Button variant="primary" className="fw-semibold fs-13 text-white mw-90">
                    <FaFilter size="11" className="me-1" /> Filter
                </Button>
            </Navbar.Toggle>
            <Navbar.Offcanvas
                id={`filter-offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`filter-offcanvasNavbarLabel-expand-${expand}`}
                className="fs-13 bg-white"
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title id={`filter-offcanvasNavbarLabel-expand-${expand}`}>
                        Filters
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="filterCover w-100">
                        <Row className="justify-content-between">
                            <Col md={3}>
                                <InputText
                                    controlId="search"
                                    placeholder="Search"
                                    handleChange={(event: any) => {
                                        setFilterData({
                                            ...filterData,
                                            [event.target.name]: event.target.value
                                        })
                                    }}
                                    name="search"
                                    value={filterData.search}
                                    size="sm"
                                />
                            </Col>
                            {!hideRightFilters ?
                                <Col md="auto">
                                    <Stack direction="horizontal" gap={2} className="align-items-md-center align-items-start flex-column flex-md-row">
                                        <span className="mb-md-3">View by</span>
                                        <Stack direction="horizontal" gap={2} className="justify-content-between flex-column flex-md-row">
                                            {filterByDateRange ?
                                                <>
                                                    <div className="w-150 mb-3">
                                                        {/* <InputText
                                                            controlId="viewByFromDate"
                                                            placeholder="From Date"
                                                            handleChange={(event: any) => {
                                                                setFilterData({
                                                                    ...filterData,
                                                                    [event.target.name]: event.target.value
                                                                })
                                                            }}
                                                            name="viewByFromDate"
                                                            value={filterData.viewByFromDate}
                                                            rightIcon={<FaCalendarDay size={10} className="text-dark" />}
                                                            size="sm"
                                                        /> */}
                                                        <DatePicker
                                                            selected={dateRange.startDate}
                                                            onChange={handleDateRangeChange}
                                                            selectsStart
                                                            startDate={dateRange.startDate}
                                                            endDate={dateRange.endDate}
                                                            dateFormat="MM/dd/yyyy"
                                                            placeholderText="From Date"
                                                            className="form-control form-control-sm  theme-calendar-icon"
                                                        />

                                                    </div>
                                                    <div className="w-150 mb-3">
                                                        <DatePicker
                                                            selected={dateRange.endDate}
                                                            onChange={handleDateRangeChange}
                                                            selectsEnd
                                                            startDate={dateRange.startDate}
                                                            endDate={dateRange.endDate}
                                                            dateFormat="MM/dd/yyyy"
                                                            placeholderText="End Date"
                                                            minDate={dateRange.startDate}
                                                            className="form-control form-control-sm  theme-calendar-icon"
                                                        />
                                                    </div>
                                                </>
                                                : null
                                            }
                                            {/* {planData ?
                                                <div className="w-120">
                                                    <CustomSelect
                                                        controlId="viewByPlan"
                                                        handleChange={(event: any) => {
                                                            setFilterData({
                                                                ...filterData,
                                                                [event.target.name]: event.target.value
                                                            })
                                                        }}
                                                        name="sortByPlan"
                                                        value={filterData.sortByPlan}
                                                        data={planData}
                                                        placeholder="Plan"
                                                        rightIcon={<MdArrowDropDown size="23" />}
                                                        size="sm"
                                                    />
                                                </div>
                                                : null
                                            } */}
                                            {viewData ?
                                                <div className="w-120">
                                                    <CustomSelect
                                                        controlId="viewByStatus"
                                                        handleChange={(event: any) => {
                                                            setFilterData({
                                                                ...filterData,
                                                                [event.target.name]: event.target.value
                                                            })
                                                        }}
                                                        name="sortByStatus"
                                                        value={filterData.sortByStatus}
                                                        data={viewData}
                                                        placeholder="Status"
                                                        rightIcon={<MdArrowDropDown size="23" />}
                                                        size="sm"
                                                    />
                                                </div>
                                                : null
                                            }
                                            {filterByDate ?
                                                <div className="w-150 mb-3">
                                                    <DatePicker
                                                        selected={selectedDate}
                                                        onChange={handleDateChange}
                                                        dateFormat={formatData.dateFormats.dateSearchFormat}
                                                        placeholderText="Created Date"
                                                        maxDate={new Date()}
                                                        className="form-control form-control-sm  theme-calendar-icon"
                                                    />
                                                </div>
                                                // <div className="w-150">
                                                //     <InputText
                                                //         controlId="viewByCreatedDate"
                                                //         placeholder="Created Date"
                                                //         handleChange={(event: any) => {
                                                //             setFilterData({
                                                //                 ...filterData,
                                                //                 [event.target.name]: event.target.value
                                                //             })
                                                //         }}
                                                //         name="sortByCreatedDate"
                                                //         value={filterData.sortByCreatedDate}
                                                //         rightIcon={<FaCalendarDay size={10} className="text-dark" />}
                                                //         size="sm"
                                                //     />
                                                // </div>
                                                : null
                                            }
                                        </Stack>
                                    </Stack>
                                </Col>
                                : null}
                        </Row>
                    </div>
                </Offcanvas.Body>
            </Navbar.Offcanvas>
        </Navbar>
    )
}
export default TableFilters;
