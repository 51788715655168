import React, { useEffect, useState } from 'react';

import { Button, Card, Col, Form, Image, Row, Stack } from "react-bootstrap"
import { IoIosArrowBack, IoIosMusicalNotes, IoIosPlayCircle } from "react-icons/io"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Formik } from "formik"
import { AboutMeSchema } from "../../../../../../validations/Users"
import InputTextHorizontal from "../../../../../../components/common/InputTextHorizontal"
import CustomSelectHorizontal from "../../../../../../components/common/CustomSelectHorizontal"
import { deleteAboutme, edit, getDetails } from '../../../../../../services/aboutMeService';
import { toast } from 'react-toastify';
import Loader from '../../../../../../components/common/Loader';
import { recipientListData } from '../../../../../../services/userService';
import { EditEntitiyMethod, handleFileUpload } from '../../../../../../helpers/helpersMethods';
import { ABOUTME_UPDATED_SUCCESS, FILE_SIZE_10MB, FILE_TYPE } from '../../../../../../validations/ValidationErrors';
import { FaImage } from 'react-icons/fa';
import ImageModal from '../../../../../../components/common/ImagePreview';
import DeleteModal from '../../../../../../components/common/DeleteModal';

export const EditAboutMe = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [redirect, setRedirect] = useState<any>();
  const [data, setData] = useState<any>();
  const [imageUrl, setImageUrl] = useState<string>('');
  const [recipientList, setRecipientList] = useState<any>([]);
  const [isAudioPopupOpen, setIsAudioPopupOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [deleteRecordId, setDeleteRecordId] = useState();
  const [deletestatus, setDeletestatus] = useState(false);

  const mediaTypeData = [
    { id: 'audio', name: 'Audio' },
    { id: 'image', name: 'Image' },
    { id: 'video', name: 'Video' }
  ];

  const recipientData = [
    { id: '1', name: 'John Simphsons' },
    { id: '2', name: 'John Carlo' },
    { id: '3', name: 'John Due' }
  ];

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  // const handleFileChange = (event: any) => {
  //   if (event.target.files && event.target.files.length > 0) {
  //     setSelectedFile(event.target.files[0]);
  //   }
  // };
  useEffect(() => {
    setLoading(true);
    if (id) {
      getDetails(id).then(
        (resp) => {
          setLoading(false);
          const res = resp?.data;
          setData(res);
          setRedirect(`/app/users/view/${res.user_id}`);
          selectList(res.user_id);
          if (res.asset_url_name) {
            setImageUrl(res.asset_url_name);
          }
        },
        (error: any) => {
          setLoading(false);
          const err = error?.response?.data;
          if (err?.statusCode === 400) {
            toast.error(err.msg);
            navigate(`/app/users`);
          }
        }
      );
    }
  }, [id]);
  const selectList = async (id: any) => {
    if (id) {
      await recipientListData(id).then(
        (resp) => {
          setLoading(false);
          const res = resp?.data;
          setRecipientList(res);
        },
        (error: any) => {
          setLoading(false);
          const err = error?.response?.data;
          if (err?.statusCode === 400) {
            toast.error(err.msg);
            navigate(`/app/users`);
          }
        }
      );
    }
  };
  const handleFileChange = (event: any, fileType: string, setFieldValue: (field: string, value: any) => void) => {
    const file = event.target.files?.[0];
    try {
      const fileUrl = handleFileUpload(file, fileType);
      setFieldValue('asset_file', file);
      setImageUrl(fileUrl);
    } catch (error: any) {
      toast.error(error.message);
    }
  };
  const handleAudioPopupOpen = () => {
    setIsAudioPopupOpen(true);
  };
  
  // Function to handle closing the audio popup
  const handleAudioPopupClose = () => {
    setIsAudioPopupOpen(false);
  };
  //delete
  const handleDeleteClick = (id:any) =>
  {
    if(id)
    {
      setDeleteRecordId(id);
    }
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };
  const recordDelete = ()=>
  {
    if(deleteRecordId)
    {
      setLoading(true);
      setShow(false);
      deleteAboutme(deleteRecordId).then(
        (resp:any) => {
        toast.success(resp.msg);
        navigate(redirect);
        },
        (error: any) => {
          const err = error?.response?.data;
          setLoading(false);
           setShow(false);
          if (err?.statusCode === 400) {
            toast.error(err.msg);
            navigate(redirect);
          }

        }
      );
    }
  }

  /**
    * Editing about me
    *
    * @param {*} value
    */
  const submitForm = (value: any) => {
    if (id && data) {
      EditEntitiyMethod(setLoading, edit, id, value, ABOUTME_UPDATED_SUCCESS, redirect, navigate);
    }
  }
  return (
    <>
      {data && redirect ? <Formik
        validationSchema={AboutMeSchema}
        initialValues={{
          media_name: data ? data.media_name : '',
          media_type: data ? data.media_type : '',
          recipient_id: data ? data.recipient_id.toString() : '',
          asset_file: data ? data.asset_file : ''

        }}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          submitForm(values)
        }}

      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
          setFieldValue
        }) => (
          <Form className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1" noValidate onSubmit={handleSubmit}>
            <div className="pageHeader px-3 my-1 pt-1 user-select-none">
              <Row className="align-items-center min-h-51">
                <Col>
                  <h1 className="fw-semibold h4 my-2">
                    <Link to={redirect} className="align-items-center d-inline-flex overflow-hidden text-dark text-decoration-none">
                      <span className="me-1 ms-n1">
                        <IoIosArrowBack size="1.68rem" />
                      </span> Users
                    </Link>
                  </h1>
                </Col>
              </Row>
            </div>
            <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
              <Card className="h-100 w-100 overflow-auto border-light-subtle pageContentInner">
                <Card.Header className="bg-white fs-16 fw-semibold p-3 border-secondary">
                  <Card.Text className="px-xl-1">Edit About Me</Card.Text>
                </Card.Header>
                <Card.Body className="p-xl-4">

                  <CustomSelectHorizontal
                    leftColSmall={true}
                    label="Media Type"
                    controlId="mediaType"
                    placeholder="Select"
                    touched={touched.media_type}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.media_type}
                    value={values.media_type}
                    data={mediaTypeData}
                    disabled={true}
                  />

                  <InputTextHorizontal
                    leftColSmall={true}
                    label="Title"
                    controlId="media_name"
                    placeholder="Enter title"
                    touched={touched.media_name}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.media_name}
                    value={values.media_name}
                  />

                  <CustomSelectHorizontal
                    leftColSmall={true}
                    label="Recipient"
                    controlId="recipient_id"
                    placeholder="Select"
                    touched={touched.recipient_id}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.recipient_id}
                    value={values.recipient_id.toString()}
                    data={recipientList}
                  />

                  <div className="theme-form-horizontal pb-sm-1 mb-3">
                    <Row>
                      <Col sm="auto" className="left-col left-col-sm">
                        <Stack direction="horizontal" gap={3} className="justify-content-between pb-2 py-sm-1">
                          <label className="fw-semibold">Media</label>
                        </Stack>
                      </Col>
                      <Col sm="auto" className="right-col">
                        <Row className="gx-3">
                          <Col xs="auto">
                            <div className="align-items-center border d-flex h-85 justify-content-center overflow-hidden position-relative rounded text-light w-85">
                              {values.media_type === 'image' && (
                                <>
                                  {!imageUrl && <FaImage size={32} />}
                                  {imageUrl && <Image width={85} height={85} src={imageUrl} alt="Profile Image" className="cursor-pointer" onClick={handleAudioPopupOpen}/>}
                                </>
                              )}
                              {values.media_type === 'audio' && (
                                <>
                                {!imageUrl && <IoIosMusicalNotes size={32} />}
                                {imageUrl && <><div className="w-100 h-100 d-block cursor-pointer" onClick={handleAudioPopupOpen}>
                                  <span className="position-absolute start-50 text-white top-50 translate-middle" >
                                    <svg width={58} height={58} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 58">
                                      <defs>
                                        <filter id="videoIcon" width="58" height="58" x="0" y="0" filterUnits="userSpaceOnUse">
                                          <feOffset />
                                          <feGaussianBlur result="blur" stdDeviation="5" />
                                          <feFlood floodOpacity=".78" />
                                          <feComposite in2="blur" operator="in" />
                                          <feComposite in="SourceGraphic" />
                                        </filter>
                                      </defs>
                                      <g filter="url(#videoIcon)">
                                        <path fill="currentColor" d="M29.003 15.003a14 14 0 1 0 14 14 14 14 0 0 0-14-14Zm5.64 14.263-9.235 5.587a.3.3 0 0 1-.451-.263V23.416a.3.3 0 0 1 .451-.262l9.235 5.587a.309.309 0 0 1 0 .524Z" />
                                      </g>
                                    </svg>
                                  </span>
                                  <video width={85} height={85} src={imageUrl}  /> 
                                </div></>}
                                </>
                              )}
                              {values.media_type === 'video' && (
                                <>
                                  {!imageUrl && <IoIosMusicalNotes size={32} />}
                                  {imageUrl && <><div className="w-100 h-100 d-block cursor-pointer" onClick={handleAudioPopupOpen}>
                                  <span className="position-absolute start-50 text-white top-50 translate-middle" >
                                    <svg width={58} height={58} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 58">
                                      <defs>
                                        <filter id="videoIcon" width="58" height="58" x="0" y="0" filterUnits="userSpaceOnUse">
                                          <feOffset />
                                          <feGaussianBlur result="blur" stdDeviation="5" />
                                          <feFlood floodOpacity=".78" />
                                          <feComposite in2="blur" operator="in" />
                                          <feComposite in="SourceGraphic" />
                                        </filter>
                                      </defs>
                                      <g filter="url(#videoIcon)">
                                        <path fill="currentColor" d="M29.003 15.003a14 14 0 1 0 14 14 14 14 0 0 0-14-14Zm5.64 14.263-9.235 5.587a.3.3 0 0 1-.451-.263V23.416a.3.3 0 0 1 .451-.262l9.235 5.587a.309.309 0 0 1 0 .524Z" />
                                      </g>
                                    </svg>
                                  </span>
                                  <video width={85} height={85} src={imageUrl}  /> 
                                </div></>}
                                </>
                              )}

                            </div>
                          </Col>

                          <Col xs className="mw-1">
                            <Form.Group controlId="formFile" className="mb-3">
                              <Stack direction="horizontal" gap={2}>
                                <div className="position-relative">
                                  <Form.Control type="file" className="bottom-0 end-0 opacity-0 position-absolute start-0 top-0 z-1" onChange={(event) => handleFileChange(event, values.media_type, setFieldValue)} />
                                  <Button className="fw-semibold fs-13 text-dark mw-90" variant="secondary">Upload</Button>
                                </div>
                                
                                {values.asset_file && (
                                  <Form.Text className="my-1 text-truncate" muted>{(values.asset_file as File)?.name}</Form.Text>
                                )}
                              </Stack>
                              {
                                values.media_type === "image" ? (<div className="fs-13 fst-italic fw-semibold text-dark text-opacity-50 my-1">Max 10MB</div>)
                                  : (<div className="fs-13 fst-italic fw-semibold text-dark text-opacity-50 my-1">Max 15MB </div>)
                              }

                            </Form.Group>

                          </Col>
                        </Row>
                        {errors.asset_file ? <div>
                          <p className='invalid-feedback' style={{ display: 'block' }}>Field is Required.</p>
                        </div> : ''}

                      </Col>
                    </Row>

                  </div>
                  <div className="theme-form-horizontal py-3">
                    <Row>
                      <Col sm="auto" className="left-col left-col-sm"></Col>
                      <Col sm="auto" className="right-col">
                        <Stack direction="horizontal" gap={3}>
                          <Button className="fw-semibold fs-13 text-white mw-90 my-1" variant="primary" type="submit">Update</Button>
                          <Link to={redirect} className="btn btn-secondary text-dark fw-semibold fs-13 mw-90 my-1">Cancel</Link>
                          <Button className="fw-semibold fs-13 text-white mw-90 my-1" variant="danger"onClick={()=>handleDeleteClick(id)}>Delete</Button>
                        </Stack>
                      </Col>
                    </Row>
                  </div>

                </Card.Body>
              </Card>
            </div>
            {isAudioPopupOpen && (

            <ImageModal
            show={isAudioPopupOpen}
            handleClose={() => setIsAudioPopupOpen(false)}
            imageUrl={imageUrl} contentType={values.media_type}/>
            )};             
          </Form>
        )}
         
      </Formik > 
      : ''}
      <Loader isLoading={loading} />
      <DeleteModal show={show} handleClose={handleClose} setDeletestatus={setDeletestatus} recordDelete={recordDelete}/>
            
    </>

  )
}
