import { FC, useEffect, useState } from 'react';
import { WithContext as ReactTags, Tag } from 'react-tag-input';
import { Col, Form, Row, Stack } from 'react-bootstrap';

interface ITagsHorizontal {
    label?: string;
    setColunView?: boolean;
    leftColSmall?: boolean;
    value?: Tag[];
    setFieldValue?:any;
    name?:String;
    errorsField?:any;
    controlId?:any;
}

/**
 * Tags Horizontal Reusable Component
 * @date 4/20/2023 - 5:12:36 PM
 *
 * @param {{ label: any; value: any; setColunView: any; leftColSmall: any; }} {
    label, value, setColunView, leftColSmall
}
 * @returns {*}
 */

const TagsHorizontal: FC<ITagsHorizontal> = ({
    label, setColunView, leftColSmall,value,setFieldValue,name,errorsField,controlId
}) => {
    const [tags, setTags] = useState<Tag[]>(value || []);
    useEffect(() =>
    {
        if (value && value.length > 0) {
            setFieldValue(name,tags);
        }
      }, []); // Include tags as a dependency
    
      const handleTagAddition = (tag: Tag) => {
        const tagWithoutSpaces = tag.text.replace(/\s+/g, ''); // Remove spaces from tag text
      
        if (tagWithoutSpaces !== '') {
          const modifiedTag = { ...tag, text: tagWithoutSpaces }; // Create a new tag object with the modified text
      
          // Check if the tag is already in the tags state
          const isTagDuplicate = tags.some((existingTag) => existingTag.text === tagWithoutSpaces);
      
          if (!isTagDuplicate) {
            const newTags = [...tags, modifiedTag];
            setTags(newTags);
            setFieldValue(name, newTags);
          }
        }
      };
  
    const handleDelete = (i: number) => {
        const newTags = [...tags];
        newTags.splice(i, 1);
        setTags(newTags);
        setFieldValue(name,newTags);
      };
    return (
        <Form.Group className="theme-form-horizontal pb-sm-1 mb-3" controlId={controlId}>
            <Row>
                <Col sm="auto" className={`left-col ${leftColSmall ? 'left-col-sm' : ''}`}>
                    {label ? (
                        <Stack direction="horizontal" gap={2} className="justify-content-sm-between custom-tag-label">
                            <Form.Label>{label}</Form.Label>
                            {setColunView ? <span className="colun-content">:</span> : null}
                        </Stack>
                    ) : null}
                </Col>
                <Col sm>
                    <div className="position-relative right-col">
                        <ReactTags
                            tags={tags}
                            handleDelete={handleDelete}
                            handleAddition={handleTagAddition}
                            inputFieldPosition="top"
                            allowDragDrop={false}
                            placeholder="Add Tag"
                            maxLength={15} 
                            autofocus={false}
                            classNames={{
                                tagInputField: 'form-control',
                                tag: 'align-items-center badge bg-secondary d-inline-flex me-2 mt-2 rounded-pill text-dark',
                                remove: 'btn-close ms-1 fs-8 p-1',
                            }}
                        />
                        <Form.Control.Feedback type="invalid" className={errorsField?"d-block":''}>
                            {errorsField}
                        </Form.Control.Feedback>
                    </div>
                </Col>
            </Row>
        </Form.Group>
    );
}

export default TagsHorizontal;