import { FC, useState } from 'react';
import { Button, Form, Modal, Stack } from 'react-bootstrap';
import InputText from '../../../components/common/InputText';
import { FaCalendarDay } from 'react-icons/fa';
import { Formik } from 'formik';
import { LegacyReleaseSubmitSchema, proclamationApproveSchema } from '../../../validations/LegacyRelease';
import { toast } from 'react-toastify';
import Loader from '../../../components/common/Loader';
import { useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import moment from 'moment';
import formatData from '../../../constants/formatData';
import "react-datepicker/dist/react-datepicker.css";
import { proclamationApprove } from '../../../services/PproclamationService';
import { EditEntitiyMethod } from '../../../helpers/helpersMethods';
import { CHARITY_UPDATED_SUCCESS } from '../../../validations/ValidationErrors';
import { nameBasedProtectedRoutes } from '../../../router/protected';
interface IApproveModal {
    show?: any;
    onHide?: any;
    details?:string;
    id?:any;
}

/**
 * Custom Approve Modal Component
 * @date 4/20/2023 - 1:36:02 PM
 *
 * @param {{ show: any; onHide: any; }} {
    show, onHide
}
 * @returns {*}
 */

export const ApproveModal: FC<IApproveModal> = ({
    show, onHide,details,id
}) => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const minDate = moment().toDate();
    
    const handleDateChangeRaw = (e:any) =>
    {
        e.preventDefault();
    }
    const submitForm = (value: any) => {
        toast.dismiss();
        EditEntitiyMethod(setLoading, proclamationApprove,id, value, CHARITY_UPDATED_SUCCESS, nameBasedProtectedRoutes.pendingProclamation.path, navigate);
    }
    return (
        <Modal
            show={show}
            onHide={onHide}
            backdrop="static"
            keyboard={false}
            centered={true}
            scrollable={false}
            size="sm"
            className="theme-modal"
        >
            <Formik
                    validationSchema={proclamationApproveSchema}
                    initialValues={{
                        expired_at: '',
                        status: 'accepted',
                        reason: null
                    }}
                    onSubmit={(values, actions) => {
                        values.expired_at = values.expired_at.trim();
                        submitForm(values);
                    }}


                >
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        errors,
                        setFieldValue,
                    }) => (
            <>
            <Form className="" noValidate onSubmit={handleSubmit}>
            <Modal.Header closeButton>
                <Modal.Title className="fs-16 fw-semibold">Approve Proclamation</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pb-2">
                <div className="fs-14 fw-semibold pb-2 mb-1">{details}</div>
                {/* <InputText
                    controlId="expiryDate"
                    placeholder="MM/DD/YYYY"
                    rightIcon={<FaCalendarDay size={10} className="text-dark" />}
                    size="sm"
                /> */}
                 <DatePicker
                            selected={values.expired_at ? moment(values.expired_at).toDate() : null}
                            onChange={(date) => {
                              const formattedDate = moment(date).format("YYYY-MM-DD");
                              setFieldValue("expired_at", formattedDate);
                            }}
                            
                            className="form-control"
                            placeholderText="MM/DD/YYYY"
                            value={values.expired_at ? moment(values.expired_at).format(formatData.dateFormats.commaSeparatedDateTime):''}
                            onChangeRaw={handleDateChangeRaw}
                            minDate={minDate}
                            
                            
                          />
                          <Form.Control.Feedback type="invalid" className={`${!!errors.expired_at  ? 'd-block' : null}`}>
                            {errors.expired_at}
                        </Form.Control.Feedback>
                          
            </Modal.Body>
            <Modal.Footer className="pt-0 border-0">
                <Stack direction="horizontal" gap={3} className="flex-wrap pb-2">
                    <Button variant="secondary" onClick={onHide} className="text-dark fw-semibold fs-13 mw-90">
                        Cancel
                    </Button>
                    <Button variant="success" className="fw-semibold fs-13 text-white mw-90" type="submit">Approve</Button>
                </Stack>
            </Modal.Footer>
            </Form>
            </>
             )}
             </Formik >
             <Loader isLoading={loading} />       
        </Modal>
    )
}