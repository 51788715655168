import { FC } from 'react';
import { Badge, Col, Form, Row, Stack } from 'react-bootstrap';
import { IoClose } from "react-icons/io5";

interface IViewTagsHorizontal {
    label?: string;
    value?: any;
    setColunView?: boolean;
    leftColSmall?: boolean;
}


/**
 * View Tags Horizontal Reusable Component
 * @date 4/19/2023 - 7:51:30 PM
 *
 * @param {{ label: any; value: any; setColunView: any; leftColSmall: any; }} {
    label, value, setColunView, leftColSmall
}
 * @returns {*}
 */

const ViewTagsHorizontal: FC<IViewTagsHorizontal> = ({
    label, value, setColunView, leftColSmall
}) => {
    return (
        <Form.Group className="theme-form-horizontal pb-sm-2">
            <Row>
                <Col sm="auto" className={`left-col ${leftColSmall ? 'left-col-sm' : ''}`}>
                    {label ? (
                        <Stack direction="horizontal" gap={2} className="justify-content-sm-between custom-tag-label">
                            <Form.Label>{label}</Form.Label>
                            {setColunView ? <span className="colun-content mb-1">:</span> : null}
                        </Stack>
                    ) : null}
                </Col>
                <Col sm>
                    <div className="position-relative right-col">
                        <div className="text-data">
                            {value.map((data: any) => {
                                return (
                                    <Badge key={data.id} pill bg="secondary" text="dark" className="mb-3 me-3">
                                        {data.text}
                                    </Badge>
                                )
                            })}

                        </div>
                    </div>
                </Col>
            </Row>
        </Form.Group>
    );
}

export default ViewTagsHorizontal;