import { ForgetPassword } from "../pages/Auth/ForgetPassword";
import { Login } from "../pages/Auth/Login";
import { ResetPassword } from "../pages/Auth/ResetPassword";
import { TermCondition } from "../pages/Auth/termCondition";
import { About } from "../pages/Auth/termCondition/about";
import { Memory } from "../pages/Auth/termCondition/memory ";
import { Privacy } from "../pages/Auth/termCondition/privacy";
// import { MultifactorAuth } from "../pages/Auth/MultifactorAuth";

const basePath = process.env.REACT_APP_BASE_NAME;
/**
 * Public Routes Available in App
 * @type {{}}
 */

export const publicRoutes = [
  {
    path: `/`,
    element: <Login />,
  },
  {
    path: `/login`,
    element: <Login />,

  },
  //   {
  //     path: "verification",
  //     element: <MultifactorAuth />,
  //   },
  {
    path: `/forgot-password`,
    element: <ForgetPassword />,
  },
  {
    path: `/reset-password/:token?`,
    element: <ResetPassword />,
  },
  {
    path: `/token-expired`,
    element: <ResetPassword />,
  },
  {
    path: `/term-conditions`,
    element: <TermCondition />,
  },
  {
    path: `/privacy-policy`,
    element: <Privacy />,
  },
  {
    path: `/about-us`,
    element: <About />,
  },
  {
    path: `/website/memory`,
    element: <Memory />,
  },
];
export const nameBasedRoutes = {
  home: {
    name: "Home",
    path: `/`,
  },
  login: {
    name: "Login",
    path: `/login`,
  },
  forgotPassword: {
    name: "Forgot Passowrd",
    path: `forgot-password`,
  },
  resetPassword: {
    name: "Reset Password",
    path: `reset-password`,
  }
};
