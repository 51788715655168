import { Card, Nav, Tab } from "react-bootstrap"
import './user-tabs.scss';
import { Memories } from "./Memories/Memories";
import { Trustee } from "./DigitalTrustee/Trustee";
import { RulesExecuteLegacy } from "./RulesExecuteLegacy/RulesExecuteLegacy";
import { AboutMe } from "./AboutMe/AboutMe";
import { OrganDonar } from "./OrganDonar/OrganDonar";
import { Recipients } from "./Recipients/Recipients";
import { PublicProclamation } from "./PublicProclamation/PublicProclamation";
import { useEffect, useMemo, useState } from "react";
import storage from "../../../../helpers/storage";
import { CharityList } from "./Charity/List";

export const UserTabs = () => {
  let tabs = useMemo(() => storage.getTab() ?? 'tab-1', []);
  const [currentTab, setCurrentTab] = useState(tabs);
  useEffect(() =>
  {
    storage.setTab(currentTab);
   
  }, [currentTab]);
  const userTabsData = [
    {
      id: 1,
      name: 'Memories',
      Component: <Memories />
      
    },
    {
      id: 2,
      name: 'Digital Trustee',
      Component: <Trustee/>
    },
    {
      id: 3,
      name: 'Rules to Execute Legacy',
      Component: <RulesExecuteLegacy />
    },
    {
      id: 4,
      name: 'Organ Donar',
      Component: <OrganDonar />
    },
    {
      id: 5,
      name: 'About Me',
      Component: <AboutMe />
    },
    {
      id: 6,
      name: 'Charity',
      Component: <CharityList/>
    },
    {
      id: 7,
      name: 'Recipients',
      Component: <Recipients/>
    },
    {
      id: 8,
      name: 'Public Proclamation',
      Component: <PublicProclamation />
    }
  ]

  return (
    <Card className="bg-white py-2 w-100 pageContentInner flex-grow-1 overflow-auto">
      <Tab.Container id="user-tab-container" defaultActiveKey={currentTab} onSelect={(key:any) => setCurrentTab(key)}>
        <div className="d-flex flex-column h-100">
          <div className="theme-tab-header">
          <Nav variant="tabs" className="px-1 flex-nowrap text-nowrap overflow-x-auto overflow-y-hidden" fill>
            {userTabsData.map((tabNames) => {
              const { id, name } = tabNames;
              return (
                <Nav.Item key={id}>
                  <Nav.Link eventKey={`tab-${id}`} className="border-4 border-end-0 border-start-0 border-top-0 fw-semibold">{name}</Nav.Link>
                </Nav.Item>
              )
            })}
          </Nav>
          </div>

          <Tab.Content className="theme-tab-content flex-grow-1 px-3 py-2 overflow-auto">
            {userTabsData.map((tabNames) => {
              const { id, Component } = tabNames;
              return (
                <Tab.Pane className="h-100" key={currentTab === `tab-${id}` ? `${id}-active` : `${id}-inactive`} eventKey={`tab-${id}`} mountOnEnter>
                <div>{Component}</div>
               </Tab.Pane>
              )
            })}
          </Tab.Content>

        </div>
      </Tab.Container>
    </Card>
  )
}
