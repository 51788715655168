import * as React from 'react';
import ReactPaginate from 'react-paginate'

interface IDataGridPagination {
    rowsPerPage?: any;
    currentPage?: any;
    totalPages?:any;
    totalRecords?:any
    setCurrentPage?:any;
    currentTotalRecord?:any;
}

/**
 * Data Grind Pagination Reusable Component
 * @date 4/13/2023 - 7:10:05 PM
 *
 * @param {{ rowsPerPage: any; currentPage: any; }} {
    rowsPerPage, currentPage
}
 * @returns {*}
 */

const DataGridPagination: React.FC<IDataGridPagination> = ({
    rowsPerPage, currentPage, totalPages,totalRecords,setCurrentPage,currentTotalRecord
}) => {

    const handlePagination = (page:any)=>{
        console.log("pagenum", page)
        setCurrentPage(page.selected+1)
    }
    return (
        <div className='align-items-center d-flex flex-column flex-md-row justify-content-between mb-2 mt-3'>
            <div className="fs-13 fw-semibold pageShowText mb-2 mb-md-0">Showing {1 + (currentPage - 1) * rowsPerPage} to {currentTotalRecord + (currentPage - 1) * rowsPerPage} of {totalRecords} entries</div>
            <ReactPaginate
                previousLabel="Previous"
                nextLabel="Next"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="flex-wrap justify-content-center mb-0 pagination user-select-none"
                activeClassName="active"
                pageCount={totalPages}
                marginPagesDisplayed={1}
                pageRangeDisplayed={4}
                forcePage={currentPage-1}
                onPageChange={(page) => handlePagination(page)}
            />
        </div>
    )
}
export default DataGridPagination;