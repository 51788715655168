import { FC } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

interface ICustomSelectHorizontalPhone {
    controlId: string;
    label?: string;
    handleChange?: any;
    handleBlur?: any;
    errorsField?: any;
    touched?: any;
    value?: any;
    data?: any;
    placeholder?: string
    id?: string;
    rightIcon?: any;
    size?: any;
    name?: any;
    setColunView?: boolean;
    leftColSmall?: boolean;
    disabled?: boolean;
}

/**
 * Custom Select Horizontal Reusable Component
 * @date 4/17/2023 - 10:12:34 AM
 *
 * @param {{ controlId: any; label: any; handleChange: any; handleBlur: any; errorsField: any; touched: any; value: any; data: any; placeholder: any; id: any; rightIcon: any; size: any; name: any; setColunView: any; leftColSmall: any; }} {
    controlId, label, handleChange, handleBlur, errorsField, touched, value, data, placeholder, id, rightIcon, size, name, setColunView, leftColSmall
}
 * @returns
 */

const CustomSelectHorizontalPhone: FC<ICustomSelectHorizontalPhone> = ({
    controlId, label, handleChange, handleBlur, errorsField, touched, value, data, placeholder, id, rightIcon, size, name, setColunView, leftColSmall, disabled
}) => {
    return (
        <Form.Group className="theme-form-horizontal pb-sm-1 mb-3" controlId={controlId}>
            <Row>
                <Col sm="auto" className={`left-col ${leftColSmall ? 'left-col-sm' : ''}`}>
                    {label ? (
                        <Stack direction="horizontal" gap={2} className={`justify-content-sm-between ${size ? 'h-35' : 'h-40'}`}>
                            <label className="fw-semibold">{label}</label>
                            {setColunView ? <span className="colun-content">:</span> : null}
                        </Stack>
                    ) : null}
                </Col>
                <Col sm>
                    <div className={`position-relative right-col ${rightIcon ? 'form-right-icon' : ''}`}>
                        <Form.Select disabled={disabled} name={name} size={size} aria-label="Select" value={value} onChange={handleChange} onBlur={handleBlur} isInvalid={!!errorsField && touched}>
                            <option value=''>{placeholder}</option>
                            {data.map((e: any) => {
                                if(e.name != 'Canada')
                                {
                                    const key = e.dial_code ? e.dial_code : '';
                                    return <option key={e.name} value={key}>{e.name} ({e.dial_code})</option>
                                }
                               
                            })}
                        </Form.Select>
                        {rightIcon ? (
                            <span className={`align-items-center d-flex end-0 justify-content-center pe-none position-absolute px-2 top-0 ${size ? 'h-35' : 'h-40'}`}>
                                {rightIcon}
                            </span>
                        ) : null}
                        <Form.Control.Feedback type="invalid">
                            {errorsField}
                        </Form.Control.Feedback>
                    </div>
                </Col>
            </Row>
        </Form.Group>
    );
}

export default CustomSelectHorizontalPhone