import React, { useEffect, useState } from 'react';

import { Button, Card, Col, Form, Row, Stack } from "react-bootstrap"
import { IoIosArrowBack } from "react-icons/io"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Formik } from "formik"
import { UsersTruesteeSchema } from "../../../../../../validations/Users"
import InputTextHorizontal from "../../../../../../components/common/InputTextHorizontal"
import CustomSelectHorizontal from "../../../../../../components/common/CustomSelectHorizontal"
import { deleteTruestee, gettrusteeDetailsById, updateTruesteeInfo } from '../../../../../../services/userService';
import { toast } from 'react-toastify';
import Loader from '../../../../../../components/common/Loader';
import { EditEntitiyMethod } from '../../../../../../helpers/helpersMethods';
import { ACCEPT_TRUESTEE_REQUEST_EDIT, NETWORK_ERROR, REJECT_TRUESTEE_REQUEST_EDIT, USER_UPDATED_SUCCESS } from '../../../../../../validations/ValidationErrors';
import DeleteModal from '../../../../../../components/common/DeleteModal';
import { countries } from 'country-list-json';
import CustomSelectHorizontalPhone from '../../../../../../components/common/CustomSelectHorizontalPhone';
export const EditTrustee = () => {

  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [redirect, setRedirect] = useState<any>();
  const [data, setData] = useState<any>();
  const [submitting, setSubmitting] = useState(false);
  const [show, setShow] = useState(false);
  const [deletestatus, setDeletestatus] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    setLoading(true);
    if (id) {
      gettrusteeDetailsById({ 'id': id }).then(
        (resp) => {
          setLoading(false);
          const res = resp?.data;
          setData(res);
          setRedirect(`/app/users/view/${res.user_id}`);
          if (res.status == "accepted") {
            toast.error(ACCEPT_TRUESTEE_REQUEST_EDIT);
            navigate(`/app/users/view/${res.user_id}`);
          }
          
          if (res.status == "rejected")
          {
            toast.error(REJECT_TRUESTEE_REQUEST_EDIT);
            navigate(`/app/users/view/${res.user_id}`);
          }
          
        },
        (error: any) => {
          setLoading(false);
          const err = error?.response?.data;
          if (err?.statusCode === 400) {
            toast.error(err.msg);
            navigate(`/app/users`);
          }
        }
      );
    }
  }, [id]);

  useEffect(() => {
    const deleteData = async () => {
      try {
        if (deletestatus) {
          setLoading(true);
          let trusteeData = await getTrustee();
          if(trusteeData)
          {
          if (trusteeData?.data.status == "accepted")
          {
            toast.error(ACCEPT_TRUESTEE_REQUEST_EDIT);
            navigate(`/app/users/view/${trusteeData.data.user_id}`);
          }else if(trusteeData?.data.status == "rejected")
          {
            toast.error(REJECT_TRUESTEE_REQUEST_EDIT);
            navigate(`/app/users/view/${trusteeData.data.user_id}`);
          }
          else {
            deleteTruestee({ 'id': id }).then(
              (resp: any) => {
                setLoading(false);
                setShow(false);
                toast.success(resp.msg);
                navigate(`/app/users/view/${data.user_id}`);
              },
              (error: any) => {
                setLoading(false);
                const err = error?.response?.data;
                if (err?.statusCode === 400) {
                  toast.error(err.msg);
                  navigate(`/app/users`);
                }
              }
            );
          }
        }

        }
      } catch (error) {
        toast.error(NETWORK_ERROR);
      }
    };

    deleteData();
  }, [deletestatus]);
  const getTrustee = async () => {
    return gettrusteeDetailsById({ id: id });
  };
  /**
     * Editing Employees
     *
     * @param {*} value
     */
  const submitForm = async (value: any) => {
    if (id && data) {
      let trusteeData = await getTrustee();
      if (trusteeData?.data.status == "accepted") {
        toast.error(ACCEPT_TRUESTEE_REQUEST_EDIT);
        navigate(`/app/users/view/${trusteeData.data.user_id}`);
      }else if(trusteeData?.data.status == "rejected")
      {
        toast.error(REJECT_TRUESTEE_REQUEST_EDIT);
        navigate(`/app/users/view/${trusteeData.data.user_id}`);
      } else {
        EditEntitiyMethod(setSubmitting, updateTruesteeInfo, id, value, USER_UPDATED_SUCCESS, `/app/users/view/${data.user_id}`, navigate);
      }
    }
  }


  return (
    <>
      {!loading && data ? <Formik
        validationSchema={UsersTruesteeSchema}
        initialValues={{
          name: data ? data.name : '',
          email: data ? data.email : '',
          phone_no: data ? data.phone_no : '',
          phone_country_code: data ? data.phone_country_code : '+1',
        }}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          submitForm(values)
        }}

      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <Form className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1" noValidate onSubmit={handleSubmit}>
            <div className="pageHeader px-3 my-1 pt-1 user-select-none">
              <Row className="align-items-center min-h-51">
                <Col>
                  <h1 className="fw-semibold h4 my-2">
                    <Link to={redirect} className="align-items-center d-inline-flex overflow-hidden text-dark text-decoration-none">
                      <span className="me-1 ms-n1">
                        <IoIosArrowBack size="1.68rem" />
                      </span> Users
                    </Link>
                  </h1>
                </Col>
              </Row>
            </div>
            <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
              <Card className="h-100 w-100 overflow-auto border-light-subtle pageContentInner">
                <Card.Header className="bg-white fs-16 fw-semibold p-3 border-secondary">
                  <Card.Text className="px-xl-1">Edit Digital Trustee Details</Card.Text>
                </Card.Header>
                <Card.Body className="p-xl-4">

                  <InputTextHorizontal
                    leftColSmall={true}
                    label="Name"
                    controlId="name"
                    placeholder="Enter Name"
                    touched={touched.name}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.name}
                    value={values.name}
                  />
                  <InputTextHorizontal
                    leftColSmall={true}
                    label="Email"
                    controlId="email"
                    placeholder="Enter Email"
                    touched={touched.email}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.email}
                    value={values.email}
                  />
                  <CustomSelectHorizontalPhone
                    leftColSmall={true}
                    label="Country Code"
                    controlId="phone_country_code"
                    placeholder="Select"
                    touched={touched.phone_country_code}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.phone_country_code}
                    value={values.phone_country_code}
                    data={countries}
                  />
                  <InputTextHorizontal
                    leftColSmall={true}
                    label="Phone"
                    controlId="phone_no"
                    placeholder="Enter Phone"
                    touched={touched.phone_no}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.phone_no}
                    value={values.phone_no}
                  />
                  <div className="theme-form-horizontal py-3">
                    <Row>
                      <Col sm="auto" className="left-col left-col-sm"></Col>
                      <Col sm="auto" className="right-col">
                        <Stack direction="horizontal" gap={3}>
                          <Button className="fw-semibold fs-13 text-white mw-90 my-1" variant="primary" type="submit">Update</Button>
                          <Link to={redirect} className="btn btn-secondary text-dark fw-semibold fs-13 mw-90 my-1">Cancel</Link>
                          <Button className="fw-semibold fs-13 text-white mw-90 my-1" variant="danger" type="button" onClick={handleShow}>Delete</Button>
                        </Stack>
                      </Col>
                    </Row>
                  </div>

                </Card.Body>
              </Card>
            </div>
          </Form>
        )}
      </Formik > : ''}
      <DeleteModal show={show} handleClose={handleClose} setDeletestatus={setDeletestatus} />
      <Loader isLoading={loading || submitting} />
    </>

  )
}
