import { Card, Container, Ratio, Stack } from "react-bootstrap";
export const Memory = () => {
    return (
        <>
            <section>

                <div className="section-content py-4">
                    <Container className="py-2">
                        <h1>How to add a memory? </h1>
                        <p>
                            <h2 className="memoryH2">Step 1</h2>
                            <p>
                                Click on the "+" icon located at the top right-hand side of the
                                screen.
                            </p>
                            <img
                                className="memoryImage"
                                src="https://trackmotor.dynamicwebsite.co.in/dev-backend/images/Add.png"
                            />
                        </p>

                        <p>
                            <h2 className="memoryH2">Step 2</h2>
                            <p>Enter the desired title for the memory.</p>
                            <img
                                className="memoryImage"
                                src="https://trackmotor.dynamicwebsite.co.in/dev-backend/images/Title.png"
                            />
                        </p>

                        <p>
                            <h2 className="memoryH2">Step 3</h2>
                            <p>
                                Choose the recipient with whom you wish to share this memory.
                            </p>
                            <img
                                className="memoryImage"
                                src="https://trackmotor.dynamicwebsite.co.in/dev-backend/images/Recipient.png"
                            />
                        </p>

                        <p>
                            <h2 className="memoryH2">Step 4</h2>
                            <p>Select the type of memory, either "Message" or "Thing".</p>
                            <img
                                className="memoryImage"
                                src="https://trackmotor.dynamicwebsite.co.in/dev-backend/images/Type.png"
                            />
                        </p>

                        <p>
                            <h2 className="memoryH2">Step 5</h2>
                            <p>Compose a personalized message for the recipient.</p>
                            <img
                                className="memoryImage"
                                src="https://trackmotor.dynamicwebsite.co.in/dev-backend/images/Message.png"
                            />
                        </p>

                        <p>
                            <h2 className="memoryH2">Step 6</h2>
                            <p>Click on Save to add the memory to your list.</p>
                            <img
                                className="memoryImage"
                                src="https://trackmotor.dynamicwebsite.co.in/dev-backend/images/Save.png"
                            />
                        </p>
                    </Container>
                </div>
            </section>
        </>
    );
};
