import { Route, Routes } from 'react-router-dom';
import { AllCMS } from './List/List';
import { EditCMS } from './Edit/Edit';

export const CMS = () => {
    return (
        <Routes>
            <Route path="/" element={<AllCMS />} />
            <Route path="/list" element={<AllCMS />} />
            <Route path="/edit/:id" element={<EditCMS />} />
        </Routes>
    )
};