import { Button, Card, Col, Form, Image, Row, Stack } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import { Formik } from "formik"
import { FaImage } from 'react-icons/fa';
import { ChangePasswordSchema } from '../../validations/Profile';
import InputTextHorizontal from '../../components/common/InputTextHorizontal';
import Loader from "../../components/common/Loader";
import { useEffect, useState } from "react";
import storage from "../../helpers/storage";
import { changePassword } from "../../services/otherService";
import { USER_UPDATED_SUCCESS } from "../../validations/ValidationErrors";
import { EditEntitiyMethod } from "../../helpers/helpersMethods";

export const ChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState<any>();
  const [submitting, setSubmitting] = useState(false);
  const [userId, setUserId] = useState();
  const navigate = useNavigate();

  useEffect(()=>{

   const getUserData =  storage.getData("user-info");
   if(getUserData)
   {
    setUserData(getUserData);
    setUserId(getUserData.id);
    
   }
  },[userId])
  const submitForm = (value:any) => {
    if (userId && value)
    {
      EditEntitiyMethod(setSubmitting, changePassword, userId, value, USER_UPDATED_SUCCESS, `login`, navigate);
    }

  };
  return (
    <>
      <Formik
        validationSchema={ChangePasswordSchema}
        onSubmit={(values, actions) => {
          values.newPassword = values.newPassword.trim();
          values.currentPassword = values.currentPassword.trim(); 
          values.confirmPassword = values.confirmPassword.trim();
          actions.setSubmitting(false);
          submitForm(values);
      }}
        initialValues={{
          currentPassword: '',
          newPassword: '',
          confirmPassword: ''
        }}
        

      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <Form className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1" noValidate onSubmit={handleSubmit}>
            <div className="pageHeader px-3 my-1 pt-1 user-select-none">
              <Row className="align-items-center min-h-51">
                <Col>
                  <h1 className="fw-semibold h4 my-2">Change Password</h1>
                </Col>
              </Row>
            </div>
            <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
              <Card className="h-100 w-100 overflow-auto border-light-subtle pageContentInner">
                <Card.Header className="bg-white fs-16 fw-semibold p-3 border-secondary">
                  <Card.Text className="px-xl-1">Change Password</Card.Text>
                </Card.Header>
                <Card.Body className="p-xl-4">

                  <InputTextHorizontal
                    label="Current Password"
                    controlId="currentPassword"
                    placeholder="Current Password"
                    touched={touched.currentPassword}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.currentPassword}
                    value={values.currentPassword}
                    type="password"
                  />

                  <InputTextHorizontal
                    label="New Password"
                    controlId="newPassword"
                    placeholder="New Password"
                    touched={touched.newPassword}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.newPassword}
                    value={values.newPassword}
                    type="password"
                  />

                  <InputTextHorizontal
                    label="Confirm New Password"
                    controlId="confirmPassword"
                    placeholder="Confirm New Password"
                    touched={touched.confirmPassword}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.confirmPassword}
                    value={values.confirmPassword}
                    type="password"
                  />

                  <div className="theme-form-horizontal pb-3">
                    <Row>
                      <Col sm="auto" className="left-col"></Col>
                      <Col sm="auto" className="right-col">
                        <Stack direction="horizontal" gap={3}>
                          <Button className="fw-semibold fs-13 text-white mw-90 my-1" variant="primary" type="submit">Submit</Button>
                          <Link to="/app" className="btn btn-secondary text-dark fw-semibold fs-13 mw-90 my-1">Cancel</Link>
                        </Stack>
                      </Col>
                    </Row>
                  </div>

                </Card.Body>
              </Card>
            </div>
          </Form>
        )}
        
      </Formik >
      <Loader isLoading={loading || submitting} />
    </>

  )
}
