import { Route, Routes } from 'react-router-dom';
import { AllFAQ } from './List/List';
import { AddFAQ } from './Add/Add';
import { EditFAQ } from './Edit/Edit';
import { ViewFAQ } from './View/View';

export const FAQ = () => {
    return (
        <Routes>
            <Route path="/" element={<AllFAQ />} />
            <Route path="/list" element={<AllFAQ />} />
            <Route path="/add" element={<AddFAQ />} />
            <Route path="/edit/:id" element={<EditFAQ />} />
            <Route path="/view/:id" element={<ViewFAQ />} />
        </Routes>
    )
};