import {axios} from "../services/axios";
import { adminApi } from "../api";
export type recipientDTO = {
  name: string
};



/**
 * Organ Donar List
 * @date 04/05/2023 - 13:21:19
 *
 * @async
 * @param {*} params
 * @returns {unknown}
 */
export const getList = async (params:any) => {
    return await axios.get(`${adminApi.recipientsList}/${params.userid}`, { params });
  };

  /**
   * Add Organ Donar
   * @date 04/05/2023 - 16:19:09
   *
   * @async
   * @param {recipientDTO} data
   * @returns {unknown}
   */
  export const addCharit = async (data: recipientDTO) => {
    const config = {headers: {"content-type": "multipart/form-data"}};
    return axios.post(`${adminApi.charityAdd}`, data,config);
  };

  /**
   * Organ Donar Get
   * @date 04/05/2023 - 17:54:51
   *
   * @async
   * @param {string} id
   * @returns {Promise<any>}
   */
  export const getDetails = async (id: string): Promise<any> => {
    return axios.get(`${adminApi.recipientsDetails}/${id}`);
  };
  

  /**
   * Organ Donar Edit
   * @date 04/05/2023 - 17:54:51
   *
   * @async
   * @param {string} id
   * @returns {Promise<any>}
   */
  export const edit = async (id: string,data: recipientDTO): Promise<any> => {
    return axios.put(`${adminApi.recipientsEdit}/${id}`,data);
  };

  export const relationListData = async () => {
    return await axios.get(`${adminApi.relationList}`);
  };

 
  