import { FC } from 'react';
import { Col, Form, Row, Stack } from 'react-bootstrap';

interface IInputTextHorizontal {
    controlId: string;
    label?: string;
    placeholder?: string;
    handleChange?: any;
    handleBlur?: any;
    errorsField?: any;
    touched?: any;
    value?: any;
    rightIcon?: any;
    size?: any;
    name?: any;
    setColunView?: boolean;
    leftColSmall?: boolean;
    disabled?: boolean;
    type?: string;
}


/**
 * Input Type Text Horizontal Reusable Component
 * @date 4/21/2023 - 11:51:20 AM
 *
 * @param {{ controlId: any; label: any; placeholder: any; handleChange: any; handleBlur: any; errorsField: any; touched: any; value: any; rightIcon: any; size: any; name: any; setColunView: any; leftColSmall: any; disabled: any; }} {
    controlId, label, placeholder, handleChange, handleBlur, errorsField, touched, value, rightIcon, size, name, setColunView, leftColSmall, disabled
}
 * @returns
 */

const InputTextHorizontal: FC<IInputTextHorizontal> = ({
    controlId, label, placeholder, handleChange, handleBlur, errorsField, touched, value, rightIcon, size, name, setColunView, leftColSmall, disabled,type 
}) => {
    return (
        <Form.Group className="theme-form-horizontal pb-sm-1 mb-3" controlId={controlId}>
            <Row>
                <Col sm="auto" className={`left-col ${leftColSmall ? 'left-col-sm' : ''}`}>
                    {label ? (
                        <Stack direction="horizontal" gap={2} className={`justify-content-sm-between ${size ? 'h-35' : 'h-40'}`}>
                            <label className="fw-semibold">{label}</label>
                            {setColunView ? <span className="colun-content">:</span> : null}
                        </Stack>
                    ) : null}
                </Col>
                <Col sm>
                    <div className={`position-relative right-col ${rightIcon ? 'form-right-icon' : ''}`}>
                        <Form.Control value={value} type={type ? type:'text'} placeholder={placeholder} onBlur={handleBlur} onChange={handleChange} isInvalid={!!errorsField && touched} size={size} name={name} disabled={disabled} />
                        {rightIcon ? (
                            <span className={`align-items-center d-flex end-0 justify-content-center pe-none position-absolute px-3 text-light top-0 ${size ? 'h-35' : 'h-40'}`}>
                                {rightIcon}
                            </span>
                        ) : null}
                        <Form.Control.Feedback type="invalid">
                            {errorsField}
                        </Form.Control.Feedback>
                    </div>
                </Col>
            </Row>
        </Form.Group>
    );
}

export default InputTextHorizontal