import ActionCell from '../../../../../components/common/dataGrid/ActionCell';
import DataGrid from '../../../../../components/common/dataGrid/DataGrid';
import { useEffect, useState } from 'react';
import constantValues from '../../../../../constants/constantValues';
import Loader from '../../../../../components/common/Loader';
import moment from 'moment';
import formatData from '../../../../../constants/formatData';
import { useParams } from 'react-router-dom';
import { maskPhoneNumber, truncateText } from '../../../../../helpers/helpersMethods';
import { getUserCharityList } from '../../../../../services/charityService';

export const CharityList = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(constantValues.rowPerPage);
  const [totalPages, setTotalPages] = useState(0);
  const [totalReults, setTotalResults] = useState(0);
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  useEffect(() => {
    setLoading(false);
    charityList({ page: currentPage, limit: rowPerPage,userid:id });
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    setLoading(false);
    charityList({ page: currentPage, limit: rowPerPage,sortColumn:sortColumn,sortDirection:sortDirection,userid:id });
  }, [sortColumn,sortDirection]);
  
  const charityList = async (params:any) => {
    setLoading(true);
    getUserCharityList({ ...params, search: params.search?.trim() }).then(
      (response) => {
        setData(response.data.record);
        setTotalPages(response.data.totalPages);
        setTotalResults(response.data.totalResults);
        setLoading(false);
      }
    );
  };


  const columns = [
    {
      id:'id',
      name: '#',
      sortable: true,
      center: true,
      selector: (row:any, index:any) => (currentPage - 1) * rowPerPage + index + 1,
      width: '60px',
    },
    {
      id:'charity_name',
      selector: (row: any) => row.global_charities_charity_title,
      name: 'Charity Name',
      sortable: true,
      minWidth: '150px',
      cell: (row: any) => {return truncateText(row.global_charities_charity_title)}
    },
    {
      id:'message',
      selector: (row: any) => row.message,
      name: 'Message',
      sortable: true,
      minWidth: '150px',
      cell: (row: any) => {return truncateText(row.message)}
    },
    {
        id:'recipient_name',
        selector: (row: any) => row.user_charity_recipients_name,
        name: 'Recipients',
        sortable: true,
        minWidth: '150px',
        cell: (row: any) => {return truncateText(row.user_charity_recipients_name)}
    },
    {
      id:'created_at',
      selector: (row: any) => row.created_at,
      name: 'Date And Time',
      sortable: true,
      minWidth: '150px',
      cell: (row:any) =>
      moment(row.created_at).format(
        formatData.datetimeFormats.forwordSeparatedDateTime
      ),
    }
  ];
  return (
    <div className="d-flex flex-column h-100 rounded-0 dataGridListMain">
      
      <DataGrid
        showTitle="Charity"
        columns={columns}
        data={data}
        pagination={true}
        totalPages={totalPages}
        totalRecords={totalReults}
        rowPerPage={rowPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        sortColumn={sortColumn}
        setSortColumn = {setSortColumn}
        sortDirection={sortDirection}
        setSortDirection = {setSortDirection}
      />
       <Loader isLoading={loading} />
    </div>
  )
}
