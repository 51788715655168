import * as React from 'react';
import './datagrid.scss';
import DataTable from 'react-data-table-component';
import DataGridPagination from './DataGridPagination';
import TableFilters from './TableFilters';

interface IDataGrid {
    showTitle?: string;
    columns?: any;
    data?: any;
    FilterSubHeader?: boolean;
    planData?: any;
    viewData?: any;
    filterByDate?: any;
    filterByDateRange?: any;
    filterData?: any;
    setFilterData?: any;
    noTableHead?: boolean;
    pagination?: boolean;
    totalPages?: any;
    totalRecords?: any;
    rowPerPage?: any;
    setCurrentPage?: any;
    currentPage?: any;
    sortColumn?: any;
    setSortColumn?: any;
    sortDirection?: any;
    setSortDirection?: any;
    hideRightFilters?: boolean;
    setSortColumnType?:any;
}


/**
 * Data Grid Common Reusable Component
 * @date 4/21/2023 - 11:36:50 AM
 *
 * @param {{ showTitle: any; columns: any; data: any; FilterSubHeader: any; planData: any; viewData: any; filterByDate: any; filterData: any; setFilterData: any; noTableHead: any; pagination: any; totalPages: any; ... 8 more ...; hideRightFilters: any; }} {
    showTitle, columns, data, FilterSubHeader, planData, viewData, filterByDate, filterData, setFilterData, noTableHead, pagination,totalPages,totalRecords,rowPerPage,currentPage,setCurrentPage,sortColumn,setSortColumn,setSortDirection,filterByDateRange, hideRightFilters}
 * @returns
 */

const DataGrid: React.FC<IDataGrid> = ({
    showTitle, columns, data, FilterSubHeader, planData, viewData, filterByDate, filterData, setFilterData, noTableHead, pagination, totalPages, totalRecords, rowPerPage, currentPage, setCurrentPage, sortColumn, setSortColumn, setSortDirection, filterByDateRange, hideRightFilters }) => {
    const customSortIcon =
        <div className='sortIconMain d-flex align-items-center flex-column'>
            <span className='sortIconUp'></span><span className='sortIconDown'></span>
        </div>;
    return (
        <DataTable
            persistTableHead
            title={showTitle}
            columns={columns}
            data={data}
            defaultSortAsc={false}
            sortIcon={customSortIcon}
            onSort={(column, direc) => {
                const getColId = window.document.querySelector(`[data-sort-id='${column.id}']`);
                setSortColumn(column.id);
                switch (direc) {
                    case "asc":
                        getColId?.classList.add('sortAsc');
                        setSortDirection('asc');
                        break;
                    case "desc":
                        getColId?.classList.add('sortDesc');
                        setSortDirection('desc');
                        break;
                    default:
                        break;
                }

            }}
            className={`dataTableCover mt-1 ${noTableHead ? 'noTableHeadActive' : ''}`}
            fixedHeader
            responsive
            subHeader={FilterSubHeader}
            subHeaderComponent={
                <TableFilters
                    planData={planData}
                    viewData={viewData}
                    filterByDate={filterByDate}
                    filterByDateRange={filterByDateRange}
                    filterData={filterData}
                    setFilterData={setFilterData}
                    hideRightFilters={hideRightFilters}
                />}
            paginationServer={true}
            sortServer={true}
            pagination={pagination}
            paginationPerPage={10}
            noTableHead={noTableHead}
            paginationComponent={() => (<DataGridPagination totalRecords={totalRecords} rowsPerPage={rowPerPage} currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} currentTotalRecord={data.length}
            />)}
        />
    );
}

export default DataGrid;