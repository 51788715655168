import { useEffect, useState } from 'react'
import { Card, Col, Row, Stack } from 'react-bootstrap'
import DataGrid from '../../../components/common/dataGrid/DataGrid';
import ActionCell from '../../../components/common/dataGrid/ActionCell';
import { Link, useNavigate } from 'react-router-dom';
import { faqDelete, getList } from '../../../services/faqService';
import Loader from '../../../components/common/Loader';
import constantValues from '../../../constants/constantValues';
import { truncateText } from '../../../helpers/helpersMethods';
import DeleteModal from '../../../components/common/DeleteModal';
import { toast } from 'react-toastify';
import { nameBasedProtectedRoutes } from '../../../router/protected';


const viewData = [
  { id: 'active', name: 'Enable' },
  { id: 'inactive', name: 'Disable' }
];

export const AllFAQ = () => {
  const [filterData, setFilterData] = useState({ search: '',searchByCreatedDate:'',sortByStatus:'' });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(constantValues.rowPerPage);
  const [totalPages, setTotalPages] = useState(0);
  const [totalReults, setTotalResults] = useState(0);
  const [sortColumn, setSortColumn] = useState("order_sequence");
  const [sortDirection, setSortDirection] = useState("asc");
  const [deletestatus, setDeletestatus] = useState(false);
  const [deleteRecordId, setDeleteRecordId] = useState();
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const searchData:string = filterData ? filterData.search:'';
    const sortByStatus:any = filterData ? filterData.sortByStatus:'';
    setLoading(false);
    faqList({ page: currentPage, limit: rowPerPage,sortColumn:sortColumn,sortDirection:sortDirection,search:searchData,searchByStatus:sortByStatus });
  }, [currentPage]);

  useEffect(() => {
    const searchData:string = filterData ? filterData.search:'';
    const sortByStatus:any = filterData ? filterData.sortByStatus:'';
    setCurrentPage(1);
    setLoading(false);
    faqList({ page: currentPage, limit: rowPerPage,sortColumn:sortColumn,sortDirection:sortDirection,search:searchData,searchByStatus:sortByStatus });
  }, [sortColumn,sortDirection]);
  
  useEffect(() => {
    const searchData:string = filterData ? filterData.search:'';
    const sortByStatus:any = filterData ? filterData.sortByStatus:'';
    if(searchData.length > 1  || sortByStatus.length > 1)
    {
      setCurrentPage(1);
      setLoading(false);
      faqList({ page: currentPage, limit: rowPerPage,sortColumn:sortColumn,sortDirection:sortDirection,search:searchData,searchByStatus:sortByStatus});
    }else
    {
      setCurrentPage(1);
      setLoading(false);
      faqList({ page: currentPage, limit: rowPerPage,sortColumn:sortColumn,sortDirection:sortDirection});
    }
  }, [filterData]);
  const handleDeleteClick = (id:any) =>
  {
    if(id)
    {
      setDeleteRecordId(id);
    }
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };
  const recordDelete = ()=>
  {
    if(deleteRecordId)
    {
      setLoading(true);
      setShow(false);
      faqDelete(deleteRecordId).then(
        (resp:any) => {
          
          setTimeout(() => {
          setLoading(false);
          setShow(false);
          toast.success(resp.msg);
          setCurrentPage(1);
          faqList({ page: currentPage, limit: rowPerPage});
          }, 1000);
        },
        (error: any) => {
          const err = error?.response?.data;
          setLoading(false);
           setShow(false);
          if (err?.statusCode === 400) {
            toast.error(err.msg);
            navigate(nameBasedProtectedRoutes.faq.path);
          }

        }
      );
    }
  }
 

  /**
   * Description fetch user list
   * @date 19/04/2023 - 12:10:33
   *
   * @async
   * @param {*} params
   * @returns {*}
   */
  const faqList = async (params:any) => {
    setLoading(true);
    getList({ ...params, search: params.search?.trim() }).then(
      (response) => {
        setLoading(false);
        setData(response.data?.record);
        setTotalPages(response.data?.totalPages);
        setTotalResults(response.data?.totalResults);
        
      }
    );
  };
  const columns = [
    {
      id:'id',
      name: '#',
      sortable: true,
      center: true,
      selector: (row:any, index:any) => (currentPage - 1) * rowPerPage + index + 1,
      width: '60px',
    },
    {
      id:'question',
      selector: (row: any) => row.question,
      name: 'Question',
      sortable: true,
      minWidth: '200px',
      cell: (row: any) => {return truncateText(row.question)}
    },
    {
      id:'answer',
      selector: (row: any) => row.answer,
      name: 'Answer',
      sortable: false,
      minWidth: '200px',
      cell: (row: any) => {return truncateText(row.answer)}
    },
    {
      id:'order_sequence',
      selector: (row: any) => row.order_sequence,
      name: 'Order',
      sortable: true,
      minWidth: '200px'
    },
    {
      id:'status',
      selector: (row: any) => row.status,
      name: 'Status',
      sortable: true,
      minWidth: '150px',
      cell: (row:any) =>
      {
        const statusText = row.status == 'active' ? 'Enable' : 'disable';
        return (<div className="text-capitalize">{statusText}</div>);
      },
    },
    {
      id:'action',
      selector: (row: any) => row.action,
      name: 'Action',
      width: '100px',
      center: true,
      button: true,
      cell: (row: any) =>
      {
        return (<ActionCell controlId="faq" isViewEnable={true}
         isEditEnable={true} isDeleteEnable={true}
          redirectID={row.id}
           handleDeleteClick={()=>handleDeleteClick(row.id)}/>);
      } 
    },
  
  ];
  return (
    <>
    <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
      <div className="pageHeader px-3 my-1 pt-1 user-select-none">
        <Row className="align-items-center min-h-51">
          <Col>
            <h1 className="fw-semibold h4 my-2">FAQ</h1>
          </Col>
          <Col xs="auto">
            <Stack direction="horizontal">
              <Link to="/app/faq/add" className="btn btn-primary my-2 fw-semibold fs-13 text-white mw-120">Add FAQ</Link>
            </Stack>
          </Col>
        </Row>
      </div>
      <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
        <Card className="h-100 p-3 w-100 border-light-subtle pageContentInner">
          <div className="d-flex flex-column h-100 rounded-0 dataGridListMain">
          <DataGrid
              columns={columns}
              data={data}
              viewData={viewData}
              FilterSubHeader={true}
              filterData={filterData}
              setFilterData={setFilterData}
              pagination={true}
              totalPages={totalPages}
              totalRecords={totalReults}
              rowPerPage={rowPerPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              sortColumn={sortColumn}
              setSortColumn = {setSortColumn}
              sortDirection={sortDirection}
              setSortDirection = {setSortDirection}
            />
          </div>
        </Card>
      </div>
    </div>
    <Loader isLoading={loading} />
    <DeleteModal show={show} handleClose={handleClose} setDeletestatus={setDeletestatus} recordDelete={recordDelete}/>
    </>
  )
}
