import * as yup from "yup";
import { REQUIRED } from "./ValidationErrors";

/**
 * ProclamationSchema for Validation
 * @date 4/19/2023 - 5:20:24 PM
 *
 * @type {*}
 */

export const ProclamationSchema = yup.object().shape({
  publicationstatus: yup.string(),
  expired_at: yup.string().nullable(),
});
export const ProclamationUnpulishSchema = yup.object().shape({
  publicationstatus: yup.string(),
  expired_at: yup.string(),
});

/**
 * Pending Proclamation Schema for Validation
 * @date 4/20/2023 - 1:07:19 PM
 *
 * @type {*}
 */

export const PendingProclamationSchema = yup.object().shape({
  title: yup.string(),
  user: yup.string(),
  releaseLegacyDate: yup.string(),
  tags: yup.string(),
  video: yup.string(),
  message: yup.string(),
  expiryDate: yup.string(),
  reason: yup.string(),
});