import * as yup from "yup";
import {CHAR_MAX_10, MAXIMUM_1000_CHAR, MAXIMUM_200_CHAR, MINIMUM_3_CHAR,MINIMUM_8_CHAR,NUMBER_VALID,REQUIRED, VALID_URL} from "./ValidationErrors";

/**
 * Profile Schema for Validation
 * @date 4/19/2023 - 9:26:15 AM
 *
 * @type {*}
 */

export const CreateSchema = yup.object().shape({
  charity_title: yup.string().min(3, MINIMUM_3_CHAR).max(200,MAXIMUM_200_CHAR).required(REQUIRED),
  ein: yup.number().min(1, 'Number must have at least 1 digits')
  .max(9999999999, 'Number cannot exceed 10 digits').typeError(NUMBER_VALID).required(REQUIRED),
  contact_person: yup.string().min(3, MINIMUM_3_CHAR).max(200,MAXIMUM_200_CHAR).required(REQUIRED),
  description: yup.string().min(8, MINIMUM_8_CHAR).max(1000,MAXIMUM_1000_CHAR).required(REQUIRED),
  status: yup.string().required(REQUIRED),
  country: yup.string().required(REQUIRED),
  category: yup.string().required(REQUIRED),
  asset_file: yup.mixed().required(REQUIRED),
  website_url: yup.string().url(VALID_URL).required(REQUIRED),

});
export const EditSchema = yup.object().shape({
  charity_title: yup.string().min(3, MINIMUM_3_CHAR).max(200, MAXIMUM_200_CHAR).required(REQUIRED),
  ein: yup
    .number()
    .min(1, 'Number must have at least 1 digit')
    .max(9999999999, 'Number cannot exceed 10 digits')
    .typeError(NUMBER_VALID)
    .required(REQUIRED),
  contact_person: yup.string().min(3, MINIMUM_3_CHAR).max(200, MAXIMUM_200_CHAR).required(REQUIRED),
  description: yup.string().min(8, MINIMUM_8_CHAR).max(1000, MAXIMUM_1000_CHAR).required(REQUIRED),
  status: yup.string().required(REQUIRED),
  country: yup.string().required(REQUIRED),
  category: yup.string().required(REQUIRED),
  asset_file: yup.mixed().notRequired(),
  website_url: yup.string().url(VALID_URL).required(REQUIRED),
});


