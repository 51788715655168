import { axiosAuth as axios } from "../utility/axios";
import { adminApi } from "../api";

/**
 * Login DTO
 * @export
 * @typedef {LoginCredentialsDTO}
 */
export type LoginCredentialsDTO = {
  email: string;
  password: string;
};
/**
 * MFA Code DTO
 * @export
 * @typedef {ResetPasswordDTO}
 */
export type ResetPasswordDTO = {
  password: string;
  token: string;
};
/**
 * MFA Code DTO
 * @export
 * @typedef {resetPasswordTokenDTO}
 */
export type resetPasswordTokenDTO = {
  token: any;
};

/**
 * MFA Code DTO
 * @export
 * @typedef {LogoutDTO}
 */
export type LogoutDTO = {
  refreshToken: string;
};

/**
 * MFA Code DTO
 * @export
 * @typedef {ForgotPasswordDTO}
 */
export type ForgotPasswordDTO = {
  email: string;
};

/**
 * Resend MFA Code DTO
 * @export
 * @typedef {ResendMFACodeDTO}
 */
export type ResendMFACodeDTO = {
  user_id: number;
};

/**
 * Login API Call
 * @param {LoginCredentialsDTO} data
 * @returns {Promise<any>}
 */
export const loginWithUserNameAndPassword = (
  data: LoginCredentialsDTO
): Promise<any> => {
  return axios.post(adminApi.login, data);
};


/**
 * Forgot Password API Call
 * @param {ForgotPasswordDTO} data
 * @returns {Promise<any>}
 */
export const forgotPassword = (data: ForgotPasswordDTO): Promise<any> => {
  return axios.post(adminApi.forgotPassword, data);
};

/**
 * Reset Password API Call
 *
 * @param {ResetPasswordDTO} data
 * @returns {Promise<any>}
 */
export const resetPassword = (data: ResetPasswordDTO): Promise<any> => {
  return axios.post(adminApi.resetPassword, data);
};
/**
 * Reset Password API Call
 *
 * @param {ResetPasswordDTO} data
 * @returns {Promise<any>}
 */
export const resetPasswordToken = (data: resetPasswordTokenDTO): Promise<any> => {
  return axios.post(adminApi.resetPasswordToken, data);
};

/**
 * Logout API Call
 * @param {LogoutDTO} data
 * @returns {Promise<any>}
 */
export const logout = (data: LogoutDTO): Promise<any> => {
  return axios.post(adminApi.logout, data);
};
