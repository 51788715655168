import { Route, Routes } from 'react-router-dom';
import { Add } from './Add/Add';
import { Edit } from './Edit/Edit';
import { AllADS } from './List/List';
import { View } from './View/View';

export const ADS = () => {
    return (
        <Routes>
            <Route path="/" element={<AllADS />} />
            <Route path="/add" element={<Add />} />
            <Route path="/edit/:id" element={<Edit />} />
            <Route path="/view/:id" element={<View />} />
        </Routes>
    )
};