import { Button, Card, Col, Form, Row, Stack } from "react-bootstrap"
import { IoIosArrowBack } from "react-icons/io"
import { Link, useNavigate, useParams } from "react-router-dom"
import InputTextHorizontal from "../../../components/common/InputTextHorizontal"
import { Formik } from "formik"
import { UsersSchema } from "../../../validations/Users"
import CustomRadioHorizontal from "../../../components/common/CustomRadioHorizontal"
import { useEffect, useState } from "react"
import { getUserApiCall, updateUsersInfo } from "../../../services/userService"
import Loader from "../../../components/common/Loader"
import { toast } from "react-toastify"
import { EditEntitiyMethod } from "../../../helpers/helpersMethods"
import { USER_UPDATED_SUCCESS } from "../../../validations/ValidationErrors"
import CustomSelectHorizontalPhone from "../../../components/common/CustomSelectHorizontalPhone"
import { countries } from 'country-list-json';
export const EditUser = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [redirect, setRedirect] = useState<any>();
  const [data, setData] = useState<any>();
  const [submitting, setSubmitting] = useState(false);
    
  
  const radioData = [
    { label: 'Enable', value: 'active' },
    { label: 'Disable', value: 'inactive' },
  ];
  useEffect(() => {
    setLoading(true);
    if(id)
    {
      getUserApiCall({'id':id}).then(
        (resp) => {
          setLoading(false);
          const res = resp?.data[0];
          setData(res); 
          setRedirect(`/app/users/view/${id}`);
        },
        (error:any) => {
          setLoading(false);
          const err = error?.response?.data;
          if (err?.statusCode === 400) {
            toast.error(err.msg);
            navigate(`/app/users`);
          }
        }
      );
  }
  }, [id]);
  /**
     * Editing Employees
     *
     * @param {*} value
     */
  const submitForm = (value: any) => {
    console.log("ccc");
    if (id) {
        EditEntitiyMethod(setSubmitting, updateUsersInfo, id, value, USER_UPDATED_SUCCESS, `/app/users/view/${id}`, navigate);
    }
    
}
  return (
    <>
      {!loading && data && redirect ? <Formik
        validationSchema={UsersSchema}
        initialValues={{
          name: data ? data.name : '',
          email: data ? data.email : '',
          phone: data ? data.phone != null ? data.phone :'': '',
          status: data ? data.status : false,
          phone_country_code: data ? data.phone_country_code : '+1',
      }}
      onSubmit={(values, actions) => {
        values.name = values.name.trim();
        values.email = values.email.trim();
        values.phone = values.phone.trim();
        values.phone_country_code = values.phone_country_code.trim();
        actions.setSubmitting(false);
        submitForm(values)
    }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
          
          <Form className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1" noValidate onSubmit={handleSubmit}>
            <div className="pageHeader px-3 my-1 pt-1 user-select-none">
              <Row className="align-items-center min-h-51">
                <Col>
                  <h1 className="fw-semibold h4 my-2">
                    <Link to={redirect}  className="align-items-center d-inline-flex overflow-hidden text-dark text-decoration-none">
                      <span className="me-1 ms-n1">
                        <IoIosArrowBack size="1.68rem" />
                      </span> Users
                    </Link>
                  </h1>
                </Col>
              </Row>
            </div>
            
            <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
              <Card className="h-100 w-100 overflow-auto border-light-subtle pageContentInner">
                <Card.Header className="bg-white fs-16 fw-semibold p-3 border-secondary">
                  <Card.Text className="px-xl-1">Edit User Details</Card.Text>
                </Card.Header>
                <Card.Body className="p-xl-4">

                  <InputTextHorizontal
                    leftColSmall={true}
                    label="Name"
                    controlId="name"
                    placeholder="Enter Name"
                    touched={touched.name}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.name}
                    value={values.name}
                  />

                  <InputTextHorizontal
                    leftColSmall={true}
                    label="Email"
                    controlId="email"
                    placeholder="Enter Email Address"
                    touched={touched.email}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.email}
                    value={values.email}
                  />
                    <CustomSelectHorizontalPhone
                    leftColSmall={true}
                    label="Country Code"
                    controlId="phone_country_code"
                    placeholder="Select"
                    touched={touched.phone_country_code}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.phone_country_code}
                    value={values.phone_country_code}
                    data={countries}
                  />
                  <InputTextHorizontal
                    leftColSmall={true}
                    label="Phone"
                    controlId="phone"
                    placeholder="Enter Phone Number"
                    touched={touched.phone}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.phone}
                    value={values.phone}
                  />

                  <CustomRadioHorizontal
                    leftColSmall={true}
                    inline={true}
                    label="Status"
                    name="status"
                    touched={touched.status}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.status}
                    value={values.status}
                    data={radioData}
                  />

                  <div className="theme-form-horizontal pb-3">
                    <Row>
                      <Col sm="auto" className="left-col left-col-sm"></Col>
                      <Col sm="auto" className="right-col">
                        <Stack direction="horizontal" gap={3}>
                          <Button className="fw-semibold fs-13 text-white mw-90 my-1" variant="primary"  type="submit">Update</Button>
                          <Link to={redirect} className="btn btn-secondary text-dark fw-semibold fs-13 mw-90 my-1">Cancel</Link>
                        </Stack>
                      </Col>
                    </Row>
                  </div>

                </Card.Body>
              </Card>
            </div>
          </Form>
        )}
      </Formik >:''}
      <Loader isLoading={loading || submitting} />
    </>

  )
}
