import { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import DataGrid from '../../../components/common/dataGrid/DataGrid';
import ActionCell from '../../../components/common/dataGrid/ActionCell';
import Loader from '../../../components/common/Loader';
import { getList } from '../../../services/releaselegacyService';
import moment from "moment";
import formatData from '../../../constants/formatData';
import constantValues from '../../../constants/constantValues';
import { toast } from 'react-toastify';
import { maskPhoneNumber, truncateText } from '../../../helpers/helpersMethods';
import storage from '../../../helpers/storage';

const planData = [
  { id: '1', name: 'Plan B' },
  { id: '2', name: 'Plan A' }
];
const viewData = [
  { id: 'pending', name: 'Pending' },
  { id: 'accepted', name: 'Accepted' },
  { id: 'rejected', name: 'Rejected' }
];
export const AllLegacyRelease = () => {

  const [filterData, setFilterData] = useState({ search: '',searchByCreatedDate:'',sortByStatus:'' });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(constantValues.rowPerPage);
  const [totalPages, setTotalPages] = useState(0);
  const [totalReults, setTotalResults] = useState(0);
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  useEffect(() => {
    const searchData = filterData ? filterData.search : '';
    const searchByCreatedDate = filterData ? filterData.searchByCreatedDate : '';
    const sortByStatus = filterData ? filterData.sortByStatus : '';
    if (searchData.length > 1 || searchByCreatedDate.length > 1 || sortByStatus.length > 1) {
      setLoading(false);
      userList({
        page: currentPage,
        limit: rowPerPage,
        sortColumn,
        sortDirection,
        search: searchData,
        searchByCreatedDate,
        searchByStatus: sortByStatus
      });
    } else {
      setLoading(false);
      userList({ page: currentPage, limit: rowPerPage, sortColumn,sortDirection});
    }
  }, [filterData, sortColumn, sortDirection, rowPerPage,currentPage]);
  useEffect(()=>{
    setCurrentPage(1);
  },[filterData])


  /**
   * Description fetch user list
   * @date 19/04/2023 - 12:10:33
   *
   * @async
   * @param {*} params
   * @returns {*}
   */
  const userList = async (params:any) => {
    setLoading(true);
    getList({ ...params, search: params.search?.trim() }).then(
      (response) => {
        setData(response.data?.record);
        setTotalPages(response.data?.totalPages);
        setTotalResults(response.data?.totalResults);
        setLoading(false);
      },(error: any) => {
        const err = error?.response?.data;
        setLoading(false);
        if (err?.statusCode === 400) {
          toast.error(err.msg);
        }

      }
    );
  };

  const columns = [
    {
      id:'id',
      name: '#',
      sortable: true,
      center: true,
      selector: (row:any, index:any) => (currentPage - 1) * rowPerPage + index + 1,
      width: '60px',
    },
    {
      id:'user_name',
      selector: (row: any) => row.logs_users_name,
      name: 'User',
      sortable: true,
      minWidth: '150px',
      cell: (row: any) => {return truncateText(row.logs_users_name)}
    },
    {
      id:'trustee_name',
      selector: (row: any) => row.logs_trustees_name,
      name: 'Truestee',
      sortable: true,
      minWidth: '150px',
      cell: (row: any) => {return truncateText(row.logs_trustees_name)}
    },
    {
      id:'trustee_email',
      selector: (row: any) => row.logs_trustees_email,
      name: 'Truestee Email',
      sortable: true,
      minWidth: '150px',
      cell: (row: any) => {return truncateText(row.logs_trustees_email)}
    },
    {
      id:'trustee_phone_no',
      selector: (row: any) => row.logs_trustees_phone_no,
      name: 'Truestee Phone',
      sortable: true,
      minWidth: '150px',
      cell: (row:any) =>
      {
      
        return row.logs_trustees_phone_no ? maskPhoneNumber(row.logs_trustees_phone_country_code+row.logs_trustees_phone_no):'-';
      },
    },
    {
      id:'status',
      selector: (row: any) => row.status,
      name: 'Status',
      sortable: true,
      minWidth: '150px',
      cell: (row:any) =>
      {
        const statusText = row.status;
        return <div className="text-capitalize">{statusText}</div>;
      },
    },
    {
      id:'created_at',
      selector: (row: any) => row.created_at,
      name: 'Creation Date',
      sortable: true,
      minWidth: '150px',
      cell: (row:any) =>
      moment(row.created_at).format(
        formatData.dateFormats.commaSeparatedDateTime
      ),
    },
    
    {
      id:'action',
      selector: (row: any) => row.action,
      name: 'Action',
      width: '100px',
      center: true,
      button: true,
      cell: (row: any) => (<ActionCell controlId="legacy-release" isViewEnable={true} redirectID={row.id} />)
    },
  
  ];

  return (
    <>
    <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
      <div className="pageHeader px-3 my-1 pt-1 user-select-none">
        <Row className="align-items-center min-h-51">
          <Col>
            <h1 className="fw-semibold h4 my-2">Legacy Release</h1>
          </Col>
        </Row>
      </div>
      <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
        <Card className="h-100 p-3 w-100 border-light-subtle pageContentInner">
          <div className="d-flex flex-column h-100 rounded-0 dataGridListMain">
            <DataGrid
              columns={columns}
              data={data}
              planData={planData}
              viewData={viewData}
              FilterSubHeader={true}
              filterData={filterData}
              setFilterData={setFilterData}
              filterByDateRange={true}
              pagination={true}
              totalPages={totalPages}
              totalRecords={totalReults}
              rowPerPage={rowPerPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              sortColumn={sortColumn}
              setSortColumn = {setSortColumn}
              sortDirection={sortDirection}
              setSortDirection = {setSortDirection}
            />
          </div>
        </Card>
      </div>
    </div>
      <Loader isLoading={loading} />
    </>
  )
}

