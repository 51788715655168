import ActionCell from '../../../../../components/common/dataGrid/ActionCell';
import DataGrid from '../../../../../components/common/dataGrid/DataGrid';
import { IoIosVideocam } from 'react-icons/io';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import constantValues from '../../../../../constants/constantValues';
import { getList } from '../../../../../services/PproclamationService';
import { truncateText } from '../../../../../helpers/helpersMethods';
import Loader from '../../../../../components/common/Loader';
import ImageModal from '../../../../../components/common/ImagePreview';
import storage from '../../../../../helpers/storage';

export const PublicProclamation = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(constantValues.rowPerPage);
  const [totalPages, setTotalPages] = useState(0);
  const [totalReults, setTotalResults] = useState(0);
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [showFilePreview, setShowImagePreview] = useState(false);
  const [previewFileUrl, setPreviewFileUrl] = useState('');
  const [releaseLegacy, setReleaseLegacy] = useState(storage.getReleaseLegacy());


  useEffect(() => {
    setLoading(false);
    proclamationList({ page: currentPage, limit: rowPerPage, userid: id });
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    setLoading(false);
    proclamationList({ page: currentPage, limit: rowPerPage, sortColumn: sortColumn, sortDirection: sortDirection, userid: id });
  }, [sortColumn, sortDirection]);
  const handleImagePreview = (imageUrl: string) => {
    setShowImagePreview(true);
    setPreviewFileUrl(imageUrl);
  };
  const proclamationList = async (params: any) => {
    setLoading(true);
    getList({ ...params, search: params.search?.trim() }).then(
      (response) => {
        setData(response.data.record);
        setTotalPages(response.data.totalPages);
        setTotalResults(response.data.totalResults);
        setLoading(false);
      }
    );
  };
  
  const columns = [
    {
      id:'id',
      name: '#',
      sortable: true,
      center: true,
      selector: (row:any, index:any) => (currentPage - 1) * rowPerPage + index + 1,
      width: '60px',
    },
    {
      id:'proclamation_title',
      selector: (row: any) => row.proclamation_title,
      name: 'Title',
      sortable: true,
      minWidth: '150px',
      cell: (row: any) => {return truncateText(row.proclamation_title)}
    },
    {
      id:'category',
      selector: (row: any) => row.category,
      name: 'Category',
      sortable: true,
      minWidth: '150px',
      cell: (row: any) => {return truncateText(row.category)}
    },
    {
      id:'tags',
      selector: (row: any) => row.tags,
      name: 'Tags',
      sortable: false,
      minWidth: '150px',
      cell: (row: any) =>
      {
        if (row.tags && row.tags.length > 0) {
          const tags = row.tags;
          const tagTexts = tags.map((tag: {value: string }) => tag);
          return truncateText(tagTexts.join(', '));
        }
        return '';
      }
    },
    {
      selector: (row: any) => row.video,
      name: 'Video',
      sortable: false,
      width: '90px',
      cell: (row: any) => (
        <div>
          <div className="d-flex bg-success align-items-center justify-content-center custom-small-badge cursor-pointer">
            <IoIosVideocam size={10} onClick={() => handleImagePreview(row.asset_url_name)}/>
          </div>
        </div>
      )
    },
    {
      selector: (row: any) => row.action,
      name: 'Action',
      width: '100px',
      center: true,
      button: true,
      cell: (row: any) => (
        <ActionCell controlId="users/public-proclamation" disabled={releaseLegacy} isEditEnable={true} redirectID={row.id} />
      )
  
    },
  ];
  return (
    <div className="d-flex flex-column h-100 rounded-0 dataGridListMain">
       <DataGrid
        showTitle="Public Proclamation"
        columns={columns}
        data={data}
        pagination={true}
        totalPages={totalPages}
        totalRecords={totalReults}
        rowPerPage={rowPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        sortColumn={sortColumn}
        setSortColumn={setSortColumn}
        sortDirection={sortDirection}
        setSortDirection={setSortDirection}
      />
       <Loader isLoading={loading} />
       {showFilePreview && (
        
        <ImageModal
        show={showFilePreview}
        handleClose={() => setShowImagePreview(false)}
        imageUrl={previewFileUrl}
        contentType="video"
      />
)}
    </div>
  )
}
