import { FC } from 'react';
import { Button, Col, Form, Image, Row, Stack } from 'react-bootstrap';
import { FaImage } from 'react-icons/fa';
import { IoIosVideocam } from 'react-icons/io';

interface IViewTextHorizontal {
    label?: string;
    value?: any;
    setColunView?: boolean;
    leftColSmall?: boolean;
    isVideoHandler?: any;
    style?: any;
}


/**
 * View Text Horizontal Reusable Component
 * @date 4/19/2023 - 11:45:59 AM
 *
 * @param {{ label: any; value: any; setColunView: any; leftColSmall: any; }} {
    label, value, setColunView, leftColSmall
}
 * @returns {*}
 */

const ViewImageHorizontal: FC<IViewTextHorizontal> = ({
    label, value, setColunView, leftColSmall, isVideoHandler,style
}) => {
    return (
        <Form.Group className="theme-form-horizontal pb-sm-2 mb-3">
            <Row>
                <Col sm="auto" className={`left-col ${leftColSmall ? 'left-col-sm' : ''}`}>
                    {label ? (
                        <Stack direction="horizontal" gap={2} className="justify-content-sm-between">
                            <Form.Label>{label}</Form.Label>
                            {setColunView ? <span className="colun-content mb-1">:</span> : null}
                        </Stack>
                    ) : null}
                </Col>
                <Col sm>
                    <div className="position-relative right-col">
                        <div className="text-data" style={style}>
                            <div className="align-items-center border d-flex h-85 justify-content-center overflow-hidden position-relative rounded text-light w-85">
                              {!value && (
                                <FaImage size={32} />
                              )}
                              {value && (
                                <Image width={85} height={85} src={value} alt="Profile Image" />
                              )}
                              
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Form.Group>
    );
}

export default ViewImageHorizontal;