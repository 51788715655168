import React, { useEffect, useState } from 'react';

import { Button, Card, Col, Form, Image, Row, Stack } from "react-bootstrap"
import { IoIosArrowBack } from "react-icons/io"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Formik } from "formik"
import { OrganDonarSchema } from "../../../../../../validations/Users"
import InputTextHorizontal from "../../../../../../components/common/InputTextHorizontal"
import CustomSelectHorizontal from "../../../../../../components/common/CustomSelectHorizontal"
import TextAreaHorizontal from "../../../../../../components/common/TextAreaHorizontal"
import { FaImage } from 'react-icons/fa';
import { recipientListData } from '../../../../../../services/userService';
import { toast } from 'react-toastify';
import { deleteOrgan, edit, getDetails } from '../../../../../../services/organDonarService';
import Loader from '../../../../../../components/common/Loader';
import { EditEntitiyMethod } from '../../../../../../helpers/helpersMethods';
import { FILE_SIZE_10MB, FILE_SIZE_500KB, FILE_TYPE, IMAGE_RATIO, ORGAN_UPDATED_SUCCESS } from '../../../../../../validations/ValidationErrors';
import { nameBasedProtectedRoutes } from '../../../../../../router/protected';
import DeleteModal from '../../../../../../components/common/DeleteModal';

export const EditOrganDonar = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [redirect, setRedirect] = useState<any>();
  const [data, setData] = useState<any>();
  const [recipientList, setRecipientList] = useState<any>([]);
  const [submitting, setSubmitting] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>('');
  const [show, setShow] = useState(false);
  const [deleteRecordId, setDeleteRecordId] = useState();
  const [deletestatus, setDeletestatus] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (id) {
      getDetails(id).then(
        (resp) => {
          if(resp.statusCode == 201)
          {
            toast.error(resp.msg);
            navigate(`/app/users`);
            return;
          }
          setLoading(false);
          const res = resp?.data;
          setData(res);
          if(res.asset_url_name)
          {
            setImageUrl(res.asset_url_name);
          }
          setRedirect(`/app/users/view/${res.user_id}`);
          selectList(res.user_id);
        },
        (error: any) => {
          setLoading(false);
          const err = error?.response?.data;
          if (err?.statusCode === 400) {
            toast.error(err.msg);
            navigate(`/app/users`);
          }
        }
      );
    }
  }, [id]);
  const selectList = async (id: any) => {
    if (id) {
      await recipientListData(id).then(
        (resp) => {
          setLoading(false);
          const res = resp?.data;
          setRecipientList(res);
        },
        (error: any) => {
          setLoading(false);
          const err = error?.response?.data;
          if (err?.statusCode === 400) {
            toast.error(err.msg);
            navigate(`/app/users`);
          }
        }
      );
    }

  };
  const handleFileChange = (event: any, setFieldValue: any) => {
    const file = event.target.files?.[0];
    const allowedTypes = ['image/jpeg', 'image/png'];
    const maxFileSize = 10 * 1024 * 1024;; // 10MB in bytes
    if (event.target.files && event.target.files.length > 0) {
      if (!allowedTypes.includes(file.type)) {
        toast.error(FILE_TYPE);
        return;
      }
      if (file.size > maxFileSize) {
        toast.error(FILE_SIZE_10MB);
        return;
      }
      const image: HTMLImageElement = new window.Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
        setFieldValue('asset_file', file);
        setImageUrl(URL.createObjectURL(file));
      };
    }

  };
  const handleDeleteClick = (id:any) =>
  {
    if(id)
    {
      setDeleteRecordId(id);
    }
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };
  const recordDelete = ()=>
  {
    if(deleteRecordId)
    {
      setLoading(true);
      setShow(false);
      deleteOrgan(deleteRecordId).then(
        (resp:any) => {
        toast.success(resp.msg);
        navigate(redirect);
        },
        (error: any) => {
          const err = error?.response?.data;
          setLoading(false);
           setShow(false);
          if (err?.statusCode === 400) {
            toast.error(err.msg);
            navigate(redirect);
          }

        }
      );
    }
  }
  /**
     * Editing Orgon Doner
     *
     * @param {*} value
     */
  const submitForm = (value: any) => {
    if (id && data) {
      EditEntitiyMethod(setLoading, edit, id, value, ORGAN_UPDATED_SUCCESS, redirect, navigate);
    }
  }


  return (
    <>
      {data ? <Formik
        validationSchema={OrganDonarSchema}
        initialValues={{
          organ_name: data ? data.organ_name : '',
          recipient_id: data ? data.recipient_id : '',
          message: data ? data.message != null ? data.message : "" : "",
          asset_file:data?data.asset_file:''
        }}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          submitForm(values)
        }}

      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
          setFieldValue
        }) => (
          <Form className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1" noValidate onSubmit={handleSubmit}>
            <div className="pageHeader px-3 my-1 pt-1 user-select-none">
              <Row className="align-items-center min-h-51">
                <Col>
                  <h1 className="fw-semibold h4 my-2">
                    <Link to={redirect} className="align-items-center d-inline-flex overflow-hidden text-dark text-decoration-none">
                      <span className="me-1 ms-n1">
                        <IoIosArrowBack size="1.68rem" />
                      </span> Users
                    </Link>
                  </h1>
                </Col>
              </Row>
            </div>
            <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
              <Card className="h-100 w-100 overflow-auto border-light-subtle pageContentInner">
                <Card.Header className="bg-white fs-16 fw-semibold p-3 border-secondary">
                  <Card.Text className="px-xl-1">Edit Organ Donation</Card.Text>
                </Card.Header>
                <Card.Body className="p-xl-4">

                  <InputTextHorizontal
                    leftColSmall={true}
                    label="Title"
                    controlId="organ_name"
                    placeholder="Enter title"
                    touched={touched.organ_name}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.organ_name}
                    value={values.organ_name}
                  />

                  <CustomSelectHorizontal
                    leftColSmall={true}
                    label="Recipient"
                    controlId="recipient_id"
                    placeholder="Select"
                    touched={touched.recipient_id}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.recipient_id}
                    value={values.recipient_id}
                    data={recipientList}
                  />
                  <TextAreaHorizontal
                    leftColSmall={true}
                    controlId="message"
                    label="Message"
                    placeholder="Message"
                    touched={touched.message}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.message}
                    value={values.message}
                  />


                  <div className="theme-form-horizontal pb-sm-1 mb-3">
                    <Row>
                      <Col sm="auto" className="left-col left-col-sm">
                        <Stack direction="horizontal" gap={3} className="justify-content-between pb-2 py-sm-1">
                          <label className="fw-semibold">Image</label>
                        </Stack>
                      </Col>
                      <Col sm="auto" className="right-col">
                        <Row className="gx-3">
                          <Col xs="auto">
                            <div className="align-items-center border d-flex h-85 justify-content-center overflow-hidden position-relative rounded text-light w-85">
                              {!imageUrl && (
                                <FaImage size={32} />
                              )}
                              {imageUrl && (
                                <Image width={85} height={85} src={imageUrl} alt="Profile Image" />
                              )}

                            </div>
                          </Col>

                          <Col xs className="mw-1">
                            <Form.Group controlId="formFile" className="mb-3">
                              <Stack direction="horizontal" gap={2}>
                                <div className="position-relative">
                                  <Form.Control type="file" className="bottom-0 end-0 opacity-0 position-absolute start-0 top-0 z-1" onChange={(event) => handleFileChange(event, setFieldValue)} />
                                  <Button className="fw-semibold fs-13 text-dark mw-90" variant="secondary">Upload</Button>
                                </div>
                                {values.asset_file && (
                                  <Form.Text className="my-1 text-truncate" muted>{(values.asset_file as File)?.name}</Form.Text>
                                )}
                              </Stack>
                              <div className="fs-13 fst-italic fw-semibold text-dark text-opacity-50 my-1">Max 10MB (JPG & PNG)</div>
                            </Form.Group>

                          </Col>
                        </Row>
                        {errors.asset_file ? <div>
                          <p className='invalid-feedback' style={{ display: 'block' }}>Field is Required.</p>
                        </div> : ''}

                      </Col>
                    </Row>

                  </div>

                  <div className="theme-form-horizontal py-3">
                    <Row>
                      <Col sm="auto" className="left-col left-col-sm"></Col>
                      <Col sm="auto" className="right-col">
                        <Stack direction="horizontal" gap={3}>
                          <Button className="fw-semibold fs-13 text-white mw-90 my-1" variant="primary" type="submit">Update</Button>
                          <Link to={redirect} className="btn btn-secondary text-dark fw-semibold fs-13 mw-90 my-1">Cancel</Link>
                          <Button className="fw-semibold fs-13 text-white mw-90 my-1" variant="danger" onClick={()=>handleDeleteClick(id)} >Delete</Button>
                        </Stack>
                      </Col>
                    </Row>
                  </div>

                </Card.Body>
              </Card>
            </div>
          </Form>
        )}
      </Formik > : ''}
      <Loader isLoading={loading || submitting} />
      <DeleteModal show={show} handleClose={handleClose} setDeletestatus={setDeletestatus} recordDelete={recordDelete}/>
    </>

  )
}
