import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Image, Row, Stack } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import { Formik } from "formik"
import { FaImage } from 'react-icons/fa';
import { ProfileSchema } from '../../validations/Profile';
import InputTextHorizontal from '../../components/common/InputTextHorizontal';
import storage from '../../helpers/storage';
import { EditEntitiyMethod, GetEntitiyByIDMethod, logOut } from '../../helpers/helpersMethods';
import {  getUserApiCall, getUserInfo } from '../../services/userService';
import Loader from '../../components/common/Loader';
import { changeProfile } from '../../services/otherService';
import { PROFILE_CHANGE } from '../../validations/ValidationErrors';
import { nameBasedProtectedRoutes } from '../../router/protected';
import { toast } from 'react-toastify';

export const Profile = () => {
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState<any>();
  const [submitting, setSubmitting] = useState(false);
  const [userId, setUserId] = useState();
  const [data, setData] = useState<any>();
  const [temp, setTemp] = useState(false);
  const navigate = useNavigate();

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string>('');

  useEffect(()=>{
    
    const getUserData =  storage.getData("user-info");
    const oldEmail = getUserData.email;
    if(getUserData)
    {
      setUserData(getUserData);
      setUserId(getUserData.id);
      if(userId)
      {
      GetEntitiyByIDMethod(
            setLoading,
            getUserInfo,
            userId,
            setData
          );
      }
    }
   },[userId,temp]);

   useEffect(()=>
   {
    if(data)
    {
      setImageUrl('');
      if(data.profile_pic)
      {
        setImageUrl(data.profile_pic);
      }
    }
   },[data]);
   const handleFileChange = (event: any) => {
    const file = event.target.files?.[0];
    const allowedTypes = ['image/jpeg', 'image/png'];
    const maxFileSize = 500 * 1024; // 500KB in bytes
  
    if (event.target.files && event.target.files.length > 0) {
      if (!allowedTypes.includes(file.type)) {
        toast.error('Only JPG or PNG files are allowed');
        return;
      }
      if (file.size > maxFileSize) {
        toast.error('File size is too large, maximum file size is 500kb');
        return;
      }
      setSelectedFile(event.target.files[0]);
    }
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImageUrl(reader.result as string);
      };
    }
  };
   /**
     * Editing Employees
     *
     * @param {*} value
     */
   const submitForm = (value: any) =>
   {
    if (userId) 
    {
        setLoading(true);
        value.profile_image = selectedFile ? selectedFile:null;
        changeProfile(userId,value).then(
          (response) => {
            const res = response?.data;
            setTimeout(() => {
              setLoading(false);
              if (response?.statusCode === 200)
              {
                toast.success(response.msg);
                setTemp(!temp);
              }
              if(res)
              {
              
                const getUserData =  storage.getData("user-info");
                const oldEmail = getUserData.email;
                const newEmail = res.email;
                setImageUrl(res.profile_pic)
                
                if(oldEmail != newEmail)
                {
                  logOut(navigate);
                }else
                {
                  storage.setData("user-info", res);
                  navigate(nameBasedProtectedRoutes.dashborad.path);
                }
              } 
              
            }, 1000);
           
          },
          (error) => {
            setLoading(false);
            toast.error(error.response.data.msg);
            
          }
        );
    }
   }
  return (
    <>
     {data  ?
     
      <Formik
        validationSchema={ProfileSchema}
        initialValues={{
          name: data ? data.name : '',
          email: data ? data.email : ''
        }}
       
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          submitForm(values)
      }}
      
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
          
          <Form className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1" noValidate onSubmit={handleSubmit}>
            <div className="pageHeader px-3 my-1 pt-1 user-select-none">
              <Row className="align-items-center min-h-51">
                <Col>
                  <h1 className="fw-semibold h4 my-2">Profile</h1>
                </Col>
              </Row>
            </div>
            <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
              <Card className="h-100 w-100 overflow-auto border-light-subtle pageContentInner">
                <Card.Header className="bg-white fs-16 fw-semibold p-3 border-secondary">
                  <Card.Text className="px-xl-1">Update Profile</Card.Text>
                </Card.Header>
                <Card.Body className="p-xl-4">

                  <div className="theme-form-horizontal pb-3 mb-1">
                    <Row>
                      <Col sm="auto" className="left-col">
                        <Stack direction="horizontal" gap={3} className="justify-content-between pb-2 py-sm-1">
                          <label className="fw-semibold">Image</label>
                        </Stack>
                      </Col>
                      <Col sm="auto" className="right-col">
                        <Row className="gx-3">
                          <Col xs="auto">
                            <div className="align-items-center border d-flex h-85 justify-content-center overflow-hidden position-relative rounded text-light w-85">
                              {!selectedFile && !imageUrl &&(
                                <FaImage size={32} />
                              )}
                              {selectedFile && (
                                <Image width={85} height={85} src={imageUrl} alt="Profile Image" />
                              )}
                              {!selectedFile && imageUrl && (
                                <Image width={85} height={85} src={imageUrl} alt="Profile Image" />
                              )}
                            </div>
                          </Col>

                          <Col xs className="mw-1">
                            <Form.Group controlId="formFile" className="mb-3">
                              <Stack direction="horizontal" gap={2}>
                                <div className="position-relative">
                                  <Form.Control type="file" className="bottom-0 end-0 opacity-0 position-absolute start-0 top-0 z-1" onChange={handleFileChange} />
                                  <Button className="fw-semibold fs-13 text-dark mw-90" variant="secondary">Upload</Button>
                                </div>
                                {selectedFile && (
                                  <Form.Text className="my-1 text-truncate" muted>{selectedFile.name}</Form.Text>
                                )}
                              </Stack>
                              <div className="fs-13 fst-italic fw-semibold text-dark text-opacity-50 my-1">Max 500KB (JPG & PNG)</div>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                  </div>

                  <InputTextHorizontal
                    label="Name"
                    controlId="name"
                    placeholder="Enter Name"
                    touched={touched.name}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.name}
                    value={values.name}
                  />

                  <InputTextHorizontal
                    label="Email Address"
                    controlId="email"
                    placeholder="Enter Email Address"
                    touched={touched.email}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.email}
                    value={values.email}
                  />

                  <div className="theme-form-horizontal pb-3">
                    <Row>
                      <Col sm="auto" className="left-col"></Col>
                      <Col sm="auto" className="right-col">
                        <Stack direction="horizontal" gap={3}>
                          <Button className="fw-semibold fs-13 text-white mw-90 my-1" variant="primary" type="submit">Submit</Button>
                          <Link to="/app" className="btn btn-secondary text-dark fw-semibold fs-13 mw-90 my-1">Cancel</Link>
                        </Stack>
                      </Col>
                    </Row>
                  </div>

                </Card.Body>
              </Card>
            </div>
          </Form>
        )}
      </Formik >:''}
      <Loader isLoading={loading || submitting} />
    </>

  )
}
