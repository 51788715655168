import Axios from 'axios';
import storage from '../helpers/storage';
import { Link, useNavigate } from 'react-router-dom';

/**
 * Axios Interceptor
 * @param {*} config
 * @returns {*}
 */
function authRequestInterceptor(config: any) {
    const token = storage.getToken();
    if (token) {
        config.headers.authorization = `Bearer ${token}`;
    }
    config.headers.Accept = 'application/json';
    return config;
}


/**
 * Axios Object to use 
 * @type {*}
 */
export const axios = Axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
    (response) => {
        return response.data;
    },
    (error) => {
        if (error.response.status === 401) {
            if (storage.getToken() !== null) {
                localStorage.removeItem('MYLO_token');
                localStorage.removeItem('user-info');
                window.location.reload();
            }
        }
        if (error.response.status === 403) {
            if (storage.getToken() !== null) {
                localStorage.removeItem('MYLO_token');
                localStorage.removeItem('user-info');
                window.location.reload();
            }
        }
        return Promise.reject(error);
    }
);
