import { Button, Stack } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { ForgotPasswordSchema } from '../../../validations/Auth';
import { Formik } from 'formik';
import { AuthHeader } from '../authHeader/AuthHeader';
import InputText from '../../../components/common/InputText';
import { FaEnvelope } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { nameBasedRoutes } from '../../../router/public';
import { useState } from 'react';
import { forgotPassword } from '../../../services/authService'
import Loader from '../../../components/common/Loader';
import { toast } from 'react-toastify';

export const ForgetPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  
  const handleSubmit = (values:any, actions:any) => {
    setLoading(true);
    forgotPassword({email:values.email}).then(
      (response) => {
        setLoading(false);
        console.log(response);
        const res = response?.data;
        if (response?.status === 200) {
          toast.success(res.msg);
          navigate(nameBasedRoutes.home.path);
        }
        
      },
      (error) => {
        setLoading(false);
        toast.error(error.response.data.msg);
        
      }
    );
  };
 
  return (
    <>
    <Formik
      validationSchema={ForgotPasswordSchema}
      onSubmit={handleSubmit}
      initialValues={{
        email: ''
      }}
    >
      {({
         values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                  status,
      }) => (
        <div className="d-flex flex-column p-3 min-vh-100 w-100">
          <div className="mw-385 mx-auto my-4 py-md-5 text-center w-100">
            <AuthHeader />
            <div className="bg-white d-inline-block mt-3 p-sm-4 p-2 rounded-3 text-start w-100">
              <Form className="p-2" noValidate onSubmit={handleSubmit}>
                <h6 className="fw-normal text-center mb-3">Forgot Password</h6>
                <InputText
                  controlId="email"
                  label='Email Address'
                  placeholder="Enter Email Address"
                  touched={touched.email}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  errorsField={errors.email}
                  value={values.email}
                  rightIcon={<FaEnvelope size="13" />}
                />
                <Stack direction="horizontal" gap={3} className="justify-content-between">
                  <Button  className="fw-semibold fs-13 text-white mw-90" variant="primary" type="submit">Send</Button>
                  <Link className="fs-13 text-decoration-none" to={nameBasedRoutes.home.path}>Back to Login</Link>
                </Stack>
              </Form>
            </div>
          </div>
        </div>
      )}
    </Formik>
     <Loader isLoading={loading} />
  </>
  )
}
