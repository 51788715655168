import { Formik } from 'formik';
import { Button, Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Link, useNavigate } from 'react-router-dom';
import { AuthHeader } from '../authHeader/AuthHeader';
import InputText from '../../../components/common/InputText';
import { LoginSchema } from '../../../validations/Auth';
import { FaEnvelope, FaLock } from "react-icons/fa";

import { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import Loader from '../../../components/common/Loader';
import storage from '../../../helpers/storage';
import { toast } from 'react-toastify';
import { CAPTCH_INVALID } from '../../../validations/ValidationErrors';
import { loginWithUserNameAndPassword } from '../../../services/authService';
import { nameBasedRoutes } from '../../../router/public';
import Cookies from "universal-cookie";

export const Login = () => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [captchaCode, setCaptchaCode] = useState("");
  const recaptchaRef = useRef<any>(null);
  const cpatchaKey: string | any = process.env.REACT_APP_CPATCHA_KEY;
  const rememberMe: { email: string; isChecked: boolean; password: string } = storage ?.getData("remember-me");

 
  // remember me
  const rememberMeEmail = cookies.get("admin_email");
  const rememberMePassword = cookies.get("admin_password");
  const alreadyChecked = cookies.get("admin_checked");
  const [isChecked, setIsChecked] = useState(!!alreadyChecked);
  const [btnStatus, setBtnStatus] = useState(true);

  const onChangeCheckbox = (event: any) => {
    setIsChecked(event.target.checked);
  };

  const onChangeCaptchaCode = (value: any) => {
    setCaptchaCode(value);
    setBtnStatus(false);
  };
  useEffect(() => {
    if (storage.getToken() != null) {
        return navigate('app/dashboard')
    } else {
        localStorage.removeItem('user-info');
    }
}, [])

  /**
   * Submit Form
   * @date 12/04/2023 - 13:32:30
   *
   * @async
   * @param {string} email
   * @param {string} password
   * @param {*} captcha
   * @param {*} actions
   * @returns {*}
   */
  const submitForm = async (
    email: string,
    password: string,
    captcha: any,
    actions: any,
    rememberMe:any) => {
    if (!captchaCode) {
      toast.error(CAPTCH_INVALID);
      return;
    }
    if (isChecked === false){
      cookies.remove("admin_email");
      cookies.remove("admin_password");
      cookies.remove("admin_checked");
    }
    setLoading(true);
    loginWithUserNameAndPassword({ email, password })
      .then((response) => {
        if (isChecked === true) {
          cookies.set("admin_email", email);
          cookies.set("admin_password", password);
          cookies.set("admin_checked", isChecked);
        }

        setLoading(false);
        storage.setToken(response.data.auth_token);
        storage.setData("user-info", response.data.userData);
        toast.success(response.data.msg);
        navigate('app/dashboard');
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response?.data.msg);
      });
  };
  return (
    <>
 <Formik
        validationSchema={LoginSchema}
        initialValues={{
        email: rememberMeEmail ? rememberMeEmail : "",
        password: rememberMePassword ? rememberMePassword : "",
        captcha: "",
        rememberCheck: false}}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          submitForm(
            values.email,
            values.password,
            values.captcha,
            actions,
            rememberMe
          );
        }}

      >

        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (

          <div className="d-flex flex-column p-3 min-vh-100 w-100">
            <div className="mw-385 mx-auto my-4 py-md-5 text-center w-100">
              <AuthHeader />
              <div className="bg-white d-inline-block mt-3 p-sm-4 p-2 rounded-3 text-start w-100">
                <Form className="p-2" noValidate onSubmit={handleSubmit}>
                  <h6 className="fw-normal text-center mb-3">Sign In</h6>

                  <InputText
                    controlId="email"
                    label='Email Address'
                    placeholder="Enter Email Address"
                    touched={touched.email}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.email}
                    value={values.email}
                    rightIcon={<FaEnvelope size="13" />}
                  />

                  <InputText
                    controlId="password"
                    label='Password'
                    placeholder="Enter Password"
                    touched={touched.password}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.password}
                    value={values.password}
                    type="password"
                    rightIcon={<FaLock size="13" />}
                  />

                  <Form.Group className="mb-3">
                    <Row xs="auto" className="align-items-center justify-content-between gx-0">
                      <Col>
                        <Form.Check
                          className="fs-12 mh-auto mb-0 user-select-none"
                          type="checkbox"
                          label="Remember me"
                          checked={isChecked}
                          onChange={onChangeCheckbox}
                        />
                      </Col>
                      <Col>
                        <Link className="fs-13 text-decoration-none" to={nameBasedRoutes.forgotPassword.path}>Forgot Password?</Link>
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="captcha">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={cpatchaKey}
                      onChange={onChangeCaptchaCode}
                      className='recaptcha overflow-x-auto overflow-y-hidden'
                    />
                  </Form.Group>

                  <Button className="fw-semibold fs-13 text-white mw-90 mt-2" variant="primary" type="submit"  disabled={btnStatus}>Login</Button>

                </Form>
              </div>
            </div>
        </div>
      )}
    </Formik >
      
      <Loader isLoading={loading} />
    </>
  )
}
