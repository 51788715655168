import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row, Stack } from "react-bootstrap"
import { IoIosArrowBack } from "react-icons/io"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Formik } from "formik"
import { PendingProclamationSchema } from "../../../../validations/Proclamation";
import ViewTextHorizontal from "../../../../components/common/ViewTextHorizontal";
import ViewTagsHorizontal from "../../../../components/common/ViewTagsHorizontal";
import { ApproveModal } from "../../Modals/ApproveModal";
import { RejectModal } from "../../Modals/RejectModal";
import { getDetails } from "../../../../services/PproclamationService";
import { toast } from "react-toastify";
import { CapitalText, EditEntitiyMethod } from "../../../../helpers/helpersMethods";
import Loader from "../../../../components/common/Loader";
import ImageModal from "../../../../components/common/ImagePreview";

export const ViewPendingProclamation = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [redirect, setRedirect] = useState<any>();
  const [data, setData] = useState<any>();
  const [imagethumb, setImagethumb] = useState<any>();
  const [isAudioPopupOpen, setIsAudioPopupOpen] = useState(false);
  const [publish, setPublished] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const handleApproveClose = () => setShowModal(false);
  const handleApproveShow = () => setShowModal(true);
  const handleRejectClose = () => setRejectModal(false);
  const handleRejectShow = () => setRejectModal(true);

  useEffect(() => {
    setLoading(true);
    if (id) {

      getDetails(id).then(
        (resp) => {
          setLoading(false);
          const res = resp?.data;
          setData(res);
          setImagethumb(res.asset_url_name);
          setRedirect(`/app/proclamation/pending-proclamation`);
          console.log(data);
        },
        (error: any) => {
          setLoading(false);
          const err = error?.response?.data;
          if (err?.statusCode === 400) {
            toast.error(err.msg);
            navigate(`/app/proclamation`);
          }
        }
      );
    }
  }, [id]);

  const isVideoHandler = () => {
    if (imagethumb) {
      setIsAudioPopupOpen(true);
    }
  }

  return (
    <>
      {data ? 
          <>
            <Form className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1" noValidate>
              <div className="pageHeader px-3 my-1 pt-1 user-select-none">
                <Row className="align-items-center min-h-51">
                  <Col>
                    <h1 className="fw-semibold h4 my-2">
                      <Link to={redirect} className="align-items-center d-inline-flex overflow-hidden text-dark text-decoration-none">
                        <span className="me-1 ms-n1">
                          <IoIosArrowBack size="1.68rem" />
                        </span> Pending Proclamation
                      </Link>
                    </h1>
                  </Col>
                </Row>
              </div>
              <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
                <Card className="h-100 w-100 overflow-auto border-light-subtle pageContentInner">
                  <Card.Header className="bg-white fs-16 fw-semibold p-3 border-secondary">
                    <Card.Text className="px-xl-1">Pending Proclamation Details</Card.Text>
                  </Card.Header>
                  <Card.Body className="p-xl-4">

                    <ViewTextHorizontal
                      label="Title"
                      setColunView={true}
                      value={data.proclamation_title}
                    />

                    <ViewTextHorizontal
                      label="User"
                      setColunView={true}
                      value={data.proclamation_user_name}
                    />

                    <ViewTextHorizontal
                      label="Release Legacy Date"
                      setColunView={true}
                      value={data.proclamation_legacy_released_date}
                    />

                     <ViewTagsHorizontal
                      label="Tags"
                      setColunView={true}
                      value={data.tags ? data.tags.map((obj: any, index: any) => ({ id: obj, text: obj })) : ''}
                    />

                    <ViewTextHorizontal
                      label="Video File"
                      setColunView={true}
                      value={data.video}
                      isVideoHandler={isVideoHandler}
                    />

                    <ViewTextHorizontal
                      label="Message"
                      setColunView={true}
                      value={data.message}
                    />
                    {
                      data.status == "rejected" ?
                        <>
                          <ViewTextHorizontal
                            label="Status"
                            setColunView={true}
                            value={CapitalText(data.status)}
                          />
                          <ViewTextHorizontal
                            label="Reason"
                            setColunView={true}
                            value={data.reason}
                          />
                          
                        </>
                        : ''}
                      {
                      data.status == "pending" ?
                    <div className="theme-form-horizontal pb-3">
                      <Row>
                        <Col sm="auto" className="left-col"></Col>
                        <Col sm="auto" className="right-col">
                          <Stack direction="horizontal" gap={3}>
                            <Button className="fw-semibold fs-13 text-white mw-90" variant="success" onClick={handleApproveShow}>Approve</Button>
                            <Button className="fw-semibold fs-13 text-white mw-90" variant="danger" onClick={handleRejectShow}>Reject</Button>
                          </Stack>
                        </Col>
                      </Row>
                    </div>
                    :''}
                  </Card.Body>
                </Card>
              </div>
            </Form>
            <ApproveModal show={showModal} onHide={handleApproveClose} details={`Are you sure you want to Approve the proclamation of ${data.proclamation_user_name}?`}id={data.id}/>
            <RejectModal show={rejectModal} onHide={handleRejectClose}  details={`Are you sure you want to Reject the proclamation of ${data.proclamation_user_name}?`}id={data.id}/>
          </>
       : ''}
      <Loader isLoading={loading} />
      <ImageModal
        show={isAudioPopupOpen}
        handleClose={() => setIsAudioPopupOpen(false)}
        imageUrl={imagethumb} contentType='video' />

    </>
  )
}