import moment from 'moment';
import { FC } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import formatData from '../../constants/formatData';
import { FaCalendarAlt } from 'react-icons/fa';
interface IDateModal {
    placeholder?: any;
    label?:any;
    selected?:any;
    onBlur?:any;
    error?:any;
    touched?:any;
    handleChange?:any;
    name?:String;
    setFieldValue?:any;
    value?:any;
    errorsField?:any;
    controlId?:any;
    allowPastDates?: boolean;
    leftColSmall?: boolean;
}
const FormDatesPicker: FC<IDateModal> = ({
    placeholder,label,handleChange,name,setFieldValue,value,errorsField,controlId,allowPastDates,leftColSmall
}) => {
    const handleDateChangeRaw = (e:any) =>
    {
       
        e.preventDefault();
    }
    const minDate = allowPastDates ? null : moment().toDate();
    return (
        <Form.Group className="theme-form-horizontal pb-3">
        <Row>
            <Col sm="auto" className={`left-col ${leftColSmall ? 'left-col-sm' : ''}`}>
                {label ? (
                    <Stack direction="horizontal" gap={2} className="justify-content-sm-between py-sm-1">
                        <Form.Label className="lh-sm">{label}</Form.Label>
                    </Stack>
                ) : null}
            </Col>
            <Col sm>
                <div className="position-relative right-col">
                <DatePicker
                            onChange={(event:any)=>
                            {
                                const dates = moment(event).format("YYYY-MM-DD")
                                setFieldValue(name,dates)
                            }}
                            
                            className="form-control"
                            placeholderText={placeholder}
                            value={value ? moment(value).format(formatData.dateFormats.commaSeparatedDateTime):''}
                            onChangeRaw={handleDateChangeRaw}
                            minDate={minDate}
                            
                          />
                    <Form.Control.Feedback type="invalid" className={`${!!errorsField  ? 'd-block' : null}`}>
                        {errorsField}
                    </Form.Control.Feedback>
                </div>
            </Col>
        </Row>
    </Form.Group>
    );
}


export default FormDatesPicker;
