import {axios} from "../services/axios";
import { adminApi } from "../api";

export type PlanDTO = {
  allowed_proclamation: string,
  allowed_recipient: string,
};


/**
 * Faq List
 * @date 04/05/2023 - 13:21:19
 *
 * @async
 * @param {*} params
 * @returns {unknown}
 */
export const getList = async () => {
    return await axios.get(`${adminApi.planList}`);
  };
/**
 * Faq List
 * @date 04/05/2023 - 13:21:19
 *
 * @async
 * @param {*} params
 * @returns {unknown}
 */
export const getPlanList = async () => {
    return await axios.get(`${adminApi.planList}`);
  };

  

 
  
  /**
   * Plan Edit
   * @date 04/05/2023 - 17:54:51
   *
   * @async
   * @param {string} id
   * @returns {Promise<any>}
   */
  export const editPlan = async (id: string,data: PlanDTO): Promise<any> => {
    return axios.patch(`${adminApi.planEdit}/${id}`,data);
  };
 
  
  /**
   * Plan change
   * @date 15/05/2023 - 12:45:32
   *
   * @async
   * @param {PlanDTO} data
   * @returns {Promise<any>}
   */
  export const editChange = async (data:any): Promise<any> => {
    return axios.patch(`${adminApi.planChange}`,data);
  };
 
 