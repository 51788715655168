import { FC } from 'react';
import { Button, Modal, Stack } from 'react-bootstrap';

interface IPublishModal {
    show: any;
    onHide: any;
    onSubmit: any;
    userName?:string;
    value?:boolean;
}

/**
 * Custom Publish Modal Component
 * @date 4/20/2023 - 12:41:39 PM
 *
 * @param {{ show: any; onHide: any; }} {
    show, onHide
}
 * @returns {*}
 */

export const PublishModal: FC<IPublishModal> = ({
    show, onHide,onSubmit,userName,value
}) => {
    const publishText = value ? 'Unpublish':'Publish';
    return (
        <Modal
            show={show}
            onHide={onHide}
            onSubmit={onSubmit}
            backdrop="static"
            keyboard={false}
            centered={true}
            scrollable={true}
            size="sm"
            className="theme-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title className="fs-16 fw-semibold">{publishText} Proclamation</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pb-2">
                <div className="fs-14 fw-semibold pb-3 mb-1">
                    Are you sure you want to {publishText} the proclamation of {userName} to general public
                </div>
            </Modal.Body>
            <Modal.Footer className="pt-0 border-0">
                <Stack direction="horizontal" gap={3} className="flex-wrap pb-2">
                    <Button variant="secondary" onClick={onHide} className="text-dark fw-semibold fs-13 mw-90">
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={onSubmit} className="fw-semibold fs-13 text-white mw-90">{publishText} Proclamation</Button>
                </Stack>
            </Modal.Footer>
        </Modal>
    )
}