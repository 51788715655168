import { FC } from 'react';
import { Button, Modal, Stack } from "react-bootstrap"

interface IDeleteModal {
    show?: boolean;
    handleClose?: any;
    setDeletestatus?:any;
    recordDelete?:any;
}

/**
 * Confirm Delete Modal Reusable Component
 * @date 4/20/2023 - 4:19:58 PM
 *
 * @param {{ show: any; handleClose: any; }} {
    controlId, show, handleClose
}
 * @returns {*}
 */

const DeleteModal: FC<IDeleteModal> = ({
    show, handleClose,setDeletestatus,recordDelete
}) => {
    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered={true}
            scrollable={true}
            size="sm"
            className="theme-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title className="fs-16 fw-semibold">Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pb-2">
                <div className="fs-14 fw-semibold pb-2 mb-1">Are you sure want to delete this entry?</div>
            </Modal.Body>
            <Modal.Footer className="pt-0 border-0">
                <Stack direction="horizontal" gap={3} className="flex-wrap pb-2">
                    <Button variant="secondary" onClick={handleClose} className="text-dark fw-semibold fs-13 mw-90">
                        Cancel
                    </Button>
                    <Button variant="danger" className="fw-semibold fs-13 text-white mw-90" onClick={recordDelete} >Confirm</Button>
                </Stack>
            </Modal.Footer>
        </Modal>
    );
}

export default DeleteModal