import * as yup from "yup";
import {
  CONFIRM_PASSWORD_REQUIRED,
  EMAIL_REQUIRED, MAXIMUM_32_CHAR, MINIMUM_8_CHAR, NEW_PASSWORD_REQUIRED, PASSWORD_MUST_MATCH, PASSWORD_REGEX, PASSWORD_REQUIRED, REQUIRED, VALID_EMAIL
} from "./ValidationErrors";

/**
 * Profile Schema for Validation
 * @date 4/19/2023 - 9:26:15 AM
 *
 * @type {*}
 */

export const ProfileSchema = yup.object().shape({
  name: yup.string().min(8, MINIMUM_8_CHAR).max(32, MAXIMUM_32_CHAR).required(REQUIRED),
  email: yup.string().required(EMAIL_REQUIRED).email(VALID_EMAIL),
});


/**
 * Change Password Schema for Validation
 * @date 4/19/2023 - 9:26:15 AM
 *
 * @type {*}
 */

export const ChangePasswordSchema = yup.object().shape({
  currentPassword: yup.string()
  .required(PASSWORD_REQUIRED)
  .min(8, MINIMUM_8_CHAR)
  .max(32, MAXIMUM_32_CHAR)
  .matches(
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9])(?!.*\s).{8,}$/,
    PASSWORD_REGEX
  ),
  newPassword: yup
  .string()
  .required(NEW_PASSWORD_REQUIRED)
  .min(8, MINIMUM_8_CHAR)
  .max(32, MAXIMUM_32_CHAR)
  .matches(
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9])(?!.*\s).{8,}$/,
    PASSWORD_REGEX
  ),
confirmPassword: yup
  .string()
  .required(CONFIRM_PASSWORD_REQUIRED)
  .oneOf([yup.ref("newPassword"), ""], PASSWORD_MUST_MATCH),
});