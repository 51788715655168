import { Link, useNavigate, useParams } from "react-router-dom"
import { Button, Card, Col, Form, Row, Stack } from "react-bootstrap"
import { IoIosArrowBack } from "react-icons/io"
import InputTextHorizontal from '../../../components/common/InputTextHorizontal';
import TextEditor from "../../../components/common/TextEditor";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { editCms, getDetails } from "../../../services/cmsService";
import { nameBasedProtectedRoutes } from "../../../router/protected";
import { Formik } from "formik";
import { EditEntitiyMethod } from "../../../helpers/helpersMethods";
import Loader from "../../../components/common/Loader";
import { CreateSchema } from "../../../validations/Cms";
import { CMS_UPDATED_SUCCESS } from "../../../validations/ValidationErrors";
import ReactQuill from "react-quill";

export const EditCMS = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [redirect, setRedirect] = useState<any>();
  const [data, setData] = useState<any>();
  const [submitting, setSubmitting] = useState(false);
  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"], // toggled buttons
      ["blockquote", "code-block"],

      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ direction: "rtl" }], // text direction

      [{ size: ["small", false, "large", "huge"] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: ["black"] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],
      ["link"],

      ["clean"],
    ],
  };
  useEffect(() => {
    setLoading(false);
    setRedirect(nameBasedProtectedRoutes.cms.path);
    if (id) {
      getDetails(id).then(
        (resp: any) => {
          const res = resp?.data;
          setData(res);
          setTimeout(() => {
            setLoading(false);

          }, 1000);
        },
        (error: any) => {
          const err = error?.response?.data;
          setLoading(false);
          if (err?.statusCode === 400) {
            toast.error(err.msg);
            //navigate(nameBasedProtectedRoutes.faq.path);
          }

        }
      );
    }

  }, [id]);
  /**
   * Editing CMS
   *
   * @param {*} value
   */
  const submitForm = (value: any, setErrors: any) => {
    if (id) {
      toast.dismiss();
      let content: string = value.description.replace(/<[^>]+>/g, '');

      if (content.length == 0) {
        setErrors({
          description: 'Field is Required.'
        });
      } else {
        value.description = value.description.replace(/<p><br><\/p>/g, '<p></p>');
        EditEntitiyMethod(setSubmitting, editCms, id, value, CMS_UPDATED_SUCCESS, nameBasedProtectedRoutes.cms.path, navigate);
      }

    }
  }
  return (
    <>
      {!loading && data ? <Formik
        validationSchema={CreateSchema}
        initialValues={{
          title: data ? data.title : '',
          description: data ? data.description : ''
        }}
        onSubmit={(values, actions) => {
          console.log(values);
          values.description = values.description.trim();
          values.title = values.title.trim();
          actions.setSubmitting(false);
          submitForm(values, actions.setErrors)
        }}

      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          isSubmitting,
          setFieldValue,
          errors,
        }) => (
          <Form className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1" noValidate onSubmit={handleSubmit}>
            <div className="pageHeader px-3 my-1 pt-1 user-select-none">
              <Row className="align-items-center min-h-51">
                <Col>
                  <h1 className="fw-semibold h4 my-2">
                    <Link to={redirect} className="align-items-center d-inline-flex overflow-hidden text-dark text-decoration-none">
                      <span className="me-1 ms-n1">
                        <IoIosArrowBack size="1.68rem" />
                      </span> CMS
                    </Link>
                  </h1>
                </Col>
              </Row>
            </div>
            <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
              <Card className="h-100 w-100 overflow-auto border-light-subtle pageContentInner">
                <Card.Header className="bg-white fs-16 fw-semibold p-3 border-secondary">
                  <Card.Text className="px-xl-1">Edit CMS</Card.Text>
                </Card.Header>
                <Card.Body className="p-xl-4">


                  <InputTextHorizontal
                    leftColSmall={true}
                    label="Title"
                    controlId="title"
                    placeholder="Title"
                    touched={touched.title}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.title}
                    value={values.title}
                    disabled={true}
                  />

                  <div className="right-col-600">
                    <Form.Group className="theme-form-horizontal pb-sm-1 mb-3" controlId="">
                      <Row>
                        <Col sm="auto" className="left-col left-col-sm">
                          <Form.Label>Content</Form.Label>
                        </Col>
                        <Col sm>
                          <div className="position-relative right-col reactEditor">
                            {/* <ReactQuill onBlur={handleBlur} onChange={handleChange} value={value} 
                                    className="theme-editor"
                                /> */}
                            <ReactQuill
                              theme="snow"
                              value={values.description}
                              onChange={(event) => {
                                setFieldValue("description", event);
                              }}
                              modules={modules}
                              placeholder="Description"
                              className={`ctmeditor rounded overflow-hidden ${touched.description &&
                                errors.description &&
                                "is-invalid form-control"
                                } `}
                            />
                            {touched.description && errors.description && (
                              <Form.Control.Feedback type="invalid">
                                {String(errors.description)}
                              </Form.Control.Feedback>

                            )}
                          </div>
                        </Col>
                      </Row>
                    </Form.Group>
                    {/* <TextEditor
                    leftColSmall={true}
                    label="Content"
                    controlId="description"
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    value={values.description}
                    errorsField={errors.description}
                  /> */}
                  </div>


                  <div className="theme-form-horizontal py-3">
                    <Row>
                      <Col sm="auto" className="left-col left-col-sm"></Col>
                      <Col sm="auto" className="right-col">
                        <Stack direction="horizontal" gap={3}>
                          <Button className="fw-semibold fs-13 text-white mw-90 my-1" variant="primary" type="submit">Update</Button>
                          <Link to={redirect} className="btn btn-secondary text-dark fw-semibold fs-13 mw-90 my-1">Cancel</Link>
                        </Stack>
                      </Col>
                    </Row>
                  </div>

                </Card.Body>
              </Card>
            </div>
          </Form>
        )}
      </Formik > : ''}
      <Loader isLoading={loading || submitting} />
    </>

  )
}
