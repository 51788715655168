import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ActionCell from '../../../../../components/common/dataGrid/ActionCell';
import DataGrid from '../../../../../components/common/dataGrid/DataGrid';
import Loader from '../../../../../components/common/Loader';
import constantValues from '../../../../../constants/constantValues';
import formatData from '../../../../../constants/formatData';
import { maskPhoneNumber, truncateText } from '../../../../../helpers/helpersMethods';
import storage from '../../../../../helpers/storage';
import { getList } from '../../../../../services/recipientService';


export const Recipients = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(constantValues.rowPerPage);
  const [totalPages, setTotalPages] = useState(0);
  const [totalReults, setTotalResults] = useState(0);
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [releaseLegacy, setReleaseLegacy] = useState(storage.getReleaseLegacy());
  useEffect(() => {
    setLoading(false);
    trusteeList({ page: currentPage, limit: rowPerPage,userid:id });
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    setLoading(false);
    trusteeList({ page: currentPage, limit: rowPerPage,sortColumn:sortColumn,sortDirection:sortDirection,userid:id });
  }, [sortColumn,sortDirection]);
  
  const trusteeList = async (params:any) => {
    setLoading(true);
    getList({ ...params, search: params.search?.trim() }).then(
      (response) => {
        setData(response.data.record);
        setTotalPages(response.data.totalPages);
        setTotalResults(response.data.totalResults);
        setLoading(false);
      }
    );
  };
  const columns = [
    {
      id:'id',
      name: '#',
      sortable: true,
      center: true,
      selector: (row:any, index:any) => (currentPage - 1) * rowPerPage + index + 1,
      width: '60px',
    },
    {
      id:'name',
      selector: (row: any) => row.name,
      name: 'Name',
      sortable: true,
      minWidth: '150px',
      cell: (row: any) => {return truncateText(row.name)}
    },
    {
      id:'email',
      selector: (row: any) => row.email,
      name: 'Email',
      sortable: true,
      minWidth: '150px',
      cell: (row: any) => {return truncateText(row.email)}
    },
    {
      id:'phone_no',
      selector: (row: any) => row.phone_no,
      name: 'Phone',
      sortable: true,
      minWidth: '150px',
      cell: (row:any) =>
      {
      
        return row.phone_no ? maskPhoneNumber(row.phone_country_code+row.phone_no):'-';
      },
    },
    {
      id:'relation',
      selector: (row: any) => row.relation,
      name: 'Relationship',
      sortable: true,
      minWidth: '150px',
      cell: (row: any) => {return truncateText(row.relation)}
    },
    // {
    //   id:'Image',
    //   selector: (row: any) => row.asset_url_name,
    //   name: 'Image',
    //   sortable: false,
    //   minWidth: '150px',
    //   cell: (row: any) =>
    //   {
    //     if(row.asset_url_name)
    //     {
    //       return <div className="position-relative">
    //       <img src={row.asset_url_name} alt="Organ Donar Image" width={30} height={30} className="border border-light rounded-1 rounded-sm" />
    //     </div>;
    //     }
    //   }
      
    // },
    // {
    //   id:'created_at',
    //   selector: (row: any) => row.created_at,
    //   name: 'Date and Time',
    //   sortable: true,
    //   minWidth: '150px',
    //   cell: (row:any) =>
    //   moment(row.created_at).format(
    //     formatData.datetimeFormats.forwordSeparatedDateTime
    //   ),
    // },
    {
      selector: (row: any) => row.action,
      name: 'Action',
      width: '100px',
      center: true,
      button: true,
      cell: (row: any) => (
        <ActionCell controlId="users/recipient" disabled={releaseLegacy} isEditEnable={true} redirectID={row.id} />
      )
  
    },
  ];
  return (
    <div className="d-flex flex-column h-100 rounded-0 dataGridListMain">
     <DataGrid
        showTitle="Recipient"
        columns={columns}
        data={data}
        pagination={true}
        totalPages={totalPages}
        totalRecords={totalReults}
        rowPerPage={rowPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        sortColumn={sortColumn}
        setSortColumn = {setSortColumn}
        sortDirection={sortDirection}
        setSortDirection = {setSortDirection}
      />
       <Loader isLoading={loading} />
    </div>
  )
}
