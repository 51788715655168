import {axios} from "../services/axios";
import { adminApi } from "../api";


export type UsersDTO = {
  name: string,
  email: string,
  phone: string,
  status: string, 
};
export type MemoryDTO = {
  legacy_title: string,
  message: string
};
export type TruesteeDTO = {
  name: string,
  email: string
  phone: string
};
export type FrequencyRuleDTO = {
  frequency: string,
  frequency_type: string
};
export type ExecuteLegacyDTO = {
  check_on_period: string,
  check_on_period_type: string
};
export type releaselegacyDTO = {
  reason: string,
};
/**
 * user List
 * @date 18/04/2023 - 16:50:33
 *
 * @async
 * @param {*} params
 * @returns {unknown}
 */
export const getUserListApiCall = async (params:any) => {
    return await axios.get(`${adminApi.userList}`, { params });
  };


//Get a user data by id
export const getUserApiCall = async (params:any) => {
  return await axios.get(
    `${adminApi.getUserData}`,{params}
  );
};

/**
 * Get user by ID
 *
 * @param {string} id
 * @returns {Promise<any>}
 */
export const getUserInfo = (id: string): Promise<any> => {
  return axios.get(`${adminApi.getUserData}/${id}`);
};


/**
 * Updating Users
 *
 * @param {string} id
 * @param {UsersDTO} data
 * @returns {Promise<any>}
 */
export const updateUsersInfo = (id: string, data: UsersDTO): Promise<any> => {
  return axios.patch(`${adminApi.updateUserData}/${id}`, data);
};


/**
 * memory List
 * @date 18/04/2023 - 16:50:33
 *
 * @async
 * @param {*} params
 * @returns {unknown}
 */
export const getMemoryListApiCall = async (params:any) => {
  return await axios.get(`${adminApi.memoryList}/${params.userid}`, { params });
};

//Get a user data by id
export const getMemoryDetailsById = async (params:any) => {
  return await axios.get(
    `${adminApi.getMemoryDetailsById}`,{params}
  );
};

/**
 * Updating Users
 *
 * @param {string} id
 * @param {MemoryDTO} data
 * @returns {Promise<any>}
 */
export const updateMemoryInfo = (id: string, data: MemoryDTO): Promise<any> => {
  const config = {headers: {"content-type": "multipart/form-data"}};
  return axios.post(`${adminApi.updateMemoryData}/${id}`, data,config);
};


/**
 * memory List
 * @date 18/04/2023 - 16:50:33
 *
 * @async
 * @param {*} params
 * @returns {unknown}
 */
export const gettrusteeListApiCall = async (params:any) => {
  return await axios.get(`${adminApi.trusteeList}/${params.userid}`, { params });
};

//Get a user data by id
export const gettrusteeDetailsById = async (params:any) => {
  return await axios.get(
    `${adminApi.gettrusteeDetailsById}`,{params}
  );
};

/**
 * Updating Truestee
 *
 * @param {string} id
 * @param {TruesteeDTO} data
 * @returns {Promise<any>}
 */
export const updateTruesteeInfo = (id: string, data: TruesteeDTO): Promise<any> => {
  return axios.post(`${adminApi.updateTrusteeData}/${id}`, data);
};

export const deleteTruestee = async (params:any) => {
  return await axios.get(
    `${adminApi.deleteTrusteeData}`,{params}
  );
};
export const recipientListData = async (id: string) => {
  return await axios.get(`${adminApi.recipientListData}/${id}`);
};

//Get a user data by id
export const getRulesDetailsByUserId = async (params:any) => {
  return await axios.get(
    `${adminApi.getRuleDetails}`,{params}
  );
};

/**
 * Updating Truestee
 *
 * @param {string} id
 * @param {TruesteeDTO} data
 * @returns {Promise<any>}
 */
export const updateFrequencyRule = (id: string, data: FrequencyRuleDTO): Promise<any> => {
  return axios.put(`${adminApi.updateFrequencyRule}/${id}`, data);
};
export const updateExecuteLegacyRule = (id: string, data: ExecuteLegacyDTO): Promise<any> => {
  return axios.put(`${adminApi.updateExecuteLegacyRule}/${id}`, data);
};
export const updateSleepRule = (id: string, data: ExecuteLegacyDTO): Promise<any> => {
  return axios.put(`${adminApi.updateSleepRule}/${id}`, data);
};
export const clearSleepRule = (id: string, data: ExecuteLegacyDTO): Promise<any> => {
  return axios.put(`${adminApi.clearSleepRule}/${id}`, data);
};
export const clearDndRule = (id: string, data: ExecuteLegacyDTO): Promise<any> => {
  return axios.put(`${adminApi.clearDndRule}/${id}`, data);
};
export const updateDndRule = (id: string, data: ExecuteLegacyDTO): Promise<any> => {
  return axios.put(`${adminApi.updateDndRule}/${id}`, data);
};
export const releaselegacySubmit = (id: string, data: releaselegacyDTO): Promise<any> => {
  return axios.patch(`${adminApi.releaselegacySubmit}/${id}`, data);
};
export const ruleSettingData = async () => {
  return await axios.get(`${adminApi.ruleSetting}`);
};
export const dashboardData = async () => {
  return await axios.get(`${adminApi.dashboard}`);
};
