import { FC } from 'react';
import Form from 'react-bootstrap/Form';

interface ICustomTextArea {
    controlId?: string;
    label?: string;
    placeholder?: string;
    handleChange?: any;
    handleBlur?: any;
    errorsField?: any;
    touched?: any;
    value?: any;
}

/**
 * Textarea Reusable Component
 * @date 4/20/2023 - 2:50:05 PM
 *
 * @param {{ controlId: any; label: any; placeholder: any; handleChange: any; handleBlur: any; errorsField: any; touched: any; value: any; }} {
    controlId, label, placeholder, handleChange, handleBlur, errorsField, touched, value
}
 * @returns {*}
 */

const CustomTextArea: FC<ICustomTextArea> = ({
    controlId, label, placeholder, handleChange, handleBlur, errorsField, touched, value
}) => {
    return (
        <Form.Group className="mb-3" controlId={controlId}>
            {label ? (
                <Form.Label className="lh-sm">{label}</Form.Label>
            ) : null}
            <div className="position-relative">
                <Form.Control as="textarea" rows={4} value={value} placeholder={placeholder} onBlur={handleBlur} onChange={handleChange} isInvalid={!!errorsField && touched} />
                <Form.Control.Feedback type="invalid">
                    {errorsField}
                </Form.Control.Feedback>
            </div>
        </Form.Group>
    );
}

export default CustomTextArea