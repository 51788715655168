export default {
  rowPerPage: 10,
  MaxLength:25,
  hoursData : [
    { id: '1', name: '1 Hours' },
    { id: '2', name: '2 Hours' },
    { id: '3', name: '3 Hours' },
    { id: '4', name: '4 Hours' },
    { id: '5', name: '5 Hours' },
    { id: '6', name: '6 Hours' },
    { id: '7', name: '7 Hours' },
    { id: '8', name: '8 Hours' },
    { id: '9', name: '9 Hours' },
    { id: '10', name: '10 Hours' },
    { id: '11', name: '11 Hours' },
    { id: '12', name: '12 Hours' },
    { id: '13', name: '13 Hours' },
    { id: '14', name: '14 Hours' }
  ],
  dailyData : [
    { id: '1', name: '1 Day' },
    { id: '2', name: '2 Day' },
    { id: '3', name: '3 Day' },
    { id: '4', name: '4 Day' },
    { id: '5', name: '5 Day' },
    { id: '6', name: '6 Day' },
    { id: '7', name: '7 Day' },
    { id: '8', name: '8 Day' },
    { id: '9', name: '9 Day' },
    { id: '10', name: '10 Day' },
    { id: '11', name: '11 Day' },
    { id: '12', name: '12 Day' },
    { id: '13', name: '13 Day' },
    { id: '14', name: '14 Day' }
  ],
  weekData : [
    { id: '1', name: '1 Week' },
    { id: '2', name: '2 Week' },
    { id: '3', name: '3 Week' },
    { id: '4', name: '4 Week' },
    { id: '5', name: '5 Week' }
  ],
  MonthData : [
    { id: '1', name: '1 Month' },
    { id: '2', name: '2 Month' },
    { id: '3', name: '3 Month' },
    { id: '4', name: '4 Month' },
    { id: '5', name: '5 Month' }
  ],
  ProclamationData : [
    { id: '1', name: '1' },
    { id: '2', name: '2' },
    { id: '3', name: '3' },
    { id: '4', name: '4' },
    { id: '5', name: '5' },
    { id: '6', name: '6' },
    { id: '7', name: '7' },
    { id: '8', name: '8' },
    { id: '9', name: '9' },
    { id: '10', name: '10' },
    { id: '11', name: '11' },
    { id: '12', name: '12' },
    { id: '13', name: '13' },
    { id: '14', name: '14' },
    { id: '15', name: '15' },
    { id: '16', name: '16' },
    { id: '17', name: '17' },
    { id: '18', name: '18' },
    { id: '19', name: '19' },
    { id: '20', name: '20' },
  ],
  CountryCode: [
    {
      "id": "+1",
      "name": "+1 US"
    }
  ],
  Country: [
    {"id": "Afghanistan", "name": "Afghanistan"},
    {"id": "Albania", "name": "Albania"},
    {"id": "Algeria", "name": "Algeria"},
    {"id": "Andorra", "name": "Andorra"},
    {"id": "Angola", "name": "Angola"},
    {"id": "Antigua and Barbuda", "name": "Antigua and Barbuda"},
    {"id": "Argentina", "name": "Argentina"},
    {"id": "Armenia", "name": "Armenia"},
    {"id": "Australia", "name": "Australia"},
    {"id": "Austria", "name": "Austria"},
    {"id": "Azerbaijan", "name": "Azerbaijan"},
    {"id": "Bahamas", "name": "Bahamas"},
    {"id": "Bahrain", "name": "Bahrain"},
    {"id": "Bangladesh", "name": "Bangladesh"},
    {"id": "Barbados", "name": "Barbados"},
    {"id": "Belarus", "name": "Belarus"},
    {"id": "Belgium", "name": "Belgium"},
    {"id": "Belize", "name": "Belize"},
    {"id": "Benin", "name": "Benin"},
    {"id": "Bhutan", "name": "Bhutan"},
    {"id": "Bolivia", "name": "Bolivia"},
    {"id": "Bosnia and Herzegovina", "name": "Bosnia and Herzegovina"},
    {"id": "Botswana", "name": "Botswana"},
    {"id": "Brazil", "name": "Brazil"},
    {"id": "Brunei", "name": "Brunei"},
    {"id": "Bulgaria", "name": "Bulgaria"},
    {"id": "Burkina Faso", "name": "Burkina Faso"},
    {"id": "Burundi", "name": "Burundi"},
    {"id": "Cabo Verde", "name": "Cabo Verde"},
    {"id": "Cambodia", "name": "Cambodia"},
    {"id": "Cameroon", "name": "Cameroon"},
    {"id": "Canada", "name": "Canada"},
    {"id": "Central African Republic", "name": "Central African Republic"},
    {"id": "Chad", "name": "Chad"},
    {"id": "Chile", "name": "Chile"},
    {"id": "China", "name": "China"},
    {"id": "Colombia", "name": "Colombia"},
    {"id": "Comoros", "name": "Comoros"},
    {"id": "Congo (Congo-Brazzaville)", "name": "Congo (Congo-Brazzaville)"},
    {"id": "Costa Rica", "name": "Costa Rica"},
    {"id": "Croatia", "name": "Croatia"},
    {"id": "Cuba", "name": "Cuba"},
    {"id": "Cyprus", "name": "Cyprus"},
    {"id": "Czechia (Czech Republic)", "name": "Czechia (Czech Republic)"},
    {"id": "Democratic Republic of the Congo", "name": "Democratic Republic of the Congo"},
    {"id": "Denmark", "name": "Denmark"},
    {"id": "Djibouti", "name": "Djibouti"},
    {"id": "Dominica", "name": "Dominica"},
    {"id": "Dominican Republic", "name": "Dominican Republic"},
    {"id": "Ecuador", "name": "Ecuador"},
    {"id": "Egypt", "name": "Egypt"},
    {"id": "El Salvador", "name": "El Salvador"},
    {"id": "Equatorial Guinea", "name": "Equatorial Guinea"},
    {"id": "Eritrea", "name": "Eritrea"},
    {"id": "Estonia", "name": "Estonia"},
    {"id": "Eswatini (fmr. Swaziland)", "name": "Eswatini (fmr. Swaziland)"},
    {"id": "Ethiopia", "name": "Ethiopia"},
    {"id": "Fiji", "name": "Fiji"},
    {"id": "Finland", "name": "Finland"},
    {"id": "France", "name": "France"},
    {"id": "Gabon", "name": "Gabon"},
    {"id": "Gambia", "name": "Gambia"},
    {"id": "Georgia", "name": "Georgia"},
    {"id": "Germany", "name": "Germany"},
    {"id": "Ghana", "name": "Ghana"},
    {"id": "Greece", "name": "Greece"},
    {"id": "Grenada", "name": "Grenada"},
    {"id": "Guatemala", "name": "Guatemala"},
    {"id": "Guinea", "name": "Guinea"},
    {"id": "Guinea-Bissau", "name": "Guinea-Bissau"},
    {"id": "Guyana", "name": "Guyana"},
    {"id": "Haiti", "name": "Haiti"},
    {"id": "Holy See", "name": "Holy See"},
    {"id": "Honduras", "name": "Honduras"},
    {"id": "Hungary", "name": "Hungary"},
    {"id": "Iceland", "name": "Iceland"},
    {"id": "India", "name": "India"},
    {"id": "Indonesia", "name": "Indonesia"},
    {"id": "Iran", "name": "Iran"},
    {"id": "Iraq", "name": "Iraq"},
    {"id": "Ireland", "name": "Ireland"},
    {"id": "Israel", "name": "Israel"},
    {"id": "Italy", "name": "Italy"},
    {"id": "Jamaica", "name": "Jamaica"},
    {"id": "Japan", "name": "Japan"},
    {"id": "Jordan", "name": "Jordan"},
    {"id": "Kazakhstan", "name": "Kazakhstan"},
    {"id": "Kenya", "name": "Kenya"},
    {"id": "Kiribati", "name": "Kiribati"},
    {"id": "Kuwait", "name": "Kuwait"},
    {"id": "Kyrgyzstan", "name": "Kyrgyzstan"},
    {"id": "Laos", "name": "Laos"},
    {"id": "Latvia", "name": "Latvia"},
    {"id": "Lebanon", "name": "Lebanon"},
    {"id": "Lesotho", "name": "Lesotho"},
    {"id": "Liberia", "name": "Liberia"},
    {"id": "Libya", "name": "Libya"},
    {"id": "Liechtenstein", "name": "Liechtenstein"},
    {"id": "Lithuania", "name": "Lithuania"},
    {"id": "Luxembourg", "name": "Luxembourg"},
    {"id": "Madagascar", "name": "Madagascar"},
    {"id": "Malawi", "name": "Malawi"},
    {"id": "Malaysia", "name": "Malaysia"},
    {"id": "Maldives", "name": "Maldives"},
    {"id": "Mali", "name": "Mali"},
    {"id": "Malta", "name": "Malta"},
    {"id": "Marshall Islands", "name": "Marshall Islands"},
    {"id": "Mauritania", "name": "Mauritania"},
    {"id": "Mauritius", "name": "Mauritius"},
    {"id": "Mexico", "name": "Mexico"},
    {"id": "Micronesia", "name": "Micronesia"},
    {"id": "Moldova", "name": "Moldova"},
    {"id": "Monaco", "name": "Monaco"},
    {"id": "Mongolia", "name": "Mongolia"},
    {"id": "Montenegro", "name": "Montenegro"},
    {"id": "Morocco", "name": "Morocco"},
    {"id": "Mozambique", "name": "Mozambique"},
    {"id": "Myanmar (formerly Burma)", "name": "Myanmar (formerly Burma)"},
    {"id": "Namibia", "name": "Namibia"},
    {"id": "Nauru", "name": "Nauru"},
    {"id": "Nepal", "name": "Nepal"},
    {"id": "Netherlands", "name": "Netherlands"},
    {"id": "New Zealand", "name": "New Zealand"},
    {"id": "Nicaragua", "name": "Nicaragua"},
    {"id": "Niger", "name": "Niger"},
    {"id": "Nigeria", "name": "Nigeria"},
    {"id": "North Korea", "name": "North Korea"},
    {"id": "North Macedonia", "name": "North Macedonia"},
    {"id": "Norway", "name": "Norway"},
    {"id": "Oman", "name": "Oman"},
    {"id": "Pakistan", "name": "Pakistan"},
    {"id": "Palau", "name": "Palau"},
    {"id": "Palestine State", "name": "Palestine State"},
    {"id": "Panama", "name": "Panama"},
    {"id": "Papua New Guinea", "name": "Papua New Guinea"},
    {"id": "Paraguay", "name": "Paraguay"},
    {"id": "Peru", "name": "Peru"},
    {"id": "Philippines", "name": "Philippines"},
    {"id": "Poland", "name": "Poland"},
    {"id": "Portugal", "name": "Portugal"},
    {"id": "Qatar", "name": "Qatar"},
    {"id": "Romania", "name": "Romania"},
    {"id": "Russia", "name": "Russia"},
    {"id": "Rwanda", "name": "Rwanda"},
    {"id": "Saint Kitts and Nevis", "name": "Saint Kitts and Nevis"},
    {"id": "Saint Lucia", "name": "Saint Lucia"},
    {"id": "Saint Vincent and the Grenadines", "name": "Saint Vincent and the Grenadines"},
    {"id": "Samoa", "name": "Samoa"},
    {"id": "San Marino", "name": "San Marino"},
    {"id": "Sao Tome and Principe", "name": "Sao Tome and Principe"},
    {"id": "Saudi Arabia", "name": "Saudi Arabia"},
    {"id": "Senegal", "name": "Senegal"},
    {"id": "Serbia", "name": "Serbia"},
    {"id": "Seychelles", "name": "Seychelles"},
    {"id": "Sierra Leone", "name": "Sierra Leone"},
    {"id": "Singapore", "name": "Singapore"},
    {"id": "Slovakia", "name": "Slovakia"},
    {"id": "Slovenia", "name": "Slovenia"},
    {"id": "Solomon Islands", "name": "Solomon Islands"},
    {"id": "Somalia", "name": "Somalia"},
    {"id": "South Africa", "name": "South Africa"},
    {"id": "South Korea", "name": "South Korea"},
    {"id": "South Sudan", "name": "South Sudan"},
    {"id": "Spain", "name": "Spain"},
    {"id": "Sri Lanka", "name": "Sri Lanka"},
    {"id": "Sudan", "name": "Sudan"},
    {"id": "Suriname", "name": "Suriname"},
    {"id": "Sweden", "name": "Sweden"},
    {"id": "Switzerland", "name": "Switzerland"},
    {"id": "Syria", "name": "Syria"},
    {"id": "Tajikistan", "name": "Tajikistan"},
    {"id": "Tanzania", "name": "Tanzania"},
    {"id": "Thailand", "name": "Thailand"},
    {"id": "Timor-Leste", "name": "Timor-Leste"},
    {"id": "Togo", "name": "Togo"},
    {"id": "Tonga", "name": "Tonga"},
    {"id": "Trinidad and Tobago", "name": "Trinidad and Tobago"},
    {"id": "Tunisia", "name": "Tunisia"},
    {"id": "Turkey", "name": "Turkey"},
    {"id": "Turkmenistan", "name": "Turkmenistan"},
    {"id": "Tuvalu", "name": "Tuvalu"},
    {"id": "Uganda", "name": "Uganda"},
    {"id": "Ukraine", "name": "Ukraine"},
    {"id": "United Arab Emirates", "name": "United Arab Emirates"},
    {"id": "United Kingdom", "name": "United Kingdom"},
    {"id": "United States of America", "name": "United States of America"},
    {"id": "Uruguay", "name": "Uruguay"},
    {"id": "Uzbekistan", "name": "Uzbekistan"},
    {"id": "Vanuatu", "name": "Vanuatu"},
    {"id": "Venezuela", "name": "Venezuela"},
    {"id": "Vietnam", "name": "Vietnam"},
    {"id": "Yemen", "name": "Yemen"},
    {"id": "Zambia", "name": "Zambia"},
    {"id": "Zimbabwe", "name": "Zimbabwe"}
  ]
};