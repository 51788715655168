import { FC } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

interface ICustomSwitchHorizontal {
    label?: string;
    value?: any;
    onClicked?: any;
    name?: string;
    changed?: any;
    setColunView?: boolean;
    leftColSmall?: boolean;
    setPublished?: any;
}

/**
 * Custom Switch Horizontal Reusable Component
 * @date 4/20/2023 - 10:34:20 AM
 *
 * @param {{ label: any; value: any; name: any; handleChange: any; setColunView: any; leftColSmall: any; }} {
    label, value, name, onClick, setColunView, leftColSmall
}
 * @returns {*}
 */

const CustomSwitchHorizontal: FC<ICustomSwitchHorizontal> = ({
    label, value, onClicked, name, changed, setColunView, leftColSmall,setPublished
}) => {
    
    return (
        <Form.Group className="theme-form-horizontal pb-3">
            <Row>
                <Col sm="auto" className={`left-col ${leftColSmall ? 'left-col-sm' : ''}`}>
                    {label ? (
                        <Stack direction="horizontal" gap={2} className="justify-content-sm-between py-sm-1">
                            <Form.Label className="lh-sm">{label}</Form.Label>
                            {setColunView ? <span className="colun-content">:</span> : null}
                        </Stack>
                    ) : null}
                </Col>
                <Col sm>
                    <div className="position-relative">
                        <Form.Check
                            type="switch"
                            id={`switch-${name}`}
                            label='' //this is required for custom css style
                            value={value}
                            onChange={onClicked}
                            name={name}
                            className="theme-switch"
                            checked={value}
                        />
                    </div>
                </Col>
            </Row>
        </Form.Group>
    );
}

export default CustomSwitchHorizontal