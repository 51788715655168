import moment from 'moment';
import { FC } from 'react';
import Form from 'react-bootstrap/Form';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
interface IDateModal {
    placeholder?: any;
    label?:any;
    selected?:any;
    onBlur?:any;
    error?:any;
    touched?:any;
    handleChange?:any;
    name?:String;
    setFieldValue?:any;
    value?:any;
    errorsField?:any;
    controlId?:any;
}
const FormTimePicker: FC<IDateModal> = ({
    placeholder,label,handleChange,name,setFieldValue,value,errorsField,controlId
}) => {
    const handleDateChangeRaw = (e:any) => {
        e.preventDefault();
    }
    return (
        <Form.Group className="mb-3" controlId={controlId}>
        {label ? (
            <Form.Label className="lh-sm">{label}</Form.Label>
        ) : null}
        <div className="position-relative">
        <DatePicker
                            onChange={(event:any)=>
                            {
                               let time = moment(event, "HH:mm:ss").format("HH:mm:ss");
                                setFieldValue(name,time)
                            }}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={30}
                            timeCaption="Time"
                            dateFormat={placeholder}
                            className="form-control"
                            placeholderText="hh:mm A"
                            value={value ? moment(value, "HH:mm:ss").format("hh:mm A"):''}
                            onChangeRaw={handleDateChangeRaw}
                            
                          />
                <Form.Control.Feedback type="invalid" className={errorsField?"d-block":''}>
                    {errorsField}
                </Form.Control.Feedback>
          
        </div>
    </Form.Group>
    );
}


export default FormTimePicker;
