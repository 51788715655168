import React, { useEffect, useState } from 'react';

import { Button, Card, Col, Form, Image, Row, Stack } from "react-bootstrap"
import { IoIosArrowBack } from "react-icons/io"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Formik } from "formik"
import { toast } from 'react-toastify';
import InputTextHorizontal from '../../../components/common/InputTextHorizontal';
import TextAreaHorizontal from '../../../components/common/TextAreaHorizontal';
import Loader from '../../../components/common/Loader';
import { CreateSchema } from '../../../validations/Faq';
import { nameBasedProtectedRoutes } from '../../../router/protected';
import CustomRadioHorizontal from '../../../components/common/CustomRadioHorizontal';
import { AddEntitiyMethod, EditEntitiyMethod } from '../../../helpers/helpersMethods';
import { addFaq, editFaq, getDetails } from '../../../services/faqService';
import { FAQ_UPDATED_SUCCESS, USER_UPDATED_SUCCESS } from '../../../validations/ValidationErrors';

export const EditFAQ = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [redirect, setRedirect] = useState<any>();
  const [data, setData] = useState<any>();
  const [submitting, setSubmitting] = useState(false);

  const radioData = [
    { label: 'Enable', value: 'active' },
    { label: 'Disable', value: 'inactive' },
  ];
  useEffect(() => {
    setLoading(false);
    setRedirect(nameBasedProtectedRoutes.faq.path);
    if (id) {
      getDetails(id).then(
        (resp:any) => {
          const res = resp?.data;
          setData(res);
          setTimeout(() => {
            setLoading(false);

          }, 1000);
        },
        (error: any) => {
          const err = error?.response?.data;
          setLoading(false);
          if (err?.statusCode === 400) {
            toast.error(err.msg);
            navigate(nameBasedProtectedRoutes.faq.path);
          }

        }
      );
    }

  }, [id]);

  /**
     * Editing Employees
     *
     * @param {*} value
     */
  const submitForm = (value: any) => {
    if (id) {
      toast.dismiss();
      EditEntitiyMethod(setSubmitting, editFaq, id, value, FAQ_UPDATED_SUCCESS,nameBasedProtectedRoutes.faq.path , navigate);
    }
  }
  return (
    <>
      {!loading && data ? <Formik
        validationSchema={CreateSchema}
        initialValues={{
          question: data?data.question:'',
          answer: data?data.answer:'',
          status: data?data.status:'',
          order_sequence: data?data.order_sequence:'',
        }}
        onSubmit={(values, actions) => {
          values.question = values.question.trim();
          values.answer = values.answer.trim();
          actions.setSubmitting(false);
          submitForm(values)
        }}

      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <Form className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1" noValidate onSubmit={handleSubmit}>
            <div className="pageHeader px-3 my-1 pt-1 user-select-none">
              <Row className="align-items-center min-h-51">
                <Col>
                  <h1 className="fw-semibold h4 my-2">
                    <Link to={redirect} className="align-items-center d-inline-flex overflow-hidden text-dark text-decoration-none">
                      <span className="me-1 ms-n1">
                        <IoIosArrowBack size="1.68rem" />
                      </span> FAQ
                    </Link>
                  </h1>
                </Col>
              </Row>
            </div>
            <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
              <Card className="h-100 w-100 overflow-auto border-light-subtle pageContentInner">
                <Card.Header className="bg-white fs-16 fw-semibold p-3 border-secondary">
                  <Card.Text className="px-xl-1">Add FAQ</Card.Text>
                </Card.Header>
                <Card.Body className="p-xl-4">

                  <InputTextHorizontal
                    leftColSmall={true}
                    label="Question"
                    controlId="question"
                    placeholder="Question"
                    touched={touched.question}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.question}
                    value={values.question}
                  />
                  <TextAreaHorizontal
                    leftColSmall={true}
                    controlId="answer"
                    label="Answer"
                    placeholder="Answer"
                    touched={touched.answer}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.answer}
                    value={values.answer}
                  />
                  <InputTextHorizontal
                    leftColSmall={true}
                    label="Order"
                    controlId="order_sequence"
                    placeholder="Order"
                    touched={touched.order_sequence}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.order_sequence}
                    value={values.order_sequence}
                    type="number"
                  />
                  <CustomRadioHorizontal
                    leftColSmall={true}
                    inline={true}
                    label="Status"
                    name="status"
                    touched={touched.status}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.status}
                    value={values.status}
                    data={radioData}
                  />


                  <div className="theme-form-horizontal py-3">
                    <Row>
                      <Col sm="auto" className="left-col left-col-sm"></Col>
                      <Col sm="auto" className="right-col">
                        <Stack direction="horizontal" gap={3}>
                          <Button className="fw-semibold fs-13 text-white mw-90 my-1" variant="primary" type="submit">Update</Button>
                          <Link to={redirect} className="btn btn-secondary text-dark fw-semibold fs-13 mw-90 my-1">Cancel</Link>
                        </Stack>
                      </Col>
                    </Row>
                  </div>

                </Card.Body>
              </Card>
            </div>
          </Form>
        )}
      </Formik > : ''}
      <Loader isLoading={loading || submitting} />
    </>

  )
}
