import { Button, Card, Col, Form, Row, Stack } from "react-bootstrap"
import { IoIosArrowBack } from "react-icons/io"
import { Link, Navigate, useNavigate, useParams } from "react-router-dom"
import { Formik } from "formik"
import { OwnFrequencySchema } from "../../../../../../validations/Users"
import CustomRadio from "../../../../../../components/common/CustomRadio"
import CustomSelect from "../../../../../../components/common/CustomSelect"
import constantValues from "../../../../../../constants/constantValues"
import { useEffect, useState } from "react"
import { getRulesDetailsByUserId, ruleSettingData, updateFrequencyRule } from "../../../../../../services/userService"
import { toast } from "react-toastify"
import Loader from "../../../../../../components/common/Loader"
import { EditEntitiyMethod } from "../../../../../../helpers/helpersMethods"
import { USER_UPDATED_SUCCESS } from "../../../../../../validations/ValidationErrors"
export const EditOwnFrequency = () => {
  
  const navigate = useNavigate();
  const [durationData, setDurationData] = useState<any>([]);
  const [selectRadio, setSelectRadio] = useState('daily');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();
  const [submitting, setSubmitting] = useState(false);
  const [hourlyOptions, setHourlyOptions] = useState<any>([]);
  const [dailyOptions, setDailyOptions] = useState<any>([]);
  const [weeklyOptions, setWeeklyOptions] = useState<any>([]);
  const [monthlyOptions, setMonthlyOptions] = useState<any>([]);
  const [redirect, setRedirect] = useState<any>();
  const { id } = useParams();
  //const durationData = constantValues.durationData;
  const radioData = [
    { label: 'Hourly', value: 'hourly' },
    { label: 'Daily', value: 'daily' },
    { label: 'Weekly', value: 'weekly' },
    { label: 'Monthly', value: 'monthly' },
  ];
  useEffect(() => {
    setLoading(true);
    if(id)
    {
      getRulesDetailsByUserId({'user_id':id}).then(
        (resp) => {
          setTimeout(() => {
            setLoading(false);
        }, 1000);
          const res = resp?.data;
          setSelectRadio(res.frequency_type);
          selectSetting();
          setRedirect(`/app/users/view/${res.user_id}`);
          setData(res);
        },
        (error:any) => {
          setLoading(false);
          const err = error?.response?.data;
          if (err?.statusCode === 400) {
            toast.error(err.msg);
            navigate(`/app/users`);
          }
        }
      );
  }
  }, [id]);

  useEffect(()=>{
    if(selectRadio == 'daily')
    {
      setDurationData(dailyOptions);
    }else if(selectRadio =='hourly')
    {
      setDurationData(hourlyOptions);
    }else if(selectRadio =='monthly')
    {
      setDurationData(monthlyOptions);
    }else
    {
      setDurationData(weeklyOptions);
    }
  },[selectRadio,hourlyOptions]);
  const selectSetting = async () => {
    if (id) {
      try {
        const resp = await ruleSettingData();
        const res = resp?.data.record;
  
        if (res) {
          setLoading(false);
          let hourly:any = [];
          let daily:any = [];
          let weekly:any = [];
          let month:any = [];
          // Hourly options
          hourly = await createOption(res.Hourly.min, res.Hourly.max, ' Hour');
          setHourlyOptions(hourly);
  
          // Daily options
          daily = await createOption(res.Daily.min, res.Daily.max, ' Day');
          setDailyOptions(daily);

          //weekly Option
          weekly = await createOption(res.Weekly.min, res.Weekly.max, ' Week');
          setWeeklyOptions(weekly);
          
          //monthly Option
          month = await createOption(res.Monthly.min, res.Monthly.max, ' Month');
          setMonthlyOptions(month);
        }
  
      } catch (error) {
        setLoading(false);
      }
    }
  };
  const createOption = async (min:number,max:number,text:string)=>
  {
    let options = [];
    for (let i = min; i <= max; i++) {
      const option = {
        id: i.toString(),
        name: i + text
      };
      options.push(option);
    }
    return options;
  }

  const submitForm = (value: any) => {
    if (id && data) {
        EditEntitiyMethod(setSubmitting, updateFrequencyRule, id, value, USER_UPDATED_SUCCESS, `/app/users/view/${id}`, navigate);
    }
  }
  return (
    <>
      {!loading && data && selectRadio && hourlyOptions ? <Formik
        validationSchema={OwnFrequencySchema}
        initialValues={{
          frequency: data ? data.frequency : '',
          frequency_type: data ? data.frequency_type: '',
      }}
      onSubmit={(values, actions) =>{
        actions.setSubmitting(false);
        submitForm(values)
   }}

      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          setFieldValue,
          touched,
          isValid,
          errors,
        }) => (
          
          <Form className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1" noValidate onSubmit={handleSubmit}>
            <div className="pageHeader px-3 my-1 pt-1 user-select-none">
              <Row className="align-items-center min-h-51">
                <Col>
                  <h1 className="fw-semibold h4 my-2">
                    <Link to={redirect} className="align-items-center d-inline-flex overflow-hidden text-dark text-decoration-none">
                      <span className="me-1 ms-n1">
                        <IoIosArrowBack size="1.68rem" />
                      </span> Users
                    </Link>
                  </h1>
                </Col>
              </Row>
            </div>
            <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
              <Card className="h-100 w-100 overflow-auto border-light-subtle pageContentInner">
                <Card.Header className="bg-white fs-16 fw-semibold p-3 border-secondary">
                  <Card.Text className="px-xl-1">Edit Rules to Execute Legacy</Card.Text>
                </Card.Header>
                <Card.Body className="p-xl-4">

                  <h6 className="fs-16 fw-bold mb-3 pb-1">Frequency to check on you</h6>
                  <CustomRadio
                    inline={true}
                    label="Select Frequency"
                    name="frequency_type"
                    touched={touched.frequency_type}
                    handleBlur={handleBlur}
                    handleChange={(e:any)=>{
                      handleChange(e);
                      setSelectRadio(e.target.value);
                      if(data.frequency_type != e.target.value)
                      {
                        setFieldValue('frequency', '');
                      }else
                      {
                        setFieldValue('frequency', data.frequency);
                      }
                    }}
                    errorsField={errors.frequency_type}
                    value={values.frequency_type}
                    data={radioData}
                  />

                  <div className="mw-300">
                   
                    <CustomSelect
                      label="Select Duration"
                      controlId="frequency"
                      placeholder="Select"
                      touched={touched.frequency}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errorsField={errors.frequency}
                      value={values.frequency}
                      data={ durationData}
                    />
                  </div>
                  <Stack direction="horizontal" gap={3} className="pt-2">
                    <Button className="fw-semibold fs-13 text-white mw-90 my-1" variant="primary" type="submit">Update</Button>
                    <Link to={redirect} className="btn btn-secondary text-dark fw-semibold fs-13 mw-90 my-1">Cancel</Link>
                  </Stack>


                </Card.Body>
              </Card>
            </div>
          </Form>
        )}
      </Formik >:''}
      <Loader isLoading={loading} />
    </>

  )
}
