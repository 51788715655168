import {axios} from "../services/axios";
import { adminApi } from "../api";
export type charityDTO = {
charity_title: string
};



/**
 * charity List
 * @date 04/05/2023 - 13:21:19
 *
 * @async
 * @param {*} params
 * @returns {unknown}
 */
export const getList = async (params:any) => {
    return await axios.get(`${adminApi.charityList}`, { params });
  };

/**
 * Category List 
 * @date 19/05/2023 - 13:50:03
 *
 * @async
 * @param {*} params
 * @returns {unknown}
 */
export const getCategoryList = async () => {
    return await axios.get(`${adminApi.charityCategory}`);
  };

  /**
   * Add charity
   * @date 04/05/2023 - 16:19:09
   *
   * @async
   * @param {charityDTO} data
   * @returns {unknown}
   */
  export const addCharit = async (data: charityDTO) => {
    const config = {headers: {"content-type": "multipart/form-data"}};
    return axios.post(`${adminApi.charityAdd}`, data,config);
  };

  /**
   * charity Edit
   * @date 04/05/2023 - 17:54:51
   *
   * @async
   * @param {string} id
   * @returns {Promise<any>}
   */
  export const getDetails = async (id: string): Promise<any> => {
    return axios.get(`${adminApi.charityDetails}/${id}`);
  };

  /**
   * charity Edit
   * @date 04/05/2023 - 17:54:51
   *
   * @async
   * @param {string} id
   * @returns {Promise<any>}
   */
  export const edit = async (id: string,data: charityDTO): Promise<any> => {
    const config = {headers: {"content-type": "multipart/form-data"}};
    return axios.post(`${adminApi.charityEdit}/${id}`,data,config);
  };



 /**
  * User Charity List
  * @date 25/05/2023 - 17:51:57
  *
  * @async
  * @param {*} params
  * @returns {unknown}
  */
  export const getUserCharityList = async (params:any) => {
    return await axios.get(`${adminApi.userCharityList}/${params.userid}`, { params });
  };
  