import { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Modal, Row, Stack, ToggleButton } from "react-bootstrap"
import Loader from '../../components/common/Loader';
import { editChange, editPlan, getList } from '../../services/planService';
import constantValues from "../../constants/constantValues";
import { EditEntitiyMethod } from '../../helpers/helpersMethods';
import { CMS_UPDATED_SUCCESS, NETWORK_ERROR } from '../../validations/ValidationErrors';
import { nameBasedProtectedRoutes } from '../../router/protected';
import { useNavigate } from 'react-router-dom';
import DeleteModal from '../../components/common/DeleteModal';
import { toast } from 'react-toastify';

export const Settings = () => {
  interface Plan {
    allowed_proclamation: string;
    video_length: string;
    allowed_recipient: string;
    // other properties of a plan
  }
  const [checked, setChecked] = useState(false);
  const [data, setData] = useState<Plan[]>([]);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [deletestatus, setDeletestatus] = useState(false);
  const [accountstatus, setAccountstatus] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  useEffect(()=>
  {
    getList().then(
      (response) => 
      {
        setLoading(false);
        setData(response?.data);
        const allActive = response?.data.every((item:any) => item.status === 'active');
        if(allActive)
        {
          setChecked(false);
        }else
        {
          setChecked(true);
        }

       // 
      }
    );
  },[]);
  const handleVideoLengthChange = (selectedValue:string,id:string, rowIndex:number,type:string) => {
    // Update the selected value in the data array based on the rowIndex
    const updatedData = [...data];
    let updateValues:any = [];
    if(type == 'proclamation')
    {
      updatedData[rowIndex].allowed_proclamation = selectedValue;
      updateValues = {allowed_proclamation:selectedValue};
    }
    if(type == 'video')
    {
      updatedData[rowIndex].video_length = selectedValue;
      updateValues = {video_length:selectedValue};
    }
    if(type == 'recipient')
    {
      updatedData[rowIndex].allowed_recipient = selectedValue;
      updateValues = {allowed_recipient:selectedValue};
    }
    setData(updatedData);
    if(updateValues)
    {
      EditEntitiyMethod(setLoading, editPlan, id, updateValues, CMS_UPDATED_SUCCESS,nameBasedProtectedRoutes.settings.path , navigate);
    }

  };
  useEffect(() => {
    const deleteData = async () => {
      try {
        if (deletestatus)
        {
          setLoading(true);
          let status = checked ? 'active':'inactive';
          await editChange({'status':status}).then(
            (resp) => {
              setLoading(false);
              setDeletestatus(false);
              setShow(false);
              setChecked(!checked);
              toast.success(resp.msg);
            },
            (error:any) => {
              setLoading(false);
              const err = error?.response?.data;
              if (err?.statusCode === 400) {
                toast.error(err.msg);
              }
            }
          );

        }
      } catch (error) {
        toast.error(NETWORK_ERROR);
      }
    };
    deleteData();
  }, [deletestatus]);
  return (
    <>
    {data  ?
    <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
      <div className="pageHeader px-3 my-1 pt-1 user-select-none">
        <Row className="align-items-center min-h-51">
          <Col>
            <h1 className="fw-semibold h4 my-2">Settings</h1>
          </Col>
        </Row>
      </div>
      <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
        <Card className="h-100 p-3 p-xl-4 w-100 border-light-subtle overflow-auto pageContentInner">
          <Row>
            <Col md lg="auto">
              <div className="setting-cover-inner">
                <h6 className="fs-16 fw-bold mb-4 pt-1">Public Proclamation</h6>
                {data.map((plan:any, rowIndex:any)=>(      
                <Form.Group key={plan.id} className="mb-4 pb-1">
                  <div className="fw-bold text-uppercase theme-ls-lg border-bottom border-secondary pb-2">{plan ? plan.plan_name:''}</div>
                  <div className="border-bottom border-secondary">
                    <Row className="align-items-center">
                      <Col sm className="text-opacity-50 text-dark mt-2 mt-sm-0">Max Limit for Video</Col>
                      <Col sm="auto">
                        <Form.Select className="border-white fw-bold rounded-0 shadow-none text-sm-end ps-0 theme-select-end" value={plan ? plan.video_length:'30'} onChange={(event) => handleVideoLengthChange(event.target.value,plan.id,rowIndex,'video')}>
                          <option value='30'>30 Seconds</option>
                          <option value='60'>60 Seconds</option>
                        </Form.Select>
                      </Col>
                    </Row>
                  </div>
                  <div className="border-bottom border-secondary">
                    <Row className="align-items-center">
                      <Col sm className="text-opacity-50 text-dark mt-2 mt-sm-0">Max limit to add</Col>
                      <Col sm="auto">
                        <Form.Select className="border-white fw-bold rounded-0 shadow-none text-sm-end ps-0 theme-select-end" value={plan ? plan.allowed_proclamation:''} onChange={(event) => handleVideoLengthChange(event.target.value,plan.id, rowIndex,'proclamation')}>
                          {constantValues.ProclamationData.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Row>
                  </div>
                </Form.Group>
             
                 ))}

              </div>
            </Col>
            <Col md="auto" className="d-none d-md-block"><div className="vr h-100 bg-light mx-1"></div></Col>
            <Col md lg="auto" className="mt-5 mt-md-0">
              <div className="setting-cover-inner">
                <h6 className="fs-16 fw-bold mb-4 pt-1">Recipients</h6>

                {data.map((plan:any, rowIndex:any)=>(      
                <Form.Group key={plan.id} className="mb-4 pb-1">
                  <div className="fw-bold text-uppercase theme-ls-lg border-bottom border-secondary pb-2">{plan ? plan.plan_name:''}</div>
                  <div className="border-bottom border-secondary">
                    <Row className="align-items-center">
                      <Col sm className="text-opacity-50 text-dark mt-2 mt-sm-0">Max limit to add</Col>
                      <Col sm="auto">
                        <Form.Select className="border-white fw-bold rounded-0 shadow-none text-sm-end ps-0 theme-select-end" value={plan ? plan.allowed_recipient:''} onChange={(event) => handleVideoLengthChange(event.target.value,plan.id, rowIndex,'recipient')}>
                          {constantValues.ProclamationData.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Row>
                  </div>
                </Form.Group>
             
                 ))}
                <Form.Group>
                  <Row className="align-items-center">
                    <Col xs className="fw-bold my-1">Ask for Payment ({checked ? 'Disabled' : 'Enabled'})</Col>
                    <Col xs="auto">
                      <ToggleButton
                        className="fw-semibold fs-13 text-white mw-90 my-1"
                        id="toggle-check"
                        type="checkbox"
                        variant="primary"
                        checked={checked}
                        value="1"
                        onChange={handleShow}
                        //onChange={(e) => setChecked(e.currentTarget.checked)}
                      >
                        {checked ? 'Enable' : 'Disable'}
                      </ToggleButton>
                    </Col>
                  </Row>
                </Form.Group>

              </div>
            </Col>
          </Row>
        </Card>
      </div>
    </div>
    :''}
    <Loader isLoading={loading} />
    <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered={true}
            scrollable={true}
            size="sm"
            className="theme-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title className="fs-16 fw-semibold">Ask For Payment</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pb-2">
                <div className="fs-14 fw-semibold pb-2 mb-1">Are you sure you want to Ask for Payment {checked ? 'Enable' : 'Disable'} ?</div>
            </Modal.Body>
            <Modal.Footer className="pt-0 border-0">
                <Stack direction="horizontal" gap={3} className="flex-wrap pb-2">
                    <Button variant="secondary" onClick={handleClose} className="text-dark fw-semibold fs-13 mw-90">
                        Cancel
                    </Button>
                    <Button variant="primary" className="fw-semibold fs-13 text-white mw-90" onClick={()=>{setDeletestatus(true)}}>Submit</Button>
                </Stack>
            </Modal.Footer>
        </Modal>
    </>
  )
}
