import * as yup from "yup";
import {
  CONFIRM_PASSWORD_REQUIRED,
  EMAIL_REQUIRED, FAQ_ORDER, FAQ_ORDER_MIN, MAXIMUM_1000_CHAR, MAXIMUM_200_CHAR, MAXIMUM_32_CHAR, MINIMUM_8_CHAR, NEW_PASSWORD_REQUIRED, PASSWORD_MUST_MATCH, PASSWORD_REGEX, PASSWORD_REQUIRED, REQUIRED, VALID_EMAIL
} from "./ValidationErrors";

/**
 * Profile Schema for Validation
 * @date 4/19/2023 - 9:26:15 AM
 *
 * @type {*}
 */

export const CreateSchema = yup.object().shape({
  question: yup.string().min(8, MINIMUM_8_CHAR).max(200,MAXIMUM_200_CHAR).required(REQUIRED),
  answer: yup.string().min(8, MINIMUM_8_CHAR).max(1000,MAXIMUM_1000_CHAR).required(REQUIRED),
  status: yup.string().required(REQUIRED),
  order_sequence: yup.number().min(1,FAQ_ORDER_MIN).max(99999999, FAQ_ORDER).required(REQUIRED),

});


