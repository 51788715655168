import ActionCell from '../../../../../components/common/dataGrid/ActionCell';
import DataGrid from '../../../../../components/common/dataGrid/DataGrid';
import { IoIosVideocam, IoIosMusicalNotes } from 'react-icons/io';
import { useEffect, useState } from 'react';
import constantValues from '../../../../../constants/constantValues';
import Loader from '../../../../../components/common/Loader';
import formatData from '../../../../../constants/formatData';
import { useParams } from 'react-router-dom';
import { maskPhoneNumber, truncateText } from '../../../../../helpers/helpersMethods';
import { getList } from '../../../../../services/aboutMeService';
import storage from '../../../../../helpers/storage';

export const AboutMe = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(constantValues.rowPerPage);
  const [totalPages, setTotalPages] = useState(0);
  const [totalReults, setTotalResults] = useState(0);
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [releaseLegacy, setReleaseLegacy] = useState(storage.getReleaseLegacy());
  useEffect(() => {
    setLoading(false);
    aboutMeList({ page: currentPage, limit: rowPerPage,userid:id });
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    setLoading(false);
    aboutMeList({ page: currentPage, limit: rowPerPage,sortColumn:sortColumn,sortDirection:sortDirection,userid:id });
  }, [sortColumn,sortDirection]);
  
  const aboutMeList = async (params:any) => {
    setLoading(true);
    getList({ ...params, search: params.search?.trim() }).then(
      (response) => {
        setData(response.data.record);
        setTotalPages(response.data.totalPages);
        setTotalResults(response.data.totalResults);
        setLoading(false);
      }
    );
  };
  const columns = [
    {
      id:'id',
      name: '#',
      sortable: true,
      center: true,
      selector: (row:any, index:any) => (currentPage - 1) * rowPerPage + index + 1,
      width: '60px',
    },
    {
      selector: (row: any) => row.media_type,
      name: 'Type',
      sortable: false,
      width: '245px',
      cell: (row: any) => {
        switch (row.media_type) {
          case 'audio':
            return (
              <div className="d-flex align-items-center justify-content-center">
                <div className="d-flex theme-bg-purple align-items-center justify-content-center custom-small-badge">
                  <IoIosMusicalNotes size={12} />
                </div>
                <span className="ms-1 ps-2 text-capitalize">{row.media_type}</span>
              </div>
            );
          case 'image':
            return (
              <div className="d-flex align-items-center justify-content-center">
                <div className="d-flex align-items-center justify-content-center custom-small-badge">
                  <svg xmlns="http://www.w3.org/2000/svg" width="8.997" height="7.498" viewBox="0 0 8.997 7.498">
                    <path d="M10.747,4.124A1.125,1.125,0,1,1,9.622,3,1.125,1.125,0,0,1,10.747,4.124ZM11.5,10.5h-9V9L5.124,4.5l3,3.749h.75L11.5,6Z" transform="translate(-2.499 -2.999)" fill="currentColor" />
                  </svg>
                </div>
                <span className="ms-1 ps-2 text-capitalize">{row.media_type}</span>
              </div>
            );
          case 'video':
            return (
              <div className="d-flex align-items-center justify-content-center">
                <div className="bg-success d-flex align-items-center justify-content-center custom-small-badge">
                  <IoIosVideocam size={10} />
                </div>
                <span className="ms-1 ps-2 text-capitalize">{row.media_type}</span>
              </div>
            );
          default:
            return null;
        }
      }
  
     },
     {
      id:'media_name',
      selector: (row: any) => row.media_name,
      name: 'Title',
      sortable: true,
      minWidth: '150px',
      cell: (row: any) => {return truncateText(row.media_name)}
    },
    {
      id:'recipient_name',
      selector: (row: any) => row.about_me_recipients_name,
      name: 'Recipients',
      sortable: true,
      minWidth: '150px',
      cell: (row: any) => {return truncateText(row.about_me_recipients_name)}
  },
    // {
    //   selector: (row: any) => row.recipient,
    //   name: 'Recipient',
    //   sortable: true,
    //   width: '200px',
    // },
    {
      selector: (row: any) => row.action,
      name: 'Action',
      width: '100px',
      center: true,
      button: true,
      cell: (row: any) => (
        <ActionCell controlId="users/about-me" isEditEnable={true} disabled={releaseLegacy} redirectID={row.id} />
      )
  
    },
  ];
  
  return (
    <div className="d-flex flex-column h-100 rounded-0 dataGridListMain">
      
      <DataGrid
        showTitle="About Me"
        columns={columns}
        data={data}
        pagination={true}
        totalPages={totalPages}
        totalRecords={totalReults}
        rowPerPage={rowPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        sortColumn={sortColumn}
        setSortColumn = {setSortColumn}
        sortDirection={sortDirection}
        setSortDirection = {setSortDirection}
      />
       <Loader isLoading={loading} />
    </div>
  )
}
