import {axios} from "../services/axios";
import { adminApi } from "../api";


export type CmsDTO = {
  title: string,
  description: string,
};



/**
 * Faq List
 * @date 04/05/2023 - 13:21:19
 *
 * @async
 * @param {*} params
 * @returns {unknown}
 */
export const getList = async (params:any) => {
    return await axios.get(`${adminApi.cmsList}`, { params });
  };

  

 
  /**
   * Cms details
   * @date 11/05/2023 - 10:36:26
   *
   * @async
   * @param {string} id
   * @returns {Promise<any>}
   */
  export const getDetails = async (id: string): Promise<any> => {
    return axios.get(`${adminApi.cmsDetails}/${id}`);
  };
  /**
   * Cms details
   * @date 11/05/2023 - 10:36:26
   *
   * @async
   * @param {string} id
   * @returns {Promise<any>}
   */
  export const cmsBySlug = async (slug: string): Promise<any> => {
    return axios.get(`${adminApi.cmsBySlug}/${slug}`);
  };

  /**
   * Faq Edit
   * @date 04/05/2023 - 17:54:51
   *
   * @async
   * @param {string} id
   * @returns {Promise<any>}
   */
  export const editCms = async (id: string,data: CmsDTO): Promise<any> => {
    return axios.patch(`${adminApi.cmsEdit}/${id}`,data);
  };
 
 