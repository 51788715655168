import React, { useEffect, useState } from 'react';

import { Button, Card, Col, Form, Row, Stack } from "react-bootstrap"
import { IoIosArrowBack } from "react-icons/io"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Formik } from "formik"
import { toast } from 'react-toastify';
import TextAreaHorizontal from '../../../components/common/TextAreaHorizontal';
import Loader from '../../../components/common/Loader';
import { nameBasedProtectedRoutes } from '../../../router/protected';
import CustomRadioHorizontal from '../../../components/common/CustomRadioHorizontal';
import { EditEntitiyMethod, maskPhoneNumber } from '../../../helpers/helpersMethods';
import { CHARITY_UPDATED_SUCCESS} from '../../../validations/ValidationErrors';
import { edit, getDetails } from '../../../services/releaselegacyService';
import ViewTextHorizontal from '../../../components/common/ViewTextHorizontal';
import moment from 'moment';
import formatData from '../../../constants/formatData';
import { LegacyReleaseEditSchema } from '../../../validations/LegacyRelease';
import CustomCheckbox from '../../../components/common/CustomCheckBox';

export const View = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [redirect, setRedirect] = useState<any>();
    const [submitting, setSubmitting] = useState(false);
    const [data, setData] = useState<any>();
    const [legacyStatus, setLegacyStatus] = useState<any>();
    const radioData = [
        { value: 'accepted', label: 'Accepted' },
        { value: 'rejected', label: 'Rejected' }
    ];
    const defaultTags: any = [];
    useEffect(() => {
        if (id) {
            setLoading(true);
            getDetails(id).then(
                (resp: any) => {
                    const res = resp?.data;
                    setData(res);
                    setLegacyStatus(res.status);
                    setRedirect(nameBasedProtectedRoutes.legacyRelease.path);
                    setTimeout(() => {
                        setLoading(false);

                    }, 1000);
                },
                (error: any) => {
                    const err = error?.response?.data;
                    setLoading(false);
                    if (err?.statusCode === 400) {
                        toast.error(err.msg);
                        navigate(nameBasedProtectedRoutes.legacyRelease.path);
                    }

                }
            );
        }

    }, [id]);


    /**
     * Add 
     * @date 19/05/2023 - 13:34:03
     *
     * @param {*} value
     */
    const submitForm = (value: any) => {
        if (id) {
            toast.dismiss();
            EditEntitiyMethod(setSubmitting, edit, id, value, CHARITY_UPDATED_SUCCESS, nameBasedProtectedRoutes.legacyRelease.path, navigate);
        }
    }


    return (
        <>
            {!loading && data ? <Formik
                validationSchema={LegacyReleaseEditSchema}
                initialValues={{
                    logs_users_name: data ? data.logs_users_name : '',
                    email: data ? data.logs_trustees_email : '',
                    trustees_name: data ? data.logs_trustees_name : '',
                    phone_code: data ? data.logs_trustees_phone_country_code : '',
                    phone_number: data ? data.logs_trustees_phone_no : '',
                    status: data ? data.status == 'pending' ? 'accepted':data.status : '',
                    reason: data ? data.reason == null ? '':data.reason: '',
                    updateforall: data ? data.updateforall : false,
                    created_at: data ? data.created_at : '',
                }}
                onSubmit={(values, actions) => {
                    const { logs_users_name,created_at,email,trustees_name,phone_code,phone_number, ...submitValues } = values; // Exclude logs_users_name from submission
                    submitValues.reason = submitValues.reason.trim();
                    submitValues.status = submitValues.status;
                    submitValues.updateforall = submitValues.updateforall ? submitValues.updateforall:false ;
                    actions.setSubmitting(false);
                    submitForm(submitValues);
                }}


            >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    isValid,
                    errors,
                    setFieldValue,
                }) => (

                    <Form className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1" noValidate onSubmit={handleSubmit}>
                        <div className="pageHeader px-3 my-1 pt-1 user-select-none">
                            <Row className="align-items-center min-h-51">
                                <Col>
                                    <h1 className="fw-semibold h4 my-2">
                                        <Link to={redirect} className="align-items-center d-inline-flex overflow-hidden text-dark text-decoration-none">
                                            <span className="me-1 ms-n1">
                                                <IoIosArrowBack size="1.68rem" />
                                            </span>Legacy Release
                                        </Link>
                                    </h1>
                                </Col>
                            </Row>
                        </div>
                        <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
                            <Card className="h-100 w-100 overflow-auto border-light-subtle pageContentInner">
                                <Card.Header className="bg-white fs-16 fw-semibold p-3 border-secondary">
                                    <Card.Text className="px-xl-1">Legacy Release Details</Card.Text>
                                </Card.Header>
                                <Card.Body className="p-xl-4">
                                    <ViewTextHorizontal
                                        label="User"
                                        setColunView={true}
                                        value={values.logs_users_name}
                                    />
                                    <ViewTextHorizontal
                                        label="Truestee"
                                        setColunView={true}
                                        value={values.trustees_name}
                                    />
                                    <ViewTextHorizontal
                                        label="Trustee Email"
                                        setColunView={true}
                                        value={values.email}
                                    />

                                    <ViewTextHorizontal
                                        label="Trustee Phone #"
                                        setColunView={true}
                                        value={maskPhoneNumber(values.phone_code + values.phone_number)}
                                    />
                                    <ViewTextHorizontal
                                        label="Date and Time"
                                        setColunView={true}
                                        value={data.created_at ? moment(data.created_at).format(formatData.datetimeFormats.forwordSeparatedDateTime) : ''}
                                    />
                                  
                                    { legacyStatus == "pending" ?
                                    <>
                                    <CustomRadioHorizontal
                                        leftColSmall={false}
                                        inline={true}
                                        label="Status"
                                        name="status"
                                        touched={touched.status}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        errorsField={errors.status}
                                        value={values.status}
                                        data={radioData}
                                    />
                                    <CustomCheckbox
                                        leftColSmall={false}
                                        inline={true}
                                        label="Update the same status for the other Trustees"
                                        name="updateforall"
                                        touched={touched.updateforall}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        errorsField={errors.updateforall}
                                        value={values.updateforall}
                                    />

                                    <TextAreaHorizontal
                                        controlId="reason"
                                        label="Reason"
                                        placeholder="Reason"
                                        touched={touched.reason}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        errorsField={errors.reason}
                                        value={values.reason}
                                    />


                                    <div className="theme-form-horizontal py-3">
                                        <Row>
                                            <Col sm="auto" className="left-col"></Col>
                                            <Col sm="auto" className="right-col">
                                                <Stack direction="horizontal" gap={3}>
                                                    <Button className="fw-semibold fs-13 text-white mw-90 my-1" variant="primary" type="submit">Submit</Button>
                                                    <Link to={redirect} className="btn btn-secondary text-dark fw-semibold fs-13 mw-90 my-1">Cancel</Link>
                                                </Stack>
                                            </Col>
                                        </Row>
                                    </div>
                                    </>:
                                    <>
                                         <ViewTextHorizontal
                                        label="Status"
                                        setColunView={true}
                                        value={data.status ? data.status: ''}/>
                                         <ViewTextHorizontal
                                        label="Reason"
                                        setColunView={true}
                                        value={data.reason ? data.reason: ''}/>

                                    </>
                                    
                                    }
                                </Card.Body>
                            </Card>
                        </div>
                    </Form>
                )}
            </Formik > : ''}
            <Loader isLoading={loading || submitting} />
        </>

    )
}
