import { useEffect, useState } from 'react'
import { Card, Col, Row, Stack } from 'react-bootstrap'
import DataGrid from '../../../components/common/dataGrid/DataGrid';
import ActionCell from '../../../components/common/dataGrid/ActionCell';
import { Link, useNavigate } from 'react-router-dom';
import { getList } from '../../../services/cmsService';
import Loader from '../../../components/common/Loader';
import constantValues from '../../../constants/constantValues';
import { truncateText } from '../../../helpers/helpersMethods';
import { toast } from 'react-toastify';

export const AllCMS = () => {
  const [filterData, setFilterData] = useState({ search: '',searchByCreatedDate:'',sortByStatus:'' });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(constantValues.rowPerPage);
  const [totalPages, setTotalPages] = useState(0);
  const [totalReults, setTotalResults] = useState(0);
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("asc");
  const navigate = useNavigate();

  useEffect(() => {
    const searchData:string = filterData ? filterData.search:'';
    const sortByStatus:any = filterData ? filterData.sortByStatus:'';
    if(searchData.length > 1  || sortByStatus.length > 1)
    {
      setCurrentPage(1);
      setLoading(false);
      cmsList({ page: currentPage, limit: rowPerPage,sortColumn:sortColumn,sortDirection:sortDirection,search:searchData,searchByStatus:sortByStatus});
    }else
    {
      setCurrentPage(1);
      setLoading(false);
      cmsList({ page: currentPage, limit: rowPerPage,sortColumn:sortColumn,sortDirection:sortDirection});
    }
  }, [currentPage,sortColumn,sortDirection,filterData]);

const cmsList = async (params:any) => {
    setLoading(true);
    getList({ ...params, search: params.search?.trim() }).then(
      (response) => {
        setLoading(false);
        setData(response.data?.record);
        setTotalPages(response.data?.totalPages);
        setTotalResults(response.data?.totalResults);
        
      }
    );
  };
  const columns = [
    {
      id:'title',
      selector: (row: any) => row.title,
      name: 'Title',
      sortable: true,
      minWidth: '200px',
      cell: (row: any) => {return truncateText(row.title)}
    },
    {
      id:'action',
      selector: (row: any) => row.action,
      name: 'Action',
      width: '100px',
      center: true,
      button: true,
      cell: (row: any) =>
      {
        return (<ActionCell controlId="cms" isEditEnable={true} redirectID={row.id}/>);
      } 
    },
  
  ];
  return (
    <>
    <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
      <div className="pageHeader px-3 my-1 pt-1 user-select-none">
        <Row className="align-items-center min-h-51">
          <Col>
            <h1 className="fw-semibold h4 my-2">CMS</h1>
          </Col>
        </Row>
      </div>
      <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
        <Card className="h-100 p-3 w-100 border-light-subtle pageContentInner">
          <div className="d-flex flex-column h-100 rounded-0 dataGridListMain">
          <DataGrid
              columns={columns}
              data={data}
              FilterSubHeader={true}
              filterData={filterData}
              setFilterData={setFilterData}
              pagination={true}
              totalPages={totalPages}
              totalRecords={totalReults}
              rowPerPage={rowPerPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              sortColumn={sortColumn}
              setSortColumn = {setSortColumn}
              sortDirection={sortDirection}
              setSortDirection = {setSortDirection}
              hideRightFilters={true}
            />
          </div>
        </Card>
      </div>
    </div>
    <Loader isLoading={loading} />
  
    </>
  )
}
