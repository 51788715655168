import { Button, Card, Col, Form, Row, Stack } from "react-bootstrap"
import { IoIosArrowBack } from "react-icons/io"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Formik } from "formik"
import { DNDFrequencySchema } from "../../../../../../validations/Users"
import CustomRadio from "../../../../../../components/common/CustomRadio"
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import 'react-quill/dist/quill.snow.css';
import { clearDndRule, getRulesDetailsByUserId, updateDndRule } from "../../../../../../services/userService";
import { toast } from "react-toastify"
import moment from "moment"
import FormWithoutTimeDatePicker from "../../../../../../components/common/FormWithoutTimeDatePicker"
import Loader from "../../../../../../components/common/Loader"
import ClearModal from "../../../../../../components/common/ClearModal"
import { EditEntitiyMethod } from "../../../../../../helpers/helpersMethods"
import { USER_UPDATED_SUCCESS } from "../../../../../../validations/ValidationErrors"
import FormTimePicker from "../../../../../../components/common/FormTimePicker"

export const EditDNDFrequency = () => {
  const navigate = useNavigate();
  const [redirect, setRedirect] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();
  const [submitting, setSubmitting] = useState(false);
  const { id } = useParams();
 

  const radioData = [
    { label: 'Date', value: 'date' },
    { label: 'Date and Time', value: 'date_and_time' },
  ];
  useEffect(() => {
    setLoading(true);
    if (id) {
      getRulesDetailsByUserId({ 'user_id': id }).then(
        (resp) => {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
          const res = resp?.data;
          setRedirect(`/app/users/view/${res.user_id}`);
          setData(res);
        },
        (error: any) => {
          setLoading(false);
          const err = error?.response?.data;
          if (err?.statusCode === 400) {
            toast.error(err.msg);
            navigate(`/app/users`);
          }
        }
      );
    }
  }, [id]);
 

  const submitForm = (value: any, setErrors: any) => {
    if (id && data) {
      if (value.frequency == 'date') {
        value.dnd_start_time = null;
        value.dnd_end_time = null;
        let startTime = value.dnd_start_date.valueOf();
        let endTime = value.dnd_end_date.valueOf();
        if (endTime < startTime || startTime > endTime) {
          setErrors({ dnd_start_date: 'Start date must be before end date.' });
          return;
        }
      } else {
        if (!value.dnd_start_time) {
          setErrors({ dnd_start_time: 'Field is required.' });
          return;
        }
        if (!value.dnd_end_time) {
          setErrors({ dnd_end_time: 'Field is required.' });
          return;
        }
        value.dnd_start_time = moment(value.dnd_start_time, "HH:mm:ss").toDate();
        value.dnd_end_time = moment(value.dnd_end_time, "HH:mm:ss").toDate();
        const startDate = moment(value.dnd_start_date);
        const startTime = startDate.clone().set({ hour: value.dnd_start_time.getHours(), minute: value.dnd_start_time.getMinutes() });
        const endDate = moment(value.dnd_end_date);
        const endTime = endDate.clone().set({ hour: value.dnd_end_time.getHours(), minute: value.dnd_end_time.getMinutes() });

        if (!startTime.isBefore(endTime)) {
          setErrors({ dnd_start_date: 'Start date and time must be before end date and time.' });
          return;
        }
        value.dnd_start_time = moment(value.dnd_start_time).format("HH:mm");
        value.dnd_end_time = value.dnd_end_time ? moment(value.dnd_end_time).format("HH:mm") : '';

      }
      //delete value.frequency;
      EditEntitiyMethod(setLoading, updateDndRule, id, value, USER_UPDATED_SUCCESS, `/app/users/view/${id}`, navigate);
    }
  }
 
  return (
    <>
      {data ?
        <Formik
          validationSchema={DNDFrequencySchema}
          initialValues={{
            frequency: data && data.dnd_start_time != null ? 'date_and_time' :'date',
            dnd_start_time: data && data.dnd_start_time != null ? data.dnd_start_time: '',
            dnd_start_date: data && data.dnd_start_date,
            dnd_end_time: data && data.dnd_end_time != null ? data.dnd_end_time : '',
            dnd_end_date: data && data.dnd_end_date,
          }}
          onSubmit={(values, actions) => {
            actions.setSubmitting(false);
            submitForm(values, actions.setErrors)
          }}
          onReset={(values, actions) =>
          {
            values.dnd_start_time = '';
            values.dnd_start_date = '';
            values.dnd_end_time = '';
            values.dnd_end_date = '';
          }}

        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            setFieldValue,
            handleReset
            
          }) => (
            <Form className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1" noValidate onSubmit={handleSubmit}>
              <div className="pageHeader px-3 my-1 pt-1 user-select-none">
                <Row className="align-items-center min-h-51">
                  <Col>
                    <h1 className="fw-semibold h4 my-2">
                      <Link to={redirect} className="align-items-center d-inline-flex overflow-hidden text-dark text-decoration-none">
                        <span className="me-1 ms-n1">
                          <IoIosArrowBack size="1.68rem" />
                        </span> Users
                      </Link>
                    </h1>
                  </Col>
                </Row>
              </div>
              <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
                <Card className="h-100 w-100 overflow-auto border-light-subtle pageContentInner">
                  <Card.Header className="bg-white fs-16 fw-semibold p-3 border-secondary">
                    <Card.Text className="px-xl-1">Edit Rules to Execute Legacy</Card.Text>
                  </Card.Header>
                  <Card.Body className="p-xl-4">
                    <h6 className="fs-16 fw-bold mb-3 pb-1">Do not Disturb</h6>
                    <CustomRadio
                      inline={true}
                      label="Select Frequency"
                      name="frequency"
                      touched={touched.frequency}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errorsField={errors.frequency}
                      value={values.frequency}
                      data={radioData}
                    />
                    <div className="mw-410">
                      <Form.Label>Select Start {values.frequency === 'date_and_time' ? 'Date and Time' : 'Date'}</Form.Label>
                      <Row className="gx-3">
                        <Col xs={6} className="mb-3">
                          <FormWithoutTimeDatePicker
                            controlId="dnd_start_date"
                            name="dnd_start_date"
                            placeholder="MM-DD-YYYY"
                            label=""
                            selected={values.dnd_start_date}
                            onBlur={handleBlur}
                            error={errors.dnd_start_date}
                            touched={touched.dnd_start_date}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            value={values.dnd_start_date ? values.dnd_start_date : ''}
                            errorsField={errors.dnd_start_date}
                            allowPastDates={false}
                          />
                        </Col>
                        {values.frequency === 'date_and_time' ?
                          <Col xs={6} className="mb-3">
                            <FormTimePicker
                              controlId="dnd_start_time"
                              name="dnd_start_time"
                              placeholder="hh:mm A"
                              label=""
                              selected={values.dnd_start_time}
                              onBlur={handleBlur}
                              error={errors.dnd_start_time}
                              touched={touched.dnd_start_time}
                              handleChange={handleChange}
                              setFieldValue={setFieldValue}
                              value={values.dnd_start_time ? values.dnd_start_time : ''}
                              errorsField={errors.dnd_start_time}
                            />
                          </Col>
                          : null}
                      </Row>
                    </div>
                    <div className="mw-410">
                      <Form.Label>Select End {values.frequency === 'date_and_time' ? 'Date and Time' : 'Date'}</Form.Label>
                      <Row className="gx-3">
                        <Col xs={6} className="mb-3">
                          <FormWithoutTimeDatePicker
                            controlId="dnd_end_date"
                            name="dnd_end_date"
                            placeholder="MM-DD-YYYY"
                            label=""
                            selected={values.dnd_end_date}
                            onBlur={handleBlur}
                            error={errors.dnd_end_date}
                            touched={touched.dnd_end_date}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            value={values.dnd_end_date ? values.dnd_end_date : ''}
                            errorsField={errors.dnd_end_date}
                            allowPastDates={false}
                          />
                        </Col>
                        {values.frequency === 'date_and_time' ?
                          <Col xs={6} className="mb-3">
                            <FormTimePicker
                              controlId="dnd_end_time"
                              name="dnd_end_time"
                              placeholder="hh:mm A"
                              label=""
                              selected={values.dnd_end_time}
                              onBlur={handleBlur}
                              error={errors.dnd_end_time}
                              touched={touched.dnd_end_time}
                              handleChange={handleChange}
                              setFieldValue={setFieldValue}
                              value={values.dnd_end_time ? values.dnd_end_time : ''}
                              errorsField={errors.dnd_end_time}
                            />
                          </Col>
                          : null}
                      </Row>
                    </div>
                    <Stack direction="horizontal" gap={3} className="pt-2">
                      <Button className="fw-semibold fs-13 text-white mw-90 my-1" variant="primary" type="submit">Update</Button>
                      <Link to={redirect} className="btn btn-secondary text-dark fw-semibold fs-13 mw-90 my-1">Cancel</Link>
                      {
                      data && data.dnd_end_date ?
                    
                    <Button className="fw-semibold fs-13 text-white mw-90" variant="danger" type="reset" onClick={handleReset}>Clear</Button>
                    : ''}
                    </Stack>


                  </Card.Body>
                </Card>
              </div>
            </Form>
          )}
        </Formik > : ''}
      <Loader isLoading={loading} />
    </>

  )
}
