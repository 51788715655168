import { Formik } from 'formik';
import { Button, Stack } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { ResetPasswordSchema } from '../../../validations/Auth';
import { AuthHeader } from '../authHeader/AuthHeader';
import InputText from '../../../components/common/InputText';
import { FaLock } from 'react-icons/fa';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Cookies from "universal-cookie";
import { useEffect, useState } from 'react';
import { nameBasedRoutes } from '../../../router/public';
import { resetPassword,resetPasswordToken } from '../../../services/authService'
import { toast } from 'react-toastify';
import Loader from '../../../components/common/Loader';
import { FaExclamationTriangle } from "react-icons/fa";


export const ResetPassword = () => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  let token:string | null = "";
  const [loading, setLoading] = useState(false);
  const [tokenExpired, setTokenExpired] = useState(false);

  const search = useLocation().search;
  
  useEffect(() => {
    token = new URLSearchParams(search).get("token");
    resetPasswordToken({'token': token }).then(
      (response) => {
        setLoading(false);
        const res = response?.data;
        if (response?.status === 200)
        {

        }
        
      },
      (error) => {
        setTokenExpired(true);
      }
    );
    if (!token) {
      navigate(nameBasedRoutes.home.path);
    }
  }, []);

  const handleSubmit = (values:any, actions:any) => {
    setLoading(true);
    resetPassword({password:values.password,'token': token ? token : ''}).then(
      (response) => {
        setLoading(false);
        const res = response?.data;
        if (response?.status === 200)
        {
          toast.success(res.msg);
          cookies.remove("admin_password");
          navigate(nameBasedRoutes.home.path);
        }
        
      },
      (error) => {
        setLoading(false);
        toast.error(error.response.data.msg);
        
      }
    );
  };

  return (
    <>
    {!tokenExpired ?
    <Formik
      validationSchema={ResetPasswordSchema}
      onSubmit={handleSubmit}
      initialValues={{
        password: '',
        confirmPassword: '',
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <div className="d-flex flex-column p-3 min-vh-100 w-100">
          <div className="mw-385 mx-auto my-4 py-md-5 text-center w-100">
            <AuthHeader />
            <div className="bg-white d-inline-block mt-3 p-sm-4 p-2 rounded-3 text-start w-100">
              <Form className="p-2" noValidate onSubmit={handleSubmit}>
                <h6 className="fw-normal text-center mb-3">Reset Password</h6>
                <InputText
                  controlId="password"
                  label='Password'
                  placeholder="Enter Password"
                  touched={touched.password}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  errorsField={errors.password}
                  value={values.password}
                  type="password"
                  rightIcon={<FaLock size="13" />}
                  isPasswordHintVisible={true}
                />
                <InputText
                  controlId="confirmPassword"
                  label='Confirm Password'
                  placeholder="Enter Confirm Password"
                  touched={touched.confirmPassword}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  errorsField={errors.confirmPassword}
                  value={values.confirmPassword}
                  type="password"
                  rightIcon={<FaLock size="13" />}
                  isPasswordHintVisible={true}
                />
                <Stack direction="horizontal" gap={3}>
                  <Button className="fw-semibold fs-13 text-white mw-90 mt-2" variant="primary" type="submit">Update</Button>
                  <Link to="/" className="btn btn-secondary text-dark fw-semibold fs-13 mw-90 mt-2">Cancel</Link>
                </Stack>
              </Form>
            </div>
          </div>
        </div>
      )}
    </Formik> : <div className="min-vh-100 w-100 d-flex flex-column p-3">
      <div className="col-lg-7 m-auto text-center">
        <FaExclamationTriangle size="3rem" className="text-danger" />
        <h1 className="fs-3 fw-semibold my-3">Oops! Your Reset Password Link expired</h1>
        <p className="fs-16 text-muted">Please request a new password to continue reset your password.</p>
        <div className="d-inline-flex gap-2 mb-5">
          <Link to={nameBasedRoutes.home.path} className="btn btn-primary fw-semibold fs-13 text-white mw-90 mt-2">
            Back to Login
          </Link>
        </div>
      </div>
    </div>
    }
    <Loader isLoading={loading} />
    </>
  )
}
