import { FC } from 'react';
import Form from 'react-bootstrap/Form';

interface ICustomSelect {
    controlId: string;
    label?: string;
    handleChange?: any;
    handleBlur?: any;
    errorsField?: any;
    touched?: any;
    value?: any;
    data?: any;
    placeholder?: string
    id?: string;
    rightIcon?: any;
    size?: any;
    name?: any;
}

/**
 * Custom Select Reusable Component
 * @date 4/13/2023 - 7:09:25 PM
 *
 * @param {{ controlId: any; label: any; handleChange: any; handleBlur: any; errorsField: any; touched: any; value: any; data: any; placeholder: any; id: any; rightIcon: any; size: any; name: any; }} {
    controlId, label, handleChange, handleBlur, errorsField, touched, value, data, placeholder, id, rightIcon, size, name
}
 * @returns {*}
 */

const CustomSelect: FC<ICustomSelect> = ({
    controlId, label, handleChange, handleBlur, errorsField, touched, value, data, placeholder, id, rightIcon, size, name
}) => {
    return (
        <Form.Group className="mb-3" controlId={controlId}>
            {label ? (
                <Form.Label className="lh-sm">{label}</Form.Label>
            ) : null}
            <div className={`position-relative ${rightIcon ? 'form-right-icon' : ''}`}>
                <Form.Select name={name} size={size} aria-label="Select" value={value} onChange={handleChange} onBlur={handleBlur} isInvalid={!!errorsField && touched}>
                    <option value=''>{placeholder}</option>
                    {data.map((e: any) => {
                        const key = e.id ? e.id : '';
                        return <option key={e.id} value={key}>{e.name}</option>
                    })}
                </Form.Select>
                {rightIcon ? (
                    <span className={`align-items-center d-flex end-0 justify-content-center pe-none position-absolute px-2 top-0 ${size ? 'h-35' : 'h-40'}`}>
                        {rightIcon}
                    </span>
                ) : null}
                <Form.Control.Feedback type="invalid">
                    {errorsField}
                </Form.Control.Feedback>
            </div>
        </Form.Group>
    );
}

export default CustomSelect