import { useEffect, useState } from 'react'
import { Card, Col, Row, Stack } from 'react-bootstrap'
import DataGrid from '../../../components/common/dataGrid/DataGrid';
import ActionCell from '../../../components/common/dataGrid/ActionCell';
import { IoIosVideocam } from 'react-icons/io';
import { Link, useNavigate } from 'react-router-dom';
import constantValues from '../../../constants/constantValues';
import { getProclamationGlobalList } from '../../../services/PproclamationService';
import { toast } from 'react-toastify';
import Loader from '../../../components/common/Loader';
import { CapitalText, redirectUser, truncateText } from '../../../helpers/helpersMethods';
import ImageModal from '../../../components/common/ImagePreview';
import { nameBasedProtectedRoutes } from '../../../router/protected';



const viewData = [
  { id: 'published', name: 'Published' },
  { id: 'unpublished', name: 'Unpublished' },
  { id: 'expired', name: 'Expired' }
];

export const AllProclamation = () => {
  const [filterData, setFilterData] = useState({ search: '',searchByCreatedDate:'',sortByStatus:'' });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(constantValues.rowPerPage);
  const [totalPages, setTotalPages] = useState(0);
  const [totalReults, setTotalResults] = useState(0);
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [plan, setPlan] = useState([]);
  const [showImagePreview, setShowImagePreview] = useState(false);
  const [previewImageUrl, setPreviewImageUrl] = useState('');
  const navigate = useNavigate();

  const handleImagePreview = (imageUrl: string) => {
    setShowImagePreview(true);
    setPreviewImageUrl(imageUrl);
  };
  useEffect(() => {
    const searchData = filterData ? filterData.search : '';
    const sortByStatus = filterData ? filterData.sortByStatus : '';
    if (searchData.length > 1  || sortByStatus.length >= 1) {
      setLoading(false);
      List({
        page: currentPage,
        limit: rowPerPage,
        sortColumn,
        sortDirection,
        search: searchData,
        searchByStatus: sortByStatus
      });
    } else {
      setLoading(false);
      List({ page: currentPage, limit: rowPerPage, sortColumn,sortDirection});
    }
  }, [filterData, sortColumn, sortDirection, rowPerPage,currentPage]);
  useEffect(()=>{
    setCurrentPage(1);
  },[filterData,sortColumn,sortDirection])


  /**
   * Description fetch user list
   * @date 19/04/2023 - 12:10:33
   *
   * @async
   * @param {*} params
   * @returns {*}
   */
  const List = async (params:any) => {
    setLoading(true);
    getProclamationGlobalList({ ...params, search: params.search?.trim() }).then(
      (response) => {
        setData(response.data?.record);
        setTotalPages(response.data?.totalPages);
        setTotalResults(response.data?.totalResults);
        setLoading(false);
      },(error: any) => {
        const err = error?.response?.data;
        setLoading(false);
        if (err?.statusCode === 400) {
          toast.error(err.msg);
        }

      }
    );
  };

  const columns = [
    {
      id:'id',
      name: '#',
      sortable: true,
      center: true,
      selector: (row:any, index:any) => (currentPage - 1) * rowPerPage + index + 1,
      width: '60px',
    },
    {
      id:'proclamation_title',
      selector: (row: any) => row.proclamation_title,
      name: 'Title',
      sortable: true,
      minWidth: '100px',
      cell: (row: any) => {return truncateText(row.proclamation_title)}
    },
    {
      id:'user_name',
      selector: (row: any) => row.proclamation_user_name,
      name: 'User',
      sortable: true,
      minWidth: '100px',
      cell: (row: any) => (<span className='table-linking' onClick={() => redirectUser(row.user_id,navigate)}>{truncateText(row.proclamation_user_name)}</span>)
    },
    
    {
      id:'category',
      selector: (row: any) => row.category,
      name: 'Category',
      sortable: true,
      width: '180px',
    },
    {
      selector: (row: any) => row.video,
      name: 'Video',
      sortable: false,
      width: '110px',
      cell: (row: any) => (
        <div>
          <div className="d-flex bg-success align-items-center justify-content-center custom-small-badge cursor-pointer" onClick={() => handleImagePreview(row.asset_url_name)}>
            <IoIosVideocam size={10} />
          </div>
        </div>
      )
    },
    {
      id:'publicationstatus',
      selector: (row: any) => row.publicationstatus,
      name: 'Status',
      sortable: true,
      width: '140px',
      cell: (row: any) => {return CapitalText(row.publicationstatus)}
    },
    {
      selector: (row: any) => row.action,
      name: 'Action',
      width: '120px',
      center: true,
      button: true,
      cell: (row: any) => (
        <ActionCell controlId="proclamation" isEditEnable={true} redirectID={row.id} />
      )
  
    },
  ];
  

  return (
    <>
    <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
      <div className="pageHeader px-3 my-1 pt-1 user-select-none">
        <Row className="align-items-center min-h-51">
          <Col>
            <h1 className="fw-semibold h4 my-2">Manage Proclamation</h1>
          </Col>
          <Col xs="auto">
            <Stack direction="horizontal">
              <Link to="/app/proclamation/pending-proclamation" className="btn btn-primary my-2 fw-semibold fs-13 text-white">Pending Proclamation</Link>
            </Stack>
          </Col>
        </Row>
      </div>
      <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
        <Card className="h-100 p-3 w-100 border-light-subtle pageContentInner">
          <div className="d-flex flex-column h-100 rounded-0 dataGridListMain">
          <DataGrid
              columns={columns}
              data={data}
              viewData={viewData}
              FilterSubHeader={true}
              filterData={filterData}
              setFilterData={setFilterData}
              filterByDateRange={false}
              pagination={true}
              totalPages={totalPages}
              totalRecords={totalReults}
              rowPerPage={rowPerPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              sortColumn={sortColumn}
              setSortColumn = {setSortColumn}
              sortDirection={sortDirection}
              setSortDirection = {setSortDirection}
            />
          </div>
        </Card>
      </div>
    </div>
    <Loader isLoading={loading} />
    {showImagePreview && (
        
        <ImageModal
        show={showImagePreview}
        handleClose={() => setShowImagePreview(false)}
        imageUrl={previewImageUrl}
        contentType="video"
      />
)}
    </>
  )
}
