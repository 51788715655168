import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Loader from '../../../components/common/Loader';
import { cmsBySlug } from '../../../services/cmsService';


export const Privacy = () => {
  let slug:string | null = "";
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();
  
  useEffect(() => {
    slug = 'privacy_policy';
    cmsBySlug(slug).then(
        (resp: any) => {
          const res = resp?.data;
          setData(res);
          setTimeout(() => {
            setLoading(false);

          }, 1000);
        },
        (error: any) => {
          const err = error?.response?.data;
          setLoading(false);
          if (err?.statusCode === 400) {
            toast.error(err.msg);
            //navigate(nameBasedProtectedRoutes.faq.path);
          }

        }
      );
  }, []);

  

  return (
    <>
    {data ?
   <div className="min-vh-100 w-100 d-flex flex-column p-3">
      <div className="col-lg-7">
        
        <h1 className="fs-3 fw-semibold">Privacy Policy</h1>
        <p className="fs-16 text-muted" dangerouslySetInnerHTML={{ __html: data.description }}></p>
      
      </div>
    </div>
    : ''}
    <Loader isLoading={loading} />
    </>
  )
}
