import * as yup from "yup";
import {MAXIMUM_1000_CHAR, MAXIMUM_200_CHAR, MINIMUM_3_CHAR,MINIMUM_1_CHAR,REQUIRED} from "./ValidationErrors";

/**
 * Profile Schema for Validation
 * @date 4/19/2023 - 9:26:15 AM
 *
 * @type {*}
 */

export const CreateSchema = yup.object().shape({
  title: yup.string().min(3, MINIMUM_3_CHAR).max(200,MAXIMUM_200_CHAR).required(REQUIRED),
  owner: yup.string().min(3, MINIMUM_3_CHAR).max(200,MAXIMUM_200_CHAR).required(REQUIRED),
  message: yup.string().min(1, MINIMUM_1_CHAR).max(1000,MAXIMUM_1000_CHAR).required(REQUIRED),
  status: yup.string().required(REQUIRED),
  tags: yup.array()
    .test('atLeastOneRequired', 'Field is Required.', (value) => value && value.length >= 1)
    .required(REQUIRED),
  publish_date: yup.string().required(REQUIRED),

});


