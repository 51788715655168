import { useEffect} from "react"
import { useNavigate } from "react-router"
import storage from "../../helpers/storage"
import { toast } from 'react-toastify';
import { FaExclamationTriangle } from "react-icons/fa";
import { Link } from "react-router-dom";

export const TokenNotFound = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (storage.getToken() == null)
    {
        return navigate('/')
    } else
    {
      return navigate('/app/dashboard')
    }
  }, [navigate])

  return (
    <div className="h-100 w-100 d-flex flex-column p-3">
      <div className="col-lg-7 m-auto text-center">
        <FaExclamationTriangle size="3rem" className="text-danger" />
        <h1 className="fs-3 fw-semibold my-3">Oops!: Page not found</h1>
        <p className="fs-16 text-muted">Sorry, the page you're looking for isn't here. <br />We suggest checking the URL for typos or navigating back to our homepage.</p>
        <div className="d-inline-flex gap-2 mb-5">
          <Link to="/app/dashboard" className="btn btn-primary fw-semibold fs-13 text-white mw-90 mt-2">
            Back to Dashboard
          </Link>
        </div>
      </div>
    </div>
  )
}