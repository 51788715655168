import {axios} from "../services/axios";
import { adminApi } from "../api";


export type TemplateDTO = {
  id: string
};



/**
 * Faq List
 * @date 04/05/2023 - 13:21:19
 *
 * @async
 * @param {*} params
 * @returns {unknown}
 */
export const getEmailTemplateList = async (params:any) => {
    return await axios.get(`${adminApi.emailTemplateList}`, { params });
};

export const editEmailTemplate = async (id: string,data: TemplateDTO): Promise<any> => {
    return axios.patch(`${adminApi.emailTemplateEdit}/${id}`,data);
  };
  export const getEmailTemplateDetails = async (id: string): Promise<any> => {
    return axios.get(`${adminApi.emailTemplateDetails}/${id}`);
  };
  

 
 
 