import { Button, Card, Col, Form, Row, Stack } from "react-bootstrap"
import { IoIosArrowBack } from "react-icons/io"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Formik } from "formik"
import { ProclamationSchema, ProclamationUnpulishSchema } from "../../../validations/Proclamation";
import ViewTextHorizontal from "../../../components/common/ViewTextHorizontal";
import CustomSwitchHorizontal from "../../../components/common/CustomSwitchHorizontal";
import ViewTagsHorizontal from "../../../components/common/ViewTagsHorizontal";
import { PublishModal } from "../Modals/PublishModal";
import { useEffect, useState } from "react";
import { getDetails, publishProclamation } from "../../../services/PproclamationService";
import { toast } from "react-toastify";
import Loader from "../../../components/common/Loader";
import ImageModal from "../../../components/common/ImagePreview";
import FormDatesPicker from "../../../components/common/FormDatesPicker";
import { CapitalText, EditEntitiyMethod } from "../../../helpers/helpersMethods";
import { CHARITY_UPDATED_SUCCESS } from "../../../validations/ValidationErrors";
import { nameBasedProtectedRoutes } from "../../../router/protected";
import moment from "moment";
import formatData from "../../../constants/formatData";

export const EditProclamation = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [redirect, setRedirect] = useState<any>();
  const [data, setData] = useState<any>();
  const [imagethumb, setImagethumb] = useState<any>();
  const [isAudioPopupOpen, setIsAudioPopupOpen] = useState(false);
  const [publish, setPublished] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [show, setShow] = useState<any>();


  useEffect(() => {
    setLoading(true);
    if (id) {

      getDetails(id).then(
        (resp) => {
          setLoading(false);
          const res = resp?.data;
          setData(res);
          setImagethumb(res.asset_url_name);
          setRedirect(`/app/proclamation`);
          const publishStatus = res.publicationstatus == 'published' ? true : false;
          setPublished(publishStatus)
        },
        (error: any) => {
          setLoading(false);
          const err = error?.response?.data;
          if (err?.statusCode === 400) {
            toast.error(err.msg);
            navigate(`/app/proclamation`);
          }
        }
      );
    }
  }, [id]);

  const submitForm = (value: any,setErrors: any) => {
    if (id) {
      if(publish && !value.expired_at)
      {
        setErrors({ expired_at: 'Field is required.' });
      }else
      {
        toast.dismiss();
        delete value.publish;
        EditEntitiyMethod(setLoading, publishProclamation, id, value, CHARITY_UPDATED_SUCCESS, nameBasedProtectedRoutes.proclamation.path, navigate);
      }
   
    }
  }
  const isVideoHandler = () => {
    if (imagethumb) {
      setIsAudioPopupOpen(true);
    }
  }
  const isPublishedStatus = () =>
  {
    
    setShow(true);
  }

  return (
    
    <>
    {publish}
      {data ? <Formik
        validationSchema={ProclamationSchema}
        initialValues={{
          proclamation_title: data ? data.proclamation_title : '',
          proclamation_user_name: data ? data.proclamation_user_name : '',
          category: data ? data.category : '',
          message: data ? data.message != null ? data.message : "" : "",
          tags: data.tags ? data.tags.map((obj: any, index: any) => ({ id: obj, text: obj })) : '',
          publicationstatus: data ? data.publicationstatus : '',
          publish: data ? data.publicationstatus == 'published' ? true : false:'',
          expired_at: data ? data.expired_at : '',
          video: ''
          //tags:data.tags ? Tag[] : '',
        }}
        onSubmit={(values, actions) => {
          const { proclamation_title, proclamation_user_name, category, message, tags, video, ...submitValues } = values; // Exclude logs_users_name from submission
          submitValues.expired_at = submitValues.expired_at?submitValues.expired_at.trim():null;
          submitValues.publicationstatus = submitValues.publish ? 'published' : 'unpublished';
          actions.setSubmitting(false);
          submitForm(submitValues,actions.setErrors);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <>
            <Form className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1" noValidate onSubmit={handleSubmit}>
              <div className="pageHeader px-3 my-1 pt-1 user-select-none">
                <Row className="align-items-center min-h-51">
                  <Col>
                    <h1 className="fw-semibold h4 my-2">
                      <Link to={redirect} className="align-items-center d-inline-flex overflow-hidden text-dark text-decoration-none">
                        <span className="me-1 ms-n1">
                          <IoIosArrowBack size="1.68rem" />
                        </span> Proclamation
                      </Link>
                    </h1>
                  </Col>
                </Row>
              </div>
              <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
                <Card className="h-100 w-100 overflow-auto border-light-subtle pageContentInner">
                  <Card.Header className="bg-white fs-16 fw-semibold p-3 border-secondary">
                    <Card.Text className="px-xl-1">Proclamation Details</Card.Text>
                  </Card.Header>
                  <Card.Body className="p-xl-4">

                    <ViewTextHorizontal
                      label="Title"
                      setColunView={true}
                      value={values.proclamation_title}
                    />

                    <ViewTextHorizontal
                      label="User"
                      setColunView={true}
                      value={values.proclamation_user_name}
                    />

                    <ViewTextHorizontal
                      label="Category"
                      setColunView={true}
                      value={values.category}
                    />

                    <ViewTagsHorizontal
                      label="Tags"
                      setColunView={true}
                      value={values.tags}
                    />

                    <ViewTextHorizontal
                      label="Video File"
                      setColunView={true}
                      value={values.video}
                      isVideoHandler={isVideoHandler}
                    />

                    <ViewTextHorizontal
                      label="Message"
                      setColunView={true}
                      value={values.message}
                    />
                    {
                      values.publicationstatus == "published" ?
                        <>
                          {/* <ViewTextHorizontal
                            label="Status"
                            setColunView={true}
                            value={CapitalText(values.publicationstatus)}
                          /> */}
                          <ViewTextHorizontal
                            label="Expire Date"
                            setColunView={true}
                            value={ moment(values.expired_at).format(
                              formatData.dateFormats.commaSeparatedDateTime
                            )}
                          />
                        </>
                        : ''}

                      
                        <CustomSwitchHorizontal
                          label="Publish"
                          onClicked={() => {isPublishedStatus(); }}
                          value={publish}
                          name="publish"
                          setPublished={setPublished}
                          

                        />
                         <div className="right-col-120">
                          <FormDatesPicker
                            controlId="expired_at"
                            name="expired_at"
                            placeholder="Select date"
                            label="Expiry Date"
                            selected={values.expired_at}
                            onBlur={handleBlur}
                            error={errors.expired_at}
                            touched={touched.expired_at}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            errorsField={errors.expired_at}
                            value={values.expired_at ? values.expired_at : ''}
                          />
                        </div>
                        <div className="theme-form-horizontal pb-3">
                          <Row>
                            <Col sm="auto" className="left-col"></Col>
                            <Col sm="auto" className="right-col">
                              <Stack direction="horizontal" gap={3}>
                                <Button className="fw-semibold fs-13 text-white mw-90 my-1" variant="primary" type="submit" disabled={!values.publicationstatus}>Update</Button>
                                <Link to={redirect} className="btn btn-secondary text-dark fw-semibold fs-13 mw-90 my-1">Cancel</Link>
                              </Stack>
                            </Col>
                          </Row>
                        </div>

                  </Card.Body>
                </Card>
              </div>
            </Form>
            <PublishModal show={show} userName ={data.proclamation_user_name} onHide={() => { setFieldValue('publish', false); setPublished(false);setShow(false); }} onSubmit={() => { if(publish){setPublished(false);setFieldValue('publish', false);setShow(false);setFieldValue('expired_at', '')}else {setFieldValue('publish', true); setPublished(true);setShow(false);} }} value ={publish} />
          </>
        )}
      </Formik > : ''}
      <Loader isLoading={loading} />
      <ImageModal
        show={isAudioPopupOpen}
        handleClose={() => setIsAudioPopupOpen(false)}
        imageUrl={imagethumb} contentType='video' />

    </>
  )
}