export default {
    dateFormats: {
      commaSeparatedDateTime: "MM/DD/YYYY",
      hyphenSeparatedDate: "MM-DD-YYYY",
      databaseFormat: "YYYY-MM-DD",
      dateSearchFormat:"dd/MM/yyyy"
    },
    datetimeFormats: {
      commaSeparatedDateTime: "MM-DD-YYYY | hh:mm A",
      forwordSeparatedDateTime: "MM/DD/yyyy | HH:mm",
    },
    timeFormats: {
      timeIn24HoursFormat: "HH:mm:ss",
      timeIn12HoursFormat: "hh:mm A",
    },
  };