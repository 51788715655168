import { useEffect, useState } from "react"
import { Button, Card, Col, Form, Image, Modal, Row, Stack } from "react-bootstrap"
import { IoIosArrowBack, IoIosVideocam } from "react-icons/io"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Formik } from "formik"
import { PublicProclamationSchema } from "../../../../../../validations/Users"
import InputTextHorizontal from "../../../../../../components/common/InputTextHorizontal"
import CustomSelectHorizontal from "../../../../../../components/common/CustomSelectHorizontal"
import TextAreaHorizontal from "../../../../../../components/common/TextAreaHorizontal"
import TagsHorizontal from "../../../../../../components/common/TagsHorizontal"
import { edit, getCategory, getDetails } from "../../../../../../services/PproclamationService"
import { toast } from "react-toastify"
import { EditEntitiyMethod, handleFileUpload } from "../../../../../../helpers/helpersMethods"
import { USER_UPDATED_SUCCESS } from "../../../../../../validations/ValidationErrors"
import Loader from "../../../../../../components/common/Loader"
import ImageModal from '../../../../../../components/common/ImagePreview';

export const EditPublicProclamation = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [redirect, setRedirect] = useState<any>();
  const [data, setData] = useState<any>();
  const [categoryList, setCategoryList] = useState<any>([]);
  const [submitting, setSubmitting] = useState(false);
  const [imagethumb, setImagethumb] = useState<any>();
  const [isAudioPopupOpen, setIsAudioPopupOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  
  useEffect(() => {
    setLoading(true);
    if(id)
    {
      
      getDetails(id).then(
        (resp) => {
          setLoading(false);
          const res = resp?.data;
          setData(res);
          setImagethumb(res.asset_url_name);
          setRedirect(`/app/users/view/${res.user_id}`);
          selectList();
        },
        (error:any) => {
          setLoading(false);
          const err = error?.response?.data;
          if (err?.statusCode === 400) {
            toast.error(err.msg);
            navigate(`/app/users`);
          }
        }
      );
  }
  }, [id]);
  const selectList = async () => 
  {
    if(id)
    {
      await getCategory().then(
        (resp) => {
          setLoading(false);
          const res = resp?.data.record.map((obj: any) => {
            return { ...obj, name: obj.title,id: obj.title};
          });
          setCategoryList(res); 
        },
        (error:any) => {
          setLoading(false);
          const err = error?.response?.data;
          if (err?.statusCode === 400) {
            toast.error(err.msg);
            navigate(`/app/users`);
          }
        }
      );
    }
     
  };
  

  
  const handleFileChange = (event: any, fileType: string, setFieldValue: (field: string, value: any) => void) => {
    const file = event.target.files?.[0];
    try {
      const fileUrl = handleFileUpload(file, fileType);
      setFieldValue('asset_file', file);
      setImagethumb(fileUrl);
    } catch (error: any) {
      toast.error(error.message);
    }
  };
  
 
  /**
     * Editing Employees
     *
     * @param {*} value
     */
  const submitForm = (value: any) => {
    if (id && data) {
      
        value.tags = value.tags ? JSON.stringify(value.tags.map((tag: { id: string, text: string }) => tag.text)):'';
        EditEntitiyMethod(setSubmitting, edit, id, value, USER_UPDATED_SUCCESS, `/app/users/view/${data.user_id}`, navigate);
    }
  }

  const handleAudioPopupOpen = () => {
    setIsAudioPopupOpen(true);
  };
  
  // Function to handle closing the audio popup
  const handleAudioPopupClose = () => {
    setIsAudioPopupOpen(false);
  };
  return (
    <>
      {!loading && data && categoryList ? <Formik
        validationSchema={PublicProclamationSchema}
        initialValues={{
          proclamation_title: data ? data.proclamation_title : '',
          recipient_id: data ? data.recipient_id: '',
          category: data ? data.category : '',
          message: data ? data.message != null ? data.message : "" : "",
          tags:data.tags ? data.tags.map((obj:any,index:any) => ({id: obj,text: obj})) : '',
          //tags:data.tags ? Tag[] : '',
      }}
      onSubmit={(values, actions) =>{
         actions.setSubmitting(false);
         submitForm(values)
    }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
          setFieldValue
        }) => (
          <Form className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1" noValidate onSubmit={handleSubmit}>
            <div className="pageHeader px-3 my-1 pt-1 user-select-none">
              <Row className="align-items-center min-h-51">
                <Col>
                  <h1 className="fw-semibold h4 my-2">
                    <Link to={redirect} className="align-items-center d-inline-flex overflow-hidden text-dark text-decoration-none">
                      <span className="me-1 ms-n1">
                        <IoIosArrowBack size="1.68rem" />
                      </span> Users
                    </Link>
                  </h1>
                </Col>
              </Row>
            </div>
            <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
              <Card className="h-100 w-100 overflow-auto border-light-subtle pageContentInner">
                <Card.Header className="bg-white fs-16 fw-semibold p-3 border-secondary">
                  <Card.Text className="px-xl-1">Edit Public Proclamation Details</Card.Text>
                </Card.Header>
                <Card.Body className="p-xl-4">

                  <InputTextHorizontal
                    leftColSmall={true}
                    label="Title"
                    controlId="proclamation_title"
                    placeholder="Enter Title"
                    touched={touched.proclamation_title}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.proclamation_title}
                    value={values.proclamation_title}
                  />

                    <CustomSelectHorizontal
                    leftColSmall={true}
                    label="Category"
                    controlId="category"
                    placeholder="Select"
                    touched={touched.category}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.category}
                    value={values.category}
                    data={categoryList}
                  />
                  <TagsHorizontal
                    name="tags"
                    leftColSmall={true}
                    label="Tags"
                    value={values.tags}
                    setFieldValue={setFieldValue}
                    errorsField={errors.tags}
                    
                  />
                  <div className="theme-form-horizontal pb-3">
                    <Row>
                      <Col sm="auto" className="left-col left-col-sm">
                        <Stack direction="horizontal" gap={3} className="justify-content-between my-sm-2 py-sm-1">
                          <label className="fw-semibold">Video</label>
                        </Stack>
                      </Col>
                      <Col sm="auto" className="right-col">
                        <Row className="gx-3">
                          <Col xs="auto">
                            <div className="align-items-center border d-flex h-85 justify-content-center overflow-hidden position-relative rounded text-light w-85">
                              {!selectedFile && !imagethumb && (
                                <IoIosVideocam size={40} />
                              )}
                              {imagethumb && (
                                <div className="w-100 h-100 d-block cursor-pointer" onClick={handleAudioPopupOpen}>
                                  <span className="position-absolute start-50 text-white top-50 translate-middle" >
                                    <svg width={58} height={58} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 58">
                                      <defs>
                                        <filter id="videoIcon" width="58" height="58" x="0" y="0" filterUnits="userSpaceOnUse">
                                          <feOffset />
                                          <feGaussianBlur result="blur" stdDeviation="5" />
                                          <feFlood floodOpacity=".78" />
                                          <feComposite in2="blur" operator="in" />
                                          <feComposite in="SourceGraphic" />
                                        </filter>
                                      </defs>
                                      <g filter="url(#videoIcon)">
                                        <path fill="currentColor" d="M29.003 15.003a14 14 0 1 0 14 14 14 14 0 0 0-14-14Zm5.64 14.263-9.235 5.587a.3.3 0 0 1-.451-.263V23.416a.3.3 0 0 1 .451-.262l9.235 5.587a.309.309 0 0 1 0 .524Z" />
                                      </g>
                                    </svg>
                                  </span>
                                  <video width={85} height={85} src={imagethumb}  /> 
                                  {/* <video  src={imagethumb} width={85} height={85} ref={videoRef}></video>
                                  <canvas ref={canvasRef} style={{ display: 'none' }} /> */}
                                </div>
                              )}
                            </div>
                          </Col>
                          {/* <video src={videoUrl} ref={videoRef} onPlay={handlePlay} /> */}
                          <Col xs className="mw-1">
                            <Form.Group controlId="formFile" className="mb-3">
                              <Stack direction="horizontal" gap={2}>
                                <div className="position-relative">
                                  <Form.Control type="file" className="bottom-0 end-0 opacity-0 position-absolute start-0 top-0 z-1" onChange={(event) => handleFileChange(event, 'video', setFieldValue)} />
                                  <Button className="fw-semibold fs-13 text-dark mw-90" variant="secondary">Upload</Button>
                                  
                                </div>
                                {selectedFile && (
                                  <Form.Text className="my-1 text-truncate" muted>{selectedFile.name}</Form.Text>
                                )}
                              </Stack>
                              <div className="fs-13 fst-italic fw-semibold text-dark text-opacity-50 my-1">Max 15MB video</div>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                  </div>

                  <TextAreaHorizontal
                    leftColSmall={true}
                    controlId="message"
                    label="Message"
                    placeholder="Enter Message"
                    touched={touched.message}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.message}
                    value={values.message}
                  />

                  <div className="theme-form-horizontal py-3">
                    <Row>
                      <Col sm="auto" className="left-col left-col-sm"></Col>
                      <Col sm="auto" className="right-col">
                        <Stack direction="horizontal" gap={3}>
                          <Button className="fw-semibold fs-13 text-white mw-90 my-1" variant="primary" type="submit">Update</Button>
                          <Link to={redirect} className="btn btn-secondary text-dark fw-semibold fs-13 mw-90 my-1">Cancel</Link>
                        </Stack>
                      </Col>
                    </Row>
                  </div>

                </Card.Body>
              </Card>
            </div>
          </Form>
        )}
       </Formik >:''}
      <Loader isLoading={loading || submitting} />
      <ImageModal
            show={isAudioPopupOpen}
            handleClose={() => setIsAudioPopupOpen(false)}
            imageUrl={imagethumb} contentType='video'/>
   
    </>

  )
}
