import React, { useEffect, useState } from 'react';

import { Button, Card, Col, Form, Image, Row, Stack } from "react-bootstrap"
import { IoIosArrowBack } from "react-icons/io"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Formik } from "formik"
import { RecipientSchema } from "../../../../../../validations/Users"
import InputTextHorizontal from "../../../../../../components/common/InputTextHorizontal"
import CustomSelectHorizontal from "../../../../../../components/common/CustomSelectHorizontal"
import TextAreaHorizontal from "../../../../../../components/common/TextAreaHorizontal"
import { FaImage } from 'react-icons/fa';
import { recipientListData } from '../../../../../../services/userService';
import { toast } from 'react-toastify';
import { edit, getDetails, relationListData } from '../../../../../../services/recipientService';
import Loader from '../../../../../../components/common/Loader';
import { EditEntitiyMethod } from '../../../../../../helpers/helpersMethods';
import { ORGAN_UPDATED_SUCCESS } from '../../../../../../validations/ValidationErrors';
import { nameBasedProtectedRoutes } from '../../../../../../router/protected';
import DeleteModal from '../../../../../../components/common/DeleteModal';
import CustomSelectHorizontalPhone from '../../../../../../components/common/CustomSelectHorizontalPhone';
import { countries } from 'country-list-json';

export const EditRecipient = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [redirect, setRedirect] = useState<any>();
  const [data, setData] = useState<any>();
  const [relationList, setRelationList] = useState<any>([]);

  useEffect(() => {
    setLoading(true);
    if (id) {
      getDetails(id).then(
        (resp) => {
          setLoading(false);
          const res = resp?.data;
          setData(res);
          setRedirect(`/app/users/view/${res.user_id}`);
          selectList();
        },
        (error: any) => {
          setLoading(false);
          const err = error?.response?.data;
          if (err?.statusCode === 400) {
            toast.error(err.msg);
            navigate(`/app/users`);
          }
        }
      );
    }
  }, [id]);
  const selectList = async () => {
    if (id) {
      try {
        const resp = await relationListData();
        const res = resp?.data.record.map((obj: any) => {
          return { ...obj, name: obj.title,id: obj.title};
        });
        setLoading(false);
        setRelationList(res);
        // Call selectList() here if needed
      } catch (error) {
        setLoading(false);
      }
    }
  };

  /**
     * Editing Orgon Doner
     *
     * @param {*} value
     */
  const submitForm = (value: any) => {
    if (id && data) {
      console.log(value);
      EditEntitiyMethod(setLoading, edit, id, value, ORGAN_UPDATED_SUCCESS, redirect, navigate);
    }
  }


  return (
    <>
      {data ? <Formik
        validationSchema={RecipientSchema}
        initialValues={{
          name: data ? data.name : '',
          relation: data ? data.relation : '',
          email: data ? data.email != null ? data.email : "" : "",
          phone_no: data ? data.phone_no : '',
          phone_country_code: data ? data.phone_country_code : '+1',
        }}
        onSubmit={(values, actions) => {
          submitForm(values)
        }}

      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
          setFieldValue
        }) => (
          <Form className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1" noValidate onSubmit={handleSubmit}>
            <div className="pageHeader px-3 my-1 pt-1 user-select-none">
              <Row className="align-items-center min-h-51">
                <Col>
                  <h1 className="fw-semibold h4 my-2">
                    <Link to={redirect} className="align-items-center d-inline-flex overflow-hidden text-dark text-decoration-none">
                      <span className="me-1 ms-n1">
                        <IoIosArrowBack size="1.68rem" />
                      </span> Users
                    </Link>
                  </h1>
                </Col>
              </Row>
            </div>
            <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
              <Card className="h-100 w-100 overflow-auto border-light-subtle pageContentInner">
                <Card.Header className="bg-white fs-16 fw-semibold p-3 border-secondary">
                  <Card.Text className="px-xl-1">Edit Recipient</Card.Text>
                </Card.Header>
                <Card.Body className="p-xl-4">

                  <InputTextHorizontal
                    leftColSmall={true}
                    label="Name"
                    controlId="name"
                    placeholder="Enter name"
                    touched={touched.name}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.name}
                    value={values.name}
                  />
                  <InputTextHorizontal
                    leftColSmall={true}
                    label="Email"
                    controlId="email"
                    placeholder="Enter email"
                    touched={touched.email}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.email}
                    value={values.email}
                  />
                  <CustomSelectHorizontalPhone
                    leftColSmall={true}
                    label="Country Code"
                    controlId="phone_country_code"
                    placeholder="Select"
                    touched={touched.phone_country_code}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.phone_country_code}
                    value={values.phone_country_code}
                    data={countries}
                  />
                  <InputTextHorizontal
                    leftColSmall={true}
                    label="Phone"
                    controlId="phone_no"
                    placeholder="Enter Phone"
                    touched={touched.phone_no}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.phone_no}
                    value={values.phone_no}
                  />
                  <CustomSelectHorizontal
                    leftColSmall={true}
                    label="Relationship"
                    controlId="relation"
                    placeholder="Select"
                    touched={touched.relation}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.relation}
                    value={values.relation}
                    data={relationList}
                  />



                  <div className="theme-form-horizontal py-3">
                    <Row>
                      <Col sm="auto" className="left-col left-col-sm"></Col>
                      <Col sm="auto" className="right-col">
                        <Stack direction="horizontal" gap={3}>
                          <Button className="fw-semibold fs-13 text-white mw-90 my-1" variant="primary" type="submit">Update</Button>
                          <Link to={redirect} className="btn btn-secondary text-dark fw-semibold fs-13 mw-90 my-1">Cancel</Link>
                          {/* <Button className="fw-semibold fs-13 text-white mw-90 my-1" variant="danger" onClick={()=>handleDeleteClick(id)} >Delete</Button> */}
                        </Stack>
                      </Col>
                    </Row>
                  </div>

                </Card.Body>
              </Card>
            </div>
          </Form>
        )}
      </Formik > : ''}
      <Loader isLoading={loading} />

    </>

  )
}
