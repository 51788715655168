import {axios} from "../services/axios";
import { adminApi } from "../api";


export type FaqDTO = {
  question: string,
  answer: string,
  status: string,
  order_sequence:number
};



/**
 * Faq List
 * @date 04/05/2023 - 13:21:19
 *
 * @async
 * @param {*} params
 * @returns {unknown}
 */
export const getList = async (params:any) => {
    return await axios.get(`${adminApi.faqList}`, { params });
  };

  /**
   * Add Faq
   * @date 04/05/2023 - 16:19:09
   *
   * @async
   * @param {FaqDTO} data
   * @returns {unknown}
   */
  export const addFaq = async (data: FaqDTO) => {
    return axios.post(`${adminApi.faqAdd}`, data);
  };

  /**
   * Faq Edit
   * @date 04/05/2023 - 17:54:51
   *
   * @async
   * @param {string} id
   * @returns {Promise<any>}
   */
  export const getDetails = async (id: string): Promise<any> => {
    return axios.get(`${adminApi.faqDetails}/${id}`);
  };

  /**
   * Faq Edit
   * @date 04/05/2023 - 17:54:51
   *
   * @async
   * @param {string} id
   * @returns {Promise<any>}
   */
  export const editFaq = async (id: string,data: FaqDTO): Promise<any> => {
    return axios.patch(`${adminApi.faqEdit}/${id}`,data);
  };
 
  export const faqDelete = async (id: string): Promise<any> => {
    return axios.get(`${adminApi.faqDelete}/${id}`);
  };