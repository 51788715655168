import { Field } from 'formik';
import { FC } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

interface ICustomRadioHorizontal {
    label?: string;
    handleChange?: any;
    handleBlur?: any;
    errorsField?: any;
    touched?: any;
    value?: any;
    data?: any;
    name?: any;
    inline?: boolean;
    setColunView?: boolean;
    leftColSmall?: boolean;
}

/**
 * Custom Radio Horizontal Reusable Component
 * @date 4/13/2023 - 7:09:25 PM
 *
 * @param {{  label: any; handleChange: any; handleBlur: any; errorsField: any; touched: any; value: any; data: any; placeholder: any; id: any; rightIcon: any; size: any; name: any; }} {
    controlId, label, handleChange, handleBlur, errorsField, touched, value, data, placeholder, id, rightIcon, size, name
}
 * @returns {*}
 */

const CustomCheckbox: FC<ICustomRadioHorizontal> = ({
    label, handleChange, handleBlur, errorsField, touched, value, data, name, inline, setColunView, leftColSmall
}) => {
    return (
        <Form.Group className="theme-form-horizontal pb-3">
            <Row>
                <Col sm="auto" className={`left-col ${leftColSmall ? 'left-col-sm' : ''}`}>
                    {label ? (
                        <Stack direction="horizontal" gap={2} className="justify-content-sm-between py-sm-1">
                            <Form.Label className="lh-sm">{label}</Form.Label>
                            {setColunView ? <span className="colun-content">:</span> : null}
                        </Stack>
                    ) : null}
                </Col>
                <Col sm>
                    <div className="position-relative">
                    <Field name={name}>
                    {({ field, form }: any) => (
                        <Form.Check
                            type="checkbox"
                            className="me-4 pe-1 mb-2 user-select-none theme-checkbox"
                            id={name}
                            label=""
                            checked={field.value}
                            onChange={() => form.setFieldValue(name, !field.value)}
                            isInvalid={false}
                            feedback={form.errors[name]}
                            name={name}
                        />
                    )}
                </Field>
                        <Form.Control.Feedback type="invalid" className={`${!!errorsField && touched ? 'd-block' : null}`}>
                            {errorsField}
                        </Form.Control.Feedback>
                    </div>
                </Col>
            </Row>
        </Form.Group>
    );
}

export default CustomCheckbox