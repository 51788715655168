import {axios} from "../services/axios";
import { adminApi } from "../api";
export type charityDTO = {
organ_name: string
};



/**
 * Organ Donar List
 * @date 04/05/2023 - 13:21:19
 *
 * @async
 * @param {*} params
 * @returns {unknown}
 */
export const getList = async (params:any) => {
    return await axios.get(`${adminApi.organDonationList}/${params.userid}`, { params });
  };

  /**
   * Add Organ Donar
   * @date 04/05/2023 - 16:19:09
   *
   * @async
   * @param {charityDTO} data
   * @returns {unknown}
   */
  export const addCharit = async (data: charityDTO) => {
    const config = {headers: {"content-type": "multipart/form-data"}};
    return axios.post(`${adminApi.charityAdd}`, data,config);
  };

  /**
   * Organ Donar Get
   * @date 04/05/2023 - 17:54:51
   *
   * @async
   * @param {string} id
   * @returns {Promise<any>}
   */
  export const getDetails = async (id: string): Promise<any> => {
    return axios.get(`${adminApi.organDonationDetails}/${id}`);
  };
  

  /**
   * Organ Donar Edit
   * @date 04/05/2023 - 17:54:51
   *
   * @async
   * @param {string} id
   * @returns {Promise<any>}
   */
  export const edit = async (id: string,data: charityDTO): Promise<any> => {
    const config = {headers: {"content-type": "multipart/form-data"}};
    return axios.post(`${adminApi.organDonationEdit}/${id}`,data,config);
  };

  /**
   * delete Organ
   * @date 25/05/2023 - 18:58:35
   *
   * @async
   * @param {string} id
   * @returns {Promise<any>}
   */
  export const deleteOrgan = async (id: string): Promise<any> => {
    return axios.delete(`${adminApi.organDonationDelete}/${id}`);
  };
 
  