import { Route, Routes } from 'react-router-dom';
import { AllProclamation } from './List/List';
import { EditProclamation } from './Edit/Edit';
import { AllPendingProclamation } from './PendingProclamation/List/List';
import { ViewPendingProclamation } from './PendingProclamation/View/View';

export const Proclamation = () => {
    return (
        <Routes>
            <Route path="/" element={<AllProclamation />} />
            <Route path="/list" element={<AllProclamation />} />
            <Route path="/edit/:id" element={<EditProclamation />} />
            <Route path="/pending-proclamation" element={<AllPendingProclamation />} />
            <Route path="/pending-proclamation/view/:id" element={<ViewPendingProclamation />} />
        </Routes>
    )
};