import { useEffect, useState } from 'react'
import { Button, Card, Col, Row, Stack } from 'react-bootstrap'
import DataGrid from '../../components/common/dataGrid/DataGrid';
import ActionCell from '../../components/common/dataGrid/ActionCell';
import constantValues from '../../constants/constantValues';
import { getList,DownloadSubscriptionList } from '../../services/subscriptionService';
import { toast } from 'react-toastify';
import { truncateText } from '../../helpers/helpersMethods';
import Loader from '../../components/common/Loader';
import moment from 'moment';
import formatData from '../../constants/formatData';
import {getPlanList } from '../../services/planService';
import { DOWNLOAD_SUCCESS } from '../../validations/ValidationErrors';


export const Subscriptions = () => {
  const [filterData, setFilterData] = useState({ search: '',searchByCreatedDate:'',sortByStatus:'' });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(constantValues.rowPerPage);
  const [totalPages, setTotalPages] = useState(0);
  const [totalReults, setTotalResults] = useState(0);
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [exportList, setExportList] = useState<boolean>(false);
  const [plan, setPlan] = useState([]);

  useEffect(() => {
    const searchData = filterData ? filterData.search : '';
    const sortByStatus = filterData ? filterData.sortByStatus : '';
    if (searchData.length > 1  || sortByStatus.length >= 1) {
      setLoading(false);
      List({
        page: currentPage,
        limit: rowPerPage,
        sortColumn,
        sortDirection,
        search: searchData,
        searchByStatus: sortByStatus
      });
    } else {
      setLoading(false);
      List({ page: currentPage, limit: rowPerPage, sortColumn,sortDirection});
    }
  }, [filterData, sortColumn, sortDirection, rowPerPage,currentPage]);
  useEffect(()=>{
    setCurrentPage(1);
  },[filterData,sortColumn,sortDirection])

  useEffect(()=>{
    FetchPlanList();
  },[])

  useEffect(() => {
    if(exportList){
    const searchData = filterData ? filterData.search : '';
    const sortByStatus = filterData ? filterData.sortByStatus : '';
    if (searchData.length > 1  || sortByStatus.length >= 1) {
      setLoading(false);
      downloadList({
        page: currentPage,
        limit: rowPerPage,
        sortColumn,
        sortDirection,
        search: searchData,
        searchByStatus: sortByStatus
      });
    } else {
      setLoading(false);
      downloadList({ page: currentPage, limit: rowPerPage, sortColumn,sortDirection});
    }
  }
  }, [exportList]);

  const downloadList = async (params:any) => {
    setLoading(true);
    toast.dismiss();
    DownloadSubscriptionList({ ...params, search: params.search?.trim() }).then(
      (response) => {
        toast.success(DOWNLOAD_SUCCESS);
        const url = response.data?.download_file;
        const link = document.createElement('a');
        link.href = url;
        link.download = 'file.csv'; // Specify the desired file name with extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoading(false);
        toast.success(DOWNLOAD_SUCCESS);
        setExportList(false);
      },(error: any) => {
        const err = error?.response?.data;
        setLoading(false);
        if (err?.statusCode === 400) {
          toast.error(err.msg);
        }

      }
    );
  };
  /**
   * Description fetch user list
   * @date 19/04/2023 - 12:10:33
   *
   * @async
   * @param {*} params
   * @returns {*}
   */
  const List = async (params:any) => {
    setLoading(true);
    getList({ ...params, search: params.search?.trim() }).then(
      (response) => {
        setData(response.data?.record);
        setTotalPages(response.data?.totalPages);
        setTotalResults(response.data?.totalResults);
        setLoading(false);
      },(error: any) => {
        const err = error?.response?.data;
        setLoading(false);
        if (err?.statusCode === 400) {
          toast.error(err.msg);
        }

      }
    );
  };

  const FetchPlanList = async () => {
    try {
      const resp = await getPlanList();
      const res = resp?.data;
      const planList = res.map((plan: any) => ({
        id:plan.id,
        name: plan.plan_name,
      }));
      setPlan(planList);
      setLoading(false);
     
    } catch (error: any) {
      setLoading(false);
      const err = error?.response?.data;
      if (err?.statusCode === 400) {
        toast.error(err.msg);
      }
    }
  };

const columns = [
  {
    id:'id',
    name: '#',
    sortable: true,
    center: true,
    selector: (row:any, index:any) => (currentPage - 1) * rowPerPage + index + 1,
    width: '60px',
  },
  {
    id:'user_name',
    selector: (row: any) => row.userdetail_name,
    name: 'User Name',
    sortable: true,
    minWidth: '150px',
    cell: (row: any) => {return truncateText(row.userdetail_name)}
  },
  {
    id:'plan_name',
    selector: (row: any) => row.plandetail_plan_name,
    name: 'Plan',
    sortable: true,
    minWidth: '150px',
    cell: (row: any) => {return truncateText(row.plandetail_plan_name)}
  },
  {
    id:'plan_price',
    selector: (row: any) => row.plandetail_plan_price,
    name: 'Amount',
    sortable: true,
    minWidth: '150px',
    cell: (row: any) => {return truncateText(row.plandetail_plan_price)}
  },
  {
    id:'updated_at',
    selector: (row: any) => row.updated_at,
    name: 'Subscription Date',
    sortable: true,
    minWidth: '150px',
    cell: (row:any) =>
    moment(row.updated_at).format(
      formatData.dateFormats.commaSeparatedDateTime
    ),
  }
];


  
  return (
    <>
    {plan && data ?
    <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
      <div className="pageHeader px-3 my-1 pt-1 user-select-none">
        <Row className="align-items-center min-h-51">
          <Col>
            <h1 className="fw-semibold h4 my-2">Subscriptions</h1>
          </Col>
          <Col xs="auto">
            <Stack direction="horizontal">
              <Button variant="primary" className="mw-120 my-2 fw-semibold fs-13 text-white"onClick={()=>{setExportList(true)}}>Export</Button>
            </Stack>
          </Col>
        </Row>
      </div>
      <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
        <Card className="h-100 p-3 w-100 border-light-subtle pageContentInner">
          <div className="d-flex flex-column h-100 rounded-0 dataGridListMain">
          
            <DataGrid
              columns={columns}
              data={data}
              viewData={plan}
              FilterSubHeader={true}
              filterData={filterData}
              setFilterData={setFilterData}
              filterByDateRange={false}
              pagination={true}
              totalPages={totalPages}
              totalRecords={totalReults}
              rowPerPage={rowPerPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              sortColumn={sortColumn}
              setSortColumn = {setSortColumn}
              sortDirection={sortDirection}
              setSortDirection = {setSortDirection}
            />
          </div>
        </Card>
      </div>
    </div>: ''}
    <Loader isLoading={loading} />
    
    </>
  )
}
