import { axios } from "../services/axios";
import { adminApi } from "../api";
export type proclamationDTO = {
  proclamation_title: string;
  recipient_id: string;
};
export type proclamationPublishDTO = {
  publicationstatus: string;
  expired_at: string;
};
export type proclamationPendingDTO = {
  expired_at: string;
  status: string;
  reason: string;
};

/**
 * About us List
 * @date 04/05/2023 - 13:21:19
 *
 * @async
 * @param {*} params
 * @returns {unknown}
 */
export const getList = async (params: any) => {
  return await axios.get(`${adminApi.proclamationList}/${params.userid}`, {
    params,
  });
};
/**
 * About us List
 * @date 04/05/2023 - 13:21:19
 *
 * @async
 * @param {*} params
 * @returns {unknown}
 */
export const getProclamationGlobalList = async (params: any) => {
  return await axios.get(`${adminApi.proclamationGlobalList}`, {
    params,
  });
};

/**
 * About us Get
 * @date 04/05/2023 - 17:54:51
 *
 * @async
 * @param {string} id
 * @returns {Promise<any>}
 */
export const getDetails = async (id: string): Promise<any> => {
  return axios.get(`${adminApi.proclamationDetails}/${id}`);
};
/**
 * Proclamation Category
 * @date 28/05/2023 - 17:35:21
 *
 * @async
 * @returns {Promise<any>}
 */
export const getCategory = async (): Promise<any> => {
  return axios.get(`${adminApi.proclamationCategory}`);
};

/**
 * About us Edit
 * @date 04/05/2023 - 17:54:51
 *
 * @async
 * @param {string} id
 * @returns {Promise<any>}
 */
export const edit = async (id: string, data: proclamationDTO): Promise<any> => {
  const config = { headers: { "content-type": "multipart/form-data" } };
  return axios.post(`${adminApi.proclamationEdit}/${id}`, data, config);
};

/**
 * Delete
 * @date 27/05/2023 - 17:50:59
 *
 * @async
 * @param {string} id
 * @returns {Promise<any>}
 */
export const deleteproclamation = async (id: string): Promise<any> => {
  return axios.delete(`${adminApi.proclamationDelete}/${id}`);
};


export const publishProclamation = async (id: string,data: proclamationPublishDTO): Promise<any> => {
  return axios.put(`${adminApi.proclamationPublish}/${id}`,data);
};



/**
 * Proclamation pending List
 * @date 09/06/2023 - 12:29:32
 *
 * @async
 * @param {*} params
 * @returns {unknown}
 */
export const getProclamationPendingGlobalList = async (params: any) => {
  return await axios.get(`${adminApi.proclamationGlobalPendingList}`, {
    params,
  });
};

/**
 * Pending proclamation Approve
 * @date 09/06/2023 - 18:29:25
 *
 * @async
 * @param {string} id
 * @param {proclamationPublishDTO} data
 * @returns {Promise<any>}
 */
export const proclamationApprove = async (id: string,data: proclamationPublishDTO): Promise<any> => {
  return axios.put(`${adminApi.proclamationGlobalApprove}/${id}`,data);
};