import {axios} from "../services/axios";
import { adminApi } from "../api";


export type ChangePasswordDTO = {
  currentPassword: string,
  newPassword: string,
  confirmPassword: string
};
export type ChangeProfileDTO = {
  name: string,
  email: string
};

/**
 * Change Password
 * @date 02/05/2023 - 16:16:34
 *
 * @param {string} id
 * @param {ChangePasswordDTO} data
 * @returns {Promise<any>}
 */
export const changePassword = (id: string, data: ChangePasswordDTO): Promise<any> => {
    return axios.post(`${adminApi.changePassword}/${id}`, data);
  };
export const changeProfile = (id: string, data: ChangeProfileDTO): Promise<any> => {
  const config = {headers: {"content-type": "multipart/form-data"}};
    return axios.post(`${adminApi.changeProfile}/${id}`, data,config);
  };

