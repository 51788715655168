import { useEffect, useState } from 'react'
import { Card, Col, Row, Stack } from 'react-bootstrap'
import DataGrid from '../../../components/common/dataGrid/DataGrid';
import ActionCell from '../../../components/common/dataGrid/ActionCell';
import { Link, useNavigate } from 'react-router-dom';
import { getList } from '../../../services/charityService';
import Loader from '../../../components/common/Loader';
import constantValues from '../../../constants/constantValues';
import { truncateText } from '../../../helpers/helpersMethods';
import DeleteModal from '../../../components/common/DeleteModal';
import { toast } from 'react-toastify';
import { nameBasedProtectedRoutes } from '../../../router/protected';


const viewData = [
  { id: 'active', name: 'Enable' },
  { id: 'inactive', name: 'Disable' }
];

export const AllADS = () => {
  const [filterData, setFilterData] = useState({ search: '',searchByCreatedDate:'',sortByStatus:'' });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(constantValues.rowPerPage);
  const [totalPages, setTotalPages] = useState(0);
  const [totalReults, setTotalResults] = useState(0);
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("asc");
  const [deletestatus, setDeletestatus] = useState(false);
  const [deleteRecordId, setDeleteRecordId] = useState();
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const searchData = filterData ? filterData.search : '';
    const searchByCreatedDate = filterData ? filterData.searchByCreatedDate : '';
    const sortByStatus = filterData ? filterData.sortByStatus : '';
    if (searchData.length > 1 || searchByCreatedDate.length > 1 || sortByStatus.length > 1) {
      setLoading(false);
      List({
        page: currentPage,
        limit: rowPerPage,
        sortColumn,
        sortDirection,
        search: searchData,
        searchByCreatedDate,
        searchByStatus: sortByStatus
      });
    } else {
      setLoading(false);
      List({ page: currentPage, limit: rowPerPage, sortColumn,sortDirection});
    }
  }, [filterData, sortColumn, sortDirection, rowPerPage,currentPage]);
  useEffect(()=>{
    setCurrentPage(1);
  },[filterData])

  
 

  /**
   * Description fetch user list
   * @date 19/04/2023 - 12:10:33
   *
   * @async
   * @param {*} params
   * @returns {*}
   */
  const List = async (params:any) => {
    setLoading(true);
    getList({ ...params, search: params.search?.trim() }).then(
      (response) => {
        setLoading(false);
        setData(response.data?.record);
        setTotalPages(response.data?.totalPages);
        setTotalResults(response.data?.totalResults);
        
      }
    );
  };
  const columns = [
    {
      id:'id',
      name: '#',
      sortable: true,
      center: true,
      selector: (row:any, index:any) => (currentPage - 1) * rowPerPage + index + 1,
      width: '60px',
    },
    {
      id:'asset_url_name',
      selector: (row: any) => row.asset_url_name,
      name: 'Image',
      sortable: false,
      minWidth: '150px',
      cell: (row: any) =>
      {
        if(row.asset_url_name)
        {
          return <div className="position-relative">
          <img src={row.asset_url_name} alt="Charity Image" width={30} height={30} className="border border-light rounded-1 rounded-sm" />
        </div>;
        }
      }
      
    },
    {
      id:'charity_title',
      selector: (row: any) => row.charity_title,
      name: 'Name',
      sortable: true,
      minWidth: '150px',
      cell: (row: any) => {return truncateText(row.charity_title)}
    },
    {
      id:'ein',
      selector: (row: any) => row.ein,
      name: 'Ein',
      sortable: true,
      minWidth: '150px',
      cell: (row: any) => {return truncateText(row.ein)}
    },
    {
      id:'category',
      selector: (row: any) => row.category,
      name: 'Category',
      sortable: true,
      minWidth: '150px',
      cell: (row: any) => {return truncateText(row.category)}
    },
   
      {
        id:'status',
        selector: (row: any) => row.status,
        name: 'Status',
        sortable: true,
        minWidth: '150px',
        cell: (row:any) =>
        {
          const statusText = row.status == 'active' ? 'Enable' : 'disable';
          return <div className="text-capitalize">{statusText}</div>;
        },
      },
      {
        id:'action',
        selector: (row: any) => row.action,
        name: 'Action',
        width: '100px',
        center: true,
        button: true,
        cell: (row: any) =>
        {
         
          return (<ActionCell controlId="charity" isViewEnable={true}
         isEditEnable={true} isDeleteEnable={false}
          redirectID={row.id}/>);
           
        }
      },
    
  
  ];
  return (
    <>
    <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
      <div className="pageHeader px-3 my-1 pt-1 user-select-none">
        <Row className="align-items-center min-h-51">
          <Col>
            <h1 className="fw-semibold h4 my-2">Charity</h1>
          </Col>
          <Col xs="auto">
            <Stack direction="horizontal">
              <Link to={nameBasedProtectedRoutes.Addcharity.path} className="btn btn-primary my-2 fw-semibold fs-13 text-white mw-120">Add Charity</Link>
            </Stack>
          </Col>
        </Row>
      </div>
      <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
        <Card className="h-100 p-3 w-100 border-light-subtle pageContentInner">
          <div className="d-flex flex-column h-100 rounded-0 dataGridListMain">
          <DataGrid
              columns={columns}
              data={data}
              viewData={viewData}
              FilterSubHeader={true}
              filterData={filterData}
              setFilterData={setFilterData}
              pagination={true}
              totalPages={totalPages}
              totalRecords={totalReults}
              rowPerPage={rowPerPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              sortColumn={sortColumn}
              setSortColumn = {setSortColumn}
              sortDirection={sortDirection}
              setSortDirection = {setSortDirection}
            />
          </div>
        </Card>
      </div>
    </div>
    <Loader isLoading={loading} />
    </>
  )
}
