import { Button, Card, Col, Form, Row, Stack } from "react-bootstrap"
import { IoIosArrowBack } from "react-icons/io"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Formik } from "formik"
import ViewTextHorizontal from "../../../components/common/ViewTextHorizontal";
import ViewTagsHorizontal from "../../../components/common/ViewTagsHorizontal";
import { nameBasedProtectedRoutes } from "../../../router/protected";
import { useEffect, useState } from "react";
import { getDetails } from "../../../services/adsService";
import { toast } from "react-toastify";
import Loader from "../../../components/common/Loader";
import moment from "moment";
import formatData from "../../../constants/formatData";

export const View = () => {
  const redirect = nameBasedProtectedRoutes.ads.path;
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    if (id) {
      getDetails(id).then(
        (resp:any) => {
          const res = resp?.data;
          setData(res);
          setTimeout(() => {
            setLoading(false);

          }, 1000);
        },
        (error: any) => {
          const err = error?.response?.data;
          setLoading(false);
          if (err?.statusCode === 400) {
            toast.error(err.msg);
            navigate(nameBasedProtectedRoutes.ads.path);
          }

        }
      );
    }

  }, [id]);

  return (
    <>
      {!loading && data ?
          <Form className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1" noValidate>
            <div className="pageHeader px-3 my-1 pt-1 user-select-none">
              <Row className="align-items-center min-h-51">
                <Col>
                  <h1 className="fw-semibold h4 my-2">
                    <Link to={redirect} className="align-items-center d-inline-flex overflow-hidden text-dark text-decoration-none">
                      <span className="me-1 ms-n1">
                        <IoIosArrowBack size="1.68rem" />
                      </span> Manage Ads
                    </Link>
                  </h1>
                </Col>
              </Row>
            </div>
            <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
              <Card className="h-100 w-100 overflow-auto border-light-subtle pageContentInner">
                <Card.Header className="bg-white fs-16 fw-semibold p-3 border-secondary">
                  <Card.Text className="px-xl-1">Ads Details</Card.Text>
                </Card.Header>
                <Card.Body className="p-xl-4">

                  <ViewTextHorizontal
                    label="Title"
                    setColunView={true}
                    value={data.title}
                  />

                  <ViewTextHorizontal
                    label="Owner"
                    setColunView={true}
                    value={data.owner}
                  />
                  <ViewTagsHorizontal
                      label="Tags"
                      setColunView={true}
                      value={data.tags}
                    />
                   <ViewTextHorizontal
                    label="Message"
                    setColunView={true}
                    value={data.message}
                  />
                  <ViewTextHorizontal
                    label="Publish Date"
                    setColunView={true}
                    value={data.publish_date ? moment(data.publish_date).format(formatData.dateFormats.commaSeparatedDateTime):''}
                  />
                  <ViewTextHorizontal
                    label="Status"
                    setColunView={true}
                    value={data.status == 'active' ? 'Enable' : 'disable'}
                    style={{ textTransform: 'capitalize' }}
                  />
                  <div className="theme-form-horizontal pb-3">
                    <Row>
                      <Col sm="auto" className="left-col"></Col>
                      <Col sm="auto" className="right-col">
                        <Stack direction="horizontal" gap={3}>
                          <Link to={redirect} className="btn btn-primary text-white fw-semibold fs-13 mw-90 my-1 ">Back</Link>
                        </Stack>
                      </Col>
                    </Row>
                  </div>

                </Card.Body>
              </Card>
            </div>
          </Form>
    
     : ''}
      <Loader isLoading={loading} />
    </>
  )
}