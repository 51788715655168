import { FC, useEffect, useState } from 'react';
import { Badge, Button, Container, Dropdown, Image, Nav, Navbar } from 'react-bootstrap';
import { FaAngleDown, FaBell } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import './header.scss';
import Logo from '../../../assets/images/logo.svg';
import storage from '../../../helpers/storage';
import { logout } from '../../../services/authService';
import {nameBasedProtectedRoutes} from '../../../router/protected';
import { GetEntitiyByIDMethod } from '../../../helpers/helpersMethods';
import { getUserInfo } from '../../../services/userService';
interface IHeader {
  isActiveSidebar: boolean;
  toggleSidebarButton?: any;
}


/**
 * Header Sidebar & Toggle Manager
 * @date 4/12/2023 - 12:56:12 PM
 *
 * @param {{ isActiveSidebar: any; toggleSidebarButton: any; }} {
  isActiveSidebar, toggleSidebarButton
}
 * @returns {*}
 */

export const Header: FC<IHeader> = ({
  isActiveSidebar, toggleSidebarButton
}) => {
  const navigate = useNavigate()
  const [isActive, setIsActive] = useState(false);
  const [loading, setLoading] = useState(false);
  
const logOut = async () => {
    setLoading(true);
    localStorage.removeItem('MYLO_token');
    localStorage.removeItem('user-info');
    navigate('/');
    setLoading(false);
}
useEffect(()=>{
    const getUserData =  storage.getData("user-info");
    if(getUserData)
    {
      setTimeout(() => {
        const userId = getUserData.id;
        if(userId)
        {
          getUserInfo(userId).then(
            (resp) => {
              const res = resp?.data;
              storage.setData("user-info", res);
            },
            (error:any) => {
              
            }
          );
        }
      }, 5000);
        
    }
 });

  const UserProfile = "https://dummyimage.com/40x40.png";
  const userName = "John Due";
  const getUserData =  storage.getData("user-info");

  return (
    <Navbar bg="white" variant="light" className="py-0 px-md-1 shadow-sm">
      <Container fluid className="px-3 mh-60">
        <Button onClick={toggleSidebarButton} variant="link" className="align-items-center d-flex justify-content-center navMenuBtn p-0">
          <span className={`bg-dark d-inline-block menuTrigger position-relative text-center ${isActiveSidebar ? 'active' : ''}`}></span>
        </Button>
        <Link to="/" className="d-xl-none mx-2 mx-md-3 px-1">
          <Image className="img-fluid" src={Logo} alt="Logo" width={80} height={32} />
        </Link>
        <Nav className="ms-auto align-items-center">
          {/* <Dropdown className="notificationDropdown me-md-2 me-1">
            <Dropdown.Toggle variant="link" id="dropdown-notification" className="fs-14 fw-semibold text-decoration-none position-relative rounded-circle d-inline-flex align-items-center justify-content-center text-light p-2">
              <FaBell size={22} />
              <Badge bg="danger" pill className="align-items-center border border-white customBadge d-flex fs-11 justify-content-center position-absolute p-1 start-100 top-0 mt-1">5
                <span className="visually-hidden">unread messages</span>
              </Badge>
            </Dropdown.Toggle>

            <Dropdown.Menu align="end" className="fs-14 shadow-sm px-3">
              Coming soon...
            </Dropdown.Menu>
          </Dropdown> */}
          <div className="bg-white mx-1 mx-md-3 vr"></div>
          <Dropdown className="profileDropdown ms-md-2 ms-1">
            {getUserData ?
            <Dropdown.Toggle variant="link" id="dropdown-profile" className="border-0 fs-14 fw-semibold text-decoration-none p-0 text-light">
              <Image className="object-fit-cover rounded-circle" src={getUserData?getUserData.profile_pic ? getUserData.profile_pic:'https://dummyimage.com/40x40.png' :'https://dummyimage.com/40x40.png'} alt="Profile Image" width={40} height={40} />
              <span className="align-middle d-none d-md-inline-block ms-1 px-2 text-truncate mw-150">{getUserData?.name}</span>
              <FaAngleDown size={16} />
            </Dropdown.Toggle>
            :''}
            <Dropdown.Menu align="end" className="fs-14 shadow-sm">
              <Dropdown.Item as={Link} to={nameBasedProtectedRoutes.profile.path} className="fw-medium">Profile</Dropdown.Item>
              <Dropdown.Item as={Link} to={nameBasedProtectedRoutes.changePassoword.path} className="fw-medium">Change Password</Dropdown.Item>
              <Dropdown.Divider className="mb-1" />
              <Dropdown.Item className="fw-medium" onClick={logOut}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Nav>
      </Container>
    </Navbar>
  )
}
