import React, { useEffect, useState } from 'react';

import { Button, Card, Col, Form, Image, Row, Stack } from "react-bootstrap"
import { IoIosArrowBack } from "react-icons/io"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Formik } from "formik"
import { toast } from 'react-toastify';
import InputTextHorizontal from '../../../components/common/InputTextHorizontal';
import TextAreaHorizontal from '../../../components/common/TextAreaHorizontal';
import Loader from '../../../components/common/Loader';
import { EditSchema } from '../../../validations/Charity';
import { nameBasedProtectedRoutes } from '../../../router/protected';
import CustomRadioHorizontal from '../../../components/common/CustomRadioHorizontal';
import { EditEntitiyMethod } from '../../../helpers/helpersMethods';
import { CHARITY_UPDATED_SUCCESS,IMAGE_REQUIRED,IMAGE_RATIO,FILE_SIZE_500KB,FILE_TYPE } from '../../../validations/ValidationErrors';
import { edit, getCategoryList,getDetails } from '../../../services/charityService';
import CustomSelectHorizontal from '../../../components/common/CustomSelectHorizontal';
import { FaImage } from 'react-icons/fa';
import constantValues from '../../../constants/constantValues';


export const Edit = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [redirect, setRedirect] = useState<any>();
  const [submitting, setSubmitting] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [imageUrl, setImageUrl] = useState<string>('');
  const [data, setData] = useState<any>();
  const radioData = [
    { label: 'Enable', value: 'active' },
    { label: 'Disable', value: 'inactive' },
  ];
  const defaultTags: any = [];
  useEffect(() => {
    setLoading(true);
    setRedirect(nameBasedProtectedRoutes.charity.path);
    FetchCategoryList();

  }, []);
  useEffect(() => {
    if (id) {
      getDetails(id).then(
        (resp:any) => {
          const res = resp?.data;
          setData(res);
          setImageUrl('');
          if(res.asset_url_name)
          {
            setImageUrl(res.asset_url_name);
          }
          setTimeout(() => {
            setLoading(false);

          }, 1000);
        },
        (error: any) => {
          const err = error?.response?.data;
          setLoading(false);
          if (err?.statusCode === 400) {
            toast.error(err.msg);
            navigate(nameBasedProtectedRoutes.charity.path);
          }

        }
      );
    }

  }, [id]);

  const FetchCategoryList = async () => {
    try {
      const resp = await getCategoryList();
      const res = resp?.data;
      const modifiedCategoryList = res.record.map((category: any) => ({
        ...category,
        name: category.title,
      }));
      setCategoryList(modifiedCategoryList);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      const err = error?.response?.data;
      if (err?.statusCode === 400) {
        toast.error(err.msg);
        navigate(redirect);
      }
    }
  };

  const handleFileChange = (event: any,setFieldValue:any) => {
    const file = event.target.files?.[0];
    const allowedTypes = ['image/jpeg', 'image/png'];
    const maxFileSize = 500 * 1024; // 500KB in bytes
    const maxAspectRatio = 1.0; // Maximum aspect ratio for 1:1 image

    if (event.target.files && event.target.files.length > 0) {
      if (!allowedTypes.includes(file.type)) {
        toast.error(FILE_TYPE);
        return;
      }
      if (file.size > maxFileSize) {
        toast.error(FILE_SIZE_500KB);
        return;
      }
      const image: HTMLImageElement = new window.Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
        const aspectRatio = image.width / image.height;
        if (aspectRatio !== maxAspectRatio) {
          toast.error(IMAGE_RATIO);
          return;
        }
        setFieldValue('asset_file', file);
        setImageUrl(URL.createObjectURL(file));
      };
    }

  };
  /**
   * Add 
   * @date 19/05/2023 - 13:34:03
   *
   * @param {*} value
   */
  const submitForm = (value: any) =>
  {
    if (id)
    {
      toast.dismiss();
      EditEntitiyMethod(setSubmitting, edit, id, value, CHARITY_UPDATED_SUCCESS,nameBasedProtectedRoutes.charity.path , navigate);
    }
  }


  return (
    <>
      {!loading && data ? <Formik
        validationSchema={EditSchema}
        initialValues={{
          charity_title: data?data.charity_title:'',
          ein: data?data.ein:'',
          contact_person: data?data.contact_person:'',
          status: data?data.status:'active',
          description: data?data.description:'',
          country: data?data.country:'',
          category: data?data.category:'',
          asset_file:data?data.asset_file:'',
          website_url:data?data.website_url:''
        }}
        onSubmit={(values, actions) => {
          values.charity_title = values.charity_title.trim();
          values.ein = values.ein.trim();
          values.contact_person = values.contact_person.trim();
          values.description = values.description.trim();
          values.country = values.country.trim();
          values.status = values.status;
          values.category = values.category;
          values.website_url = values.website_url;
          actions.setSubmitting(false);
          submitForm(values)
        }}

      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
          setFieldValue,
        }) => (
          <Form className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1" noValidate onSubmit={handleSubmit}>
            <div className="pageHeader px-3 my-1 pt-1 user-select-none">
              <Row className="align-items-center min-h-51">
                <Col>
                  <h1 className="fw-semibold h4 my-2">
                    <Link to={redirect} className="align-items-center d-inline-flex overflow-hidden text-dark text-decoration-none">
                      <span className="me-1 ms-n1">
                        <IoIosArrowBack size="1.68rem" />
                      </span> Manage Charity
                    </Link>
                  </h1>
                </Col>
              </Row>
            </div>
            <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
              <Card className="h-100 w-100 overflow-auto border-light-subtle pageContentInner">
                <Card.Header className="bg-white fs-16 fw-semibold p-3 border-secondary">
                  <Card.Text className="px-xl-1">Edit Charity</Card.Text>
                </Card.Header>
                <Card.Body className="p-xl-4">
                  <div className="theme-form-horizontal pb-sm-1 mb-3">
                    <Row>
                      <Col sm="auto" className="left-col left-col-sm">
                        <Stack direction="horizontal" gap={3} className="justify-content-between pb-2 py-sm-1">
                          <label className="fw-semibold">Image</label>
                        </Stack>
                      </Col>
                      <Col sm="auto" className="right-col">
                        <Row className="gx-3">
                          <Col xs="auto">
                            <div className="align-items-center border d-flex h-85 justify-content-center overflow-hidden position-relative rounded text-light w-85">
                              {!imageUrl && (
                                <FaImage size={32} />
                              )}
                              {imageUrl && (
                                <Image width={85} height={85} src={imageUrl} alt="Profile Image" />
                              )}
                              
                            </div>
                          </Col>

                          <Col xs className="mw-1">
                            <Form.Group controlId="formFile" className="mb-3">
                              <Stack direction="horizontal" gap={2}>
                                <div className="position-relative">
                                  <Form.Control type="file" className="bottom-0 end-0 opacity-0 position-absolute start-0 top-0 z-1" onChange={(event)=>handleFileChange(event,setFieldValue)} />
                                  <Button className="fw-semibold fs-13 text-dark mw-90" variant="secondary">Upload</Button>
                                </div>
                                {values.asset_file  && (
                                  <Form.Text className="my-1 text-truncate" muted>{(values.asset_file as File)?.name}</Form.Text>
                                )}
                              </Stack>
                              <div className="fs-13 fst-italic fw-semibold text-dark text-opacity-50 my-1">Max 500KB (JPG & PNG)</div>
                            </Form.Group>
                            
                          </Col>
                        </Row>
                        {errors.asset_file ? <div>
                          <p className='invalid-feedback' style={{display:'block'}}>Field is Required.</p>
                        </div>:''}
                        
                      </Col>
                    </Row>
                  
                  </div>
                  <InputTextHorizontal
                    leftColSmall={true}
                    label="Name"
                    controlId="charity_title"
                    placeholder="Name"
                    touched={touched.charity_title}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.charity_title}
                    value={values.charity_title}
                  />
                  <InputTextHorizontal
                    leftColSmall={true}
                    label="EIN#"
                    controlId="ein"
                    placeholder="EIN#"
                    touched={touched.ein}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.ein}
                    value={values.ein}
                  />
                  <CustomSelectHorizontal
                    leftColSmall={true}
                    label="Category"
                    controlId="category"
                    placeholder="Select"
                    touched={touched.category}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.category}
                    value={values.category}
                    data={categoryList}
                  />
                  <CustomSelectHorizontal
                    leftColSmall={true}
                    label="Country"
                    controlId="country"
                    placeholder="Select"
                    touched={touched.country}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.country}
                    value={values.country}
                    data={constantValues.Country}
                  />

                  <InputTextHorizontal
                    leftColSmall={true}
                    label="Contact Person"
                    controlId="contact_person"
                    placeholder="Contact Person"
                    touched={touched.contact_person}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.contact_person}
                    value={values.contact_person}
                  />
                   <InputTextHorizontal
                    leftColSmall={true}
                    label="URL"
                    controlId="website_url"
                    placeholder="URL"
                    touched={touched.website_url}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.website_url}
                    value={values.website_url}
                  />
                  <TextAreaHorizontal
                    leftColSmall={true}
                    controlId="description"
                    label="Description"
                    placeholder="Description"
                    touched={touched.description}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.description}
                    value={values.description}
                  />
                  <CustomRadioHorizontal
                    leftColSmall={true}
                    inline={true}
                    label="Status"
                    name="status"
                    touched={touched.status}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.status}
                    value={values.status}
                    data={radioData}
                  />


                  <div className="theme-form-horizontal py-3">
                    <Row>
                      <Col sm="auto" className="left-col left-col-sm"></Col>
                      <Col sm="auto" className="right-col">
                        <Stack direction="horizontal" gap={3}>
                          <Button className="fw-semibold fs-13 text-white mw-90 my-1" variant="primary" type="submit">Submit</Button>
                          <Link to={redirect} className="btn btn-secondary text-dark fw-semibold fs-13 mw-90 my-1">Cancel</Link>
                        </Stack>
                      </Col>
                    </Row>
                  </div>

                </Card.Body>
              </Card>
            </div>
          </Form>
        )}
      </Formik > : ''}
      <Loader isLoading={loading || submitting} />
    </>

  )
}
