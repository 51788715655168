export const USER_NAME_REQUIRED = "Username is required.";
export const EMAIL_REQUIRED = "Email address is required.";
export const PASSWORD_REQUIRED = "Password is required.";
export const CONFIRM_PASSWORD_REQUIRED = "Confirm new password is required.";
export const MINIMUM_15_CHAR = "Must be a minimum of 15 characters.";
export const MAXIMUM_30_CHAR = "Can not be a maximum than 30 characters.";
export const PASSWORD_REGEX =
  "Must contain 1 Capital letter. Must contain 1 lower case letter.Must be a minimum of 8 characters.Must be a maximum of 32 characters.Must contain at least 1 of the special characters.";
export const PASSWORD_MUST_MATCH =
  "Confirm password must match with new password.";
export const VALID_EMAIL = "Email must be a valid email.";
export const SITE_CODE_REQUIRED = "Site code is required.";
export const SITE_NAME_REQUIRED = "Site name is required.";
export const CONTACT_FIRST_NAME_REQUIRED = "Person first name is required.";
export const CONTACT_LAST_NAME_REQUIRED = "Person last name is required.";
export const PHONE_NO_REQUIRED = "Phone no is required.";
export const CHAR_MAX_10 = "Can exeed upto maximum 10 characters.";
export const CHAR_MAX_20 = "Can exeed upto maximum 20 characters.";
export const CHAR_MAX_50 = "Can exeed upto maximum 50 characters.";
export const CHAR_MAX_300 = "Can exeed upto maximum 300 characters.";
export const PHONE_NUM_LENGTH = "Phone no should have 10 characters.";
export const EMPLOYEE_CODE_REQUIRED = "Employee code is required.";
export const SITES_CODE_REQUIRED = "Sites selection is required.";
export const REQUIRED = "Field is Required.";
export const NUMERIC_10 = "Should be less than 10";
export const NUMERIC_PERCENTAGE = "Should be valid percentage";
export const CAPTCH_INVALID = "Please Enter Captcha";
export const USER_UPDATED_SUCCESS = "User Updated Successfully";
export const MINIMUM_8_CHAR = "Must be a minimum of 8 characters.";
export const MINIMUM_1_CHAR = "Must be a minimum of 1 characters.";
export const MAXIMUM_32_CHAR = "Can not be a maximum than 32 characters.";
export const MINIMUM_3_CHAR = "Must be a minimum of 3 characters.";
export const MAXIMUM_50_CHAR = "Can not be a maximum than 50 characters.";
export const PHONE_NUMBER_NOT_VALID = "Phone number is not valid";
export const PHONE_NUMBER_WITH_COUNTRY_CODE_NOT_VALID =
  "Please enter phone number with country code ex:+19999999999";
export const NETWORK_ERROR = "Something went wrong. Please try again.";
export const MAXIMUM_100_CHAR = "Can not be a maximum than 100 characters.";
export const MAXIMUM_200_CHAR = "Can not be a maximum than 200 characters.";
export const MAXIMUM_1000_CHAR = "Can not be a maximum than 1000 characters.";
export const MAXIMUM_5000_CHAR = "Can not be a maximum than 5000 characters.";
export const MAXIMUM_10000_CHAR = "Can not be a maximum than 10000 characters.";
export const RECIPIENTSELECT = "Please select Recipient.";
export const RELATIONSELECT = "Please select Relation.";
export const NEW_PASSWORD_REQUIRED = "New password is required.";
export const PROFILE_CHANGE = "Profile Change Successfully.";
export const FAQ_ORDER = "Number must be less than or equal to 99999999.";
export const FAQ_ORDER_MIN = "Number must be greater than or equal to 1.";
export const FAQ_UPDATED_SUCCESS = "FAQ Updated Successfully.";
export const CMS_UPDATED_SUCCESS = "CMS Updated Successfully.";
export const ACCEPT_TRUESTEE_REQUEST_EDIT =
  "Approved trustee can not be edited.";
export const REJECT_TRUESTEE_REQUEST_EDIT =
  "Rejected trustee can not be edited.";
export const ADS_UPDATED_SUCCESS = "Ads Added Successfully";
export const RECORD_UPDATED_SUCCESS = "Record Added Successfully";
export const ADS_PUBLISH_DONT_ALLOW_EDIT = "Publish Ads can not be edited.";
export const NUMBER_VALID = "Please enter valid number.";
export const IMAGE_REQUIRED = "Image is required.";
export const IMAGE_RATIO = "Image aspect ratio must be 1:1.";
export const FILE_SIZE_500KB =
  "File size is too large, maximum file size is 500kb";
export const FILE_SIZE_10MB =
  "File size is too large, maximum file size is 10MB";
export const FILE_TYPE = "Only JPG or PNG files are allowed";
export const CHARITY_UPDATED_SUCCESS = "Charity Added Successfully";
export const ORGAN_UPDATED_SUCCESS = "Organ Doner updated Successfully";
export const ABOUTME_UPDATED_SUCCESS = "Abput me updated Successfully";
export const VALID_URL = "Please enter valid URL.";
export const DOWNLOAD_SUCCESS = "Download Successfully";
