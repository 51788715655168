import {axios} from "../services/axios";
import { adminApi } from "../api";


export type legacyDTO = {
  title: string,
};



/**
 * Faq List
 * @date 04/05/2023 - 13:21:19
 *
 * @async
 * @param {*} params
 * @returns {unknown}
 */
export const getList = async (params:any) => {
    return await axios.get(`${adminApi.releaselegacyList}`, { params });
  };


  /**
   * Faq Edit
   * @date 04/05/2023 - 17:54:51
   *
   * @async
   * @param {string} id
   * @returns {Promise<any>}
   */
  export const getDetails = async (id: string): Promise<any> => {
    return axios.get(`${adminApi.releaselegacyDetails}/${id}`);
  };

  /**
   * Faq Edit
   * @date 04/05/2023 - 17:54:51
   *
   * @async
   * @param {string} id
   * @returns {Promise<any>}
   */
  export const edit = async (id: string,data: legacyDTO): Promise<any> => {
    return axios.patch(`${adminApi.releaselegacyUpdate}/${id}`,data);
  };
 
 