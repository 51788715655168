import { Button, Col, Row, Stack } from 'react-bootstrap';
import ActionCell from '../../../../../components/common/dataGrid/ActionCell';
import DataGrid from '../../../../../components/common/dataGrid/DataGrid';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { clearDndRule, getRulesDetailsByUserId } from '../../../../../services/userService';
import { toast } from 'react-toastify';
import Loader from '../../../../../components/common/Loader';
import moment from 'moment';
import ClearModal from '../../../../../components/common/ClearModal';
import { EditEntitiyMethod } from '../../../../../helpers/helpersMethods';
import { USER_UPDATED_SUCCESS,NETWORK_ERROR } from '../../../../../validations/ValidationErrors';
import formatData from '../../../../../constants/formatData';
import storage from '../../../../../helpers/storage';

let data = [
  {
    'id': 1,
    'title': 'Frequency to check on you',
    'timeline': '',
    'action': 'action',
    'redirect_url': 'own-frequency'
  },
  {
    'id': 2,
    'title': 'After how much time do we need to contact Digital Trustees to check on you?',
    'timeline': '',
    'action': 'action',
    'redirect_url': 'trustee-frequency'
  },
  {
    'id': 3,
    'title': 'Sleep Time',
    'timeline': '',
    'action': 'action',
    'redirect_url': 'sleep-time'
  },
];

export const RulesExecuteLegacy = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [myArray, setMyArray] = useState(data);
  const [fetchdata, setFetchData] = useState<any>();
  const [loadStatus, setLoadStatus] = useState(false);
  const [sleepTime, setSleepTime] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [show, setShow] = useState(false);
  const [deletestatus, setDeletestatus] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);  
  const [releaseLegacy, setReleaseLegacy] = useState(storage.getReleaseLegacy());

  const columns = [
    {
      selector: (row: any) => row.title,
      name: 'Title',
      minWidth: '300px',
    },
    {
      selector: (row: any) => row.timeline,
      name: 'Timeline',
      width: '200px',
      when: (row: any) => row.timeline,
      style: {
        fontWeight: 'bold'
      },
    },
    {
      selector: (row: any) => row.action,
      name: 'Action',
      width: '60px',
      center: true,
      button: true,
      cell: (row: any) => {
        return <ActionCell controlId="users/rulese-execute-legacy" disabled={releaseLegacy} isEditEnable={true} redirectID={row.redirect_url} />;
      }

    },
  ];
  const fetchRulesDetails = async (id:string) => {
    try {
      setLoading(true);
      const resp = await getRulesDetailsByUserId({ 'user_id': id });
      const res = resp?.data;
      if (res) {
        setFetchData(res);
        let defaultStr = res.created_at == res.updated_at ? 'Default' : '';
        let status = false;
        data.forEach((item: any) => {
          
          if (item.title === "Frequency to check on you") {
            item.timeline = res.frequency+" ";
            switch (res.frequency_type) {
              case 'hourly':
                item.timeline += res.frequency === 1 ? 'Hour' : 'Hours';
                break;
              case 'daily':
                item.timeline += res.frequency === 1 ? 'Day' : 'Days';
                break;
              case 'weekly':
                item.timeline += res.frequency === 1 ? 'Week' : 'Weeks';
                break;
              case 'monthly':
                item.timeline += res.frequency === 1 ? 'Month' : 'Months';
                break;
              default:
                item.timeline += res.frequency_type;
                break;
            }
            item.redirect_url = 'own-frequency/' + id;
          }
          if (item.id === 2) {
            item.timeline = res.check_on_period+" ";
            switch (res.check_on_period_type) {
              case 'hourly':
                item.timeline += res.check_on_period === 1 ? 'Hour' : 'Hours';
                break;
              case 'daily':
                item.timeline += res.check_on_period === 1 ? 'Day' : 'Days';
                break;
              case 'weekly':
                item.timeline += res.check_on_period === 1 ? 'Week' : 'Weeks';
                break;
              case 'monthly':
                item.timeline += res.check_on_period === 1 ? 'Month' : 'Months';
                break;
              default:
                item.timeline += res.check_on_period_type;
                break;
            }
            
            
            item.redirect_url = 'trustee-frequency/' + id;
          }
          if (item.id === 3) {
            let startTime = '';
            let endTime = '';
            if (res.sleep_start_time && res.sleep_end_time) {
              startTime = moment('2016-10-01' + " " + res.sleep_start_time).format("hh:mm A");
              endTime = moment('2016-10-01' + " " + res.sleep_end_time).format("hh:mm A");
              setSleepTime(startTime + ' to ' + endTime);
            }
            item.timeline = sleepTime;
            item.redirect_url = 'sleep-time/' + id;
          }
        });
        setMyArray(data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      // const err = error?.response?.data;
      // if (err?.statusCode === 400) {
      //   toast.error(err.msg);
      //   navigate(`/app/users`);
      // }
    }
  };
  
  useEffect(() => {
    if (id) {
      fetchRulesDetails(id);
    }
  }, [id, sleepTime]);

  
  const GoToEditDND = (()=>{
    navigate('/app/users/rulese-execute-legacy/edit/dnd-frequency/'+id);
  })
   
    useEffect(()=>{
      if(deletestatus)
      {
        setLoading(true);
        setShow(false);
        let value = {'dnd_start_date':'','dnd_end_date':''};
        if (id)
        {
          const editEntity = async () => {
            try {
              await EditEntitiyMethod(setLoading, clearDndRule, id, value, USER_UPDATED_SUCCESS, `/app/users/view/${id}`, navigate);
              setTimeout(() => {
                fetchRulesDetails(id);
              }, 1000);  // Assuming fetchData is an asynchronous function
            } catch (error) {
              toast.error(NETWORK_ERROR);
              navigate(`/app/users/view/${id}`);
            }
          };
         
          editEntity();
          
        }
      }
     },[deletestatus]);
  return (
    <>
    {!loading && fetchdata  ?
    <div className="d-flex flex-column h-100 mw-760">
      <div className="d-flex flex-column rounded-0 dataGridListMain">
        <DataGrid
          showTitle="Rules to Execute Legacy"
          columns={columns}
          data={myArray}
          noTableHead={true}
          pagination={false}
        />
      </div>
      <div className="dndBoxMain py-2">
        <Stack direction="horizontal" gap={2}>
          <h6 className="fs-16 fw-bold my-1 me-auto">Do not Disturb</h6>
          {!releaseLegacy ? 
          <>
          {fetchdata.dnd_start_date ? 
          <Button size="sm" className="fw-semibold fs-13 text-white px-3 my-1" variant="primary" type="button" onClick={handleShow}>Clear DND</Button>
          :<Button onClick={GoToEditDND} size="sm" className="fw-semibold fs-13 text-white px-3 my-1" variant="primary" type="submit" >{fetchdata.dnd_start_date ? 'Edit':'Add'} DND</Button>}
          </>
          :''}
          
        </Stack>
        <Row>
          <Col sm={4} md={3}>
            <label>Start Date</label>
            <div className="fw-bold mt-2">{fetchdata.dnd_start_date?moment(fetchdata.dnd_start_date).format(formatData.dateFormats.commaSeparatedDateTime):'-'} {fetchdata.dnd_start_time ? moment(fetchdata.dnd_start_time, "HH:mm:ss").format("hh:mm A"):''}</div>
          </Col>
          <Col sm="auto" className="ms-sm-2 mt-3 mt-sm-0">
            <label>End Date</label>
            <div className="fw-bold mt-2">{fetchdata.dnd_end_date?moment(fetchdata.dnd_end_date).format(formatData.dateFormats.commaSeparatedDateTime):'-'} {fetchdata.dnd_end_time ? moment(fetchdata.dnd_end_time, "HH:mm:ss").format("hh:mm A"):''}</div>
          </Col>
        </Row>
      </div>
    </div>:
    
    <div className="d-flex flex-column h-100">
    <div className="d-flex flex-column rounded-0 dataGridListMain">
      <DataGrid
        showTitle="Rules to Execute Legacy"
        columns={[]}
        data={[]}
        noTableHead={true}
        pagination={false}
      />
    </div>
  </div>
    }
    <ClearModal show={show} handleClose={handleClose} setDeletestatus={setDeletestatus} messageText ="Are you sure want to clear DND?"/>
    <Loader isLoading={loading} /></>
  )
}
