import * as yup from "yup";
import {
  MAXIMUM_200_CHAR,
  MINIMUM_8_CHAR,
  REQUIRED,
  MAXIMUM_10000_CHAR,
} from "./ValidationErrors";

/**
 * Profile Schema for Validation
 * @date 4/19/2023 - 9:26:15 AM
 *
 * @type {*}
 */

export const CreateSchema = yup.object().shape({
  title: yup.string().max(200, MAXIMUM_200_CHAR).required(REQUIRED),
  description: yup.string().max(5000, MAXIMUM_10000_CHAR).required(REQUIRED),
});
